import { Card } from '@etiquette-ui/misc';
import { H3 } from '@etiquette-ui/typography';
import IconLink from 'components/IconLink';
import MainHeaderLayout from 'containers/MainHeaderLayout';
import useI18n from 'i18n/useI18n';
import { useRouter } from 'providers/router';
import { useSearch } from 'providers/search';
import React, { useEffect } from 'react';
import useInactive86 from 'state/inactive86';
import useProducts from 'state/products';
import styled from 'styled-components';
import FullPageContainer from '../../containers/FullPageContainer';
import FullPageLayout from '../../containers/FullPageLayout';
import StatisticsTable from './components/StatisticsTable';
import CardContainer from 'containers/CardContainer';
import ResponsiveCard from 'containers/ResponsiveCard';
import ResponsiveTitleContainer from 'pages/MenuItems/containers/ResponsiveTitleContainer';
import FormTitle from 'pages/MenuItems/components/FormTitle';

const Actions = styled.div`
    display: flex;
    min-height: 57px;
`;

const CustomSubtitle = styled(H3)`
    text-align: center;
    font-weight: 500;
    font-size: 1.5rem;
`;

const History86 = () => {
    const { translate } = useI18n();
    const { route, canGoBack } = useRouter();
    const [, dispatcher] = useInactive86();
    const [, productsDispatcher] = useProducts();
    const { setVisible } = useSearch();

    useEffect(() => {
        dispatcher.fetchStatisticsStart();
        productsDispatcher.fetchStart();
        setVisible(true);
        return () => setVisible(false);
    }, []);

    return (
        <MainHeaderLayout>
            <FullPageContainer>
                <FullPageLayout>
                    <IconLink to={canGoBack() ? route.from : '/menu/items/management-86'}>{translate("Back to 86's")}</IconLink>

                    <ResponsiveTitleContainer>
                        <FormTitle>{translate("86's Items History")}</FormTitle>
                    </ResponsiveTitleContainer>

                    <div style={{ minHeight: '18px' }} />

                    <CardContainer>
                        <ResponsiveCard>
                            <CustomSubtitle>{translate("86's Items History")}</CustomSubtitle>
                            <div style={{ height: '30px' }} />
                            <StatisticsTable />
                        </ResponsiveCard>
                    </CardContainer>
                </FullPageLayout>
            </FullPageContainer>
        </MainHeaderLayout>
    );
};

export default History86;
