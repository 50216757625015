import ArrangeButton from 'components/Buttons/ArrangeButton';
import styled from 'styled-components';

const FloatingArrangeButton = styled(ArrangeButton)`
    display: none;
    position: fixed;
    bottom: 80px;
    left: 25px;
    height: 48px;
    @media (max-width: 768px) {
        display: block;
    }
`;

export default FloatingArrangeButton;
