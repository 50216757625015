export const filterByCategory = (item, selectedCategories, selectedSubcategories, categoriesOptions) => {
    const categoriesMap = categoriesOptions.reduce((acc, curr) => ({ ...acc, [curr.value]: true }), {});
    if (selectedCategories.length === 1 &&
        selectedCategories[0].value === -1
    ) {
        if (selectedSubcategories.length === 1 &&
            selectedSubcategories[0].value === -1) return true;

        if (selectedSubcategories.length === 1 &&
            selectedSubcategories[0].value === -2) {
            return item.categories.every((c) => categoriesMap[c.category] === true);
        }

        return item.categories.some((c) => selectedSubcategories.some(({ value }) => +value === +c.category));
    }

    if (selectedSubcategories.length === 1 &&
        selectedSubcategories[0].value === -1) {
        return item.categories.some((c) =>
            selectedCategories.some(({ subcategories }) => subcategories.some(({ id }) => +id === +c.category))
        ) || item.categories.some((c) => selectedCategories.some(({ value }) => +value === +c.category));
    }

    if (selectedSubcategories.length === 1 &&
        selectedSubcategories[0].value === -2) {
        return item.categories.some((c) => selectedCategories.some(({ value }) => +value === +c.category)) &&
            item.categories.every((c) => categoriesMap[c.category] === true);
    }

    return item.categories.some((c) => selectedSubcategories.some(({ value }) => +value === +c.category));
}

export const filterByParentCategory = (list, selectedCategories) => {
    const ids = selectedCategories.map(({ value }) => +value);
    if (ids.length === 1 && ids[0] === -1) return list;

    return list.filter(({ parentId }) => ids.includes(+parentId));
}

export const filterByStatus = (item, selectedStatus) =>
    selectedStatus && selectedStatus.value ? selectedStatus.value === item.localStatus : true
