import React, {useEffect} from 'react';
import useConfig from 'state/config';
import useI18n from 'i18n/useI18n';
import useCategories from 'state/categories';
import { Body, Item, Image } from './styles';
import { IMAGES_URL } from 'resources/constants/urls';
import { NAMES } from 'pages/Settings/resources/constants';

const getTextFromConfig = (payload, lang) => {
  if(!payload || !payload.message) return '';  
  return !!lang ? payload.message.en : payload.message.es;
}

const getCardImg = (payload) => {
  if(!payload || !payload.img) return '';

  return `${IMAGES_URL}/${payload.img}`;
}

const getLogoData = (formData, configData) => {
  const label = NAMES.logo;
  if(!!formData && formData.name === label){
    return ({
      img: !!formData.imgs.img && formData.imgs.img instanceof Object ? formData.imgs.img.dataURL : getCardImg(formData.imgs)
    });
  }
  if(!configData.map[label]) return {};

  return ({
    img: getCardImg(configData.map[label].payload)
  });
}

const getMainCardData = (formData, configData, lang) => {
  const label = NAMES.mainCard;
  if(!!formData && formData.name === label){
    return ({
      active: formData.texts.active ?? false,
      img: !!formData.imgs.img && formData.imgs.img instanceof Object ? formData.imgs.img.dataURL : getCardImg(formData.imgs),
      description: !!lang ? formData.translates.message.en : formData.translates.message.es
    });
  }
  if(!configData.map[label]) return {};

  return ({
    active: configData.map[label].payload.active ?? false,
    img: getCardImg(configData.map[label].payload),
    description: getTextFromConfig(configData.map[label].payload, lang)
  });
}

const translations = {
  en: {  caption: 'Please, select a menu'},
  es: { caption: 'Por favor, selecciona un menú'}
}

const Home = ({formData}) => {
  const [config] = useConfig();
  const {translateObject, selectedLang} = useI18n();
  const [categories, categoriesDispatcher] = useCategories();

  const logoData = getLogoData(formData, config);
  const mainCardData = getMainCardData(formData, config, selectedLang);

  useEffect(()=>{
    categoriesDispatcher.fetchStart();
  },[]);

  return (
    <Body exitsLogo={Object.keys(logoData).length > 0 && !!logoData.img}>
      <div className="header">
        <figure>
          {Object.keys(logoData).length > 0 && !!logoData.img && (
            <img src={logoData.img} alt="Logo" />
          )}
        </figure>
      </div>
      <div className="label">
        {translateObject(translations).caption}
      </div>
      <div className="grid-area">
        {categories.list.map( category => category.id !== 259 ? (
          <Item img={`${IMAGES_URL}/${category.img}`} key={category.id}>
            <div className="img"></div>
            <div className="name">
              {!!selectedLang ? category.translations.en.title : category.translations.es.title}
            </div>
          </Item>
        ) : null)}
        
        {Object.keys(mainCardData).length > 0 && !!mainCardData.active && (
          <Item isWhiteCard={true}>
            <Image src={mainCardData.img} />
            <div 
              className="description" 
              dangerouslySetInnerHTML={{ __html: mainCardData.description }}
             />
          </Item>
        )}        
      </div>
    </Body>
  )
}
export default Home;