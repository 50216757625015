import React from 'react';
import { dateFormat } from 'utils/date';
import ResponsiveText from 'components/Table/responsive/Text';

const WhyExpected = ({ item }) => {
  return (
    <div style={{ padding: '0 5px' }}>
      <ResponsiveText text={ item.why ? item.why : '-'} />
      <div style={{ height: '10px' }}></div>
      <ResponsiveText text={item.estimate ? dateFormat(item.estimate + 'T00:00:00') : '-'} />
    </div>
  );
};

export default WhyExpected;
