import React from 'react';
import styled from 'styled-components';
import SideNav from 'components/SideNav';
import MainHeaderLayout from './MainHeaderLayout';
import SettingsIcon from 'resources/img/settings-icon.svg';
import { POS_ENABLED, STATISTICS_ENABLED } from 'resources/constants/config';

const Container = styled.div`
    display: grid;
    grid-template-columns: 1fr 5fr;
    grid-gap: 20px;
    grid-template-rows: minmax(0, 100%);
    height: 100%;
    @media (max-width: 768px) {
        grid-template-columns: 1fr;
        grid-gap: 0;
        grid-template-rows: minmax(0, 1fr);
    }
`;

const SideBar = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 54px;
    width: 100%;
    order: 1;
    @media (max-width: 768px) {
        order: unset;
        margin-top: 0;
        flex-direction: row;
        height: 56px;
        position: fixed;
        bottom: 0;
    }
`;

const Content = styled.div`
    display: flex;
    height: 100%;
    padding: 16px;
    padding-bottom: 0;
    width: 100%;
    order: 2;
    overflow: hidden;
    @media (max-width: 768px) {
        order: unset;
        padding: 0;
    }
`;

const routes = () => {
    const routes = [
        { path: `/menu`, title: 'Menu Items', icon: 'item' }        
    ];
    if (STATISTICS_ENABLED) routes.push( { path: `/statistics`, title: 'Statistics', icon: 'expiration' },);
    if (POS_ENABLED) routes.push({ path: `/id-checkup`, title: 'ID Checkup', icon: 'list' });
    routes.push({ path: `/settings`, title: 'Settings', imgIcon: SettingsIcon })
    return routes;
};

const MainLayout = ({ location, children }) => {
    return (
        <MainHeaderLayout>
            <Container>
                <Content>{children}</Content>
                <SideBar>
                    <SideNav routes={routes()} current={location.pathname} />
                </SideBar>
            </Container>
        </MainHeaderLayout>
    );
};

export default MainLayout;
