import { DEFAULT_FETCHING_STATE, DEFAULT_NO_ERROR } from 'state/constants';
import Types from './types';

const INITIAL_STATE = {
    map: {},
    fetching: { state: false },
    config: {},
    error: { ...DEFAULT_NO_ERROR },
    isUpdatingState: false
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case Types.FETCH_START:
            return { ...state, fetching: { state: true } };

        case Types.FETCH_SUCCESS:
            return { ...state, map: action.payload, fetching: { ...DEFAULT_FETCHING_STATE }, error: { ...DEFAULT_NO_ERROR } };

        case Types.FETCH_ERROR:
            return { ...state, fetching: { ...DEFAULT_FETCHING_STATE }, error: { timestamp: Date.now(), message: action.payload } };

        case Types.UPDATE_START:
            return { ...state, fetching: { state: true }, config: { id: action.payload.id ?? null}, isUpdatingState: true };

        case Types.UPDATE_SUCCESS: {
            const map = { ...state.map };
            let payload = {};
            let isPrivate = action.payload.isPrivate ?? false;
            for (const [key,value] of Object.entries(action.payload.payload)) {
                if(key === 'name' ) continue;
                payload[key] = value;            }
            map[action.payload.name] = { payload , isPrivate };
            return { ...state, map, fetching: { ...DEFAULT_FETCHING_STATE }, error: { ...DEFAULT_NO_ERROR }, isUpdatingState: false };
        }

        case Types.UPDATE_ERROR:
            return { ...state, fetching: { ...DEFAULT_FETCHING_STATE }, error: { timestamp: Date.now(), message: action.payload }, isUpdatingState: false };

        case Types.RESET_ERROR:
            return { ...state, error: { ...DEFAULT_NO_ERROR } };

        default:
            return state;
    }
};
