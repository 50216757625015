import { DEFAULT_FETCHING_STATE, DEFAULT_NO_ERROR } from 'state/constants';
import Types from './types';

const INITIAL_STATE = {
    fetching: { state: false },
    config: { isPercentage: true },
    isWaiting: false,
    error: { ...DEFAULT_NO_ERROR },
};

const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case Types.FETCH_START_HOSTS:
            return { ...state, isWaiting: true, fetching: { state: true } };

        case Types.FETCH_SUCCESS_HOSTS:
            return { ...state, hosts: action.payload, isWaiting: false, fetching: { ...DEFAULT_FETCHING_STATE }, error: { ...DEFAULT_NO_ERROR } };

        case Types.FETCH_ERROR_HOSTS:
            return { ...state, isWaiting: false, fetching: { ...DEFAULT_FETCHING_STATE }, error: { timestamp: Date.now(), message: action.payload } };

        case Types.FETCH_START_SERVERS:
            return { ...state, isWaiting: true, fetching: { state: true } };

        case Types.FETCH_SUCCESS_SERVERS:
            return { ...state, servers: action.payload, isWaiting: false, fetching: { ...DEFAULT_FETCHING_STATE }, error: { ...DEFAULT_NO_ERROR } };

        case Types.FETCH_ERROR_SERVERS:
            return { ...state, isWaiting: false, fetching: { ...DEFAULT_FETCHING_STATE }, error: { timestamp: Date.now(), message: action.payload } };

        case Types.FETCH_START_MANAGERS:
            return { ...state, isWaiting: true, fetching: { state: true } };

        case Types.FETCH_SUCCESS_MANAGERS:
            return { ...state, managers: action.payload, isWaiting: false, fetching: { ...DEFAULT_FETCHING_STATE }, error: { ...DEFAULT_NO_ERROR } };

        case Types.FETCH_ERROR_MANAGERS:
            return { ...state, isWaiting: false, fetching: { ...DEFAULT_FETCHING_STATE }, error: { timestamp: Date.now(), message: action.payload } };

        case Types.FETCH_START_ITEMS_BY_PERIOD:
            return { ...state, isWaiting: true, fetching: { state: true } };

        case Types.FETCH_SUCCESS_ITEMS_BY_PERIOD:
            return { ...state, itemsData: action.payload.data, isWaiting: false, fetching: { ...DEFAULT_FETCHING_STATE }, config: { ...state.config, ...action.payload }, error: { ...DEFAULT_NO_ERROR } };

        case Types.FETCH_ERROR_ITEMS_BY_PERIOD:
            return { ...state, isWaiting: false, fetching: { ...DEFAULT_FETCHING_STATE }, error: { timestamp: Date.now(), message: action.payload } };

        case Types.FETCH_START_ITEMS_BY_BULK:
            return { ...state, isWaiting: true, fetching: { state: true } };

        case Types.FETCH_SUCCESS_ITEMS_BY_BULK:
            return { ...state, itemsData: action.payload.data, isWaiting: false, fetching: { ...DEFAULT_FETCHING_STATE }, config: { report: action.payload.report, category: action.payload.category }, error: { ...DEFAULT_NO_ERROR } };

        case Types.FETCH_ERROR_ITEMS_BY_BULK:
            return { ...state, isWaiting: false, fetching: { ...DEFAULT_FETCHING_STATE }, error: { timestamp: Date.now, message: action.payload } };

        case Types.SET_PERCENTAGE:
            return { ...state, config: { ...state.config, isPercentage: action.payload } };

        default:
            return state;
    }
}

export default reducer;
