import React from "react";
import { formatMoney } from "utils/text";
import { PriceCell, Container, Input, Cancel, PricesContainer } from "./styles";
import ResponsiveText from "components/Table/responsive/Text";
import useI18n from "i18n/useI18n";
import ReactTooltip from "react-tooltip";
import { useEditPrices } from "../../pages/List/providers/EditPricesProvider";

const cleanInputNumber = (value = "") => {
    return value.replace(/[^\d.]/g, "");
};

const Price = ({ item, style={}, isEditable = true }) => {
    const { selectedLang } = useI18n();
    const {
        hovers,
        registerVariantHover,
        unregisterVariantHover,
        focus,
        registerVariantFocus,
        unregisterVariantFocus,
        newPrices,
        editVariantPrice,
    } = useEditPrices();

    const onChange = (variant, product, value) => {
        const cleanValue = +cleanInputNumber(value);

        if (!isNaN(cleanValue)) {
            if (cleanValue === 0) {
                editVariantPrice(variant, product, "");
            } else {
                editVariantPrice(variant, product, cleanValue);
            }
        }
    };

    return (
        <PricesContainer>
            {item.variants.map((variant) => (
                <Container key={variant.id}>
                    <Input>
                        {item.config?.marketPrice ? (
                            <span className="price-tag">MKT</span>
                        ) : hovers[variant.id] ||
                          focus[variant.id] ||
                          (newPrices[variant.id] &&
                              variant.price !== newPrices[variant.id].price) ? (
                            <>
                                <span className="money-symbol">$</span>
                                <PriceCell
                                    value={newPrices[variant.id]?.price ?? variant.price}
                                    onChange={(e) => {
                                        onChange(variant, item, e.target.value);
                                    }}
                                    onFocus={() => isEditable ?  registerVariantFocus(variant.id) : null}
                                    onBlur={() => isEditable ? unregisterVariantFocus(variant.id) : null}
                                    onMouseLeave={() => isEditable ? unregisterVariantHover(variant.id) : null}
                                />
                                {newPrices[variant.id] && isEditable &&
                                    variant.price !== newPrices[variant.id].price && (
                                        <Cancel
                                            onClick={() =>
                                                editVariantPrice(variant, item, variant.price)
                                            }
                                        />
                                    )}
                            </>
                        ) : (
                            <div
                                style={{ padding: 3 }}
                                onMouseEnter={() => isEditable ? registerVariantHover(variant.id) : null}
                            >
                                <span className="price-tag">{formatMoney(variant.price)}</span>
                            </div>
                        )}
                    </Input>
                    {!!variant.translations && (
                        <div data-tip data-for={`var:${variant.id}`}>
                            <ResponsiveText
                                text={
                                    selectedLang === 1
                                        ? variant.translations.en
                                        : variant.translations.es
                                }
                                style={style}
                            />
                            <ReactTooltip id={`var:${variant.id}`} place="top" effect="solid">
                                {selectedLang === 1
                                    ? variant.translations.en
                                    : variant.translations.es}
                            </ReactTooltip>
                        </div>
                    )}
                </Container>
            ))}
        </PricesContainer>
    );
};

export default React.memo(Price);
