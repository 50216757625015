import { takeLatest, takeEvery, put, all, call } from 'redux-saga/effects';
import { categoriesAPI } from 'resources/api';
import { processError } from 'state/utils';
import { toFormData } from 'utils';
import Types from './types';
import { goBack } from 'connected-react-router';

function* fetchStartAsync() {
    try {
        const res = yield categoriesAPI.getAll();
        yield put({ type: Types.FETCH_SUCCESS, payload: res });
    } catch (error) {
        const message = processError(error);
        console.error(message);
        yield put({ type: Types.FETCH_ERROR, payload: message });
    }
}

function* fetchStart() {
    yield takeLatest(Types.FETCH_START, fetchStartAsync);
}

function* updateStartAsync({ payload }) {
    try {
        const { listKey, item, goBack: shouldGoBack, fetchAll } = payload;
        const form = toFormData(item);
        yield categoriesAPI.update(form);
        yield put({ type: Types.UPDATE_SUCCESS, payload: {item, listKey} });
        if (shouldGoBack) yield put(goBack());
        if (fetchAll) yield put({ type: Types.FETCH_START, payload: {} });
    } catch (error) {
        const message = processError(error);
        console.error(message);
        yield put({ type: Types.UPDATE_ERROR, payload: message });
    }
}

function* updateStart() {
    yield takeEvery(Types.UPDATE_START, updateStartAsync);
}

function* createStartAsync({ payload }) {
    const { listKey, item, goBack: shouldGoBack } = payload;

    try {
        const form = toFormData(item);
        yield categoriesAPI.create(form);
        yield put({ type: Types.CREATE_SUCCESS, payload: {item, listKey} });
        if (shouldGoBack) yield put(goBack());
    } catch (error) {
        const message = processError(error);
        console.error(message);
        yield put({ type: Types.CREATE_ERROR, payload: message });
    }
}

function* createStart() {
    yield takeLatest(Types.CREATE_START, createStartAsync);
}

function* deleteStartAsync({ payload }) {
    try {
        const { item } = payload;
        yield categoriesAPI.delete(item);
        yield put({ type: Types.DELETE_SUCCESS, payload });
    } catch (error) {
        const message = processError(error);
        console.error(message);
        yield put({ type: Types.DELETE_ERROR, payload: message });
    }
}

function* deleteStart() {
    yield takeLatest(Types.DELETE_START, deleteStartAsync);
}

function* toggleActiveAsync({ payload }) {
    try {
    const { item, listKey } = payload;
      const form = toFormData(item);
      yield categoriesAPI.update(form);
      yield put({ type: Types.UPDATE_SUCCESS, payload, listKey });
      yield put({ type: Types.FETCH_START });
    } catch (error) {
      const message = processError(error);
      console.error(message);
      yield put({ type: Types.UPDATE_ERROR, payload: message });
    }
  }
  
  function* toggleActive() {
    yield takeLatest(Types.TOGGLE_ACTIVE, toggleActiveAsync);
  }

function* positionAsync({ payload }) {
    try {
        const form = toFormData(payload);
        yield categoriesAPI.update(form);
        yield put({ type: Types.FETCH_START, payload: {} });
        yield put({ type: Types.POSITION_SUCCESS});
    } catch (error) {
        const message = processError(error);
        console.error(message);
        yield put({ type: Types.POSITION_ERROR, payload: message });
    }
}

function* position() {
    yield takeLatest(Types.POSITION_START, positionAsync);
}

export default function* sagas() {
    yield all([
      call(fetchStart), 
      call(updateStart), 
      call(createStart), 
      call(deleteStart), 
      call(toggleActive),
      call(position)
    ]);
}
