import Types from './types';
export default class Dispatcher {
    constructor(dispatch) {
        this.dispatch = dispatch;
    }

    fetchStart = () => this.dispatch({ type: Types.FETCH_START, payload: {} });

    fetchHistoryStart = () => this.dispatch({ type: Types.FETCH_HISTORY_START, payload: {} });

    fetchStatisticsStart = () => this.dispatch({ type: Types.FETCH_STATISTICS_START, payload: {} });

    activateProductStart = (payload) => this.dispatch({ type: Types.ACTIVATE_PRODUCT_START, payload });

    updateProductStart = (payload) => this.dispatch({ type: Types.UPDATE_PRODUCT_START, payload });

    inactive86 = (payload) => this.dispatch({ type: Types.INACTIVE_86, payload });

    comment = (payload) => this.dispatch({ type: Types.COMMENT_START, payload });

    resetError = () => this.dispatch({ type: Types.RESET_ERROR, payload: {} });
}