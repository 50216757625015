import useI18n from 'i18n/useI18n';
import React, { useEffect, useState } from 'react';
import { ITEM_STATUS } from 'utils/status';
import Filter from 'components/Filter';
import { onSelectCategory, onSelectSubcategory } from 'pages/MenuItems/utils/events';
import {
    getProcessedCategoriesWithOptions,
    getTranslatedCategories,
    getProcessedSubcategoriesWithOptions,
} from 'pages/MenuItems/utils/transformers';
import StatusFilter from 'components/StatusFilter';
import useCategories from 'state/categories';
import { filterByCategory, filterByStatus } from 'pages/MenuItems/utils/filters';
import useWindowDimensions from 'hooks/useWindowDimensions';
import { useSearch } from 'providers/search';
import { compareStrings } from 'utils';

import styled from 'styled-components';
import useFilters from 'state/filters';

const Container = styled.div`    
    max-width: 70%;
    flex: 0 0 70%;
    display: flex;
    gap: 15px;
    margin-right: 15px;

    > div {
        min-width: 250px;
        max-width: 30%;
        flex: 0 0 30%;
    }

    @media (max-width: 768px) {
        max-width: 100%;
        flex: 0 0 100%;
        margin-right: 0px;
    }
`;

const STATUS_OPTIONS = [
    { value: null, label: 'All Status' },
    { value: ITEM_STATUS.ACTIVE, label: 'On Menu' },
    { value: ITEM_STATUS.INACTIVE86, label: "86's no stock" },
    { value: ITEM_STATUS.INACTIVE, label: 'Inactive' },
    { value: ITEM_STATUS.ARCHIVED, label: 'Archived' },
];

const restrictTextLength = (text = '', width) => {
    let maxLength = 18;
    if(width>1024) {
        maxLength = 27;
    }

    if(text.length < maxLength) return text;
    const textElements = text.split('');
    textElements.splice(maxLength-3, text.length-(maxLength-3));
    return textElements.join('') + '...'; 
}

const Filters = ({ data }) => {
    const { translate, selectedLang } = useI18n();
    const [categories] = useCategories();
    const [selectedStatus, setSelectedStatus] = useState({ ...STATUS_OPTIONS[0], label: translate(STATUS_OPTIONS[0].label) });
    const { width } = useWindowDimensions();
    const { search } = useSearch();

    const [filters, filtersDispatcher] = useFilters();
    
    useEffect(() => {
        const processed = getProcessedCategoriesWithOptions(categories);
        filtersDispatcher.itemsSetCategoriesOptions(processed);
    }, [categories.list, categories.subcategories]);

    useEffect(() => {
        if (filters.items.selectedCategories.length === 1 && filters.items.selectedCategories[0].value === -1) {
            filtersDispatcher.itemsSetSubcategoriesOptions(getProcessedSubcategoriesWithOptions(categories.subcategories));
            return;
        }
        const reduced = filters.items.selectedCategories.reduce((acc, curr) => [...acc, ...curr.subcategories], []);
        filtersDispatcher.itemsSetSubcategoriesOptions(getProcessedSubcategoriesWithOptions(reduced));
    }, [filters.items.selectedCategories, categories.subcategories]);

    useEffect(() => {
        const newList = data.list
            .filter((p) => filterByCategory(p, filters.items.selectedCategories, filters.items.selectedSubcategories, filters.items.categoriesOptions))
            .filter((p) => filterByStatus(p, selectedStatus))
            .filter(
                ({ translations }) => 
                Object.values(translations).some(({ title, description }) => compareStrings(title ?? '', search) || compareStrings(description ?? '', search))
            );

            filtersDispatcher.itemsSetList(newList);
    }, [data.list, filters.items.selectedCategories, filters.items.selectedSubcategories, selectedStatus, search]);

    return (
        <Container>
            <Filter
                labelProps={{ label: translate('Category') }}
                selectProps={{
                    onChange: onSelectCategory(filters.items.selectedCategories, filtersDispatcher.itemsSetSelectedCategories),
                    options: getTranslatedCategories(filters.items.categoriesOptions, translate, selectedLang),
                    placeholder: restrictTextLength(filters.items.selectedCategories.map((cat) => cat.label).join(' | '), width),
                }}
                selectedValues={filters.items.selectedCategories}
                containerProps={{styles:{
                    height: 'fit-content'
                }}}
            />
            <Filter
                labelProps={{ label: translate('Subcategory') }}
                selectProps={{
                    onChange: onSelectSubcategory(filters.items.selectedSubcategories, filtersDispatcher.itemsSetSelectedSubcategories),
                    options: getTranslatedCategories(filters.items.subcategoriesOptions, translate, selectedLang),
                    placeholder: restrictTextLength(filters.items.selectedSubcategories.map((cat) => cat.label).join(' | '), width),
                }}
                selectedValues={filters.items.selectedSubcategories}
                containerProps={{styles:{
                    height: 'fit-content'
                }}}
            />
            <StatusFilter
                labelProps={{ label: translate('Status') }}
                selectProps={{
                    onChange: (value) => {
                        setSelectedStatus(value);
                    },
                    options: STATUS_OPTIONS.map((opt) => ({ ...opt, label: translate(opt.label) })),
                    placeholder: translate(''),
                    value: selectedStatus,
                }}
                containerProps={{styles:{
                    height: 'fit-content'
                }}}
            />
        </Container>
    );
};

export default Filters;
