export default class Utils {
    constructor(state, dispatcher) {
        this.state = state;
        this.dispatcher = dispatcher;
    }
    
    findOne(id){
        return this.state.list.find(e => +e.id === +id);
    }
    
    findSubcategory(id){
        return this.state.subcategories.find(e => +e.id === +id);
    }
}