export const ITEM_STATUS = {
  ACTIVE: 'active',
  ARCHIVED: 'archived',
  INACTIVE: 'inactive',
  INACTIVE86: 'inactive-86',
};

export const getItemStatus = ({ status, stockTotal }) => {
  if (status === 'inactive') {
    return ITEM_STATUS.INACTIVE;
  }
  if (status === 'archived') {
    return ITEM_STATUS.ARCHIVED;
  }
  if (stockTotal > 0) {
    return ITEM_STATUS.ACTIVE;
  }
  return ITEM_STATUS.INACTIVE86;
};
