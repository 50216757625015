import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router';
import Archived from './pages/Archived';
import Create from './pages/Create';
import Edit from './pages/Edit';
import History86 from './pages/History86';
import List from './pages/List';
import Management86 from './pages/Management86';
import Arrange from './pages/Arrange';

const Items = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={path} component={List} />
      <Route path={`${path}/create`} component={Create} />
      <Route path={`${path}/edit/:itemId([0-9]+)`} component={Edit} />
      <Route path={`${path}/management-86`} component={Management86} />
      <Route path={`${path}/history-86`} component={History86} />
      <Route path={`${path}/archived`} component={Archived} />
      <Route path={`${path}/arrange`} component={Arrange} />
    </Switch>
  );
};

export default Items;
