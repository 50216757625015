import { PRIMARY, WRONG_LIGHT } from '@etiquette-ui/colors';
import { ArrowRightIcon } from '@etiquette-ui/icons';
import { Spinner } from '@etiquette-ui/misc';
import { SubtitleSmall } from '@etiquette-ui/typography';
import useI18n from 'i18n/useI18n';
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { ITEM_STATUS } from 'utils/status';

const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-right: 25px;
`;

const Button = styled.div`
    cursor: pointer;
    display: flex;
    align-items: center;
    p {
        color: ${({ hasError }) => (hasError ? WRONG_LIGHT : PRIMARY)};
        margin-right: 10px;
    }

    @media( max-width: 768px ){
        p { margin-right: 0; }
        svg { display: none; }
    }
`;

const Text = styled(SubtitleSmall)`
    @media (max-width: 768px) {
        font-size: 13px;
    }
`;

const StatusButton = ({ className, loading = false, item, onClick, spinnerBackground }) => {
    const [hasError, setError] = useState(false);
    const [status, setStatus] = useState('');
    const { translate } = useI18n();

    useEffect(() => {
        const itemStatus = item.localStatus;
        setError(false);
        if (itemStatus === ITEM_STATUS.ACTIVE) {
            setStatus('Active');
            return;
        }
        if (itemStatus === ITEM_STATUS.INACTIVE) {
            setStatus('Inactive');
            return;
        }
        if (itemStatus === ITEM_STATUS.ARCHIVED) {
            setStatus('Archived');
            return;
        }
        if (itemStatus === ITEM_STATUS.INACTIVE86) {
            setError(true);
            setStatus("86's no stock");
        }
    }, [item.localStatus]);

    return (
        <Container className={className ?? ''}>
            <Button onClick={onClick} hasError={hasError}>
                {loading && (
                    <Spinner
                        style={{
                            '--color': PRIMARY,
                            '--bg': spinnerBackground ?? 'white',
                            marginRight: '10px',
                        }}
                    />
                )}
                <Text>{translate(status)}</Text>
                <ArrowRightIcon
                    styles={{
                        svg: {
                            width: '20px',
                            height: '20px',
                            marginRight: '10px',
                        },
                        line: {
                            fill: 'none',
                            strokeLinecap: 'round',
                            strokeLinejoin: 'round',
                            strokeWidth: '1.5px',
                            stroke: hasError ? WRONG_LIGHT : PRIMARY,
                        },
                    }}
                />
            </Button>
        </Container>
    );
};

export default StatusButton;
