import ES from "./es"
import EN from "./en"

const VAR_REGEX = /%[A-Za-z0-9-_ ]*%/g;

const hasVariables = (string) => {
    return [...string.matchAll(VAR_REGEX)].length!==0;
}

export const getTranslation = (key, vars = {}, lang) => {
    const dictionary = lang === 1 ? EN : ES;

    if(!dictionary[key]){ 
        console.info("TRANSLATION NOT FOUND FOR: ", `'${key}'`);
        return `**${key}**`;
    }

    if(!hasVariables(dictionary[key])) return dictionary[key];

    return dictionary[key].replaceAll(VAR_REGEX, (match)=> vars[match.replaceAll('%','')] ?? '');
}

export const processTranslations = (value, lang) => {
    const langKey = langs[lang];

    return {...value[langKey]};
}

export const langs = ['es', 'en'];