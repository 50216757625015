import React from 'react';
import { Subtitle } from '@etiquette-ui/typography';
import FormSpacer from 'components/Form/FormSpacer';
import useI18n from 'i18n/useI18n';
import FloatingButton from '../../FloatingButton';
import Family from './Family';
import Categories from './Categories';
import Subcategories from './Subcategories';
import Tags from './Tags';
import FormTitle from 'pages/MenuItems/components/FormTitle';

const Groups = ({ formData, dispatch, next, title }) => {
  const { translate } = useI18n();

  const submit = (e) => {
    e.preventDefault();
    next();
  }
  
  return (
    <form style={{ width: '100%' }} onSubmit={submit}>
      <FormTitle style={{ marginBottom: '20px', fontSize: '1.5rem' }}>{title}</FormTitle>
      <Family formData={formData} dispatch={dispatch} />
      <FormSpacer />
      <Categories formData={formData} dispatch={dispatch} />
      <FormSpacer />
      <Subcategories formData={formData} dispatch={dispatch} />
      <Tags formData={formData} dispatch={dispatch} />
      <FormSpacer />      
      <FormSpacer />
      <FloatingButton type={'submit'}>{translate('Continue')}</FloatingButton>
    </form>
  );
};

export default Groups;