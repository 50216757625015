import styled from 'styled-components';

const FormSpacer = styled.div`
  height: 32px;

  @media( max-width: 768px ){
    height: 25px;
    background-color: white;
    margin-top: -1px;
  }
`;

export default FormSpacer;
