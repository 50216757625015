import React, {useState} from 'react';
import MainLayout from 'containers/MainLayout';
import BaseMenuTabsContainer from 'components/BaseMenuTabsContainer';
import { Route, Switch, useRouteMatch, Redirect } from 'react-router';
import OutOfStockMessage from './pages/OutOfStockMessage';
import RestaurantLogo from './pages/RestaurantLogo';
import MainPageCard from './pages/MainPageCard';
import Promotion from './pages/Promotion';
import PreviewButton from './components/Previews';

const routes = [
  { path: `/main-page-card`, title: 'Main Page Card' },
  { path: `/out-of-stock-message`, title: 'Out Of Stock Message' },
  { path: `/promotion`, title: 'Promotion' },
  { path: `/restaurant-logo`, title: 'Restaurant Logo' },
];

const Settings = ({ location }) => {

  const { path } = useRouteMatch();

  const [previewSettings, setPreviewSetting] = useState({
    bodyType: 'home',
    showPromoModal: false,
    formData: undefined
  });
    
  return (
    <MainLayout location={location}>
      <BaseMenuTabsContainer
        location={location}
        routes={routes}
        isExtendedNavbar={true}      
        navbarItems={<PreviewButton  {...previewSettings}/>}
      >
        <Switch>
          <Route exact path={path}>
            <Redirect to={`${path}/main-page-card`} />
          </Route>
          <Route path={`${path}/main-page-card`} >
            <MainPageCard setPreviewSetting={setPreviewSetting}/>
          </Route>
          <Route path={`${path}/out-of-stock-message`} >
            <OutOfStockMessage setPreviewSetting={setPreviewSetting}/>
          </Route>
          <Route path={`${path}/promotion`} >
            <Promotion setPreviewSetting={setPreviewSetting}/>
          </Route>
          <Route path={`${path}/restaurant-logo`} >
            <RestaurantLogo setPreviewSetting={setPreviewSetting}/>
          </Route>
        </Switch>
      </BaseMenuTabsContainer >
    </MainLayout>
  );
};

export default Settings;
