export default {
    FETCH_START_HOSTS: "[HOSTS] FETCH START",
    FETCH_SUCCESS_HOSTS: "[HOSTS] FETCH SUCCESS",
    FETCH_ERROR_HOSTS: "[HOSTS] FETCH ERROR",

    FETCH_START_SERVERS: "[SERVERS] FETCH START",
    FETCH_SUCCESS_SERVERS: "[SERVERS] FETCH SUCCESS",
    FETCH_ERROR_SERVERS: "[SERVERS] FETCH ERROR",

    FETCH_START_MANAGERS: "[MANAGERS] FETCH START",
    FETCH_SUCCESS_MANAGERS: "[MANAGERS] FETCH SUCCESS",
    FETCH_ERROR_MANAGERS: "[MANAGERS] FETCH ERROR",

    FETCH_START_MANAGERS: "[MANAGERS] FETCH START",
    FETCH_SUCCESS_MANAGERS: "[MANAGERS] FETCH SUCCESS",
    FETCH_ERROR_MANAGERS: "[MANAGERS] FETCH ERROR",

    FETCH_START_ITEMS_BY_PERIOD: "[ITEMS BY PERIOD] FETCH START",
    FETCH_SUCCESS_ITEMS_BY_PERIOD: "[ITEMS BY PERIOD] FETCH SUCCESS",
    FETCH_ERROR_ITEMS_BY_PERIOD: "[ITEMS BY PERIOD] FETCH ERROR",

    FETCH_START_ITEMS_BY_BULK: "[ITEMS BY BULK] FETCH START",
    FETCH_SUCCESS_ITEMS_BY_BULK: "[ITEMS BY BULK] FETCH SUCCESS",
    FETCH_ERROR_ITEMS_BY_BULK: "[ITEMS BY BULK] FETCH ERROR",

    SET_PERCENTAGE: "[ITEMS SET PERCENTAGE] SET PERCENTAGE",
};
