import React, {useEffect, useState, useMemo} from 'react';
import useI18n from 'i18n/useI18n';
import styled from 'styled-components';
import {categories} from '../utils/constants';
import { CustomDateOptions } from '../../components/CustomDateOptions';
import { Selector } from './Selects';
import useStatistics from 'state/statistics';
import { subDays } from 'date-fns'; 
import { DATE_FORMAT, dateFormat } from 'utils/date';
import { RangeDateInput } from '@etiquette-ui/inputs-dropdowns';
import { FiltersContainer, SwipeItem } from '../styles';

const FilterContainer = styled.div`
  min-width: 200px;
  max-width: 300px;
  margin-right: 10px;
  margin-bottom: 5px;

  &.is-disable{
    opacity: .5;

    &:hover, &:focus {
      opacity: 1;
    }
  }
`;

const existCategoryOption = (category, options) => {
  if(options.includes(category) ) return true;
  return false;
}

const Filters = ({}) => {
  const { translate } = useI18n();
  const reportOptions = categories.map((category)=>{
    return ({label: translate(category.label), value: category.label});
  });
  
  const [report, setReport] = useState(null);
  const [category, setCategory] = useState(null);

  const [dateRange, setDateRange] = useState([subDays(new Date(), 30), new Date()]);
  const [startDate, endDate] = dateRange;

  const [selectedYears, setSelectedYears] = useState([]);
  const [selectedMonths, setSelectedMonths] = useState([]);

  const [,dispatcher] = useStatistics();

  const categoryOptions = useMemo(()=>{
    if(!!report){
      let options = categories.find( category => category.label === report.value);
      if(!!options){
        options = options.subcategories.map( subcategory => {
          return({label: translate(subcategory), value: subcategory });
        });
        return options;
      }
    }
  },[report]);

  const periodSaved = useMemo(()=>{
    if(selectedYears.length > 0 || selectedMonths.length > 0){
      return({start: startDate, end: endDate});
    }
    return ({start:'', end: ''})
  },[selectedYears, selectedMonths]);

  const isPeriodFilterActive = useMemo(()=>{
    if(periodSaved.start === startDate && periodSaved.end === endDate){
      return false;
    }
    return true;
  },[startDate, endDate, periodSaved]);

  useEffect(()=>{
    if(!!report && !!category && existCategoryOption(category, categoryOptions)){
      if(isPeriodFilterActive){        
        dispatcher.fetchItemsByPeriod(
          report.value, 
          category.value, 
          dateFormat(startDate, DATE_FORMAT), 
          dateFormat(endDate, DATE_FORMAT)
        );
      } else {
        if(selectedYears.length > 0 && selectedMonths.length > 0){          
          dispatcher.fetchItemsByBulk(
            report.value, 
            category.value, 
            getOptionsValue(selectedYears), 
            getOptionsValue(selectedMonths)
          );
        }
      }
    }
    
  },[startDate, endDate, selectedYears, selectedMonths, category, report]);

  const getOptionsValue = (options) => {
    if(options.length === 1 && options[0].value === '-1')
      return options[0].realValue;
    
    return options.map(option => option.value).join(',');
  }

  const onChangeReportOption = (item) => {
    setReport(item);
  }

  const onChangeCategoryOption = (item) => {
    setCategory(item);
  }

  return (
    <FiltersContainer>
      <SwipeItem style={{display: 'flex'}}>
        <FilterContainer>
          <Selector
            labelProps={{ 
              label: translate('Report'),
              style:{
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                maxWidth: '100%',
                overflow: 'hidden',
              }
            }}
            selectProps={{
              options: reportOptions,
              onChange: onChangeReportOption,
              placeholder: translate('Options')
            }}
          />
        </FilterContainer>
        <FilterContainer>
          <Selector
            labelProps={{ 
              label: translate('Category'),
              style:{
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                maxWidth: '100%',
                overflow: 'hidden',
              }
            }}
            selectProps={{
              value: !!category && existCategoryOption(category, categoryOptions) ? category : null,
              options: categoryOptions,
              onChange: onChangeCategoryOption,
              placeholder: translate('Options')
            }}
          />
        </FilterContainer>
        <FilterContainer className={!isPeriodFilterActive && 'is-disable'}>
            <RangeDateInput
              labelsProps={{start: { label: translate('Start Date') }, end: { label: translate('End Date') }}}
              values={dateRange}
              calendarProps={{ onChange: setDateRange }}
            />
        </FilterContainer>
        <FilterContainer className={isPeriodFilterActive && 'is-disable'}>
          <CustomDateOptions
            labels={[
              translate('Years'),
              translate('Months')
            ]}
            selectedValues={[
              selectedYears, 
              selectedMonths
            ]}
            setSelectedValues={[
              setSelectedYears, 
              setSelectedMonths
            ]}
          />
        </FilterContainer>
      </SwipeItem>
    </FiltersContainer>
  )
}
export default Filters;
