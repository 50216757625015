import Types from './types';
export default class Dispatcher {
  constructor(dispatch) {
    this.dispatch = dispatch;
  }

  fetchStart = () => this.dispatch({ type: Types.FETCH_START, payload: {} });

  updateStart = (payload) => this.dispatch({ type: Types.UPDATE_START, payload });

  resetError = () => this.dispatch({ type: Types.RESET_ERROR, payload: {} });
}
