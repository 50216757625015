import React, { useEffect } from 'react';
import { useAlert } from '@etiquette-ui/alert-dialog';
import MenuTabsContainer from 'pages/MenuItems/containers/MenuTabsContainer';
import structure from './structure';
import useI18n from 'i18n/useI18n';
import useProducts from 'state/products';
import useCategories from 'state/categories';
import Table from 'components/Table';
import ListLayout from 'pages/MenuItems/containers/ListLayout';
import ListActionsContainer from 'pages/MenuItems/containers/ListActionsContainer';
import MainLayout from 'containers/MainLayout';
import NavbarItems from 'components/MainHeader/components/NavbarItems';
import Filters from './components/Filters';
import ResponsiveSpacer from 'pages/MenuItems/components/ResponsiveSpacer';
import CardContainer from 'containers/CardContainer';
import ResponsiveCard from 'containers/ResponsiveCard';
import FloatingArrangeButton from 'pages/MenuItems/components/FloatingArrangeButton';
import { useHistory } from 'react-router-dom';
import { useSearch } from 'providers/search';
import { withFiltersContext } from './hocs/WithFilters';
import { EditPricesProvider } from './providers/EditPricesProvider';
import ActionsContainer from './containers/ActionsContainer';

const List = ({ location }) => {
    const { translate, selectedLang } = useI18n();
    const history = useHistory();
    const [data, productsDispatcher] = useProducts();
    const [categories, categoriesDispatcher] = useCategories();
    const alert = useAlert();
    const { setVisible } = useSearch();

    useEffect(() => {
        productsDispatcher.fetchStart();
        categoriesDispatcher.fetchStart();
        setVisible(true);
        return () => setVisible(false);
    }, []);

    const arrange = () => history.push("items/arrange");

    const TableWithFitlers = withFiltersContext(Table);

    return (
        <EditPricesProvider>
            <MainLayout location={location}>
                <MenuTabsContainer
                    location={location}
                    fabLink={`${location.pathname}/create`}
                    fabText={'Add Item'}
                    navbarItems={<NavbarItems />}
                    isExtendedNavbar={true}
                >
                    <ListLayout>
                        <ListActionsContainer>
                            <Filters data={data} />
                            <ActionsContainer />
                        </ListActionsContainer>
                        <ResponsiveSpacer />
                        <CardContainer>
                            <ResponsiveCard>
                                <TableWithFitlers
                                    structure={structure}
                                    config={{
                                        translate,
                                        selectedLang,
                                        alert,
                                        categories: categories.list,
                                        subcategories: categories.subcategories,
                                        productsDispatcher,
                                        fetching: data.fetching,
                                    }}
                                />
                            </ResponsiveCard>
                        </CardContainer>
                    </ListLayout>
                </MenuTabsContainer>
                <FloatingArrangeButton onClick={arrange} />
            </MainLayout>
        </EditPricesProvider>
    );
};

export default List;
