const CATEGORY_KEYS = {
    TRANSLATIONS: 'translations',
    PARENT_ID: 'parentId',
    IMAGE: 'img',
    POSITION: 'position',
    ACTIVE: 'active',
    SUBCATEGORIES: 'subcategories',
    CONFIG: 'config',
};

export const CATEGORY_CONFIG_KEYS = {
    ICON: 'icon',
    VIEW: 'view',
    MESSAGE: 'message',
    EXPANDED: 'expanded',
    FILTER_TYPE: 'filterType',
    WITH_SELECT_ALL: 'withSelectAll',
}

export default CATEGORY_KEYS;