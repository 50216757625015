import { takeLatest, put, all, call } from 'redux-saga/effects';
import { getUsersOfGroup } from 'resources/api';
import Types from './types';

function* fetchStartAsync({payload}) {
  try {
    const list = yield getUsersOfGroup({});
    
    yield put({ type: Types.FETCH_SUCCESS, payload: list });
  } catch (error) {
    console.error(error);
    yield put({ type: Types.FETCH_ERROR, payload: `Error fetching data: ${error.response?.data?.message}` });
  }
}

function* fetchStart() {
  yield takeLatest(Types.FETCH_START, fetchStartAsync);
}

export default function* sagas() {
  yield all([
    call(fetchStart), 
  ]);
}
