import React from 'react';
import logoPNG from 'resources/img/managersite-icon.png';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { GROUP_NAME } from 'resources/constants/config';

const Logocontainer = styled.div`
  height: 64px;
  display: flex;
  align-items: center;
  padding: 8px 18px;
  box-sizing: border-box;
  @media (max-width: 992px) {
    padding: 4px;
  }
  h1,h2{
    margin: 0px;
    padding: 0px;
    padding-left: 12px;
    cursor: pointer;
    box-sizing: border-box;
    color: white;
    @media (max-width: 768px) {
      padding-left: 0;
      color: black;
    }
  }
  h1 {
    font-size: 24px;
    font-weight: 600;
    @media (max-width: 992px) {
      font-size: 20px;
      font-weight: 500;
      line-height: auto;
    }
  }
  h2 {
    font-size: 14px;
    font-weight: 300;
    margin-top: 2px;
    @media (max-width: 992px) {
      margin-top: 0px;
    }
  }
  img {
    width: 48px;
    height: 48px;
    @media (max-width: 992px) {
      width: 38px;
      height: 38px;
    }
    @media (max-width: 768px) {
      display: none;
    }
  }
`;

const Logo = () => {
  return (
    <Link to={'/'} style={{textDecoration: 'none'}}>
      <Logocontainer>
        <img src={logoPNG} alt={'managersite-logo'}/>
        <div>
          <h1>Manager Site</h1>
          <h2>{GROUP_NAME ?? ''}</h2>
        </div>
        
      </Logocontainer>
    </Link>
  );
};

export default Logo;
