import React, { createContext, useState, useEffect } from "react";
import { differenceInHours } from "date-fns";

export const Stock86Context = createContext();

const initStatus = (status) => {
  if(!!status) {
    status = JSON.parse(status);
    status.date = new Date(status.date);
    return status;
  } 
  return ({isOpen: 0, date: new Date()});
}

export const Stock86Provider = ({ children }) => {
    const [isAlertOpened, setIsAlertOpened] = useState(1);
    const [lastSeenAt, setLastSeenAt] = useState(undefined);
    
    useEffect(() => {
        const statusFromStorage = localStorage.getItem('alert-86-open-status');
        const status = initStatus(statusFromStorage);
        if(status.isOpen === 0 || differenceInHours(new Date(), status.date) >= 24){
          setIsAlertOpened(0);
        }    
        else { setLastSeenAt(status.date); }
    }, []);

    const values = {
        isAlertOpened: isAlertOpened,
        lastSeenAt: lastSeenAt,
        setIsAlertOpened: (status) => {
          const dateTime = new Date();
          setIsAlertOpened(status);
          setLastSeenAt(dateTime);
          localStorage.setItem('alert-86-open-status', JSON.stringify({isOpen:status, date: dateTime.toLocaleString()}));
        }
    }

    return (<Stock86Context.Provider value={values}>{children}</Stock86Context.Provider>);
};
