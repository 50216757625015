import React, { useMemo } from "react";
import { useHistory } from 'react-router-dom';
import useProducts from "state/products";
import ResponsiveArrangeButton from "pages/MenuItems/components/ResponsiveArrangeButton";
import EditPriceButtons from "../components/EditPriceButtons";
import { useEditPrices } from "../providers/EditPricesProvider";

const ActionsContainer = () => {
    const history = useHistory();
    const arrange = () => history.push("items/arrange");
    const [, productsDispatcher] = useProducts();
    const { newPrices, clearPrices } = useEditPrices();

    const onSave = () => {
        const changedPrices = Object.values(newPrices).filter(({price, variant})=>price !== variant.price);

        productsDispatcher.updatePrices(changedPrices);
        clearPrices();
    }

    const differentPrices = useMemo(()=>Object.values(newPrices).some(({price, variant})=>price !== variant.price), [newPrices]);

    return differentPrices ? (
        <EditPriceButtons
            newPrices={newPrices}
            onClean={clearPrices}
            onSave={onSave}
        />
    ) : (
        <ResponsiveArrangeButton onClick={arrange} />
    );
};

export default ActionsContainer;
