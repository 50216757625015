import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Icon from '@etiquette-ui/icons';

const Container = styled.div`

  width: 15px;
  height: 15px;

  position: fixed;
  top: 21px;
  left: 10px;
  z-index: 1000;

  padding-right: 10px;

  & > a {

    width: 25px;
    height: 25px;

    display: flex;
    align-items: center;

    font-size: 0px;

    & svg {
      position: absolute;
      top: 0;
      left: 0;
      width: 25px;
      height: 25px;
      filter: brightness(0);
    }
  }

  @media( min-width: 768px ){

    width: auto;
    mx-width: 50%;
    overflow: visible;
    
    position: relative;
    z-index: 1;
    top: 0px;
    left: 0px;


    & > a {
      width: 100%;
      height: auto;

      ${({ disabled }) => (disabled ? 'cursor: default; opacity: 0.2; pointer-events: none;' : '')}
      user-select: none;

      color: white;
      font-weight: 600;
      font-size: 0.875rem;
      text-decoration: none;

      & svg {
        position: relative;
        top: 0;
        left: 0;
        width: 20px;
        height: 20px;
        filter: unset;
      }
    }

    
  }
`;

const IconLink = ({ to, children, className, icon, disabled }) => {
  return (
    <Container className={className} disabled={disabled}>
      <Link to={disabled ? '#' : to}>
        <Icon
          icon={icon ?? 'arrow-left'}
          styles={{
            svg: {
              width: '20px',
              height: '20px',
              marginRight: '10px',
            },
            line: {
              fill: 'none',
              strokeLinecap: 'round',
              strokeLinejoin: 'round',
              strokeWidth: '1.5px',
              stroke: 'white',
            },
          }}
        />
        {children}
      </Link>
    </Container>
  );
};

export default IconLink;
