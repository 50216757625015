import React from 'react';
import Actions from 'components/Table/cells/Actions';
import { INPUTS } from '@etiquette-ui/colors';
import { useHistory } from 'react-router';

const RowActions = ({ item, globalState, count }) => {
  const history = useHistory();
  
  const actions = [
    {
      event: () => history.push({
        pathname: `/menu/items/edit/${item.id}`,
        state: { 
          fromArrangeList: true, 
        },
      }),
      icon: 'edit',
    },
  ];

  return (
    <Actions
      actions={actions}
      style={{ alignItems: 'start', marginTop: '10px' }}
      spinnerBackground={count % 2 === 0 ? INPUTS : 'white'}
    />
  );
};

export default RowActions;
