import { Subtitle } from '@etiquette-ui/typography';
import styled from 'styled-components';

const FormTitle = styled(Subtitle)`
  font: normal normal normal 24px/29px Montserrat;
  background: white;
  padding: 10px 0px 10px 0px;
  
  @media( min-width: 768px ){
    font-size: 24px;
    text-align: center;
  }
`;

export default FormTitle;