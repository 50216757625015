import React from 'react';
import { IMAGE_SHAPES } from '@etiquette-ui/dynamic-table-components';
import RowContainer from 'components/Table/RowContainer';
import SortText from 'components/Table/headers/SortText';
import { IMAGES_URL } from 'resources/constants/urls';
import CustomImage from 'components/Table/cells/CustomImage';
import ResponsiveHeaderText from 'components/Table/responsive/HeaderText';
import ResponsiveText from 'components/Table/responsive/Text';
import Icon from './cells/Icon';
import RowActions from './cells/Actions';

const structure = {
  row: RowContainer,
  columns: [
    {
      flex: 1,
      header: ({ globalState }) => <ResponsiveHeaderText style={{ marginLeft: '16px' }} text={globalState.config.translate('Icon')} />,
      body: Icon,
    },
    {
      flex: 1,
      header: ({ globalState }) => <ResponsiveHeaderText style={{ marginLeft: '16px' }} text={globalState.config.translate('Image')} />,
      body: ({ item }) => <CustomImage image={item.img ? `${IMAGES_URL}${item.img}` : null} shape={IMAGE_SHAPES.RECTANGLE} />,
    },
    {
      flex: 2,
      header: ({ globalState, ...rest }) => (
        <SortText property={'translations.en.title'} text={globalState.config.translate('Category (EN)')} {...rest} />
      ),
      body: ({ item }) => <ResponsiveText text={item.translations.en.title} style={{ marginLeft: '16px' }} />,
    },
    {
      flex: 2,
      header: ({ globalState, ...rest }) => (
        <SortText property={'translations.es.title'} text={globalState.config.translate('Category (ES)')} {...rest} />
      ),
      body: ({ item }) => <ResponsiveText text={item.translations.es.title} style={{ marginLeft: '16px' }} />,
    },
    {
      flex: 3,
      header: ({ globalState }) => <ResponsiveHeaderText text={globalState.config.translate('Message')} />,
      body: ({ item }) => <ResponsiveText text={item.config?.message?.translations?.en.title.replaceAll(/<\/?[^>]+(>|$)/g, '')} />,
    },
    {
      flex: 2,
      header: () => <ResponsiveHeaderText text={''} />,
      body: RowActions,
  },
  ],
};

export default structure;
