import { Card } from '@etiquette-ui/misc';
import styled from 'styled-components';

const ResponsiveCard = styled(Card)`
    height: 100%;
    @media (max-width: 768px) {
        width: fit-content;
        min-width: 900px;
        margin-right: 8px;
    }
`;

export default ResponsiveCard;
