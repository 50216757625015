const TYPE = `[INACTIVE 86]`;

export default {
    FETCH_START: `${TYPE} FETCH START`,
    FETCH_SUCCESS: `${TYPE} FETCH SUCCESS`,
    FETCH_ERROR: `${TYPE} FETCH ERROR`,

    FETCH_HISTORY_START: `${TYPE} FETCH HISTORY START`,
    FETCH_HISTORY_SUCCESS: `${TYPE} FETCH HISTORY SUCCESS`,

    FETCH_STATISTICS_START: `${TYPE} FETCH STATISTICS START`,
    FETCH_STATISTICS_SUCCESS: `${TYPE} FETCH STATISTICS SUCCESS`,

    ACTIVATE_PRODUCT_START: `${TYPE} ACTIVATE PRODUCT START`,
    ACTIVATE_PRODUCT_SUCCESS: `${TYPE} ACTIVATE PRODUCT SUCCESS`,
    ACTIVATE_PRODUCT_ERROR: `${TYPE} ACTIVATE PRODUCT ERROR`,

    UPDATE_PRODUCT_START: `${TYPE} UPDATE PRODUCT START`,
    UPDATE_PRODUCT_SUCCESS: `${TYPE} UPDATE PRODUCT SUCCESS`,
    UPDATE_PRODUCT_ERROR: `${TYPE} UPDATE PRODUCT ERROR`,

    COMMENT_START: `${TYPE} COMMENT START`,
    COMMENT_SUCCESS: `${TYPE} COMMENT SUCCESS`,
    COMMENT_ERROR: `${TYPE} COMMENT ERROR`,

    INACTIVE_86 : `${TYPE} INACTIVE 86`,

    RESET_ERROR: `${TYPE} RESET ERROR`,
}