import React from 'react';
import styled from 'styled-components';
import LogoutSVG from 'resources/img/logout-white.svg';
import { PSmall } from '@etiquette-ui/typography';
import useI18n from 'i18n/useI18n';

const Container = styled.div`
  height: 68px;
  display: flex;
  align-items: center;
  border-top: solid 1px rgba(255, 255, 255, 76);
`;

const Target = styled.div`
  display: flex;
  margin-left: 40px;
  align-items: center;
  cursor: pointer;

  p {
      color: white;
  }

`;

const Icon = styled.img`
  width: 25px;
  margin-right: 10px;
`;

const Logout = () => {
  const { translate } = useI18n();

  const logout = () => {
    localStorage.removeItem('auth');
    window.location.reload();
  };
  return (
    <Container>
      <Target onClick={logout}>
        <Icon src={LogoutSVG} alt={'logout'} />
        <PSmall>{translate('Log Out')}</PSmall>
      </Target>
    </Container>
  );
};

export default Logout;
