import MainHeaderLayout from 'containers/MainHeaderLayout';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { H3 } from '@etiquette-ui/typography';
import IconLink from 'components/IconLink';
import useI18n from 'i18n/useI18n';
import { useRouter } from 'providers/router';
import useCategories from 'state/categories';
import useProducts from 'state/products';
import Filters from './components/Filters';
import ArchivedTable from './components/ArchivedTable';
import CardContainer from 'containers/CardContainer';
import ResponsiveCard from 'containers/ResponsiveCard';
import ResponsiveTitleContainer from 'pages/MenuItems/containers/ResponsiveTitleContainer';
import FormTitle from 'pages/MenuItems/components/FormTitle';

const Container = styled.div`
    height: 100%;
    padding: 16px;
    display: grid;
    grid-template-rows: 57px 57px minmax(0, 1fr);
    grid-gap: 18px;

    @media( max-width: 768px ){
        grid-template-rows: 0px 57px minmax(0, 1fr);
        grid-gap: 15px;
        padding: 0px 0px 8px 0px;
    }
`;
const Actions = styled.div`
    display: flex;

    &.isFilter {
        @media( max-width: 768px ){
            position: relative;
            width: 100%;
            height: 90vh;
            overflow-x: scroll;
            display: grid;
            grid-template-columns: 1fr 1fr 0px;

            padding-left: 8px;
            padding-right: 8px;
        }
    }
`;

const CustomSubtitle = styled(H3)`
    text-align: center;
    position: relative;
    font-weight: 500;
    font-size: 1.5rem;
`;

const TableContainer = styled.div`
    height: 90%;
    margin-top: 30px;
`;

const Archived = () => {
    const { translate } = useI18n();
    const { route, canGoBack } = useRouter();
    const [list, setList] = useState([]);
    const [, categoriesDispatcher] = useCategories();
    const [products, productsDispatcher] = useProducts();

    useEffect(() => {
        categoriesDispatcher.fetchStart();
        productsDispatcher.fetchStart();
    }, []);

    return (
        <MainHeaderLayout>
            <Container>
                <Actions>
                    <IconLink to={canGoBack() ? route.from : '/menu/items'}>{translate('Back to Menu Items')}</IconLink>
                </Actions>

                <ResponsiveTitleContainer>
                    <FormTitle>{translate('Archived Management')}</FormTitle>
                </ResponsiveTitleContainer>

                <Actions className='isFilter'>
                    <Filters products={products.list} setList={setList} />
                    <div style={{flex: 3}}></div>
                </Actions>
                
                <CardContainer>
                    <ResponsiveCard>
                            <CustomSubtitle>{translate('Actual Archived Items')}</CustomSubtitle>
                            <TableContainer>
                                <ArchivedTable list={list} />
                            </TableContainer>                            
                    </ResponsiveCard>
                </CardContainer>
            </Container>
        </MainHeaderLayout>
    );
};

export default Archived;
