import React, { useMemo } from "react";
import useI18n from "i18n/useI18n";
import styled from "styled-components";
import Filters from "./components/Filters";
import Message from "../components/Message";

import { getStandardStructure } from "./utils";

import useStatistics from "state/statistics";
import Chart from "./components/Chart";

import Loader from "./components/Loader";

const Container = styled.div`
    position: relative;
`;

const Row = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
`;

const Pbi = ({ reference }) => {
    const [data] = useStatistics();
    const { translate, selectedLang } = useI18n();

    const chartData = useMemo(() => {
        if (!data.itemsData) return null;
        return data.itemsData;
    }, [data]);

    return (
        <Container>
            <Filters />
            <Row>
                {chartData === null && !data.isWaiting ? (
                    <Message message={translate("Please Select a Report")} />
                ) : (
                    <>
                        {!!chartData && !data.isWaiting ? (
                            chartData?.rated_total === 0 ? (
                                <Message message={translate("No data for selected period")} />
                            ) : (
                                <Chart
                                    reference={reference}
                                    report={data.config.report}
                                    category={data.config.category}
                                    data={getStandardStructure(
                                        chartData.data,
                                        data.config.report,
                                        selectedLang === 0 ? "es" : "en"
                                    )}
                                />
                            )
                        ) : (
                            <Loader />
                        )}
                    </>
                )}
            </Row>
        </Container>
    );
};
export default Pbi;
