import { DragIcon } from '@etiquette-ui/icons';
import React from 'react';

const DragHandle = () => {
    return (
        <div
            style={{
                cursor: 'pointer',
                margin: 'auto',
                userSelect: 'none',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <DragIcon
                styles={{
                    svg: {
                        width: '28px',
                    }
                    
                }}
            />
        </div>
    );
};

export default DragHandle;
