import ResponsiveText from 'components/Table/responsive/Text';
import useI18n from 'i18n/useI18n';
import React from 'react';


const Tags = ({ item }) => {

    const { translateObject } = useI18n();

    return (
        <div style={{ padding: '0 5px', display: 'flex', flexDirection: 'column', gap: '5px', justifyContent: 'center' }}>
            {(item.tags ?? []).map((tag) => (
                <>
                    <ResponsiveText text={translateObject(tag).title} />
                </>
            ))}
        </div>
    );
};

export default Tags;
