import React, { useEffect, useState } from 'react';
import { authAPI } from 'resources/api';
import { ACCESS_USER_TYPES, GROUP_ID } from 'resources/constants/config';

const AccessContext = React.createContext();

const AccessProvider = ({ children }) => {
  const [accessList, setAccessList] = useState([]);
  const [accessByGroup, setAccessByGroup] = useState({});
  const [userType, setUserType] = useState('');
  const auth = localStorage.getItem("auth");

  useEffect(()=>{
    const parsedAuth = JSON.parse(auth);
    if(parsedAuth === null) return;
    setAccessList(parsedAuth !== null ? parsedAuth.access : []);
    setUserType(parsedAuth !== null ? parsedAuth.ofType : '');
    loadAccess();
  }, [auth]);

  const loadAccess = async () => {
    const access = {};
    const res = await authAPI.getAll(GROUP_ID);
    access[+GROUP_ID] = res;
    setAccessByGroup(access);
  }

  const hasAccess = (access, type) => {
    return userType === ACCESS_USER_TYPES.ROOT || accessList.includes(access) || userType === type;
  }

  const hasAccessInGroup = (groupId, access, type) => {
    return userType === ACCESS_USER_TYPES.ROOT || (accessByGroup[+groupId] ?? []).includes(access) || userType === type;
  }

  return <AccessContext.Provider value={{hasAccess, hasAccessInGroup}}>{children}</AccessContext.Provider>;
};

export const useAccess = () => React.useContext(AccessContext);

export default AccessProvider;