import styled from 'styled-components';

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(autofill, minmax(min(300px, 50%), 1fr));
  grid-gap: 30px;
  align-items: flex-end;

  margin-botom: 150px;

  position: relative;

  @media (min-width: 768px) {
    grid-template-columns: 1fr 1fr;
  }
`;


export default GridContainer;