import { Subtitle } from '@etiquette-ui/typography';
import styled from 'styled-components';

const FormTitle = styled(Subtitle)`
    @media( max-width: 768px ){
        position: fixed;
        top: 0px;
        left: 0px;
        height: 4rem;
        width: 60%;
        padding: 21px 0px 10px 40px;
        font-size: 18px!important;
        text-align: left!important;
        background: white;
    }
`;

export default FormTitle;