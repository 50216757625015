import React, { useEffect, useState } from 'react';
import { HeaderSort, HEADER_SORT_TYPES } from '@etiquette-ui/dynamic-table-components';
import { getCategoriesFromItem, getCategoryName } from 'pages/MenuItems/utils/transformers';

const SortCategory = ({ applyFilter, resetFilters, filters, globalState, text }) => {
  const translate = globalState.config.translate;
  const selectedLang = globalState.config.selectedLang;
  const categories = globalState.config.categories;

  const [type, setType] = useState(HEADER_SORT_TYPES.UNORDER);
  const [change, setChange] = useState(false);

  useEffect(() => {
    if (Array.from(filters.keys()).length === 0 && change) {
      applyFilter('category', (items) => {
        return items.sort((a, b) => {
          if(type === HEADER_SORT_TYPES.UNORDER) return 0;

          const catsA = getCategoriesFromItem(categories, a);
          const A = getCategoryName(catsA, translate, selectedLang).toUpperCase();

          const catsB = getCategoriesFromItem(categories, b);
          const B = getCategoryName(catsB, translate, selectedLang).toUpperCase();

          if (A < B) {
            return type===HEADER_SORT_TYPES.ASC ? 1 : -1;
          }
          if (A > B) {
            return type===HEADER_SORT_TYPES.ASC ? -1 : 1;
          }
        });
      });
      setChange(false);
    }
  }, [filters]);

  const onClick = () => {
    resetFilters();

    switch(type){
      case HEADER_SORT_TYPES.DESC:
        setType(HEADER_SORT_TYPES.ASC);
        break;
      case HEADER_SORT_TYPES.ASC:
        setType(HEADER_SORT_TYPES.UNORDER);
        break;
      case HEADER_SORT_TYPES.UNORDER: 
        setType(HEADER_SORT_TYPES.DESC);
    }

    setChange(true);
  };

  return <HeaderSort 
    text={text} 
    sortType={type}
    onClick={onClick} 
    style={{ marginLeft: '16px' }}
    enabled={!Array.from(filters.keys()).includes('category')} 
  />;
};

export default SortCategory;
