import {useMemo, useReducer} from "react";
import formReducer, {INIT_STATE} from "./formReducer";

const useForm = () => {
  const [formData, formDispatcher] = useReducer(formReducer, INIT_STATE);
  const dispatch = useMemo(() => (type, payload) => formDispatcher({ type, payload }), [formDispatcher]);

  return [formData, dispatch]
}


export default useForm;


