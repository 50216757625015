import { DEFAULT_FETCHING_STATE, DEFAULT_NO_ERROR } from 'state/constants';
import Types from './types';

const INITIAL_STATE = {
  list: [],
  subcategories: [],
  fetching: { state: false },
  error: { ...DEFAULT_NO_ERROR },
  config: {},
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case Types.FETCH_START:
      return { ...state, fetching: { ...DEFAULT_FETCHING_STATE, state: true } };
    case Types.FETCH_SUCCESS:
      return { ...state, list: action.payload, fetching: { ...DEFAULT_FETCHING_STATE }, error: { ...DEFAULT_NO_ERROR } };
    case Types.FETCH_ERROR:
      return { ...state, fetching: { ...DEFAULT_FETCHING_STATE }, error: { timestamp: Date.now(), message: action.payload } };

    case Types.UPDATE_START:
      return { ...state, fetching: { state: true, config: { id: action.payload.variantId } } };
    case Types.UPDATE_SUCCESS:
      const list = [...state.list];
      const element = list.find(p => p.variantId === action.payload.variantId);
      const index = list.indexOf(element);
      if (index >= 0) {
        const { posPrice, posCode, price, posName, posId } = action.payload;
        list[index] = { ...list[index], posPrice: +posPrice, posCode, price: +price, posName, posId };
      }
      return { ...state, list, fetching: { ...DEFAULT_FETCHING_STATE }, error: { ...DEFAULT_NO_ERROR } };
    case Types.UPDATE_ERROR:
      return { ...state, fetching: { ...DEFAULT_FETCHING_STATE }, error: { timestamp: Date.now(), message: action.payload } };

    case Types.RESET_ERROR: return { ...state, error: { ...DEFAULT_NO_ERROR } }

    default:
      return state;
  }
};
