const EN = {
    "Search": "Search",
    "Log Out": "Log Out",
    "Cancel": "Cancel",
    "images_disclaimer": "Image must be PNG, JPEG or JPG under 200 Kb in file size.",
    "Maximum file size is:": "Maximum file size is: %size%",
    "Internal Server Error": "Internal Server Error",
    "Please report this to the dev team.": "Please report this to the dev team.",
    "OK": "OK",
    "Go Back": "Go Back",
    "Delete": "Delete",
    "Images from": "Images from",
    "Drop file here": "Drop file here",
    "Drop files to upload or": "Drop files to upload or",
    "Select File": "Select File",
    "Save": "Save",
    "Open Menu Website": "Open Menu Website",
    "Download": "Download",
    "Activate": "Activate",
    "Deactivate": "Deactivate",

    "Unauthorized": "Unauthorized",
    "You don't have access to this app": "You don't have access to this app",

    "Menu Items": "Menu Items",
    "Items": "Items",
    "Image": "Image",
    "Item Name": "Item Name",
    "Category": "Category",
    "Subcategory": "Subcategory",
    "Price": "Price",
    "Status": "Status",
    "Multiple Categories": "Multiple Categories",
    "Multiple Subcategories": "Multiple Subcategories",
    "Pick one...": "Pick one...",
    "All Categories": "All Categories",
    "All Subcategories": "All Subcategories",
    "Without Subcategory": "Without Subcategory",
    "Inactive": "Inactive",
    "Archived": "Archived",
    "Active": "Active",
    "86's no stock": "86's no stock",
    "Change status": "Change status",
    "Change Status": "Change Status",
    "On Menu": "On Menu",
    "Family": "Family",
    "Drinks": "Drinks",
    "Food": "Food",
    "Tags": "Tags",
    "Ingredient (EN)": "Ingredient (EN)",
    "Ingredient (ES)": "Ingredient (ES)",
    "Add Ingredient": "Add Ingredient",
    "Copy Item Data": "Copy Item Data",
    "Copy Data": "Copy Data",
    "Select where you want to arrange items": "Select where you want to arrange items",
    "Search Ingredient": "Search Ingredient",
    "86 Management": "86 Management",
    "Archived Management": "Archived Management",
    "All Status": "All Status",

    "Change to 86's no stock": "Change to 86's no stock",
    'Why is in "86\'s No Stock"?': 'Why is in "86\'s No Stock"?',
    "Reason": "Reason",
    "When do you think this item will be in stock again?": "When do you think this item will be in stock again?",

    "Back to Menu Items": "Back to Menu Items",
    "Back to Arrange List Items": "Back to Arrange List Items",
    "Back to Menu Subcategories": "Back to Menu Subcategories",
    "Back to Menu Categories": "Back to Menu Categories",
    "Basic Data": "Basic Data",
    "Group": "Group",
    "Ingredients": "Ingredients",
    "New Item": "New Item",
    "Edit Item": "Edit Item",
    "Create Item": "Create Item",
    "Update Item": "Update Item",
    "Item Details": "Item Details",
    "Add Photo": "Add Photo",
    "Edit Photo": "Edit Photo",
    "Item Name (English)": "Item Name (English)",
    "Item Name (Spanish)": "Item Name (Spanish)",
    "Description (English)": "Description (English)",
    "Description (Spanish)": "Description (Spanish)",
    "POS Code": "POS Code",
    "Item visible and active": "Item visible and active",
    "Item not visible and inactive": "Item not visible and inactive",
    "Total Stock": "Total Stock",
    "Par Stock": "Par Stock",
    "Alert Stock": "Alert Stock",
    "Description required": "Description required",
    "Description optional": "Description optional",
    "Click here to edit": "Click here to edit",
    "Click here to lock": "Click here to lock",
    "Stock": "Stock",
    "Sponsor": "Sponsor",
    "Continue": "Continue",
    "Add Logo": "Add Logo",
    "Edit Logo": "Edit Logo",
    "New Tag": "New Tag",
    "Update the code in ID Checkup": "Update the code in ID Checkup",

    "Categories": "Categories",
    "Category active": "Category active",
    "Category inactive": "Category inactive",
    "Arrange": "Arrange",
    "Icon": "Icon",
    "Category (EN)": "Category (EN)",
    "Category (ES)": "Category (ES)",
    "Message": "Message",
    "Order": "Order",
    "Icons not assigned": "Icons not assigned",
    "Icon Set Selected": "Icon Set Selected",
    "Apply Icon Set": "Apply Icon Set",
    "Add New": "Add New",

    "New Category": "New Category",
    "Edit Category": "Edit Category",
    "Create Category": "Create Category",
    "Update Category": "Update Category",
    "Back to Categories": "Back to Categories",
    "Representative image": "Representative image",
    "Details": "Details",
    "Category Name (English)": "Category Name (English)",
    "Category Name (Spanish)": "Category Name (Spanish)",
    "Message (English)": "Message (English)",
    "Message (Spanish)": "Message (Spanish)",
    "Settings": "Settings",
    "Background Color": "Background Color",
    "Default view": "Default view",
    "Tags Filter Type": "Tags Filter Type",
    "Gallery View": "Gallery View",
    "List View": "List View",
    "Accumulative": "Accumulative",
    "Unique": "Unique",
    "Expanded List": "Expanded List",
    "Minimized List": "Minimized List",
    'With "Full Menu"': 'With "Full Menu"',
    'Without "Full Menu"': 'Without "Full Menu"',
    "Representative icon": "Representative icon",
    "Delete Category": "Delete Category",
    "Are you sure you want to delete this category?": "Are you sure you want to delete this category?",
    "Are you sure you want to activate this category?": "Are you sure you want to activate this category?",
    "Are you sure you want to deactivate this category?": "Are you sure you want to deactivate this category?",

    "Subcategories": "Subcategories",
    "Subcategory active": "Subcategory active",
    "Subcategory inactive": "Subcategory inactive",
    "Delete Subcategory": "Delete Subcategory",
    "Are you sure you want to delete this subcategory?": "Are you sure you want to delete this subcategory?",
    "New Subcategory": "New Subcategory",
    "Edit Subcategory": "Edit Subcategory",
    "Create Subcategory": "Create Subcategory",
    "Update Subcategory": "Update Subcategory",
    "Assign to Category": "Assign to Category",
    "Subcategory Name (English)": "Subcategory Name (English)",
    "Subcategory Name (Spanish)": "Subcategory Name (Spanish)",
    "Back to Subcategories": "Back to Subcategories",
    "Are you sure you want to activate this subcategory?": "Are you sure you want to activate this subcategory?",
    "Are you sure you want to deactivate this subcategory?": "Are you sure you want to deactivate this subcategory?",

    "86's Verification Page": "86's Verification Page",
    "Items History": "Items History",
    "Menu Item": "Menu Item",
    "Who & When 86": "Who & When 86",
    "Why 86 & Expected restock": "Why 86 & Expected restock",
    "Today": "Today",
    "Overdue days": "Overdue %days% days",
    "How long 86": "How long 86",
    "days": "days",
    "History 86's Items": "History 86's Items",
    "Removed from": "Removed from",
    "Pending...": "Pending...",
    "Unknown User": "Unknown User",
    "Comments": "Comments",
    "Unable to add comment": "Unable to add comment",
    "Why 86's (Not on menu)": "Why 86's (Not on menu)",
    "Add Comment": "Add Comment",
    "History": "History",
    "Who 86": "Who 86",
    "When 86": "When 86",
    "Why 86": "Why 86",
    "LAST UPDATED": "LAST UPDATED",
    "PREVIOUS COMMENT": "PREVIOUS COMMENT",

    "86's Items History": "86's Items History",
    "Back to 86's": "Back to 86's",
    "86's frequency": "86's frequency",
    "Last 30 days": "Last 30 days",
    "Last 90 days": "Last 90 days",
    "Last 365 days": "Last 365 days",

    "Power BI": "Power BI",
    "Select Report": "Select Report",
    "Please Select a Report": "Please Select a Report",

    "Statistics": "Statistics",
    "Print": "Print",
    "Start Date": "Start Date",
    "End Date": "End Date",
    "Select statistic": "Select statistic",
    "Stars:": "Stars: %stars%",
    "Comments:": "Comments: %comments%",
    "Employees": "Employees",

    "Actual Archived Items": "Actual Archived Items",
    "Changed to archived": "Changed to archived",
    "Date of change": "Date of change",
    "Days archived": "Days archived",

    "ID Checkup": "ID Checkup",
    "MENU - Item Name": "MENU - Item Name",
    "POS - Item Name": "POS - Item Name",
    "MENU - Price": "MENU - Price",
    "POS - Price": "POS - Price",
    "POS ID": "POS ID",
    "Filter": "Filter",
    "All": "All",
    "Only Errors": "Only Errors",
    "Different Price": "Different Price",
    "Not Assigned": "Not Assigned",
    "POS Product Not Found": "POS Product Not Found",
    "MENU - Presentation": "MENU - Presentation",
    "Item Status": "Item Status",
    "Presentation Status": "Presentation Status",

    "Choose an option": "Choose an option",
    "Select Category": "Select Category",
    "Years": "Years",
    "Months": "Months",
    "All options": "All options",
    "stars": "Stars",
    "Percentages": "Percentages",
    "Totals": "Totals",
    "rated_total": "Rated Total",
    "Rating": "Rating",
    "Report": "Report",
    "Options": "Options",
    "No data for selected period": "No data for selected period",

    "food": "Food",
    "breakfast": "Breakfast",
    "salads": "Salads",
    "starters": "Starters",
    "entrees": "Entrees",
    "desserts": "Desserts",
    "sandwiches": "Sandwiches",
    "soups": "Soups",
    "sushi": "Sushi",

    "mixology": "Mixology",

    "server": "Server",
    "speed": "Pace",
    "server_speed_fast": "Moved fast",
    "server_speed_ok": "Ok speed",
    "server_speed_slow": "Slow",
    "delivery": "Food delivery",
    "server_delivery_perfectly": "Perfect",
    "server_delivery_adequate": "Adequate",
    "server_delivery_slow": "Slow",
    "server_delivery_noOpinion": "No opinion",
    "questions": "Knows Menu",
    "server_questions_perfectly": "Perfect",
    "server_questions_ok": "Ok",
    "server_questions_notGood": "Not good",
    "interruptions": "Interactions",
    "server_interruptions_adequate": "Comfortable interactions",
    "server_interruptions_tooMany": "Too many interruptions",
    "friendly": "Demeanor",
    "server_friendly_superFriendly": "Very friendly",
    "server_friendly_friendly": "Semi-friendly",
    "server_friendly_unfriendly": "Unfriendly",
    "attitude": "Attitude",
    "server_attitude_smiling": "Always Smiling",
    "server_attitude_notSmiling": "Smiling Enough",
    "server_attitude_frowning": "Frowning",
    "temperature": "Food temp.",
    "server_temperature_perfectly": "Perfect",
    "server_temperature_adequate": "Adequate",
    "server_temperature_incorrect": "Incorrect",
    "server_temperature_noOpinion": "No opinion",

    "hostess": "Hostess",
    "communication": "Communication",
    "hostess_communication_great": "Great",
    "hostess_communication_ok": "Ok",
    "hostess_communication_poor": "Poor",
    "hostess_friendly_superFriendly": "Super friendly",
    "hostess_friendly_friendly": "Ok",
    "hostess_friendly_unfriendly": "Unfriendly",
    "reservations": "Booked table",
    "hostess_reservations_helpful": "Very Profess.",
    "hostess_reservations_noProblem": "No Problem",
    "hostess_reservations_notHelpful": "Not Profess.",
    "hostess_reservations_noOpinion": "Online",
    "smiling": "Attitude",
    "hostess_smiling_smiling": "Smiling",
    "hostess_smiling_notSmiling": "Ok",
    "hostess_smiling_frowning": "Frowning",
    "courtesy": "Courtesy",
    "hostess_courtesy_offered": "Yes",
    "hostess_courtesy_notOffered": "No",

    "manager": "Manager",
    "manager_smiling_smiling": "Smiling",
    "manager_smiling_notSmiling": "Ok",
    "manager_smiling_frowning": "Frowning",
    "manager_friendly_superFriendly": "Super friendly",
    "manager_friendly_friendly": "Ok",
    "manager_friendly_unfriendly": "Unfriendly",
    "waiting": "Waiting time",
    "helpful": "Follow up",
    "manager_helpful_helpful": "Very Helpful",
    "manager_helpful_noHelpNeeded": "Ok",
    "manager_helpful_notHelpful": "Not helpful when needed",
    "perfection": "Perfection",
    "manager_perfection_dontRemember": "Don't Remember",
    "manager_perfection_serverInquired": "Yes Server",
    "manager_perfection_someoneInquired": "Other",
    "manager_perfection_nobodyInquired": "No one",
    "manager_perfection_managerCaptainInquired": "Yes Manager",
    "checked": "Checked",
    "Percentages": "Percentages",
    "Totals": "Totals",
    "rated_total": "Rated Total",
    "Rating": "Rating",
    "Report": "Report",
    "Options": "Options",
    "No data for selected period": "No data for selected period",
    "Dates": "Dates",
    
    'presentations': 'Presentations',
    'presentations_clarification_text': 'The presentation name is not required if only one presentation is available.',
    'add_presentation': 'Add Presentation',
    'presentation_name_en': 'Presentation Name (En)',
    'presentation_name_es': 'Presentation Name (Es)',
    'remove': 'Remove',
    'market_price_title': 'Market Price',
    'market_price_description': 'If enabled prices will be ignored and "MKT" will be displayed instead',
    
    "Preview": "Preview",
    "Main Page Card": "Main Page Card",
    "Main Page Card Settings": "Main Page Card Settings",
    "Link Destination": "Link Destination",
    "Section on Menu":"Section on Menu",
    "URL": "URL",
    "Out Of Stock Message": "Out Of Stock Message",
    "Out Of Stock Message Settings": "Out Of Stock Message Settings",
    "Promotion": "Promotion",
    "Promotion Image Settings": "Promotion Image Settings",
    "Promotion is disabled":"Promotion is disabled",
    "Restaurant Logo": "Restaurant Logo",
    "Restaurant Logo On Menu": "Restaurant Logo On Menu",
    "Message (Espanish)": "Message (Espanish)",
    "Save And Apply": "Save And Apply",
    "Saving": "Saving",
    "Image must be PNG, JPEG or JPG. We recommend portrait orientation.": "Image must be PNG, JPEG or JPG. We recommend portrait orientation.",
    "Background color": "Background color",
    "Show on Menu": "Show on Menu",
}

export default EN;