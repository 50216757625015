import { takeLatest, put, all, call } from 'redux-saga/effects';
import { tagAPI } from 'resources/api';
import { processError } from 'state/utils';
import Types from './types';

function* fetchStartAsync() {
    try {
        const res = yield tagAPI.getAll();
        yield put({ type: Types.FETCH_SUCCESS, payload: res });
    } catch (error) {
        const message = processError(error);
        console.error(message);
        yield put({ type: Types.FETCH_ERROR, payload: message });
    }
}

function* fetchStart() {
  yield takeLatest(Types.FETCH_START, fetchStartAsync);
}

function* createStartAsync({ payload }) {

    try {
        yield tagAPI.create(payload);
        yield put({ type: Types.CREATE_SUCCESS, payload: {} });
        yield put({ type: Types.FETCH_START, payload: {} });
    } catch (error) {
        const message = processError(error);
        console.error(message);
        yield put({ type: Types.CREATE_ERROR, payload: message });
    }
}

function* createStart() {
    yield takeLatest(Types.CREATE_START, createStartAsync);
}

export default function* sagas() {
    yield all([
        call(fetchStart),
        call(createStart),
    ]);
}
