import React from 'react';
import { useAccess } from 'providers/access';
import { Stock86Provider } from './provider';
import Alert from './Alert';

const Stock86Alert = ({children}) => {
  const { hasAccess } = useAccess(); 
  
  return (
    <Stock86Provider>
      <Alert/>
      {children}
    </Stock86Provider> 
  );
}
export default Stock86Alert;