import React from 'react';
import FormSection from 'containers/Form/FormSection';
import { Subtitle } from '@etiquette-ui/typography';
import useI18n from 'i18n/useI18n';
import FormSpacer from 'components/Form/FormSpacer';
import CustomGridContainer from 'containers/Form/CustomGridContainer';
import { Input } from '@etiquette-ui/inputs-dropdowns';
import ITEM_KEYS from '../keys';

const Stock = ({ formData, handleItemInfo }) => {
  const { translate } = useI18n();

  return (
    <FormSection>
      <Subtitle>{translate('Stock')}</Subtitle>
      <FormSpacer />
      <CustomGridContainer columns={3}>
        <Input
          labelProps={{ label: translate('Total Stock') }}
          iconProps={{ icon: 'pos' }}
          inputProps={{
            value: formData[ITEM_KEYS.STOCK_TOTAL],
            onChange: (e) => handleItemInfo(ITEM_KEYS.STOCK_TOTAL, e.target.value),
            type: 'number',
            required: true,
          }}
        />
        <Input
          labelProps={{ label: translate('Par Stock') }}
          iconProps={{ icon: 'par-stock' }}
          inputProps={{
            value: formData[ITEM_KEYS.STOCK_PAR],
            onChange: (e) => handleItemInfo(ITEM_KEYS.STOCK_PAR, e.target.value),
            type: 'number',
            required: true,
          }}
        />
        <Input
          labelProps={{ label: translate('Alert Stock') }}
          iconProps={{ icon: 'triangle-alert' }}
          inputProps={{
            value: formData[ITEM_KEYS.STOCK_ALERT],
            onChange: (e) => handleItemInfo(ITEM_KEYS.STOCK_ALERT, e.target.value),
            type: 'number',
            required: true,
          }}
        />
      </CustomGridContainer>
    </FormSection>
  );
};

export default Stock;
