import { INPUTS, PRIMARY, TEXT } from '@etiquette-ui/colors';
import Icon from '@etiquette-ui/icons';
import React, { useEffect } from 'react';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import styled from 'styled-components';

const CustomEditor = styled(ReactQuill)`
    width: 100%;
    height: 100%;
    font-size: 0.875rem;
    padding-left: 1.125rem;
    background-color: transparent;
    border: none;
    outline: none;
    padding-top: 0.625rem;

    &.editor {
        display: flex;
        flex-direction: column;
    }

    div div {
        background-color: ${({ customBackgroundColor }) => (customBackgroundColor ? customBackgroundColor : 'transparent')};
    }

    .ql-toolbar{
        border-top: none;
        border-left: none;
        border-right: none;
    }

    .ql-container{
        border: none;
    }

    p {
        font-size: 1rem;
    }
`;

const Container = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-start;
    background-color: ${INPUTS};
    border-radius: 8px;
    padding: 0.875rem;
    position: relative;
`;

const IconContainer = styled.div`
    width: 1.75rem;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    color: ${TEXT};
`;

const InputContainer = styled.div`
    height: 100%;
    position: relative;
    flex: 1;
`;

const Label = styled.label`
    font-weight: 500;
    position: absolute;
    padding-left: 1.125rem;
    color: ${PRIMARY};
    pointer-events: none;
    left: 0;
    top: -0.5625rem;
    font-size: 0.75rem;
`;

const modules = {
    toolbar: {
        container: [['bold', 'italic', 'underline', 'strike'], [{ color: [] }], [{ align: [] }], ['clean']],
    },
    clipboard: {
        matchVisual: false,
    },
};

const formats = ['bold', 'italic', 'underline', 'strike', 'color', 'indent', 'align'];

const WYSIWYG = ({ onChange, value, iconProps = {}, labelProps = {}, backgroundColor }) => {
    const { icon, iconStyles = {} } = iconProps;
    const { label, ...restLabelProps } = labelProps;

    useEffect(() => {
        Quill.register(Quill.import('attributors/style/align'), true);
    }, []);

    return (
        <Container>
            {icon && (
                <IconContainer>
                    <Icon icon={icon} styles={{ svg: {width: '1.75rem', height: '1.75rem'}, ...iconStyles }} />
                </IconContainer>
            )}
            <InputContainer>
                <CustomEditor
                    customBackgroundColor={backgroundColor}
                    className={'editor'}
                    theme={'snow'}
                    onChange={onChange}
                    value={value}
                    modules={modules}
                    formats={formats}
                    placeholder={''}
                    preserveWhitespace={true}
                />
                <Label {...restLabelProps}>{label}</Label>
            </InputContainer>
        </Container>
    );
};

export default WYSIWYG;
