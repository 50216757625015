import ResponsiveText from 'components/Table/responsive/Text';
import React from 'react';
import useProductHistory from 'state/productHistory';
import { millisecondsToDays } from 'utils/time';

const getDate = (i, history) => {
    const status = history.find(({productId})=> productId === i);
    if(!status) return '-';

    const when = new Date(status.when);
    const today = new Date();
    return status ? Math.round(millisecondsToDays(today.getTime() - when.getTime())) : '';
};

const Days = ({ item }) => {
    const [productHistory] = useProductHistory();

    return <ResponsiveText text={item.id ? getDate(item.id, productHistory.list) : '-'} />;
};

export default Days;
