import { SecondaryButton } from '@etiquette-ui/buttons';
import styled from 'styled-components';

export const CustomSecondaryButton = styled(SecondaryButton)`
  button {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 20px;
    margin: unset;
    height: 3rem;
    min-width: fit-content;
    font-weight: 600;
  }
`;
