import React from 'react';
import { IMAGE_SHAPES } from '@etiquette-ui/dynamic-table-components';
import RowContainer from 'components/Table/RowContainer';
import SortText from 'components/Table/headers/SortText';
import { IMAGES_URL } from 'resources/constants/urls';
import styled from 'styled-components';
import Category from '../../../cells/Category';
import Subcategory from './cells/Subcategory';
import SortNumber from 'components/Table/headers/SortNumber';
import SortCategory from '../../../cells/SortCategory';
import SortSubcategory from './cells/SortSubcategory';
import Status from '../../../cells/Status';
import Name from './cells/Name';
import ResponsiveImage from 'components/Table/responsive/Image';
import ResponsiveHeaderText from 'components/Table/responsive/HeaderText';

import Price from '../../../cells/Price/index';

const CustomImage = styled(ResponsiveImage)`
  margin-left: 16px;
`;

const structure = {
  row: RowContainer,
  columns: [
    {
      flex: 1,
      header: ({ globalState }) => <ResponsiveHeaderText style={{ marginLeft: '16px' }} text={globalState.config.translate('Image')} />,
      body: ({ item }) => <CustomImage image={item.img ? `${IMAGES_URL}${item.img}` : null} shape={IMAGE_SHAPES.RECTANGLE} />,
    },
    {
      flex: 3,
      header: ({ globalState, ...rest }) => (
        <SortText property={'translations.en.title'} text={globalState.config.translate('Item Name')} {...rest} />
      ),
      body: Name,
    },
    {
      flex: 3,
      header: ({ globalState, ...rest }) => (
        <SortCategory text={globalState.config.translate('Category')} globalState={globalState} {...rest} />
      ),
      body: Category,
    },
    {
      flex: 3,
      header: ({ globalState, ...rest }) => (
        <SortSubcategory text={globalState.config.translate('Subcategory')} globalState={globalState} {...rest} />
      ),
      body: Subcategory,
    },
    {
      flex: 3,
      header: ({ globalState, ...rest }) => (
        <SortNumber property={'price'} text={globalState.config.translate('Price')} {...rest} />
      ),
      body: Price,
    },
    {
      flex: 3,
      header: ({ globalState }) => (
        <ResponsiveHeaderText text={globalState.config.translate('Status')} style={{ justifyContent: 'flex-end', marginRight: '35px' }} />
      ),
      body: Status,
    },
  ],
};

export default structure;
