import ResponsiveText from 'components/Table/responsive/Text';
import useI18n from 'i18n/useI18n';
import React from 'react';
import useProductHistory from 'state/productHistory';
import useUsers from 'state/users';
import { getFullName } from 'utils/text';

const getUserName = (users, i, history) => {
    const status = history.find(({productId})=> productId === i);
    if(!status) return '';
    const user = users.find(({ id }) => id === status.who);
    return user ? getFullName(user) : '';
};

const Who = ({ item }) => {
    const [users] = useUsers();
    const [productHistory] = useProductHistory();
    const { translate } = useI18n();

    return <ResponsiveText text={item.id ? getUserName(users.all, item.id, productHistory.list) : translate('Pending...')} />;
};

export default Who;
