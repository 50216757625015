import React from 'react';
import styled from 'styled-components';

const MessageContainer = styled.div`
    width: 100%;
    min-height: 492px;
    height: 100%;

    display: flex;
    align-items: center;
    justify-content: center;

    font-size: 18px;
    text-align: center;
`;

const Message = ({message}) => {
  return (
    <MessageContainer>{message}</MessageContainer>
  )
}
export default Message;