
import React from 'react';
import { SecondaryButton } from '@etiquette-ui/buttons';
import styled from 'styled-components';
import useI18n from 'i18n/useI18n';
import { PrintIcon } from '@etiquette-ui/icons';


const PrintButton = ({ ...props }) => {
    const { translate } = useI18n();

    return (
        <BaseButton {...props}>
            <PrintIcon styles={{
                svg: {
                    width: '20px',
                    height: '20px',
                    marginRight: '8px'
                } 
            }} />
            {translate('Print')}
        </BaseButton>
    )
}

export const BaseButton = styled(SecondaryButton)`
    display: flex;
    justify-content: start;
    align-items: start;
    pointer-events: all;
    margin-left: auto;
    margin-right: 15px;

    button {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: unset;
        height: 50px;
        max-height: 48px;
        padding: 0 20px;
        min-width: 100%;
        font-weight: 600;
    }

    @media( max-width: 768px ){
        button { height: 54px; }
    }
`;

export default PrintButton;