import { BaseDialog } from '@etiquette-ui/alert-dialog';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import PrimaryButton, { TextButton } from '@etiquette-ui/buttons';
import useI18n from 'i18n/useI18n';
import { RadioButton } from '@etiquette-ui/selectors';
import { SECONDARY } from '@etiquette-ui/colors';
import { fetchIcons } from 'resources/api';
import { ICONS_URL } from 'resources/constants/urls';

const Buttons = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 20px;
    button {
        padding: 15px 0;
        height: unset;
    }
`;

const Dialog = styled(BaseDialog)`
    & > div {
        max-height: 95% !important;
        max-width: 654px !important;
        display: flex;
        flex-direction: column;

        & > div {
            overflow: scroll;
        }
    }
`;

const RowContainer = styled.div`
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-bottom: 20px;
`;

const IconRow = styled.div`
    display: flex;
    align-items: center;
    height: 80px;
    padding: 10px;
    border-radius: 8px;
    flex: 1;
    ${({ $selected }) => ($selected ? `background-color: ${SECONDARY};` : '')}
    overflow: scroll;
    > div {
        display: flex;
        > div {
            height: 60px;
            width: 60px;
            margin-right: 10px;
            background-color: white;
            border-radius: 8px;
            border: solid 1px ${({ $selected }) => ($selected ? 'transparent' : 'black')};
            display: flex;
            justify-content: center;
            align-items: center;
            img {
                width: 90%;
                height: 90%;
            }
        }
    }
`;

const Radio = styled(RadioButton)`
    min-width: 170px;
    max-width: 170px;
    margin-right: 20px;
`;

const SelectableIconRow = ({ title, galleryKey, gallery, setGallery }) => {
    const [icons, setIcons] = useState({});

    const asyncFetchGalleries = async () => {
        const res = await fetchIcons(galleryKey);
        setIcons(res);
    };

    useEffect(() => {
        asyncFetchGalleries();
    }, []);

    return (
        <RowContainer onClick={() => setGallery(galleryKey)}>
            <Radio checked={galleryKey === gallery}>{title}</Radio>
            <IconRow $selected={galleryKey === gallery}>
                <div>
                    {typeof icons === 'object' &&
                        Object.keys(icons).map((icon) => (
                            <div key={icon}>
                                <img src={`${ICONS_URL}${galleryKey}/${icon}`} alt={icons[icon]} />
                            </div>
                        ))}
                </div>
            </IconRow>
        </RowContainer>
    );
};

const GalleryPicker = ({ open, onSubmit, onClose, galleries, selectedGallery }) => {
    const { translate } = useI18n();
    const [gallery, setGallery] = useState(selectedGallery);

    const submit = (e) => {
        e.preventDefault();

        onSubmit(gallery);
    };

    const disabled = gallery === selectedGallery;

    return (
        <Dialog open={open} onClose={onClose} isResponsive={false} title={translate('Icon Set Selected')}>
            <div style={{ flex: 1, overflow: 'scroll' }}>
                {typeof galleries === 'object' &&
                    Object.keys(galleries).map((key) => (
                        <SelectableIconRow
                            key={key}
                            title={galleries[key]}
                            galleryKey={key}
                            gallery={gallery}
                            setGallery={setGallery}
                        />
                    ))}
            </div>
            <div style={{ height: '30px' }} />
            <Buttons>
                <TextButton onClick={onClose}>{translate('Go Back')}</TextButton>
                <PrimaryButton onClick={disabled ? () => {} : submit} disabled={disabled}>
                    {translate('Apply Icon Set')}
                </PrimaryButton>
            </Buttons>
        </Dialog>
    );
};

export default GalleryPicker;
