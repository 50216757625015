export const getCategoriesFromItem = (categories, item) => {
    const itemCategories = item.categories;
    const cats = categories.filter(
        ({ id, subcategories }) =>
            itemCategories.some(({ category }) => +id === +category) ||
            subcategories.some(({ id }) => itemCategories.some(({ category }) => +id === +category))
    );

    return cats;
};

export const getCategoryName = (itemCategories, translate, selectedLang) => {
    if (itemCategories.length === 0) return '';
    if (itemCategories.length === 1)
        return selectedLang === 1 ? itemCategories[0].translations.en.title : itemCategories[0].translations.es.title;

    return translate('Multiple Categories');
};

export const getSubcategoriesFromItem = (subcategories, item) => {
    const itemCategories = item.categories;

    return subcategories.filter(({ id }) => itemCategories.some(({ category }) => +id === +category));
};

export const getSubcategoryName = (itemCategories, translate, selectedLang) => {
    
    if (itemCategories.length === 0) return translate('Without Subcategory');

    if (itemCategories.length === 1) {
        return selectedLang === 1 ? itemCategories[0].translations.en.title : itemCategories[0].translations.es.title;
    }

    return translate('Multiple Subcategories');
};

export const getProcessedCategories = (categories) => {
    return [
        ...categories.list.map((cat) => ({
            value: cat.id,
            label: cat.translations,
            subcategories: cat.subcategories,
        })),
    ];
};

export const getProcessedCategoriesWithOptions = (categories) => {
    const processedCategories = [
        {
            value: -1,
            label: 'All Categories',
            subcategories: [],
        },
        ...getProcessedCategories(categories),
    ];
    return processedCategories;
};

export const getProcessedSubcategories = (subcategories) => {
    return [
        ...subcategories.map((cat) => ({
            value: cat.id,
            label: cat.translations,
        })),
    ];
};

export const getProcessedSubcategoriesWithOptions = (subcategories) => {
    const processedSubcategories = [
        { value: -1, label: 'All Subcategories' },
        { value: -2, label: 'Without Subcategory' },
        ...getProcessedSubcategories(subcategories),
    ];
    return processedSubcategories;
};

export const getTranslatedCategories = (categories, translate, selectedLang) => {
    return categories.map((category) => getTranslatedCategory(category, translate, selectedLang));
};

export const getTranslatedCategory = (category, translate, selectedLang) => {
    let label = '';
    if (typeof category.label === 'string') {
        label = translate(category.label);
    } else {
        label = selectedLang === 1 ? category.label?.en?.title : category.label?.es?.title;
    }
    return { ...category, label: label ? label : '' };
};
