import React from "react";
import useI18n from "i18n/useI18n";
import styled from "styled-components";
import { isCurrent } from "utils/routes";
import SideNavItem from "./SideNavItem";
import PublicMenuLink from "components/PublicMenuLink";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 90%;
  @media (max-width: 768px) {
    width: 100%;
    flex-direction: row;
    height: 100%;
    background-color: #37485f;
    border-radius: 16px 16px 0px 0px;
  }
`;

const SideNav = ({routes, current}) => {
  const { translate } = useI18n();
  return (
    <Container>
      {routes.map(({ path, title, icon, imgIcon }, i) => (
        <SideNavItem
          key={i}
          iconProps={!!icon ? {
            icon: icon
          } : {}}
          imgIcon={!!imgIcon ? imgIcon : undefined}
          linkProps={{
            content: translate(title),
            href: path
          }}
          active={isCurrent(path, current, 1)}
        />
      ))}
      <PublicMenuLink className={'onSidebar'}/>
    </Container>
  );
};

export default SideNav;
