import React from 'react';
import styled from 'styled-components';
import { PrimaryButton } from '@etiquette-ui/buttons';
import useI18n from 'i18n/useI18n';
import { Spinner } from '@etiquette-ui/misc';
import { PRIMARY } from '@etiquette-ui/colors';

const Container = styled.div`
  position: sticky;
  width: 100%;
  bottom: 0px;
  display: flex;
  align-items: center;
  justify-content: center;

  flex-direction: column;
`;

const Button = styled(PrimaryButton)`
  min-width: 330px;
  max-width: 450px;
  margin: auto;
`;

const LoadingLabel = styled.div`
  display: flex;
  font-size: 14px;
  font-weight: 600;
  color: 'white';
  justify-content: center;
`;

const UpdateButton = ({onClick, isUpdating = false}) => {
  const {translate} = useI18n();
  return (
    <Container style={{
      opacity: isUpdating ? '.5' : '1',
      pointerEvents: isUpdating ? 'none' : 'all'
      }}>       
      <Button onClick={isUpdating ? ()=>{} : onClick}>
      { isUpdating ? (
          <LoadingLabel>
            <Spinner 
              style={{
                '--color': 'white',
                '--bg': PRIMARY,
                'marginRight': '15px',
              }}
            /> {translate('Saving')}...
          </LoadingLabel>
        ) : translate("Save And Apply")
      }
      </Button>
    </Container>
  )
}
export default UpdateButton;
