import React from 'react';
import { Spinner } from '@etiquette-ui/misc';
import { dateFormat } from 'utils/date';
import Header from './components/Header';
import Table from './components/Table';
import styled from 'styled-components';
import { getData } from './utils/data';

const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 420px;
`;

const Managers = ({ data, isWaiting = false }) => {
    return (
        <>
            {!data || !!isWaiting ? (
                <Container>
                    <Spinner style={{ '--size': '120px', '--color': '#364456', '--bg': '#fff' }} />
                </Container>
            ) : (
                <>
                    {!!data &&
                        data.stats.map((element, i) => {
                            const { title, avgs, items } = getData(element, dateFormat);
                            const day = element.period.day;
                            return (
                                <>
                                    <Header title={title} avgs={avgs} />
                                    <Table header={data.shifts} items={items} withAttendance={!day} />
                                </>
                            );
                        })}
                </>
            )}
        </>
    );
};

export default Managers;
