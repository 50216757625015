const createFormObject = (object) => {
  let payload = {
    ...object.texts,
    ...object.translates
  };
  let imgs = {};
  Object.entries(object.imgs).forEach(([key, value]) => {
    if( typeof value === "object" && !!value.file && (value.file instanceof File) )
      imgs[key] = value.file;
    else payload[key] = value;
  });
  
  return ({
    payload: payload,
    ...imgs,
    hasImages: Object.keys(imgs).length > 0 ? true : false
  });
}

const getCroppedFile = async (url, name, type) => {
  const rawFile = await fetch(url)
  const blobFile = await rawFile.blob()
  const file = new File([blobFile], name, { type })
  return file;
};

const castConfigData = (data, dataMap, name) => {
  let newdata = {};

  if(Object.keys(data).length > 0){

    newdata.name =  name;
    newdata.isPrivate = data.isPrivate ?? false;
    newdata.isReadyToRender = true;
    
    for(const label in data.payload){
      const type = !!dataMap[label] ? dataMap[label].type : 'texts';
      if(!newdata[type]) newdata[type] = {};
      newdata[type][label] = data.payload[label];
    }
  }
  return newdata;
}

export{createFormObject, getCroppedFile, castConfigData};