import { PRIMARY } from '@etiquette-ui/colors';
import ResponsiveText from 'components/Table/responsive/Text';
import React from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components';

const Container = styled.div`
    margin-left: 16px;
    display: flex;
    align-items: center;
    cursor: pointer;
`;

const Link = styled(ResponsiveText)`
  color: ${PRIMARY};
  font-weight: 600;
`;

const Name = ({ item }) => {

    const history = useHistory();

  return (
    <Container onClick={()=>history.push(`/menu/items/edit/${item.id}`)}>
      <Link text={item.translations.en.title} />
    </Container>
  );
};

export default Name;
