import React from 'react';
import styled from 'styled-components';
import { BG_DARK } from '@etiquette-ui/colors';
import { Spinner } from '@etiquette-ui/misc';

const Container = styled.div`
  width: 100%;
  min-height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Loader = () => {
  return (
    <Container>
      <Spinner
        style={{
          '--color': BG_DARK,
          '--bg': 'white',
          marginRight: '10px',
        }}
      />
    </Container>
  )
}

export default Loader;
