import { takeLatest, put, all, call } from 'redux-saga/effects';
import { inactive86API, productsAPI } from 'resources/api';
import { processError } from 'state/utils';
import Types from './types';
import ProductTypes from '../products/types';

function* fetchStartAsync() {
  try {
    const res = yield inactive86API.verification();
    yield put({ type: Types.FETCH_SUCCESS, payload: res });
  } catch (error) {
    const message = processError(error);
    console.error(message);
    yield put({ type: Types.FETCH_ERROR, payload: message });
  }
}

function* fetchStart() {
  yield takeLatest(Types.FETCH_START, fetchStartAsync);
}

function* activateProductStartAsync({ payload }) {
  try {
    yield productsAPI.update(payload);
    yield put({ type: Types.ACTIVATE_PRODUCT_SUCCESS, payload });
    yield put({ type: Types.FETCH_HISTORY_START, payload: {} });
  } catch (error) {
    const message = processError(error);
    console.error(message);
    yield put({ type: Types.ACTIVATE_PRODUCT_ERROR, payload: message });
  }
}

function* activateProductStart() {
  yield takeLatest(Types.ACTIVATE_PRODUCT_START, activateProductStartAsync);
}

function* fetchHistoryStartAsync() {
  try {
    const res = yield inactive86API.history();
    yield put({ type: Types.FETCH_HISTORY_SUCCESS, payload: res });
  } catch (error) {
    const message = processError(error);
    console.error(message);
    yield put({ type: Types.FETCH_ERROR, payload: message });
  }
}

function* fetchHistoryStart() {
  yield takeLatest(Types.FETCH_HISTORY_START, fetchHistoryStartAsync);
}

function* fetchStatisticsStartAsync() {
  try {
    const res = yield inactive86API.statistics();
    yield put({ type: Types.FETCH_STATISTICS_SUCCESS, payload: res });
  } catch (error) {
    const message = processError(error);
    console.error(message);
    yield put({ type: Types.FETCH_ERROR, payload: message });
  }
}

function* fetchStatisticsStart() {
  yield takeLatest(Types.FETCH_STATISTICS_START, fetchStatisticsStartAsync);
}

function* commentStartAsync({ payload }) {
  try {
    const res = yield inactive86API.comment(payload);
    yield put({ type: Types.COMMENT_SUCCESS, payload: res });
    yield put({ type: Types.FETCH_START, payload: {} });
    yield put({ type: Types.FETCH_HISTORY_START, payload: {} });
  } catch (error) {
    const message = processError(error);
    console.error(message);
    yield put({ type: Types.COMMENT_ERROR, payload: message });
  }
}

function*  commentStart() {
  yield takeLatest(Types.COMMENT_START, commentStartAsync);
}

function* updateProductStartAsync({ payload }) {
  try {
    yield productsAPI.update(payload);
    yield put({ type: Types.FETCH_STATISTICS_START, payload: {} });
    yield put({ type: ProductTypes.FETCH_START, payload: {} });
    yield put({ type: Types.UPDATE_PRODUCT_SUCCESS, payload });
  } catch (error) {
    const message = processError(error);
    console.error(message);
    yield put({ type: Types.UPDATE_PRODUCT_ERROR, payload: message });
  }
}

function* updateProductStart() {
  yield takeLatest(Types.UPDATE_PRODUCT_START, updateProductStartAsync);
}

function* inactive86Async({ payload }) {
  try {
    yield inactive86API.inactivate(payload);
    yield put({ type: Types.UPDATE_PRODUCT_SUCCESS, payload: {} });
    yield put({ type: Types.FETCH_STATISTICS_START, payload: {} });
  } catch (error) {
    const message = processError(error);
    console.error(message);
    yield put({ type: Types.UPDATE_PRODUCT_ERROR, payload: message });
  }
}

function* inactive86() {
  yield takeLatest(Types.INACTIVE_86, inactive86Async);
}

export default function* sagas() {
    yield all([
        call(fetchStart),
        call(activateProductStart),
        call(fetchHistoryStart),
        call(fetchStatisticsStart),
        call(commentStart),
        call(updateProductStart),
        call(inactive86),
    ]);
}
