import React from 'react';
import BarChart from './bar';
import Assessments from './assessments';
import useI18n from 'i18n/useI18n';
import styled from 'styled-components';

const Title = styled.div`
  margin-top: 20px;
  margin-bottom: 0px;
`;

const Chart = ({ data, category, report, reference, width, dates, isPercentage }) => {
  const { translate } = useI18n();

  const renderDates = () => {
    if (dates && dates.from && dates.to) {
      return <>| <strong>{translate('Dates')}: </strong> {`${dates.from} - ${dates.to}`}</>
    }
    return '';
  };

  return (
    <div style={{ width: '100%' }}>
      <Title>
        <strong>{translate('Report')}:</strong> {translate(report)}  
        | <strong>{translate('Category')}:</strong> {translate(category)} 
        {renderDates()}
      </Title>
      <BarChart dataObject={data} category={category} width={width} defaultPercentage={isPercentage} />
      <Assessments category={category} report={report} dataObject={data} reference={reference} width={width} />
    </div>
  )
}
export default Chart;
