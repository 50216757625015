import React, { useEffect, useState } from 'react';
import { BaseDialog } from '@etiquette-ui/alert-dialog';
import { RadioButton } from '@etiquette-ui/selectors';
import useI18n from 'i18n/useI18n';
import styled from 'styled-components';
import PrimaryButton, { TextButton } from '@etiquette-ui/buttons';
import { ITEM_STATUS } from 'utils/status';

const Radio = styled(RadioButton)`
  margin: 10px 40px;
`;

const Buttons = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 20px;
  button {
    padding: 15px 0;
    height: unset;
  }
`;

const StatusOptions = ({ open, onSubmit, onClose, item }) => {
  const { translate } = useI18n();
  const [itemStatus, setItemStatus] = useState('');
  const disabled = item.localStatus===itemStatus;

  useEffect(()=>{
    setItemStatus(item.localStatus);
  },[]);

  return (
    <BaseDialog open={open} onClose={onClose} isResponsive={false} title={translate('Change status')}>
      <Radio checked={itemStatus === ITEM_STATUS.ACTIVE} onClick={()=>setItemStatus(ITEM_STATUS.ACTIVE)}>
        {translate('Active')}
      </Radio>
      <Radio checked={itemStatus === ITEM_STATUS.INACTIVE86} onClick={()=>setItemStatus(ITEM_STATUS.INACTIVE86)}>
        {translate("86's no stock")}
      </Radio>
      <Radio checked={itemStatus === ITEM_STATUS.ARCHIVED} onClick={()=>setItemStatus(ITEM_STATUS.ARCHIVED)}>
        {translate('Archived')}
      </Radio>
      <Radio checked={itemStatus === ITEM_STATUS.INACTIVE} onClick={()=>setItemStatus(ITEM_STATUS.INACTIVE)}>
        {translate('Inactive')}
      </Radio>
      <div style={{ height: '30px' }} />
      <Buttons>
        <TextButton onClick={onClose}>{translate('Cancel')}</TextButton>
        <PrimaryButton onClick={disabled ? ()=>{} : ()=>onSubmit(itemStatus)} disabled={disabled}>{translate('Change Status')}</PrimaryButton>
      </Buttons>
    </BaseDialog>
  );
};

export default StatusOptions;
