import SUBCATEGORY_KEYS from './keys';

export const TRANSLATION = {
    title: '',
};

export const CATEGORY = (id) => ({ category: id, subcategories: [] });

const INIT_STATE = () => ({
    [SUBCATEGORY_KEYS.IMAGE]: '',
    [SUBCATEGORY_KEYS.PARENT_ID]: null,
    [SUBCATEGORY_KEYS.ACTIVE]: true,
    [SUBCATEGORY_KEYS.TRANSLATIONS]: {
        en: { ...TRANSLATION },
        es: { ...TRANSLATION },
    },
});

export default INIT_STATE;
