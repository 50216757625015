import styled from 'styled-components';

const Body = styled.div`
  width: 100%;
  padding: 15px;
  padding-top: 30px;  
  
  .is86-img{
    position: relative;
    overflow: hidden;
    &:after {
      content: '';
      width: 15px;
      height: 200%;
      position: absolute;
      top: -57%;
      left: 50%;
      transform: rotate(45deg);
      transform-origin: left;
      background-color: rgba(232,73,97,.7);
    }
  }
`;

const Card = styled.div`
  max-height: 125px;
  width: 100%;
  display: flex;
  border-radius: 0 8px 8px 0;
  background-color: white;
  box-shadow: 1px 2px 6px #ccc;
  border-radius: 8px;
  margin-bottom: 15px;
  position: relative;
  box-sizing: border-box;

  figure {
    max-width: 125px;
    flex: 0 0 125px;
    overflow: hidden;
    margin: 0px;
    img {
      width: auto;
      height: 100%;
    }
  }
  .content {
    max-width: auto;
    flex: 0 0 1;
    padding: 6px 8px;
    position: relative;
    .title {
      margin: 0;
      font-weight: bold;
      font-size: 0.8rem;
      max-width: 75%;
    }
    .description {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      height: 100%;
      width: 100%;
      flex: 1;
      overflow: scroll;
      margin-top: 5px;
      font-size: 0.8rem !important;
      > p {
        margin: 0px;
        text-align: left;
      }
    }
  }  

  .top-price {
    position: absolute;
    top: 5px;
    right: 10px;
    font-size: 13px;
  }

  .footer{
    right: 0px;
    bottom: 0px;
    width: 100%;
    display: flex;
    padding: 0px 8px;
    justify-content: space-between;
    position: absolute;

    .tag {
      font-size: 12.5px;
      opacity: 0.8;
      flex: 1;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      position: relative;
    }
  }

  .simple-product {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 6px 8px;
    height: 4.5rem;
    cursor: default;
    position: relative;

    max-width: 100%;
    flex: 0 0 100%;

    .title{
      width: 60%;
      display: flex;
      padding-left: 24px;
    }

    .price {
      margin: 0;
      font-size: 0.8rem;
    }
  }
`;

const Details = styled.div`
  figure {
    width: 100%;
    margin: 0px;
    img {
      width: 100%;
      height: auto;
      border-radius: 0px;
    }
  }
  .data {
    padding: 14px 20px;
    position: relative;
    .title-container {
      display: flex;
      justify-content: space-between;
      .title {
        font-size: 20px;
        font-weight: bold;
      }
      .price {
        vertical-align: middle;
        display: inline-block;
        line-height: 27px;
      }
    }

    .description {
      font-size: 0.875rem !important;
      white-space: pre-wrap;
      > p {
        margin: 0px;
        text-align: left;
      }
    }
  }
`;

const Label86 = styled.div`
  margin-bottom: 0px;
  text-align: right;
  font-size: 11px;
  font-weight: 600;
  color: #E84961;
  text-transform: capitalize;
  background-color: white;
  padding: 0px;
  border-radius: 12px;
  white-space: nowrap;
  margin-left: auto;

  p {
    margin: 0px;
    text-align: right;
  }

  @media(min-width: 400px){
    font-size: 12px;
    padding-bottom: 0px;
  }
`;

export {
  Body,
  Card,
  Details,
  Label86
}
