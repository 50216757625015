export const ACTIONS = {
  INIT: 'init',
  SET_DATA: 'set-data',
  ADD_TEXT: 'text',
  ADD_IMG: 'img',
  ADD_TRANSLATE: 'translate'
};

export const INIT_STATE = {
  name: '',
  isPrivate: false,
  texts: {},
  imgs: {},
  translates: {},
  isReadyToRender: false,
}

const formReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case ACTIONS.INIT: {
      let newState = {
        ...state,
        ...action.payload
      }
      return newState;
    }
    case ACTIONS.ADD_TEXT: {
      const newState = {...state}
      newState.texts[action.payload.name] = action.payload.value
      return newState;
    }
    case ACTIONS.ADD_IMG: {
      const newState = {...state}
      newState.imgs[action.payload.name] = action.payload.value;
      return newState;
    }
    case ACTIONS.ADD_TRANSLATE: {
      const newState = {...state}
      const translate = !!newState.translates[action.payload.name] ? newState.translates[action.payload.name] : {en:'', es:''};
      translate[action.payload.lang] = action.payload.value;
      newState.translates[action.payload.name] = translate;
      return newState;
    }
    default:
      throw new Error(`Action of type ${action.type} not valid`);
  }
};

export default formReducer;
