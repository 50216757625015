import React from 'react';
import { Text, HeaderText } from '@etiquette-ui/dynamic-table-components';
import RowContainer from 'components/Table/RowContainer';

const structure = {
  row: RowContainer,
  columns: [
    {
      flex: 1,
      header: ({ globalState }) => (
        <HeaderText text={globalState.config.translate('Ingredient (EN)')} style={{ marginLeft: '15px' }} />
      ),
      body: ({ item }) => <Text style={{ marginLeft: '15px' }} text={item.translations.en.title} />,
    },
    {
      flex: 2,
      header: ({ globalState }) => (
        <HeaderText text={globalState.config.translate('Ingredient (ES)')} />
      ),
      body: ({ item }) => <Text text={item.translations.es.title} />,
    },
  ],
};

export default structure;
