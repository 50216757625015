import React, { useEffect, useMemo, useState } from "react";
import { PRIMARY } from "@etiquette-ui/colors";
import { AddIcon, RemoveIcon } from "@etiquette-ui/icons";
import { Input } from "@etiquette-ui/inputs-dropdowns";
import { Subtitle, Label, P } from "@etiquette-ui/typography";
import { CustomSecondaryButton } from "components/Buttons";
import FormSpacer from "components/Form/FormSpacer";
import FormSection from "containers/Form/FormSection";
import useI18n from "i18n/useI18n";
import ITEM_KEYS, { CONFIG_ITEM_KEYS } from "../../keys";
import { ACTIONS } from "../../Reducer";
import styled from "styled-components";
import StrechGridContainer from "pages/MenuItems/pages/Items/components/ItemForm/BasicStep/Presentations/StrechGridContainer";
import { Checkbox, SwitchButton } from "@etiquette-ui/selectors";
import usePOS from "state/pos";
import { POS_ENABLED } from "resources/constants/config";
import { DotPulse } from "@uiball/loaders";
import POSCodeInput from "./POSCodeInput";

const PresentationSection = styled.div`
    display: flex;

    & .checkColumn {
        margin-right: 15px;
        display: flex;
        align-items: center;

        div {
            position: relative;
            div {
                position: absolute;
                top: 0px;
                left: 3px;
            }
            p {
                position: relative;
                width: 100%;
                padding-left: 1.5rem;
                font-size: 14px;
            }
        }
    }

    @media (max-width: 768px) {
        & .checkColumn {
            flex: 0 0 35%;
            max-width: 35%;
            margin-right: 0px;

            div div {
                top: 3px;
            }
        }

        & .blockButtonColumn {
            flex: 0 0 65%;
            max-width: 65%;
            padding: 0px 0px 0px 10px;
        }
    }
`;

const MarketPriceSection = styled.div`
    .mktPriceCheckbox span {
        margin-left: 5px;
    }
`;

const Presentations = ({ formData, dispatch }) => {
    const { translate } = useI18n();
    const [pos, posDispatcher, posUtils] = usePOS();
    const [posSync, setPosSync] = useState(null);
    const [posChange, setPosChange] = useState(null);

    useEffect(() => {
        if (POS_ENABLED) {
            posDispatcher.fetchStart();
        }
    }, [posDispatcher]);

    useEffect(() => {
        if (pos.list.length === 0 || formData[ITEM_KEYS.VARIANTS].length === 0) setPosSync(null);
        const posList = posUtils.findAll(formData[ITEM_KEYS.VARIANTS].map(({ id }) => id));
        const posSync = {};

        for (const pos of posList) {
            const { posCode, posId, price } = pos;
            posSync[pos.variantId] = { posCode, posId, price };
        }

        setPosSync(posSync);
        setPosChange(null);
    }, [pos.list, posUtils, formData]);

    const showAdditionalInfo = useMemo(() => formData[ITEM_KEYS.VARIANTS].length > 1, [formData]);

    return (
        <FormSection>
            <div style={{ display: "flex", gap: 10, alignItems: "center" }}>
                <Subtitle>{translate("presentations")}</Subtitle>
                {pos.fetching.state && <DotPulse size={22} color={"black"} />}
            </div>
            <Label>({translate("presentations_clarification_text")})</Label>
            <FormSpacer />
            {formData[ITEM_KEYS.VARIANTS].map((variant, i) => (
                <StrechGridContainer className={showAdditionalInfo ? 'expanded' : ''}>
                    {showAdditionalInfo && (
                        <Input
                            className={'double'}
                            labelProps={{ label: translate("presentation_name_en") }}
                            iconProps={{ icon: "pos" }}
                            inputProps={{
                                value: variant.translations?.en,
                                onChange: (e) =>
                                    dispatch(ACTIONS.VARIANT, {
                                        idx: i,
                                        variant: {
                                            ...variant,
                                            translations: {
                                                ...variant.translations,
                                                en: e.target.value,
                                            },
                                        },
                                    }),
                                required: true,
                            }}
                        />
                    )}
                    {showAdditionalInfo && (
                        <Input
                            className={'double'}
                            labelProps={{ label: translate("presentation_name_es") }}
                            iconProps={{ icon: "pos" }}
                            inputProps={{
                                value: variant.translations?.es,
                                onChange: (e) =>
                                    dispatch(ACTIONS.VARIANT, {
                                        idx: i,
                                        variant: {
                                            ...variant,
                                            translations: {
                                                ...variant.translations,
                                                es: e.target.value,
                                            },
                                        },
                                    }),
                                required: true,
                            }}
                        />
                    )}
                    <Input
                        labelProps={{ label: translate("Price") }}
                        iconProps={{ icon: "price" }}
                        inputProps={{
                            value: variant.price,
                            onChange: (e) =>
                                dispatch(ACTIONS.VARIANT, {
                                    idx: i,
                                    variant: {
                                        ...variant,
                                        price: +e.target.value,
                                    },
                                }),
                            type: "number",
                            required: true,
                        }}
                    />
                    <POSCodeInput variantId={variant.id} posSync={posSync ? posSync[variant.id] :  null} />
                    {showAdditionalInfo && (
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                height: "100%",
                            }}
                        >
                            <SwitchButton
                                onClick={() =>
                                    dispatch(ACTIONS.VARIANT, {
                                        idx: i,
                                        variant: {
                                            ...variant,
                                            active: !variant.active,
                                        },
                                    })
                                }
                                active={!!formData[ITEM_KEYS.VARIANTS][i].active}
                            />
                            <div style={{ width: "15px" }}></div>
                            <P>{translate("Active")}</P>
                        </div>
                    )}
                    {showAdditionalInfo && (
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                height: "100%",
                                cursor: "pointer",
                            }}
                            onClick={() => dispatch(ACTIONS.REMOVE_VARIANT, i)}
                        >
                            <div style={{ width: "30px", marginRight: "5px", lineHeight: "1px" }}>
                                <RemoveIcon />
                            </div>
                            <P>{translate("remove")}</P>
                        </div>
                    )}
                </StrechGridContainer>
            ))}
            <MarketPriceSection className="checkColumn">
                <Checkbox
                    className={'mktPriceCheckbox'}
                    checked={formData[ITEM_KEYS.CONFIG][CONFIG_ITEM_KEYS.MKT_PRICE]}
                    onClick={() => dispatch(ACTIONS.CONFIG, { key: [CONFIG_ITEM_KEYS.MKT_PRICE], value: !formData[ITEM_KEYS.CONFIG][CONFIG_ITEM_KEYS.MKT_PRICE] })}
                >
                    <Subtitle>{translate("market_price_title")}</Subtitle>
                    <Label>({translate("market_price_description")})</Label>
                </Checkbox>
            </MarketPriceSection>
            <FormSpacer />
            <PresentationSection>
                <div className="blockButtonColumn">
                    <CustomSecondaryButton
                        onClick={() => dispatch(ACTIONS.NEW_VARIANT, null)}
                        type="button"
                    >
                        <AddIcon
                            styles={{
                                svg: { width: "20px", height: "20px", marginRight: "8px" },
                                line: {
                                    fill: "none",
                                    strokeLinecap: "round",
                                    strokeLinejoin: "round",
                                    strokeWidth: "1.5px",
                                    stroke: PRIMARY,
                                },
                            }}
                        />
                        {translate("add_presentation")}
                    </CustomSecondaryButton>
                </div>
            </PresentationSection>
        </FormSection>
    );
};

export default Presentations;
