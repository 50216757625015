import styled from "styled-components";
import { WRONG, WRONG_LIGHT, PRIMARY_DARK, DISABLED_BUTTON   } from "@etiquette-ui/colors";
import { Link } from "react-router-dom";

const Container = styled.div`
  max-width: 500px;
  text-align: left;
`;

const Title = styled.h1`
  font-size: 20px;
  text-align: inherit;
  margin-top: -10px;
  color: ${WRONG};
`;

const Description = styled.p`
  font-size: 15px;
  text-align: inherit!important;;
`;

const List = styled.ol`
  font-size: 15px;
  padding-left: 18px;
  text-align: inherit;
  span {
    font-weight: 500;
    color: ${WRONG_LIGHT};
  }
`;

const PrimaryButton = styled(Link)`
  background-color: ${PRIMARY_DARK};
  border-radius: 8px;
  cursor: pointer;
  font-size: 14px;
  height: 38px;
  margin: 2px 0;
  padding: 9px 10px;
  color: white!important;
  text-decoration: none;
  text-align: center;
  box-shadow: -1px 4px 18px 5px ${DISABLED_BUTTON };
  transition-duration: .2s;
  &:hover {
    opacity: .95;
  }
`;

const ButtonsContainer = styled.div`
  display: flex;
  gap: 20px;
  padding-top: 10px;
  margin-bottom: -10px;
  button, a {
    min-width: 150px;
    font-weight: 500;
  }
`;

export {
  Title, 
  Container,
  Description,
  List,
  ButtonsContainer,
  PrimaryButton
};