import INIT_STATE from "./initState";

export const ACTIONS = {
  INIT: 'init',
  ITEM_INFO: 'itemInfo',
  TRANSLATIONS: 'translations',
};

const Reducer = (state = INIT_STATE(), action) => {

  switch (action.type) {
    case ACTIONS.INIT: { 
      const newState = {...action.payload};
      return newState;
    }
    case ACTIONS.ITEM_INFO: {
      const newState = { ...state, ...action.payload };
      return newState;
    }
    case ACTIONS.TRANSLATIONS: {
      const newState = { ...state };
      const { lang, text, value } = action.payload;

      const translations = { ...newState.translations };
      translations[lang][text] = value;

      newState.translations = translations;

      return newState;
    }
    default:
      throw new Error(`Action of type ${action.type} not valid`);
  }
};

export default Reducer;
