import React from 'react';
import styled from 'styled-components';
import Columns from './Columns';
import TableHeader from './TableHeader';

const Container = styled.div`
    display: grid;
    grid-template-columns: 185px ${({ withAttendance }) => (withAttendance ? '50px ' : '')}auto;
    align-items: center;
    overflow-x: auto;
    column-gap: 5px;
`;
const TableContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    column-gap: 5px;

    &.col > div:nth-child(2n + 1):nth-child(odd) {
        background: #e7e8e8;
    }

    &.col > div:nth-child(2n):nth-child(even) {
        background: #e7e8e8;
    }
`;
const Names = styled.div`
    text-align: left;
    font-size: 15px;
    letter-spacing: 0px;
    height: fit-content;
    text-transform: uppercase;
`;

const HeaderContainer = styled.div`
    display: grid;
    grid-template-columns: 185px ${({ withAttendance }) => (withAttendance ? '50px ' : '')}1fr 1fr 1fr 1fr 1fr 1fr;
    margin-top: 10px;
    column-gap: 5px;
    text-align: center;
`;

const Element = styled.div`
    width: 100%;
    height: 100%;
    display: inherit;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    letter-spacing: 0px;
    background: ${({ color }) => color};
    border-width: 2px;
`;

const ElementHeader = styled(Element)`
    font-size: 12px;
    height: 42px;
    font-weight: 600;
`;

const Table = ({ header, items, withAttendance = false }) => {
    const newHeader = ['Total', ...header];
    const tableHeader = ['Total', ...header];

    const isDarker = (title) => {
        switch (title) {
            case 'Open-1pm':
            case '5pm-7pm':
            case '11pm-Close':
                return true;
            default:
                return false;
        }
    };

    return (
        <>
            <HeaderContainer withAttendance={withAttendance}>
                <div></div>
                {withAttendance && (
                    <div style={{ display: 'flex', alignItems: 'flex-end', width: '100%', height: '100%' }}>
                        <ElementHeader color={'#F6F6F6'}>{'Days Worked'}</ElementHeader>
                    </div>
                )}
                {newHeader.map((title) => (
                    <TableHeader title={title} opacity={isDarker(title)} />
                ))}
            </HeaderContainer>

            <Container withAttendance={withAttendance}>
                {items.map((item, i) => {
                    return (
                        <>
                            <Names>{item.name}</Names>
                            {withAttendance && (
                                <Element color={i % 2 ? '#EEEEEE' : '#F6F6F6'}>
                                    {item.id === 999 || item.id < 0 ? 'N/A' : item.attendance }
                                </Element>
                            )}
                            <TableContainer>
                                {tableHeader.map((title) => {
                                    const dataTitle = item.hours;
                                    const dataItem = title === 'Total' ? item.total : dataTitle[title];

                                    return <Columns items={dataItem} color={i} opacity={isDarker(title)} />;
                                })}
                            </TableContainer>
                        </>
                    );
                })}
            </Container>
        </>
    );
};

export default Table;
