import React from 'react';
import RowContainer from 'components/Table/RowContainer';
import SortText from 'components/Table/headers/SortText';
import ResponsiveHeaderText from 'components/Table/responsive/HeaderText';
import ResponsiveText from 'components/Table/responsive/Text';
import WhoWhen from './cells/WhoWhen';
import WhyExpected from './cells/WhyExpected';
import Status from './cells/Status';
import Comments from './cells/Comments';

const structure = {
  row: RowContainer,
  columns: [
    {
      flex: 2,
      header: ({ globalState, ...rest }) => (
        <SortText property={'translations.en.title'} text={globalState.config.translate('Menu Item')} {...rest} />
      ),
      body: ({ item }) => <ResponsiveText text={item.translations.en.title} style={{ marginLeft: '16px' }} />,
    },
    {
      flex: 1,
      header: ({ globalState }) => <ResponsiveHeaderText text={globalState.config.translate('Who & When 86')} />,
      body: WhoWhen,
    },
    {
      flex: 3,
      header: ({ globalState }) => <ResponsiveHeaderText text={globalState.config.translate('Why 86 & Expected restock')} />,
      body: WhyExpected,
    },
    {
      flex: 1,
      header: ({ globalState }) => <ResponsiveHeaderText text={globalState.config.translate('How long 86')} />,
      body: ({ item, globalState }) => <ResponsiveText text={item.howLong === null || item.howLong === undefined ? '-' : `${item.howLong} ${globalState.config.translate('days')}`} />,
    },
    {
      flex: 2,
      header: () => <ResponsiveHeaderText text={''} />,
      body:  Comments,
    },
    {
      flex: 1,
      header: ({ globalState }) => <ResponsiveHeaderText text={globalState.config.translate('Status')} />,
      body: Status,
    },
  ],
};

export default structure;
