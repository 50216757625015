import React from 'react';
import styled from 'styled-components';

const Row = styled.div`
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 42px;
    grid-gap: 2px;
    text-align: center;
`;

const Element = styled.div`
    width: 100%;
    height: 100%;
    display: inherit;
    align-items: center;

    font-size: 18px;
    letter-spacing: 0px;
    background: ${({color}) => (color)};
    border-width: 2px;
`;

const Columns = ({ items, color, opacity}) => {
    const star = !!items ? Number(items.stars).toFixed(1) : '-';
    const tag = !!items ? Number(items.tags).toFixed(1) : '-';
    const avg = !!items ? Number(items.avg).toFixed(1) : '-';
    const total = !!items ? Number(items.total).toFixed(1) : '-';

    const hasStars = !!items && !!+items.stars;
    const hastTags = !!items && !!+items.tags;
    const totalTotal = hasStars && hastTags ? total : (hasStars ? star : (hastTags ? tag : '-'))
    const isTotal = !!items && items.total ? totalTotal : avg ;

    const isOdd = color % 2 ? '#F6F6F6' : '#FFFF';
    const isDarker = opacity && (color % 2 ? '#EEEEEE' : '#F6F6F6');


    return (
      <Row>
        <Element color={opacity ? isDarker : isOdd}>{star!=0 ? star : '-'}</Element>
        <Element color={opacity ? isDarker : isOdd}>{tag!=0 ? tag : '-'}</Element>
        <Element color={opacity ? isDarker : isOdd}>{isTotal!=0 ? isTotal : '-'}</Element>
      </Row>
  );
};

export default Columns;