import React, { useEffect } from 'react';
import useI18n from 'i18n/useI18n';
import useConfig from 'state/config';

import Loader from 'pages/Settings/components/Loader';
import ImageCropDialog from 'components/ImageCropDialog';
import LinkItem from 'pages/Settings/components/LinkItem/index';
import useForm from 'pages/Settings/formState/dispatcher';
import { NAMES } from 'pages/Settings/resources/constants';
import FormTitle from 'pages/Settings/components/FormTitle';
import { ACTIONS } from 'pages/Settings/formState/formReducer';
import UpdateButton from 'pages/Settings/components/UpdateButton';
import ToggleButton from 'pages/Settings/components/ToggleButton';
import { ALERT_TYPES, useAlert } from '@etiquette-ui/alert-dialog';
import { createFormObject, getCroppedFile, castConfigData } from 'pages/Settings/formState/utils';
import { Container, Row, Subtitles, ImageCard, ImageLabel, ImageButton, TextAreas } from 'pages/Settings/components/styles';

import CardContainer from 'containers/CardContainer';
import { Card } from '@etiquette-ui/misc';
import ImageUpload from 'components/ImageUpload';
import WYSIWYG from 'components/WYSIWYG';


const MainPageCard = ({setPreviewSetting}) => {
  const NAME = NAMES.mainCard;

  const alert = useAlert()
  const { translate } = useI18n();
  const [configData, configDispatcher] = useConfig();

  const [formData, formDataDispatcher] = useForm();

  const keys = {
    img: {name: 'img', type: 'imgs'},
    message: {name: 'message', type: 'translates'},
    active: {name: 'active', type: 'texts'},
    link: {name: 'linkDestination', type: 'texts'},
    url: {name: 'url', type: 'texts'}
  }

  useEffect(()=>{
    configDispatcher.fetchStart();     
  },[]);
  
  useEffect(()=>{
    setPreviewSetting({
      showPromoModal: false,
      bodyType: 'home',
      formData
    });
  },[formData]);

  useEffect(()=>{
    if(!!configData.map[NAME]){
      formDataDispatcher(
        ACTIONS.INIT, 
        castConfigData(configData.map[NAME], keys, NAME)
      );      
    }
  },[configData]);

  const onChangeImage = (value) => {
    const name = value.file.name;
    const type = value.file.type || `image/${name.split('.').pop()}`;
    alert({
      type: ALERT_TYPES.CUSTOM,
      config: {
        body: ImageCropDialog,
        props: {
          src: value.dataURL,
          fileType: type,
          isSquareAspect: false
        },
      },
    }).then(async (croppedImage) => {
      const file = await getCroppedFile(croppedImage, name, type);
      formDataDispatcher(
        ACTIONS.ADD_IMG, 
        {
          name: keys.img.name, 
          value: {dataURL: croppedImage, file}
        }
      );
    })
  };

  const onChangeMessage = (language) => (value) => {
    formDataDispatcher(ACTIONS.ADD_TRANSLATE, {name: keys.message.name, lang: language, value} );
  }
  
  const onChangeActive = (active) => {
    formDataDispatcher(ACTIONS.ADD_TEXT, {name: keys.active.name, value: active } );
  }

  const onChangeLink = (value) => {
    formDataDispatcher(ACTIONS.ADD_TEXT, {name: keys.link.name, value } );
  }

  const onSaveForm = () => {
    configDispatcher.updateStart({
      name: formData.name, 
      isPrivate: formData.isPrivate,
      ...createFormObject(formData),
    });
  }
  
  return (
    !!formData && formData.name === NAME && !!formData.isReadyToRender ? (<Container style={{position: 'relative'}}> 
      <CardContainer>
        <Card>
          <FormTitle>{translate('Main Page Card Settings')}</FormTitle>
          <Row>
            <Card>
              <Subtitles>{translate('Image')}</Subtitles>
              <ImageCard>
                <ImageButton>
                  <ImageUpload
                    text={translate('Add Photo')}
                    altText={translate('Edit Photo')}
                    src={formData.imgs.img}
                    onChange={onChangeImage}
                  />
                </ImageButton>                
                <ImageLabel>{translate('Image must be PNG, JPEG or JPG. We recommend portrait orientation.')}</ImageLabel>
              </ImageCard>
            </Card>
          </Row>
          <Row>
            <Card>
              <Subtitles>{translate('Message')}</Subtitles>
              <TextAreas>
                <div className='card'>
                  <WYSIWYG
                    iconProps={{ icon: 'align-left' }}
                    labelProps={{
                      label: translate('Message (English)')
                    }}
                    onChange={ onChangeMessage('en') }
                    value={formData[keys.message.type][keys.message.name].en}
                  />
                </div>
                <div className='card'>
                  <WYSIWYG
                    iconProps={{ icon: 'align-left' }}
                    labelProps={{
                      label: translate('Message (Espanish)')
                    }}
                    onChange={ onChangeMessage('es') }
                    value={formData[keys.message.type][keys.message.name].es}
                  />
                </div>
              </TextAreas>            
            </Card>
          </Row>
          <Row>
            <Card>
              <Subtitles>{translate('Link Destination')}</Subtitles>
              <LinkItem 
                onChange={onChangeLink}
                value={ !!formData[keys.link.type][keys.link.name] 
                  ? formData[keys.link.type][keys.link.name].url
                  : formData[keys.url.type][keys.url.name]
                }
                checkValue={
                  !!formData[keys.link.type][keys.link.name] 
                  ? formData[keys.link.type][keys.link.name].isSectionOnMenu
                  : true
                }
              />
            </Card>
          </Row>
          <Row>
            <ToggleButton initState={formData[keys.active.type][keys.active.name]} onChange={onChangeActive}/>    
          </Row>
        </Card>
      <UpdateButton isUpdating={configData.isUpdatingState} onClick={onSaveForm} />
      </CardContainer>
    </Container>) : (<Loader/>)
  )
}
export default MainPageCard;