import Types from './types';

export default class Dispatcher {
    constructor(dispatch) {
        this.dispatch = dispatch;
    }

    itemsSetList = (payload) => this.dispatch({ type: Types.ITEMS_SET_LIST, payload });

    itemsSetSelectedCategories = (payload) => this.dispatch({ type: Types.ITEMS_SET_SELECTED_CATEGORIES, payload });

    itemsSetCategoriesOptions = (payload) => this.dispatch({ type: Types.ITEMS_SET_CATEGORIES_OPTIONS, payload });

    itemsSetSubcategoriesOptions = (payload) => this.dispatch({ type: Types.ITEMS_SET_SUBCATEGORIES_OPTIONS, payload });

    itemsSetSelectedSubcategories = (payload) => this.dispatch({ type: Types.ITEMS_SET_SELECTED_SUBCATEGORIES, payload });
    
    arrangeItemsSetSelectedCategory = (payload) => this.dispatch({ type: Types.ARRANGE_ITEMS_SET_SELECTED_CATEGORY, payload });

    arrangeItemsSetSelectedSubcategory = (payload) => this.dispatch({ type: Types.ARRANGE_ITEMS_SET_SELECTED_SUBCATEGORY, payload });
}