import useI18n from 'i18n/useI18n';
import React, { useEffect, useMemo, useReducer } from 'react';
import useCategories from 'state/categories';
import CategoryForm from '../../components/CategoryForm';
import INIT_STATE from '../../components/CategoryForm/initState';
import { toSubmitObject, toFormData } from '../../components/CategoryForm/processor';
import Reducer, { ACTIONS } from '../../components/CategoryForm/Reducer';
import { useParams } from 'react-router';

const Edit = ({ location }) => {
    const [data, categoriesDispatcher, categoriesUtils] = useCategories();
    const [formData, formDispatcher] = useReducer(Reducer, INIT_STATE());
    const dispatch = useMemo(() => (type, payload) => formDispatcher({ type, payload }), [formDispatcher]);
    const { translate } = useI18n();
    const { categoryId } = useParams();

    useEffect(() => {
        categoriesDispatcher.fetchStart();
    }, []);

    useEffect(() => {
        if (data.fetching.state || data.list.length === 0) return;
        const item = toFormData(categoriesUtils.findOne(categoryId));
        
        dispatch(ACTIONS.INIT, item);
      }, [data.list]);

    const submit = (e) => {
        e.preventDefault();
        const item = toSubmitObject(formData);

        categoriesDispatcher.updateStart('list', {id: +categoryId, ...item});
    };

    return (
        <CategoryForm
            location={location}
            formData={formData}
            dispatch={dispatch}
            submit={submit}
            title={translate('Edit Category')}
            button={translate('Update Category')}
        />
    );
};

export default Edit;
