import { DEFAULT_FETCHING_STATE, DEFAULT_NO_ERROR } from 'state/constants';
import { getItemStatus } from 'utils/status';
import Types from './types';

const INITIAL_STATE = {
  list: [],
  detail: {},
  fetching: { state: false },
  error: { ...DEFAULT_NO_ERROR },
  pricesInBulkUpdated: null,
  config: {},
};

export const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {

    case Types.FETCH_START: return { ...state, fetching: { ...DEFAULT_FETCHING_STATE, state: true } };
    case Types.FETCH_SUCCESS: return { ...state, list: action.payload, fetching: { ...DEFAULT_FETCHING_STATE }, error: { ...DEFAULT_NO_ERROR } };
    case Types.FETCH_ERROR: return { ...state, fetching: { ...DEFAULT_FETCHING_STATE }, error: { timestamp: Date.now(), message: action.payload } };

    case Types.FETCH_ONE_START: return { ...state, fetching: { ...DEFAULT_FETCHING_STATE, state: true } };
    case Types.FETCH_ONE_SUCCESS: return { ...state, detail: action.payload, fetching: { ...DEFAULT_FETCHING_STATE }, error: { ...DEFAULT_NO_ERROR } };

    case Types.INACTIVE_86:
    case Types.UPDATE_START: return { ...state, fetching: { state: true, config: { id: action.payload.id } } }
    case Types.UPDATE_SUCCESS: {
      const list = [...state.list];
      const item = list.find(({ id }) => id === action.payload.id);
      const index = list.indexOf(item);
      if (index > -1) {
        Object.keys(action.payload).forEach((key) => {
          item[key] = action.payload[key];
        });
        item.localStatus = getItemStatus(item);
        list[index] = item;
      }
      return { ...state, list, fetching: { ...DEFAULT_FETCHING_STATE }, error: { ...DEFAULT_NO_ERROR } }
    }
    case Types.UPDATE_ERROR: return { ...state, fetching: { ...DEFAULT_FETCHING_STATE }, error: { timestamp: Date.now(), message: action.payload } }

    case Types.CREATE_START: return { ...state, fetching: { ...DEFAULT_FETCHING_STATE, state: true } }
    case Types.CREATE_SUCCESS: return { ...state, fetching: { ...DEFAULT_FETCHING_STATE }, error: { ...DEFAULT_NO_ERROR } }
    case Types.CREATE_ERROR: return { ...state, fetching: { ...DEFAULT_FETCHING_STATE }, error: { timestamp: Date.now(), message: action.payload } }

    case Types.POSITION_START: return { ...state, fetching: { state: true, config: { id: action.payload.id } } }
    case Types.POSITION_SUCCESS: return { ...state, list: action.payload, fetching: { ...DEFAULT_FETCHING_STATE }, error: { ...DEFAULT_NO_ERROR } };
    case Types.POSITION_ERROR: return { ...state, fetching: { ...DEFAULT_FETCHING_STATE }, error: { timestamp: Date.now(), message: action.payload } }

    case Types.UPDATE_PRICES_START: return { ...state, fetching: { state: true, config: { id: action.payload } }, pricesInBulkUpdated: false }
    case Types.UPDATE_PRICES_SUCCESS: {
      const newList = state.list.map(product => {
        const priceUpdates = action.payload.filter(newPrice => newPrice.product.id === product.id);
        
        if (priceUpdates.length > 0) {
          const updatedProduct = { ...product, variants: [...product.variants] };

          priceUpdates.forEach(newPrice => {
            const variantIndex = updatedProduct.variants.findIndex(variant => variant.id === newPrice.variant.id);

            if (variantIndex !== -1) {
              updatedProduct.variants[variantIndex].price = newPrice.price;
            }
          });

          return updatedProduct;
        }

        return product;
      });
      
      return { ...state, list: newList, fetching: { ...DEFAULT_FETCHING_STATE }, error: { ...DEFAULT_NO_ERROR }, pricesInBulkUpdated: true };
    }

    case Types.UPDATE_PRICES_ERROR: return { ...state, fetching: { ...DEFAULT_FETCHING_STATE }, error: { timestamp: Date.now(), message: action.payload } }

    case Types.RESET_ERROR: return { ...state, error: { ...DEFAULT_NO_ERROR } }

    default:
      return state;
  }
};

export default reducer;
