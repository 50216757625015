import { DEFAULT_FETCHING_STATE, DEFAULT_NO_ERROR } from 'state/constants';
import Types from './types';

const INITIAL_STATE = {
  list: [],
  history: [],
  statistics: [],
  fetching: { state: false },
  config: {},
  error: { ...DEFAULT_NO_ERROR },
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case Types.FETCH_START:
      return { ...state, fetching: { state: true } };
    case Types.FETCH_SUCCESS:
      return { ...state, list: action.payload, fetching: { ...DEFAULT_FETCHING_STATE }, error: { ...DEFAULT_NO_ERROR } };
    case Types.FETCH_ERROR:
      return { ...state, fetching: { ...DEFAULT_FETCHING_STATE }, error: { timestamp: Date.now(), message: action.payload } };

    case Types.ACTIVATE_PRODUCT_START:
    case Types.COMMENT_START:
      return { ...state, fetching: { state: true }, config: { id: action.payload.id } };
    case Types.ACTIVATE_PRODUCT_SUCCESS: {
      const list = [...state.list];
      const item = list.find((it) => it.productId == action.payload.id);
      const index = list.indexOf(item);
      if (index > -1) {
        list.splice(index, 1);
      }
      return { ...state, list, fetching: { ...DEFAULT_FETCHING_STATE }, error: { ...DEFAULT_NO_ERROR } };
    }
    case Types.ACTIVATE_PRODUCT_ERROR:
      return { ...state, fetching: { ...DEFAULT_FETCHING_STATE }, error: { timestamp: Date.now(), message: action.payload } };

    case Types.UPDATE_PRODUCT_START:
    case Types.INACTIVE_86:
      return { ...state, fetching: { state: true }, config: { id: action.payload.id } };
    case Types.UPDATE_PRODUCT_SUCCESS:
      return { ...state, fetching: { ...DEFAULT_FETCHING_STATE }, error: { ...DEFAULT_NO_ERROR } };
    case Types.UPDATE_PRODUCT_ERROR:
      return { ...state, fetching: { ...DEFAULT_FETCHING_STATE }, error: { timestamp: Date.now(), message: action.payload } };

    case Types.FETCH_HISTORY_START:
      return { ...state, fetching: { state: true } };
    case Types.FETCH_HISTORY_SUCCESS:
      return { ...state, history: action.payload, fetching: { ...DEFAULT_FETCHING_STATE }, error: { ...DEFAULT_NO_ERROR } };

    case Types.FETCH_STATISTICS_START:
      return { ...state, fetching: { state: true } };
    case Types.FETCH_STATISTICS_SUCCESS:
      return { ...state, statistics: action.payload, fetching: { ...DEFAULT_FETCHING_STATE }, error: { ...DEFAULT_NO_ERROR } };

    case Types.COMMENT_SUCCESS:
      return { ...state, fetching: { ...DEFAULT_FETCHING_STATE }, error: { ...DEFAULT_NO_ERROR } };
    case Types.COMMENT_ERROR:
      return { ...state, fetching: { ...DEFAULT_FETCHING_STATE }, error: { timestamp: Date.now(), message: action.payload } };

    case Types.RESET_ERROR:
      return { ...state, error: { ...DEFAULT_NO_ERROR } };

    default:
      return state;
  }
};
