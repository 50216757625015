import styled from 'styled-components';

const StrechGridContainer = styled.div`
  display: grid;
  grid-gap: 30px;
  grid-template-columns: 1fr 1fr;
  align-items: flex-end;

  margin-bottom: 20px;

  position: relative;

  &.expanded {
    grid-template-columns: 3fr 3fr 2fr 2fr 1fr 1fr;
  }
`;


export default StrechGridContainer;