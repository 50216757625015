import React, { useState } from 'react';
import { Subtitle } from '@etiquette-ui/typography';
import FormSpacer from 'components/Form/FormSpacer';
import useI18n from 'i18n/useI18n';
import { ACTIONS } from '../Reducer';
import FloatingButton from '../../FloatingButton';
import Details from './Details';
import Stock from './Stock';
import Sponsor from './Sponsor';
import GridContainer from 'containers/Form/GridContainer';
import { Dropdown } from '@etiquette-ui/inputs-dropdowns';
import styled from 'styled-components';
import { CustomSecondaryButton } from 'components/Buttons';
import useProducts from 'state/products';
import { toFormData } from '../processor';
import ITEM_KEYS from '../keys';
import useCategories from 'state/categories';
import { ITEM_STATUS } from 'utils/status';
import { ALERT_TYPES, useAlert } from '@etiquette-ui/alert-dialog';
import Inactive86Alert from '../../../alert/Inactive86Alert';
import FormTitle from 'pages/MenuItems/components/FormTitle';
import Presentations from './Presentations';

const TitleContainer = styled.div`
  display: flex;
  gap: 30px;
  align-items: flex-end;
  justify-content: space-between;

  @media (min-width: 768px) {
    grid-template-columns: 1fr 5fr;
  }
`;

const SelectorContainer = styled.div`
    display: flex;
    > div {
        flex: 1;
    }
    @media( max-width: 768px ){
        display: none;
    }
`;

const Selector = styled(Dropdown)`
    flex: 1;
    min-width: 250px;
    & > div {
        padding: 2px;
    }
`;

const Button = styled(CustomSecondaryButton)`
    align-self: center;
    margin-left: 15px;
    button {
        width: unset;
    }
`;

const statusChanged = (formData, previousData) => {
    return (
        formData[ITEM_KEYS.STOCK_TOTAL] != previousData[ITEM_KEYS.STOCK_TOTAL] ||
        formData[ITEM_KEYS.STATUS] != previousData[ITEM_KEYS.STATUS]
    );
};

const BasicStep = ({ formData, dispatch, next, title, isEdit, previousData }) => {
    const { translate, translateObject } = useI18n();
    const [item, setItem] = useState(null);
    const [products, productsDispatcher] = useProducts();
    const [categories] = useCategories();
    const alert = useAlert();

    const handleItemInfo = (field, value) => {
        const newField = { [field]: value };
        dispatch(ACTIONS.ITEM_INFO, newField);
    };

    const onConfirm = () => {
        const { value, label, ...product } = item;

        const newItem = toFormData(product, categories.list);
        newItem[ITEM_KEYS.POS_CODE] = '';

        dispatch(ACTIONS.INIT, newItem);
        setItem(null);
    };

    const submit = (e) => {
        e.preventDefault();
        if (
            formData[ITEM_KEYS.STOCK_TOTAL] == 0 &&
            formData[ITEM_KEYS.STATUS] === ITEM_STATUS.ACTIVE &&
            isEdit &&
            statusChanged(formData, previousData)
        ) {
            alert({
                type: ALERT_TYPES.CUSTOM,
                config: {
                    body: Inactive86Alert,
                },
            })
                .then(({ date, reason }) => {
                    productsDispatcher.inactive86({ id: formData.id, why: reason, estimate: date });
                    next();
                })
                .catch(() => { });
        } else {
            next();
        }
    };

    return (
        <form style={{ width: '100%' }} onSubmit={submit}>
            <TitleContainer>
                <FormTitle style={{ fontSize: '1.5rem', alignSelf: 'center' }}>{title}</FormTitle>
                <SelectorContainer>
                    <Selector
                        labelProps={{
                            label: translate('Copy Item Data'),
                        }}
                        leadingIconProps={{
                            icon: 'search',
                        }}
                        selectProps={{
                            onChange: (value) => setItem(value),
                            options: products.list.map(({ id, translations, ...rest }) => ({
                                value: id,
                                label: translateObject(translations).title,
                                translations,
                                ...rest,
                            })),
                            placeholder: translate('Pick one...'),
                            value: item,
                            isSearchable: true
                        }}
                    />
                    <Button type="button" onClick={item === null ? () => { } : onConfirm}>
                        {translate('Copy Data')}
                    </Button>
                </SelectorContainer>
            </TitleContainer>
            <FormSpacer />
            <Details formData={formData} dispatch={dispatch} handleItemInfo={handleItemInfo} />
            <FormSpacer />
            <Presentations formData={formData} dispatch={dispatch} handleItemInfo={handleItemInfo} />
            <FormSpacer />
            <Stock formData={formData} handleItemInfo={handleItemInfo} />
            <FormSpacer />
            <Sponsor formData={formData} handleItemInfo={handleItemInfo} />
            <FormSpacer />
            <FormSpacer />
            <FloatingButton type={'submit'}>{translate('Continue')}</FloatingButton>
        </form>
    );
};

export default BasicStep;
