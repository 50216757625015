import { ALERT_TYPES, useAlert } from '@etiquette-ui/alert-dialog';
import { INPUTS } from '@etiquette-ui/colors';
import React from 'react';
import useProducts from 'state/products';
import styled from 'styled-components';
import StatusOptions from '../../../../../alert/StatusOptions';
import StatusButton from 'components/Buttons/StatusButton';
import useInactive86 from 'state/inactive86';

const CustomStatus = styled(StatusButton)`
  justify-content: flex-start;
`;

const Status = ({ item, count }) => {
  const alert = useAlert();
  const [products] = useProducts();
  const [, inactive86Dispatcher] = useInactive86();

  const product = products.list.find(({ id }) => id === item.productId);

  const showAlert = () => {
    alert({
      type: ALERT_TYPES.CUSTOM,
      config: {
        body: StatusOptions,
        props: {
          item: product,
        },
      },
    })
      .then((status) => {
        inactive86Dispatcher.activateProductStart({ id: product.id, status, stockTotal: 1 }, false);
      })
      .catch(() => {});
  };

  return product ? (
    <CustomStatus
      item={product}
      onClick={showAlert}
      loading={products.fetching.state && products.config?.id === product.id}
      spinnerBackground={count % 2 === 0 ? INPUTS : 'white'}
    />
  ) : (
    <div style={{display: 'flex', alignItems: 'center' }}>{'-'}</div>
  );
};

export default Status;
