import { P } from '@etiquette-ui/typography';
import useI18n from 'i18n/useI18n';
import React, { useEffect, useState } from 'react';
import useTags from 'state/tags';
import { mapIdsToObjects } from 'utils';

const Tags = ({ item }) => {
    const { translateObject } = useI18n();
    const [tags] = useTags();
    const [list, setList] = useState([]);

    useEffect(() => {
        if(tags.list.length === 0) return;
        const list = mapIdsToObjects(item.tags, tags.list);
        setList(list);
    }, [tags.list]);

    return (
        <div style={{ padding: '0 5px', display: 'flex', flexDirection: 'column', justifyContent: 'center', gap: '5px' }}>
            {list.map((tag) => (
                <div key={tag}>
                    <P>{translateObject(tag.translations).title}</P>
                </div>
            ))}
        </div>
    );
};

export default Tags;
