import { useContext } from 'react';
import { Stock86Context } from './provider';

const useStock86 = () => {
    const { isAlertOpened, lastSeenAt, setIsAlertOpened } = useContext(Stock86Context);

    return { isAlertOpened, lastSeenAt, setIsAlertOpened };
}


export default useStock86;