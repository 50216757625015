import styled from 'styled-components';

const ResponsiveSpacer = styled.div`
    display: none;
    @media (max-width: 768px) {
        display: block;
    }
`;

export default ResponsiveSpacer;
