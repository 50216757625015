import React, { useRef, useState, useEffect } from 'react';
import { Dropdown, RangeDateInput } from '@etiquette-ui/inputs-dropdowns';
import Hosts from './reports/Hosts';
import Servers from './reports/Servers';
import Managers from './reports/Manager';
import Select from './reports/Select';
import styled from 'styled-components';
import { dateFormat, DATE_FORMAT } from 'utils/date';
import PrintButton from 'components/Buttons/PrintButton';
import useI18n from 'i18n/useI18n';
import useStatistics from 'state/statistics';
import { useReactToPrint } from 'react-to-print';
import { Ring } from "@uiball/loaders";
import CardContainer from 'containers/CardContainer';
import ResponsiveCard from 'containers/ResponsiveCard';
import DownloadButton from 'components/Buttons/DownloadButton';
import Axios from 'axios';
import { getPDF } from 'resources/api/statistics';

const Container = styled.div`
    width: 100%;
    height: 100%;
    overflow: scroll;
    padding-bottom: 16px;
    padding-top: 15px;

    @media( max-width: 768px ){
        position: relative;
        padding-top: 80px;
    }    
`;
const TopHeader = styled.div`
    width: 100%;
    display: flex;
    margin-bottom: 15px;
    justify-content: space-between;

    @media( max-width: 768px ){
        overflow-x: scroll;
        overflow-y: visible;

        height: 100%;
        position: absolute;
        top: 15px;
        left: 0px;
        padding-left: 8px;
        padding-right: 8px;
    }
`;

const TopButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  padding-left: 8px;

  @media(max-width: 768px) {
    & > div:first-child { 
      display: none;
    }
  }
`;

const DropDownsContainer = styled.div`
    display: grid;
    grid-template-columns: 300px 220px;
    column-gap: 16px;
    margin-right: 15px;
`;
const CalendarContainer = styled.div`
    width: 100%;
    height: 100%;
    cursor: pointer;
    position: relative;    
`;

const ReportContainer = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    padding-bottom: 50px;
`;

const options = [
  {
    value: 'hosts',
    label: 'Hosts',
  },
  {
    value: 'servers',
    label: 'Servers',
  },
  {
    value: 'managers',
    label: 'Managers',
  },
];

const Employees = () => {
  const [data, setData] = useState(false);
  const [report, setReport] = useState(null);
  const [pdfLoading, setPdfLoading] = useState(false);
  const r = useRef();
  const content = useRef();
  const handlePrint = useReactToPrint({
    content: () => r.current,
  });
  const [dateRange, setDateRange] = useState([new Date(), new Date()]);
  const [startDate, endDate] = dateRange;
  const startDay = dateFormat(startDate, DATE_FORMAT);
  const endDay = dateFormat(endDate, DATE_FORMAT);
  const { translate } = useI18n();
  const [statistics, dispatcher] = useStatistics();

  useEffect(() => {
    if (!!report && !!startDate && !!endDate) {
      setData(false);
      const fetcher = getFectcher(report);
      fetcher(startDay, endDay);
    }
  }, [report, startDay, endDay]);

  useEffect(() => {
    if (!!statistics && !!report && !!statistics[report]) setData(statistics[report]);
  }, [statistics]);

  const getFectcher = (value) => {
    switch (value) {
      case 'host':
        return dispatcher.fetchHost;
      case 'servers':
        return dispatcher.fetchServers;
      case 'managers':
        return dispatcher.fetchManagers;

      default:
        return dispatcher.fetchHost;
    }
  };

  const getReport = () => {
    switch (report) {
      case 'hosts':
        return <Hosts data={data} isWaiting={statistics.isWaiting} />;
      case 'servers':
        return <Servers data={data} isWaiting={statistics.isWaiting} />;
      case 'managers':
        return <Managers data={data} isWaiting={statistics.isWaiting} />;
      default:
        return <Select />;
    }
  };

  const downloadPDF = async () => {
    try {
      setPdfLoading(true);
      const response = await getPDF("employee", { report, data })
      const blob = new Blob([response], { type: 'application/pdf' });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `report.pdf`);
      document.body.appendChild(link);
      link.click();
      window.URL.revokeObjectURL(url);
      link.parentNode.removeChild(link);
      setPdfLoading(false);
    } catch (error) {
      console.error('Error downloading the PDF', error);
    }
  }


  return (
    <Container>
      <TopHeader>
        <DropDownsContainer>
          <CalendarContainer>
            <RangeDateInput
              labelsProps={{ start: { label: translate('Start Date') }, end: { label: translate('End Date') } }}
              showIcon={false}
              dateFormat="MMM d''yy"
              values={dateRange}
              calendarProps={{ onChange: setDateRange }}
            />
          </CalendarContainer>
          <Dropdown
            labelProps={{ label: translate('Select statistic') }}
            selectProps={{
              options,
              onChange: (option) => {
                setReport(option.value);
              }
            }}
            containerProps={{
              styles: {
                height: 'fit-content'
              }
            }}
          />
        </DropDownsContainer>

        <TopButtonsContainer>
          {
            pdfLoading ?
              <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "8rem" }}>
                <Ring />
              </div> :
              <DownloadButton onClick={downloadPDF} />
          }
          <PrintButton onClick={handlePrint} />
        </TopButtonsContainer>

      </TopHeader>
      <CardContainer ref={content}>
        <ResponsiveCard>
          <ReportContainer ref={r}>{getReport()}</ReportContainer>
        </ResponsiveCard>
      </CardContainer>
    </Container>
  )
}
export default Employees;