import Axios from "axios";
import Authorization from './auth';
import { API } from '../constants/urls';

const STATISTICS_ENDPOINT = 'menu/statistics';
const GENERATE_PDF_ENDPOINT = 'menu/generate-pdf';

export const getHosts = (dateInit, dateEnd) => getStatistic('hosts', dateInit, dateEnd);

export const getServers = (dateInit, dateEnd) => getStatistic('servers', dateInit, dateEnd);

export const getManagers = (dateInit, dateEnd) => getStatistic('managers', dateInit, dateEnd);

export const getItemsByPerid = async (report, category, dateInit, dateEnd) => {
    try {
        const response = await Axios.get(`${API}/${STATISTICS_ENDPOINT}/items/${report}/${category}/${dateInit}/${dateEnd}`, { headers: Authorization });
        return response.data
    } catch (error) {
        throw error;
    }
}

export const getItemsByBulk = async (report, category, years, months) => {
    try {
        const response = await Axios.get(`${API}/${STATISTICS_ENDPOINT}/items/bulk/${report}/${category}/${years}/${months}`, { headers: Authorization });
        return response.data
    } catch (error) {
        throw error;
    }
}

async function getStatistic(stat, dateInit, dateEnd) {
    try {
        const response = await Axios.get(`${API}/${STATISTICS_ENDPOINT}/${stat}/${dateInit}/${dateEnd}`, { headers: Authorization });
        return response.data
    } catch (error) {
        throw error;
    }
}

export async function getPDF(path, data) {
    
    const domain = window.location.host
    const url = `http://${domain}/generate-pdf/${path}`;

    try {
        const response = await Axios.post(
            `${API}/${GENERATE_PDF_ENDPOINT}`,
            {
                data: {data},
                url
            },
            {
                headers: Authorization,
                responseType: 'blob'
            });
        return response.data
    } catch (error) {
        throw error;
    }
}
