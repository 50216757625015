import { PrimaryButton } from '@etiquette-ui/buttons';
import { P } from '@etiquette-ui/typography';
import React from 'react';
import { Content, ImageUploadFrame } from './styles';
import ImageUploading from 'react-images-uploading';
import useI18n from 'i18n/useI18n';

const ImageUpload = ({ onChange }) => {

    const { translate } = useI18n();

    return (<Content>
        <ImageUploading
            onChange={onChange}
            acceptType={["jpg", "jpeg", "png", "tiff", "tif", "webpg", "heic"]}
            allowNonImageType={true}
        >
            {({ imageList, onImageUpload, isDragging, dragProps }) => (
                <ImageUploadFrame dragging={isDragging} {...dragProps}>
                    <P>{isDragging ? translate('Drop file here') : translate('Drop files to upload or')}</P>
                    {!isDragging && <PrimaryButton onClick={onImageUpload}>
                        {translate('Select File')}
                    </PrimaryButton>}
                </ImageUploadFrame>
            )}
        </ImageUploading>
    </Content>);
}

export default ImageUpload;
