export default class Utils {
    constructor(state, dispatcher) {
        this.state = state;
        this.dispatcher = dispatcher;
    }
    
    findOne(id){
        return this.state.list.find(e => +e.variantId === +id);
    }

    findAll(idsList = []){
        const ids = idsList.map(id=>+id);
        return this.state.list.filter(e => ids.includes(+e.variantId));
    }
    
    findByPosCode(code){
        return this.state.list.find(e => e.posCode === code);
    }
    
    findByPosId(id){
        return this.state.list.find(e => e.posId === id);
    }
}