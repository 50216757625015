export const APP_ID = process.env.REACT_APP_APP_ID;
export const GROUP_ID = process.env.REACT_APP_GROUP_ID;
export const GROUP_NAME = process.env.REACT_APP_GROUP_NAME;
export const POS_ENABLED = !!+process.env.REACT_APP_POS_ENABLED;
export const STATISTICS_ENABLED = !!+process.env.REACT_APP_STATISTICS_ENABLED;
export const STATISTICS_DATE_LIMIT = process.env.REACT_APP_STATISTICS_DATE_LIMIT;

export const ACCESS_LIST = {
    MENU: 'menu',
    STATISTICS: 'statistics',
    EMPLOYEE_STATISTICS: 'employee_statistics',
}

export const ACCESS_USER_TYPES = {
    ROOT: 'root',
    GLOBAL: 'global',
    REGULAR: 'regular',
}