export const API = process.env.REACT_APP_API_URL;
export const ADMIN_API = process.env.REACT_APP_ADMIN_API_URL;

export const IMAGES_URL = process.env.REACT_APP_IMAGES_URL;
export const ICONS_URL = process.env.REACT_APP_ICONS_URL;

export const MENU_URL = process.env.REACT_APP_MENU_URL;

export const ADMIN_IMAGES = process.env.REACT_APP_IMAGES_ADMINSITE_URL;

export const ADMINSITE = process.env.REACT_APP_ADMINSITE_URL;

