import { combineReducers } from 'redux';
import products from './products/reducer';
import categories from './categories/reducer';
import tags from './tags/reducer';
import families from './families/reducer';
import ingredients from './ingredients/reducer';
import inactive86 from './inactive86/reducer';
import users from './users/reducer';
import config from './config/reducer';
import productHistory from './productHistory/reducer';
import pos from './pos/reducer';
import statistics from './statistics/reducer';
import filters from './filters/reducer';

import { connectRouter } from 'connected-react-router';

const rootReducer = (history) => combineReducers({
  products,
  categories,
  tags,
  families,
  ingredients,
  inactive86,
  users,
  config,
  productHistory,
  pos,
  statistics,
  filters,
  router: connectRouter(history),
});

export default rootReducer;