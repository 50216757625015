
import styled from "styled-components";

const Container = styled.div`
  width: 100%;
`;

const FiltersContainer = styled.div`
  width: 100%;

  scrollbar-width: none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const SwipeItem = styled.div`
  width: 100%;
  pointer-events: all;
  display: flex;
  flex-wrap: wrap;
`;


export {
  Container,
  FiltersContainer,
  SwipeItem
}