import React from 'react';
import { IMAGE_SHAPES } from '@etiquette-ui/dynamic-table-components';
import SortText from 'components/Table/headers/SortText';
import { IMAGES_URL } from 'resources/constants/urls';
import CustomImage from 'components/Table/cells/CustomImage';
import ResponsiveHeaderText from 'components/Table/responsive/HeaderText';
import ResponsiveText from 'components/Table/responsive/Text';
import ResponsiveEditableText from 'components/Table/responsive/EditableText';
import SortNumber from 'components/Table/headers/SortNumber';
import { formatMoney } from 'utils/text';
import RowError from './cells/RowError';
import RowActions from './cells/Actions';
import Status from './cells/Status';
import PresentationStatus from './cells/PresentationStatus';

const structure = {
    row: RowError,
    columns: [
        {
            flex: 1,
            header: ({ globalState }) => (
                <ResponsiveHeaderText style={{ marginLeft: '16px' }} text={globalState.config.translate('Image')} />
            ),
            body: ({ item }) => (
                <CustomImage image={item.img ? `${IMAGES_URL}${item.img}` : null} shape={IMAGE_SHAPES.RECTANGLE} />
            ),
        },
        {
            flex: 4,
            header: ({ globalState, ...rest }) => (
                <SortText property={'productTranslations.en.title'} text={globalState.config.translate('MENU - Item Name')} {...rest} />
            ),
            body: ({ item }) => <ResponsiveText text={item.productTranslations.en.title} style={{ marginLeft: '16px' }} />,
        },
        {
            flex: 3,
            header: ({ globalState }) => (
                <ResponsiveHeaderText text={globalState.config.translate('MENU - Presentation')} />
            ),
            body: ({ item }) => <ResponsiveText text={item.translations?.en} />,
        },
        
        {
            flex: 4,
            header: ({ globalState, ...rest }) => (
                <SortText property={'posName'} text={globalState.config.translate('POS - Item Name')} {...rest} />
            ),
            body: ({ item }) => <ResponsiveText text={item.posName} style={{ marginLeft: '16px' }} />,
        },
        {
            flex: 2,
            header: ({ globalState, ...rest }) => (
                <SortNumber property={'price'} text={globalState.config.translate('MENU - Price')} {...rest} />
            ),
            body: ({ item, state, setState }) => (
                <ResponsiveEditableText
                    editing={state.editing}
                    text={formatMoney(item.price)}
                    inputProps={{ value: state.price, onChange: (e) => setState('price', e.target.value) }}
                />
            ),
        },
        {
            flex: 2,
            header: ({ globalState, ...rest }) => (
                <SortNumber property={'posPrice'} text={globalState.config.translate('POS - Price')} {...rest} />
            ),
            body: ({ item }) => <ResponsiveText text={item.posPrice ? formatMoney(item.posPrice) : ''} />,
        },
        {
            flex: 3,
            header: ({ globalState, ...rest }) => (
                <SortText property={'posCode'} text={globalState.config.translate('POS Code')} {...rest} />
            ),
            body: ({ item, state, setState }) => (
                <ResponsiveEditableText
                    editing={state.editing}
                    text={item.posCode}
                    inputProps={{ value: state.posCode, onChange: (e) => setState('posCode', e.target.value) }}
                />
            ),
        },
        {
            flex: 2,
            header: ({ globalState, ...rest }) => (
                <SortNumber property={'posId'} text={globalState.config.translate('POS ID')} {...rest} />
            ),
            body: ({ item }) => <ResponsiveText text={item.posId} />,
        },
        {
            flex: 2,
            header: () => <ResponsiveHeaderText text={''} />,
            body: RowActions,
        },
        {
            flex: 2,
            header: ({ globalState }) => (
              <ResponsiveHeaderText text={globalState.config.translate('Item Status')} style={{ justifyContent: 'flex-start', padding: 0 }} />
            ),
            body: Status,
        },
        {
            flex: 2,
            header: ({ globalState }) => (
                <ResponsiveHeaderText text={globalState.config.translate('Presentation Status')} style={{ justifyContent: 'flex-start', padding: 0 }} />
            ),
            body: PresentationStatus,
        },
    ],
};

export default structure;
