import React, { useState, useEffect } from 'react';
import { IMAGES_URL } from 'resources/constants/urls';
import useCategories from 'state/categories';
import useProducts from 'state/products';
import { Content, ImagesContainer, Image } from './styles';

const ImageComponent = ({ item, selectedImage, setSelectedId, setSelectedImage }) => {
    const getSource = (img) => {
        if (!!img && (typeof img === 'string' || img instanceof String)) {
            return `${IMAGES_URL}${item.img}`;
        }
        if (!!img && typeof img === 'object') {
            return `${img.dataURL}`;
        }
        return '';
    };

    const onClick = () => {
        setSelectedId(item.id);
        setSelectedImage(item.img);
    }

    return (
        <Image src={`${getSource(item.img)}`} selected={item.id === selectedImage} onClick={onClick} />
    );
};

const ImageGrid = ({ selectedOption, searchField = '', uploadedImage = null, setSelectedImage, selectedId, setSelectedId  }) => {
    const [categories] = useCategories();
    const [products, productDispatcher] = useProducts();
    const [filtered, setFiltered] = useState([]);

    useEffect(() => {
        productDispatcher.fetchStart();
    }, []);

    useEffect(() => {
        const imagesList = [];
        if (uploadedImage !== null) {
            imagesList.push({
                id: -1,
                img: uploadedImage,
            });
        }

        const filter = (property) => property && property.en.title.toLowerCase().includes(searchField.toLowerCase());

        switch (selectedOption.value) {
            case 0:
                setFiltered([
                    ...imagesList,
                    ...categories.list.filter((cat) => filter(cat.translations)),
                    ...categories.subcategories.filter((cat) => filter(cat.translations)),
                ]);
                break;
            case 1:
                setFiltered([...imagesList, ...products.list.filter((p) => filter(p.translations))]);
                break;
            default:
                return;
        }
    }, [categories, products, selectedOption, searchField, uploadedImage]);

    return (
        <Content>
            <ImagesContainer>
                {filtered.map(
                    (item, i) =>
                        !!item.img && (
                            <ImageComponent
                                key={item.id}
                                item={item}
                                selectedImage={selectedId}
                                setSelectedId={setSelectedId}
                                setSelectedImage={setSelectedImage}
                            />
                        )
                )}
            </ImagesContainer>
        </Content>
    );
};

export default ImageGrid;
