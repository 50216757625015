import CATEGORY_KEYS from "./keys";

const findCategory = (list, id) => {
  const cat = list.find((c)=>c.id===id);
  return cat ? { translations: cat.translations, value: cat.id } : null;
}

export const toFormData = (category, categories) => {

  const processed = {
    ...category,
    [CATEGORY_KEYS.PARENT_ID]: findCategory(categories, category[CATEGORY_KEYS.PARENT_ID])
  };

  return processed;
};

export const toSubmitObject = (formData) => {

  const item = {
    [CATEGORY_KEYS.ACTIVE]: formData[CATEGORY_KEYS.ACTIVE],
    [CATEGORY_KEYS.TRANSLATIONS]: formData[CATEGORY_KEYS.TRANSLATIONS],
    [CATEGORY_KEYS.PARENT_ID]: formData[CATEGORY_KEYS.PARENT_ID].value,
  };

  const image = formData[CATEGORY_KEYS.IMAGE];
  item[CATEGORY_KEYS.IMAGE] = image?.file ? image.file : image || null;

  return item;
};