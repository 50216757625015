import React, { useState } from 'react';
import MainLayout from 'containers/MainLayout';
import { Dropdown } from '@etiquette-ui/inputs-dropdowns';
import { Card } from '@etiquette-ui/misc';
import styled from 'styled-components';
import Report from './Report';
import dashboards from './dashboards';
import useI18n from 'i18n/useI18n';

import CardContainer from 'containers/CardContainer';
import ResponsiveCard from 'containers/ResponsiveCard';

const Container = styled.div`
    width: 100%;
    height: 100%;
    overflow: scroll;
    padding-bottom: 16px;
    padding-top: 15px;
`;
const DropdownContainer = styled.div`
    width: 280px;
    margin-bottom: 15px;
    padding-left: 8px;
`;
const MessageContainer = styled.div`
    width: 100%;
    min-height: 492px;
    height: 100%;

    display: flex;
    align-items: center;
    justify-content: center;

    font-size: 29px;
    text-align: center;
`;
const Message = ({ message }) => <MessageContainer>{message}</MessageContainer>;

const PowerBi = ({ location }) => {
    const [report, setReport] = useState(null);
    const { translate } = useI18n();
    const options = dashboards.map((i) => ({ label: i.label, value: i.id }));

    const onChange = (item) => {
        setReport({url: item.value, title: item.label});
    };

    return (
        <MainLayout location={location}>
            <Container>
                <DropdownContainer>
                    <Dropdown
                        labelProps={{ label: translate('Select Report') }}
                        selectProps={{
                            options,
                            onChange,
                        }}
                    />
                </DropdownContainer>
                <CardContainer>
                    <ResponsiveCard>
                        {!!report ? <Report url={report.url} title={report.title} /> : <Message message={translate('Please Select a Report')} />}
                    </ResponsiveCard>
                </CardContainer>
            </Container>
        </MainLayout>
    );
};

export default PowerBi;
