import useI18n from 'i18n/useI18n';
import React, { useEffect, useMemo, useReducer } from 'react';
import useCategories from 'state/categories';
import SubcategoryForm from '../../components/SubcategoryForm';
import INIT_STATE from '../../components/SubcategoryForm/initState';
import { toSubmitObject, toFormData } from '../../components/SubcategoryForm/processor';
import Reducer, { ACTIONS } from '../../components/SubcategoryForm/Reducer';
import { useParams } from 'react-router';

const Edit = ({ location }) => {
    const [data, categoriesDispatcher, categoriesUtils] = useCategories();
    const [formData, formDispatcher] = useReducer(Reducer, INIT_STATE());
    const dispatch = useMemo(() => (type, payload) => formDispatcher({ type, payload }), [formDispatcher]);
    const { translate } = useI18n();
    const { subcategoryId } = useParams();

    useEffect(() => {
        categoriesDispatcher.fetchStart();
    }, []);

    useEffect(() => {
        if (data.fetching.state || data.subcategories.length === 0 || data.list.length === 0) return;
        const item = toFormData(categoriesUtils.findSubcategory(subcategoryId), data.list);
        
        dispatch(ACTIONS.INIT, item);
      }, [data.subcategories, data.list]);

    const submit = (e) => {
        e.preventDefault();
        const item = toSubmitObject(formData);

        categoriesDispatcher.updateStart('subcategories', {id: +subcategoryId, ...item});
    };

    return (
        <SubcategoryForm
            location={location}
            formData={formData}
            dispatch={dispatch}
            submit={submit}
            title={translate('Edit Subcategory')}
            button={translate('Update Subcategory')}
        />
    );
};

export default Edit;
