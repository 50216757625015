import { BaseDialog } from '@etiquette-ui/alert-dialog';
import { PrimaryButton, SecondaryButton } from '@etiquette-ui/buttons';
import { PRIMARY, WRONG } from '@etiquette-ui/colors';
import { Dropdown, Input } from '@etiquette-ui/inputs-dropdowns';
import styled from 'styled-components';

export const DialogContainer = styled(BaseDialog)`
    > div {
        width: 90%;
        height: 80%;

        & > div {
            flex: 1;
            min-height: 0;

            & > div {
                flex: 1;
                min-height: 0;
            }
        }
    }
`;

export const Header = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 30px;
    padding: 10px 0;
    border-bottom: solid 0.5px #d2d2d2;
    margin-bottom: 10px;

    @media( max-width: 768px ){
        grid-template-columns: 1fr;
    }
`;

export const Select = styled(Dropdown)`
    width: 60%;

    @media( max-width: 768px ){
        width: 60%;

    }
`;

export const PickersSection = styled.div`
    display: flex;
    align-items: center;
    gap: 20px;

    @media( max-width: 768px ){
        margin-bottom: 15px;
    }
`;

export const SearchSection = styled.div`
    display: flex;
    align-items: center;
    & > div {
        width: 80%;
    }
`;

export const Content = styled.div`
    display: flex;
    padding: 10px;
    flex: 1;
    min-height: 0;
`;

export const ImagesContainer = styled(Content)`
    display: grid;
    grid-gap: 20px;
    grid-template-columns: repeat(auto-fit, 90px);
    grid-template-rows: repeat(90px);
    overflow: scroll;
`;

export const ImageUploadFrame = styled.div`
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    justify-content: center;
    border: 1px dashed ${({ dragging }) => (dragging ? `${WRONG}` : '#707070')};
    flex: 1;
`;

export const Image = styled.img`
    width: 90px;
    height: 90px;
    outline: solid ${({ selected }) => (selected ? `5px ${PRIMARY}` : '1px black')};
    cursor: pointer;
`;

export const Cancel = styled(SecondaryButton)`
    flex: 1;
`;

export const Accept = styled(PrimaryButton)`
    flex: 1;
`;

export const ActionsContainer = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 10px;
    gap: 30px;
`;