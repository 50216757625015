import { INPUTS } from '@etiquette-ui/colors';
import styled from 'styled-components';

const RowContainer = styled.div`
    display: grid;
    grid-template-columns: ${({ columns }) => columns};
    column-gap: 5px;
    overflow-y: visible;
    padding: 5px 0;
    min-height: 45px;
    border-radius: 8px;
    background-color: ${({count})=>count%2===0 ? `${INPUTS}` : 'transparent'};

    @media (max-width: 768px) { 
        min-height: 40px;
        padding: 2px 0;
    }
`;

export default RowContainer;