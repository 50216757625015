
export const DEFAULT_VIEW_OPTIONS = [
    { label: 'Gallery View', value: 'gallery' },
    { label: 'List View', value: 'list' },
]

export const TAGS_FILTER_TYPES_OPTIONS = [
    { label: 'Accumulative', value: 'accumulative' },
    { label: 'Unique', value: 'unique' },
]

export const findLabel = (list, value) => {
    const obj = list.find((item)=>item.value===value);
    return obj ? obj.label : '';
}

export const findOption = (list, value) => {
    return list.find((item)=>item.value===value);
}