import React from 'react';
import { ALERT_TYPES } from '@etiquette-ui/alert-dialog';
import { INPUTS } from '@etiquette-ui/colors';

import StatusOptions from '../alert/StatusOptions';
import { ITEM_STATUS } from 'utils/status';
import Inactive86Alert from '../alert/Inactive86Alert';
import StatusButton from 'components/Buttons/StatusButton';


const Status = ({ globalState, item, count }) => {
  const alert = globalState.config.alert;
  const productsDispatcher = globalState.config.productsDispatcher;
  const fetching = globalState.config.fetching;

  const showAlert = () => {
    alert({
      type: ALERT_TYPES.CUSTOM,
      config: {
        body: StatusOptions,
        props: {
          item,
        },
      },
    })
      .then((status) => {
        if (status === ITEM_STATUS.ACTIVE || status === ITEM_STATUS.INACTIVE || status === ITEM_STATUS.ARCHIVED) {
          productsDispatcher.updateStart({ id: item.id, status, stockTotal: 1 }, false);
          return;
        }
        alert({
          type: ALERT_TYPES.CUSTOM,
          config: {
            body: Inactive86Alert,
            props: {
              item,
            },
          },
        })
          .then(({ date, reason }) => {
            productsDispatcher.inactive86({ id: item.id, why: reason, estimate: date });
          })
          .catch(() => {});
      })
      .catch(() => {});
  };

  return (
    <StatusButton
      item={item}
      onClick={showAlert}
      loading={fetching.state && fetching.config?.id === item.id}
      spinnerBackground={count % 2 === 0 ? INPUTS : 'white'}
    />
  );
};

export default Status;
