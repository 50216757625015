import styled from 'styled-components';

const FullPageLayout = styled.div`
    display: flex;
    flex-direction: column;
    width: 90%;
    position: relative;
    min-height: 100%;
    justify-content: flex-start;

    @media( max-width: 768px ){
        display: grid;
        width: 100%;
    }
`;

export default FullPageLayout;
