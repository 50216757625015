import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import ListActionsContainer from 'pages/MenuItems/containers/ListActionsContainer';
import ListLayout from 'pages/MenuItems/containers/ListLayout';
import MenuTabsContainer from 'pages/MenuItems/containers/MenuTabsContainer';
import Filter from 'components/Filter';
import Table from 'components/Table';
import useCategories from 'state/categories';
import structure from './structure';
import useI18n from 'i18n/useI18n';
import {
    getTranslatedCategories,
    getProcessedCategoriesWithOptions
} from 'pages/MenuItems/utils/transformers';
import { filterByParentCategory } from 'pages/MenuItems/utils/filters';
import { onSelectCategory } from 'pages/MenuItems/utils/events';
import styled from 'styled-components';
import ResponsiveArrangeButton from 'pages/MenuItems/components/ResponsiveArrangeButton';
import CardContainer from 'containers/CardContainer';
import ResponsiveCard from 'containers/ResponsiveCard';
import FloatingArrangeButton from 'pages/MenuItems/components/FloatingArrangeButton';
import ResponsiveSpacer from 'pages/MenuItems/components/ResponsiveSpacer';
import { useSearch } from 'providers/search';
import { compareStrings } from 'utils';
import useFilters from '../../filters/useFilters';
import { PRIMARY } from '@etiquette-ui/colors';
import { Spinner } from '@etiquette-ui/misc';

const Actions = styled(ListActionsContainer)`
    justify-content: flex-end;
    @media (max-width: 768px) {
        justify-content: flex-start;
    }
`;

const List = ({ location }) => {
    const { translate, translateObject, selectedLang } = useI18n();
    const history = useHistory();
    const { setVisible, search } = useSearch();

    const [data, setData] = useState([]);
    const {selectedCategories, setSelectedCategories, initFilters} = useFilters();
    const [categoriesOptions, setCategoriesOptions] = useState([]);
    const [categories, dispatcher] = useCategories();
    const [defaultCategory, setDefaultCategory] = useState(null);

    useEffect(() => {
        const processed = getProcessedCategoriesWithOptions(categories);
        setDefaultCategory(processed.filter(cat => cat.value === -1));
        setCategoriesOptions(processed);
        initFilters(processed);
    }, [categories.list, categories.subcategories]);

    useEffect(() => {
        dispatcher.fetchStart();
        setVisible(true);
        return () => setVisible(false);
    }, []);

    useEffect(() => {
        const filtered = filterByParentCategory(categories.subcategories, selectedCategories).filter(({ translations }) =>
            Object.values(translations).some(
                ({ title, description }) => compareStrings(title ?? '', search) || compareStrings(description ?? '', search)
            )
        );
        setData(filtered);
    }, [selectedCategories, search]);

    const arrange = () => history.push('subcategories/arrange');

    const getPlaceholder = ( categories ) => {
        let placeholder = '';

        categories.forEach( (category, index) => {
            if( index !== 0 ) placeholder += ' | ';
            if( typeof category.label === 'string') placeholder += category.label;
            else placeholder += translateObject(category.label).title;
        });

        return placeholder;
    }

    return (
        <MenuTabsContainer location={location} fabLink={`${location.pathname}/create`} fabText={'Add Item'}>
            <ListLayout>
                <Actions>
                    <div style={{ width: '30%' }}>
                        <Filter
                            labelProps={{ label: translate('Category') }}
                            selectProps={{
                                onChange: onSelectCategory(selectedCategories, setSelectedCategories, defaultCategory),
                                options: getTranslatedCategories(categoriesOptions, translate, selectedLang),
                                placeholder: getPlaceholder(selectedCategories),
                            }}
                            selectedValues={selectedCategories}
                        />
                    </div>
                    <div style={{ flex: 1 }}></div>
                    <ResponsiveArrangeButton onClick={arrange} />
                </Actions>
                <ResponsiveSpacer />
                <CardContainer>
                    <ResponsiveCard>
                        { data.length > 0 
                            ? (<Table items={data} structure={structure} config={{ translate }} />)
                            : (<Spinner
                                style={{
                                    '--color': PRIMARY,
                                    '--bg': 'white',
                                    'margin': 'auto',
                                    'marginTop': '25px',
                                }}
                            />)
                        }
                    </ResponsiveCard>
                </CardContainer>
            </ListLayout>
            <FloatingArrangeButton onClick={arrange} />
        </MenuTabsContainer>
    );
};

export default List;
