import { ALERT_TYPES, useAlert } from '@etiquette-ui/alert-dialog';
import { PRIMARY, WRONG } from '@etiquette-ui/colors';
import { AddCommentIcon } from '@etiquette-ui/icons';
import { P, SubtitleSmall } from '@etiquette-ui/typography';
import useI18n from 'i18n/useI18n';
import React from 'react';
import useInactive86 from 'state/inactive86';
import styled from 'styled-components';
import AddComment from '../alert/AddComment';

const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 25px;
`;

const Button = styled.div`
    cursor: pointer;
    display: flex;
    align-items: center;
    p {
        color: ${PRIMARY};
        margin-right: 10px;
    }
`;

const Comments = ({ item }) => {
    const { translate } = useI18n();
    const alert = useAlert();
    const [, inactive86Dispatcher] = useInactive86();

    const onClick = () => {
        alert({
            type: ALERT_TYPES.CUSTOM,
            config: {
              body: AddComment,
              props: {
                item,
              },
            },
          })
            .then(({ date, reason }) => {
              inactive86Dispatcher.comment({ id: item.id, why: reason, estimate: date, productId: item.productId });
            })
            .catch(() => {});
    };

    return item.id ? (
        <Container>
            <Button onClick={onClick}>
                <SubtitleSmall>{translate('Comments')}</SubtitleSmall>
                <AddCommentIcon
                    styles={{
                        svg: {
                            width: '20px',
                            height: '20px',
                            marginRight: '10px',
                        },
                        line: {
                            fill: 'none',
                            strokeLinecap: 'round',
                            strokeLinejoin: 'round',
                            strokeWidth: '2px',
                            stroke: PRIMARY,
                        },
                    }}
                />
            </Button>
        </Container>
    ) : (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <P style={{color: WRONG}}>{translate('Unable to add comment')}</P>
        </div>
    );
};

export default Comments;
