const TYPE = `[TAGS]`;

export default {
    FETCH_START: `${TYPE} FETCH START`,
    FETCH_SUCCESS: `${TYPE} FETCH SUCCESS`,
    FETCH_ERROR: `${TYPE} FETCH ERROR`,

    CREATE_START: `${TYPE} CREATE START`,
    CREATE_SUCCESS: `${TYPE} CREATE SUCCESS`,
    CREATE_ERROR: `${TYPE} CREATE ERROR`,

    RESET_ERROR: `${TYPE} RESET ERROR`,
}