import React from 'react';
import { Dropdown, Input } from '@etiquette-ui/inputs-dropdowns';
import { SwitchButton } from '@etiquette-ui/selectors';
import { Subtitle, P } from '@etiquette-ui/typography';
import FormSpacer from 'components/Form/FormSpacer';
import FormSection from 'containers/Form/FormSection';
import GridContainer from 'containers/Form/GridContainer';
import useI18n from 'i18n/useI18n';
import { ACTIONS } from '../Reducer';
import SUBCATEGORY_KEYS from '../keys';
import { TEXT } from '@etiquette-ui/colors';
import useCategories from 'state/categories';

const Details = ({ formData, dispatch, handleItemInfo }) => {
    const { translate, translateObject } = useI18n();
    const [categories] = useCategories();
    return (
        <FormSection>
            <Subtitle>{translate('Details')}</Subtitle>
            <FormSpacer />
            <GridContainer>
                <Input
                    labelProps={{ label: translate('Subcategory Name (English)') }}
                    iconProps={{ icon: 'title' }}
                    inputProps={{
                        value: formData[SUBCATEGORY_KEYS.TRANSLATIONS].en.title,
                        onChange: (e) => dispatch(ACTIONS.TRANSLATIONS, { lang: 'en', text: 'title', value: e.target.value }),
                        required: true,
                    }}
                />
                <Input
                    labelProps={{ label: translate('Subcategory Name (Spanish)') }}
                    iconProps={{ icon: 'title' }}
                    inputProps={{
                        value: formData[SUBCATEGORY_KEYS.TRANSLATIONS].es.title,
                        onChange: (e) => dispatch(ACTIONS.TRANSLATIONS, { lang: 'es', text: 'title', value: e.target.value }),
                        required: true,
                    }}
                />
            </GridContainer>
            <FormSpacer />
            <GridContainer>
                <Dropdown
                    labelProps={{
                        label: translate('Assign to Category'),
                    }}
                    leadingIconProps={{
                        icon: 'list',
                        styles: {
                            line: {
                                fill: 'none',
                                strokeLinecap: 'round',
                                strokeLinejoin: 'round',
                                strokeWidth: '1.5px',
                                stroke: TEXT,
                            },
                        },
                    }}
                    selectProps={{
                        onChange: (value) => handleItemInfo(SUBCATEGORY_KEYS.PARENT_ID, value),
                        options: categories.list.map((opt) => ({
                            label: translateObject(opt.translations).title,
                            value: opt.id,
                            translations: opt.translations,
                        })),
                        placeholder: translate('Pick one...'),
                        value: formData[SUBCATEGORY_KEYS.PARENT_ID] ? {
                            label: translateObject(formData[SUBCATEGORY_KEYS.PARENT_ID].translations).title,
                            value: formData[SUBCATEGORY_KEYS.PARENT_ID],
                        } : null,
                    }}
                />
            </GridContainer>
            <FormSpacer />
            <div style={{ display: 'flex', marginLeft: '-10px', alignItems: 'center' }} onClick={() => handleItemInfo(SUBCATEGORY_KEYS.ACTIVE, !formData[SUBCATEGORY_KEYS.ACTIVE])} >
                <SwitchButton active={formData[SUBCATEGORY_KEYS.ACTIVE]} />
                <P style={{marginLeft: '15px'}}>
                {translate(
                    formData[SUBCATEGORY_KEYS.ACTIVE] ? 'Subcategory active' : 'Subcategory inactive'
                )}
                </P>
            </div>
        </FormSection>
    );
};

export default Details;
