import { Checkbox } from '@etiquette-ui/selectors';
import { Subtitle, SubtitleSmall } from '@etiquette-ui/typography';
import FormSpacer from 'components/Form/FormSpacer';
import CustomGridContainer from 'containers/Form/CustomGridContainer';
import FormSection from 'containers/Form/FormSection';
import useI18n from 'i18n/useI18n';
import React, { useEffect } from 'react';
import useCategories from 'state/categories';
import styled from 'styled-components';
import ITEM_KEYS from '../keys';
import { ACTIONS } from '../Reducer';

const Check = styled(Checkbox)`
  margin: 10px 0;
`;

const Grid = styled(CustomGridContainer)`
  align-items: flex-start;
`;

const Subcategories = ({ formData, dispatch }) => {
  const { translate, translateObject } = useI18n();
  const [, , utils] = useCategories();

  return (
    formData[ITEM_KEYS.CATEGORIES].length > 0 && (
      <>
        <FormSection>
          <Subtitle>{translate('Subcategory')}</Subtitle>
          <FormSpacer />
          <Grid columns={3}>
            {formData[ITEM_KEYS.CATEGORIES].map(({ category, subcategories }, idx) => (
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <SubtitleSmall>{translateObject(utils.findOne(category).translations).title}</SubtitleSmall>
                {utils.findOne(category).subcategories.map(({ id, translations }) => (
                  <Check
                    key={id}
                    checked={subcategories.includes(id)}
                    onClick={() => dispatch(ACTIONS.SUBCATEGORIES, { categoryIndex: idx, subcategory: id })}
                  >
                    {translateObject(translations).title}
                  </Check>
                ))}
              </div>
            ))}
          </Grid>
        </FormSection>
        <FormSpacer />
      </>
    )
  );
};

export default Subcategories;
