import PrintButton from "components/Buttons/PrintButton";
import React from "react";
import styled from "styled-components";
import { usePrintButton } from "./NavButtonsProvider";
import DownloadButton from "components/Buttons/DownloadButton";
import { Ring } from "@uiball/loaders";

const PrintButtonContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    gap: 1rem; 
`;

const StyledButton = styled.button`
    border: none;
    background: none;
    padding: 0; 
    margin: 0; 
`;

const NavPrintButton = () => {
    const [isLoading, setIsLoading] = React.useState(false);
    const { printFunction, downloadFunction } = usePrintButton();

    const handleDownload = async () => {
        setIsLoading(true);
        await downloadFunction();
        setIsLoading(false);
    }

    return (
        <PrintButtonContainer>
            {printFunction && (
                <>
                    {
                        isLoading ? (
                            <div style={{display: "flex", justifyContent: "center", alignItems: "center", width: "8rem"}}>
                                <Ring />
                            </div>
                        ) : (
                            <DownloadButton onClick={handleDownload} />
                        )
                    }
                    <StyledButton as={PrintButton} onClick={printFunction} />
                </>
            )}
        </PrintButtonContainer>
    );
};

export default NavPrintButton;
