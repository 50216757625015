import React from 'react';
import styled from 'styled-components';
import { PRIMARY } from '@etiquette-ui/colors';
import Icon from '@etiquette-ui/icons';
import { Spinner } from '@etiquette-ui/misc';

const ActionsContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: visible;
`;

const IconButton = styled.button`
    cursor: pointer;
    background-color: transparent;
    border: none;
    outline: none;
    ${({ disabled }) => (disabled ? 'cursor: default; pointer-events: none; opacity: 0.2;' : '')}
`;

const SpinnerContainer = styled.div`
    width: 25px;
`;

const Action = styled.div`
    margin: 0 5px;
`;

const Actions = ({ loading, actions, style, spinnerBackground, spinnerForeground }) => {
    return (
        <ActionsContainer style={style}>
            <>
                <SpinnerContainer>
                    {!!loading && (
                        <Spinner
                            style={{
                                '--color': spinnerForeground ?? PRIMARY,
                                '--bg': spinnerBackground ?? 'white',
                                margin: '0 5px',
                                size: '25px',
                            }}
                        />
                    )}
                </SpinnerContainer>
                {actions.map(({ event, icon, iconConfig={}, child, disabled }, i) => {
                    return (
                        <Action key={i}>
                            {!!icon ? (
                                <IconButton type="button" disabled={disabled || loading} onClick={disabled || loading ? () => {} : event}>
                                    <Icon
                                        icon={icon}
                                        styles={{
                                            svg: { height: '20px' },
                                            line: {
                                                fill: 'none',
                                                strokeLinecap: 'round',
                                                strokeLinejoin: 'round',
                                                strokeWidth: '2px',
                                                stroke: PRIMARY,
                                            },
                                            ...iconConfig,
                                        }}
                                    />
                                </IconButton>
                            ) : (
                                child
                            )}
                        </Action>
                    );
                })}
            </>
        </ActionsContainer>
    );
};

export default Actions;
