import { DEFAULT_VIEW_OPTIONS, findLabel, TAGS_FILTER_TYPES_OPTIONS } from "./config";
import { DEFAULT_CATEGORY_CONFIG } from "./initState";
import CATEGORY_KEYS, { CATEGORY_CONFIG_KEYS } from "./keys";

const getView = (config) => {
  if(!config || !config[CATEGORY_CONFIG_KEYS.VIEW]) return ({label: findLabel(DEFAULT_VIEW_OPTIONS, 'gallery'), value: 'gallery'});
  const view = config[CATEGORY_CONFIG_KEYS.VIEW];

  return {label: findLabel(DEFAULT_VIEW_OPTIONS, view), value: view};
}

const getTagFilter = (config) => {
  if(!config || !config[CATEGORY_CONFIG_KEYS.FILTER_TYPE]) return ({label: findLabel(TAGS_FILTER_TYPES_OPTIONS, 'accumulative'), value: 'accumulative'});
  const tags = config[CATEGORY_CONFIG_KEYS.FILTER_TYPE];

  return {label: findLabel(TAGS_FILTER_TYPES_OPTIONS, tags), value: tags};
}

export const toFormData = (category) => {

  const processed = {
    ...category,
    [CATEGORY_KEYS.CONFIG]: {
      ...DEFAULT_CATEGORY_CONFIG(),
      ...(category.config ?? {}),
      [CATEGORY_CONFIG_KEYS.VIEW]: getView(category.config),
      [CATEGORY_CONFIG_KEYS.FILTER_TYPE]: getTagFilter(category.config),
    }
  };

  return processed;
};

export const toSubmitObject = (formData) => {

  const item = {
    [CATEGORY_KEYS.ACTIVE]: formData[CATEGORY_KEYS.ACTIVE],
    [CATEGORY_KEYS.TRANSLATIONS]: formData[CATEGORY_KEYS.TRANSLATIONS],
    [CATEGORY_KEYS.CONFIG]: {
      ...formData.config,
      [CATEGORY_CONFIG_KEYS.VIEW]: formData.config[CATEGORY_CONFIG_KEYS.VIEW].value,
      [CATEGORY_CONFIG_KEYS.FILTER_TYPE]: formData.config[CATEGORY_CONFIG_KEYS.FILTER_TYPE].value,
    }
  };

  const image = formData[CATEGORY_KEYS.IMAGE];
  item[CATEGORY_KEYS.IMAGE] = image?.file ? image.file : image || null;

  return item;
};