import React from 'react';
import styled from 'styled-components';
import Search from './components/Search';
import User from './components/User';
import Lang from './components/Lang';
import Logout from './components/Logout';
import Logo from './components/Logo';
import SearchSmall from './components/SearchSmall';
import ShowMenu from './components/ShowMenu';

const Header = styled.header`
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  grid-gap: 10px;
  background-color: #012739;
  color: white;
  border-radius: 0px 0px 25px 25px;
  align-items: center;
  height: 100%;
  width: 100%;
  padding: 0 1rem;

  & .searchIconToggle {
    display: none;
  }

  @media (max-width: 992px) {
    grid-template-columns: 1fr 1fr 2fr;
  }
  @media (max-width: 768px) {
    background-color: white;
    grid-template-columns: 1fr 1fr;
    border-radius: 0;
    position: relative;

    & .searchIconToggle:checked + div {
      transform: translateY(0%) scale(1);
    }
  }
`;

const Left = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 0px;
`;

const Middle = styled.div`
  display: flex;
  justify-content: center;

  @media (max-width: 768px) {
    width: 98%;
    top: 105%;
    left: 1%;
    position: absolute;
    transform: translateY(-50%) scale(0);
    transition-duration: .5s;
  }
`;

const Right = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 100%;
  @media (max-width: 768px) {
    justify-content: end;
  }
`;

const MainHeader = () => {

  return (
    <Header>
      <Left>
        <Logo />
      </Left>
      <input 
        type="checkbox" 
        name='searchIconToggle' 
        className='searchIconToggle'
        id='searchIconToggle'
      />
      <Middle>
        <Search />
      </Middle>
      <Right>
        <Lang />
        <User />
        <SearchSmall />
        <Logout />
        <ShowMenu />
      </Right>
    </Header>
  );
};

export default MainHeader;
