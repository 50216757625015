import { ALERT_TYPES, useAlert } from '@etiquette-ui/alert-dialog';
import { TextButton } from '@etiquette-ui/buttons';
import { PRIMARY } from '@etiquette-ui/colors';
import { AddIcon } from '@etiquette-ui/icons';
import { Spinner } from '@etiquette-ui/misc';
import { Checkbox } from '@etiquette-ui/selectors';
import { Subtitle } from '@etiquette-ui/typography';
import FormSection from 'containers/Form/FormSection';
import useI18n from 'i18n/useI18n';
import React, { useEffect } from 'react';
import useTags from 'state/tags';
import styled from 'styled-components';
import ITEM_KEYS from '../keys';
import { ACTIONS } from '../Reducer';
import CreateTagDialog from './alert/CreateTagDialog';

const Check = styled(Checkbox)`
    margin: 10px 0;
`;

const AddButton = styled(TextButton)`
    button {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
    }
`;

const Tags = ({ formData, dispatch }) => {
    const { translate, translateObject } = useI18n();
    const [tags, dispatcher] = useTags();
    const alert = useAlert();

    useEffect(() => {
        dispatcher.fetchStart();
    }, []);

    const onNewTag = () => {
        alert({
            type: ALERT_TYPES.CUSTOM,
            config: {
                body: CreateTagDialog,
                props: {},
            },
        })
            .then((newTag) => {
                dispatcher.createStart(newTag);
            })
            .catch(() => {});
    };

    return (
        <FormSection>
            <Subtitle>{translate('Tags')}</Subtitle>
            <div style={{ height: '10px' }}></div>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                {tags.list.map(({ id, translations }) => (
                    <Check key={id} checked={formData[ITEM_KEYS.TAGS].includes(id)} onClick={() => dispatch(ACTIONS.TAGS, id)}>
                        {translateObject(translations).title}
                    </Check>
                ))}
            </div>
            <div style={{ height: '10px' }}></div>
            <AddButton type="button" onClick={onNewTag}>
                {tags.fetching.state ? (
                    <Spinner style={{ '--size': '40px', '--color': '#364456', '--bg': '#fff' }}/>
                ) : (
                    <>
                        <AddIcon
                            styles={{
                                svg: { width: '20px', height: '20px' },
                                line: {
                                    fill: 'none',
                                    strokeLinecap: 'round',
                                    strokeLinejoin: 'round',
                                    strokeWidth: '1.5px',
                                    stroke: PRIMARY,
                                },
                            }}
                        />
                        {translate('New Tag')}
                    </>
                )}
            </AddButton>
        </FormSection>
    );
};

export default Tags;
