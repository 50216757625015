import MainLayout from 'containers/MainLayout';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Table from 'components/Table';
import structure from './structure';
import useI18n from 'i18n/useI18n';
import { useAlert } from '@etiquette-ui/alert-dialog';
import { Card } from '@etiquette-ui/misc';
import { Dropdown } from '@etiquette-ui/inputs-dropdowns';
import CardContainer from 'containers/CardContainer';
import usePOS from 'state/pos';
import { ITEM_STATUS } from 'utils/status';
import { useSearch } from 'providers/search';
import { compareStrings } from 'utils';

const Container = styled.div`
    width: 100%;
    height: 100%;
    max-height: 100%;
    padding-bottom: 16px;
    display: grid;
    grid-template-rows: 3.5rem minmax(0, 1fr);
    gap: 16px;
`;

const ResponsiveCard = styled(Card)`
    height: 100%;
    @media (max-width: 768px) {
        width: unset;
        min-width: 900px;
        margin-right: 8px;
    }
`;

const DropdownContainer = styled.div`
    height: 100%;

    padding: 10px 8px 0px 8px;

    display: grid;
    grid-template-columns: 310px 310px 310px;
    gap: 20px;
`;

const FILTER_OPTIONS = [
    { value: 0, label: 'All' },
    { value: 1, label: 'Only Errors' },
    { value: 2, label: 'Different Price' },
    { value: 3, label: 'Not Assigned' },
    { value: 4, label: 'POS Product Not Found' },
];

const STATUS_OPTIONS = [
    { value: null, label: 'All Status' },
    { value: ITEM_STATUS.ACTIVE, label: 'On Menu' },
    { value: ITEM_STATUS.INACTIVE86, label: "86's no stock" },
    { value: ITEM_STATUS.INACTIVE, label: 'Inactive' },
    { value: ITEM_STATUS.ARCHIVED, label: 'Archived' },
];

const PRESENTATION_STATUS_OPTIONS = [
    { value: null, label: 'All Status' },
    { value: 'active', label: 'Active' },
    { value: 'inactive', label: 'Inactive' },
];

const IDCheckup = ({ location }) => {
    const { translate, selectedLang } = useI18n();
    const alert = useAlert();
    const [pos, posDispatcher] = usePOS();
    const [filter, setFilter] = useState(FILTER_OPTIONS[0]);
    const [list, setList] = useState([]);
    const [selectedStatus, setSelectedStatus] = useState({
        ...STATUS_OPTIONS[0],
        label: translate(STATUS_OPTIONS[0].label),
    });
    const [selectedPresentationStatus, setSelectedPresentationStatus] = useState({
        ...PRESENTATION_STATUS_OPTIONS[0],
        label: translate(PRESENTATION_STATUS_OPTIONS[0].label),
    });
    const { search, setVisible, setSearch } = useSearch();

    useEffect(() => {
        posDispatcher.fetchStart();
        setVisible(true);
        return () => {
            setVisible(false);
            setSearch('');
        };
    }, []);

    useEffect(() => {
        const selected = FILTER_OPTIONS.find(
            (opt) => opt.value === filter.value
        );
        setFilter({ ...selected, label: translate(selected.label) });
    }, [selectedLang]);

    useEffect(() => {
        const newList = pos.list
            .filter((p) => {
                switch (filter.value) {
                    case 1:
                        return (
                            p.price !== p.posPrice ||
                            p.posCode === null ||
                            p.posId === null
                        );
                    case 2:
                        return (
                            p.price !== p.posPrice &&
                            p.posCode !== null &&
                            p.posId !== null
                        );
                    case 3:
                        return p.posCode === null;
                    case 4:
                        return p.posCode !== null && p.posId === null;
                    case 0:
                    default:
                        return true;
                }
            })
            .filter((p) =>
                selectedStatus && selectedStatus.value
                    ? selectedStatus.value === p.localStatus
                    : true
            )
            .filter((p) => {
                if(!selectedPresentationStatus || !selectedPresentationStatus.value) return true;
                if(selectedPresentationStatus.value === 'active') {
                    return p.active;
                }
                if(selectedPresentationStatus.value === 'inactive') {
                    return !p.active;
                }
                return true;
            }).filter(
                ({ productTranslations }) => 
                Object.values(productTranslations).some(({ title, description }) => compareStrings(title ?? '', search) || compareStrings(description ?? '', search))
            );

        setList(newList);
    }, [filter, pos.list, selectedStatus, selectedPresentationStatus, search]);

    return (
        <MainLayout location={location}>
            <Container>
                <DropdownContainer>
                    <Dropdown
                        labelProps={{ label: translate('Filter') }}
                        selectProps={{
                            value: filter,
                            options: FILTER_OPTIONS.map((opt) => ({
                                ...opt,
                                label: translate(opt.label),
                            })),
                            onChange: (value) => setFilter(value),
                        }}
                    />
                    <Dropdown
                        labelProps={{ label: translate('Item Status') }}
                        selectProps={{
                            onChange: (value) => {
                                setSelectedStatus(value);
                            },
                            options: STATUS_OPTIONS.map((opt) => ({
                                ...opt,
                                label: translate(opt.label),
                            })),
                            placeholder: translate(''),
                            value: selectedStatus,
                        }}
                    />
                    <Dropdown
                        labelProps={{ label: translate('Presentation Status') }}
                        selectProps={{
                            onChange: (value) => {
                                setSelectedPresentationStatus(value);
                            },
                            options: PRESENTATION_STATUS_OPTIONS.map((opt) => ({
                                ...opt,
                                label: translate(opt.label),
                            })),
                            placeholder: translate(''),
                            value: selectedPresentationStatus,
                        }}
                    />
                </DropdownContainer>
                <CardContainer>
                    <ResponsiveCard>
                        <Table
                            structure={structure}
                            config={{ translate, alert }}
                            items={list}
                        />
                    </ResponsiveCard>
                </CardContainer>
            </Container>
        </MainLayout>
    );
};

export default IDCheckup;
