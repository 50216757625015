import React from 'react';
import { TEXT } from '@etiquette-ui/colors';
import styled from 'styled-components';
import useI18n from 'i18n/useI18n';

const Container = styled.div`
    color: ${TEXT};
    text-align: center;
    margin-top: 40px;
`;

const Title = styled.p`
    font-size: 29px;
    letter-spacing: 0px;
    margin: 0px;
    padding-bottom: 10px;
`;
const SubTitle = styled.div`
    display: flex;
    justify-content: space-between;
    width: 280px;
    margin: 0 auto;
    font-size: 19px;
    letter-spacing: 0px;
`;

const Header = ({ title, avgs }) => {
    const { translate } = useI18n();
    return (
        <Container>
            <Title>{title}</Title>
            <SubTitle>
                <span>{translate('Stars:', { stars: avgs?.stars ?? '-' })}</span>
                <span>{translate('Comments:', { comments: avgs?.comments ?? '-' })}</span>
            </SubTitle>
        </Container>
    );
};
export default Header;
