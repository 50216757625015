import React, {useState} from 'react';
import styled from 'styled-components';
import { SwitchButton } from '@etiquette-ui/selectors';
import useI18n from 'i18n/useI18n';

const Container = styled.div`
  display: flex;
  align-items: center;
`;
const Label = styled.label`
  margin-right: 10px;
  font: normal normal 600 16px/19px Montserrat;
`;

const ToggleButton = ({initState = false, onChange = (active)=>{}}) => {
  const [active, setActive] = useState(undefined);
  const { translate } = useI18n();

  const innerOnChange = () => {
    if(active === undefined && !!initState){
      setActive(!initState);
      onChange(!initState)
    } else {
      setActive(!active);
      onChange(!active)
    }
    
  }
  return (
    <Container onClick={innerOnChange}>
      <Label>{translate('Show on Menu')}</Label>
      <SwitchButton active={active ?? initState}>Custom</SwitchButton>
    </Container> 
  )
}
export default ToggleButton
