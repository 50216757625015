import React, {useMemo} from 'react';
import { LangDropdown } from '@etiquette-ui/inputs-dropdowns';
import styled from 'styled-components';
import useI18n from 'i18n/useI18n';
import useWindowDimensions from "hooks/useWindowDimensions";

const LangSelector = styled(LangDropdown)`
  @media (max-width: 768px) {
    margin-right: 15px;
  }
`;

const Lang = () => {
  const { selectedLang, setSelectedLang } = useI18n();
  const windowDimensions = useWindowDimensions();
  const {width} = windowDimensions;

  const color = useMemo(() => {
    return width < 768 ? 'black' : 'white';
  }, [width]);

  return (
    <LangSelector
      selectedLang={selectedLang}
      setSelectedLang={setSelectedLang}
      chevronStyles={{
        line: {
          fill: 'none',
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
          strokeWidth: '1.5px',
          stroke: color,
        },
      }}
      labelStyles={{
        color: color
      }}
    />
  );
};

export default Lang;
