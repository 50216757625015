
import styled from 'styled-components';

const ImageSectionContainer = styled.div`
  display: flex;
  align-items: center;

  @media( max-width: 768px ){
    flex-direction: column;
    align-items: start;
  }
`;

export default ImageSectionContainer;