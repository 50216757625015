import { Checkbox } from '@etiquette-ui/selectors';
import { Subtitle } from '@etiquette-ui/typography';
import FormSection from 'containers/Form/FormSection';
import useI18n from 'i18n/useI18n';
import React, { useEffect } from 'react';
import useCategories from 'state/categories';
import styled from 'styled-components';
import ITEM_KEYS from '../keys';
import { ACTIONS } from '../Reducer';

const Check = styled(Checkbox)`
  margin: 10px 0;
`;

const Categories = ({ formData, dispatch }) => {
  const { translate, translateObject } = useI18n();
  const [categories] = useCategories();

  return (
    <FormSection>
      <Subtitle>{translate('Category')}</Subtitle>
      <div style={{ height: '10px' }}></div>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        {categories.list.map(({ id, translations }) => (
          <Check
            key={id}
            checked={formData[ITEM_KEYS.CATEGORIES].map(({ category }) => category).includes(id)}
            onClick={() => dispatch(ACTIONS.CATEGORIES, id)}
          >
            {translateObject(translations).title}
          </Check>
        ))}
      </div>
    </FormSection>
  );
};

export default Categories;
