import MainLayout from 'containers/MainLayout';
import useI18n from 'i18n/useI18n';
import React, { useEffect, useMemo, useReducer } from 'react';
import useCategories from 'state/categories';
import useProducts from 'state/products';
import ItemForm from '../../components/ItemForm';
import INIT_STATE from '../../components/ItemForm/initState';
import { toSubmitObject } from '../../components/ItemForm/processor';
import Reducer, { ACTIONS } from '../../components/ItemForm/Reducer';

const Create = ({ location }) => {
  const [, categoriesDispatcher] = useCategories();
  const [, productsDispatcher] = useProducts();
  const [formData, formDispatcher] = useReducer(Reducer, INIT_STATE());
  const dispatch = useMemo(() => (type, payload) => formDispatcher({ type, payload }), [formDispatcher]);
  const { translate } = useI18n();

  useEffect(() => {
    categoriesDispatcher.fetchStart();
    productsDispatcher.fetchStart();
  }, []);

  const submit = () => {
    const item = toSubmitObject(formData);
    productsDispatcher.createStart(item);
  };

  return (
    <MainLayout location={location}>
      <ItemForm
        location={location}
        formData={formData}
        dispatch={dispatch}
        submit={submit}
        title={translate('New Item')}
        button={translate('Create Item')}
      />
    </MainLayout>
  );
};

export default Create;
