import React, { useRef, useMemo } from 'react';
import styled from 'styled-components';
import Message from './components/Message';
import useI18n from 'i18n/useI18n';
import Chart from './pbi/components/Chart';
import Loader from './pbi/components/Loader';
import Filters from './pbi/components/Filters';
import { getStandardStructure } from './pbi/utils';
import { Card } from '@etiquette-ui/misc';

const CardContainer = styled.div`
    overflow-x: scroll;
    width: 100%;
    padding: 0 8px;
`;

const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`;

const Container = styled.div`
  position: relative;
`;

const ItemsPDFView = () => {
    const rawData = sessionStorage.getItem('data');
    const { data } = JSON.parse(rawData);
    const { translate, selectedLang } = useI18n();
    const reference = useRef();

    const chartData = useMemo(() => {
        if (!data.itemsData) return null;
        return data.itemsData;
    }, [data]);

    return (
        <CardContainer ref={reference}>
            <Card>
                <Container>
                    <Row>
                        {chartData === null && !data.isWaiting ? (<Message message={translate('Please Select a Report')} />)
                            : (<>
                                {!!chartData && !data.isWaiting
                                    ? (<Chart
                                        reference={reference}
                                        report={data.config.report}
                                        category={data.config.category}
                                        data={getStandardStructure(chartData.data, data.config.report, selectedLang === 0 ? 'es' : 'en')}
                                        width={"full"}
                                        dates={ {
                                            from: data.config.dateInit,
                                            to: data.config.dateEnd
                                        } }
                                        isPercentage={data.config.isPercentage}
                                    />)
                                    : (<Loader />)
                                }
                            </>)}

                    </Row>
                </Container>
            </Card>
        </CardContainer>
    )
};

export default ItemsPDFView;