import React, { useState } from 'react';
import { BaseDialog } from '@etiquette-ui/alert-dialog';
import useI18n from 'i18n/useI18n';
import styled from 'styled-components';
import PrimaryButton, { TextButton } from '@etiquette-ui/buttons';
import { PSmall } from '@etiquette-ui/typography';
import { Input } from '@etiquette-ui/inputs-dropdowns';
import { Calendar } from '@etiquette-ui/calendars';
import { dateFormat, DATE_FORMAT } from 'utils/date';

const Buttons = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 20px;
  button {
    padding: 15px 0;
    height: unset;
  }
`;

const CustomizedCalendar = styled(Calendar)`
  .react-datepicker {
    width: 100%;
  }
`;

const Inactive86Alert = ({ open, onSubmit, onClose }) => {
  const { translate, formatDate } = useI18n();
  const [reason, setReason] = useState('');
  const [date, setDate] = useState(new Date());
  const disabled = !reason || !date;

  const submit = (e) => {
    e.preventDefault();
    if (disabled) return;

    onSubmit({ date: dateFormat(date, DATE_FORMAT), reason });
  };

  return (
    <BaseDialog open={open} onClose={onClose} isResponsive={false} title={translate("Change to 86's no stock")}>
      <form onSubmit={submit}>
        <div style={{ height: '30px' }} />
        <PSmall style={{ textAlign: 'left', marginBottom: '8px' }}>{translate('Why is in "86\'s No Stock"?')}</PSmall>
        <Input
          labelProps={{ label: translate('Reason') }}
          iconProps={{ icon: '' }}
          inputProps={{
            value: reason,
            onChange: (e) => setReason(e.target.value),
            required: true,
          }}
        />
        <div style={{ height: '30px' }} />
        <PSmall style={{ textAlign: 'left', marginBottom: '8px' }}>
          {translate('When do you think this item will be in stock again?')}
        </PSmall>
        <CustomizedCalendar
          selected={date}
          formatDate={(date) => formatDate(date, 'MMMM yyyy')}
          minDate={new Date()}
          onChange={(date) => setDate(date)}
        />
        <div style={{ height: '30px' }} />
        <Buttons>
          <TextButton onClick={onClose}>{translate('Cancel')}</TextButton>
          <PrimaryButton disabled={disabled}>{translate('Change Status')}</PrimaryButton>
        </Buttons>
      </form>
    </BaseDialog>
  );
};

export default Inactive86Alert;
