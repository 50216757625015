import React, { useMemo } from 'react';
import useI18n from 'i18n/useI18n';
import { SubtitleSmall } from '@etiquette-ui/typography';
import styled from 'styled-components';

const Text = styled(SubtitleSmall)`
    align-self: center;
    @media (max-width: 768px) {
        font-size: 13px;
    }
`;

const PresentationStatus = ({ item }) => {
    const { translate } = useI18n();

    const status = useMemo(() => {
        if(item.active) {
            return 'Active';
        }
        return 'Inactive';
    }, [item.active]);

    return (
      <Text>{translate(status)}</Text>
    );
};

export default PresentationStatus;