import { ITEM_STATUS } from 'utils/status';
import ITEM_KEYS, { CONFIG_ITEM_KEYS } from './keys';

export const TRANSLATION = () => ({
  title: '',
  description: ''
});

export const CONFIG = () => ({
  [CONFIG_ITEM_KEYS.DESCRIPTION_REQUIRED]: true,
  [CONFIG_ITEM_KEYS.MKT_PRICE]: false,
})

export const CATEGORY = (id) => ({category: id, subcategories: []});

const INIT_STATE = () => ({
  [ITEM_KEYS.CATEGORIES]: [],
  [ITEM_KEYS.FAMILY]: [],
  [ITEM_KEYS.IMAGE]: '',
  [ITEM_KEYS.INGREDIENTS]: [],
  [ITEM_KEYS.LOGO]: '',
  [ITEM_KEYS.STATUS]: ITEM_STATUS.ACTIVE,
  [ITEM_KEYS.STOCK_ALERT]: 0,
  [ITEM_KEYS.STOCK_PAR]: 0,
  [ITEM_KEYS.STOCK_TOTAL]: 1,
  [ITEM_KEYS.TAGS]: [],
  [ITEM_KEYS.TRANSLATIONS]: {
    en: {...TRANSLATION()},
    es: {...TRANSLATION()},
  },
  [ITEM_KEYS.CONFIG]: {
    ...CONFIG()
  },
  [ITEM_KEYS.VARIANTS]: [
    {
      [ITEM_KEYS.TRANSLATIONS]: {
        en: '',
        es: '',
      },
      [ITEM_KEYS.PRICE]: '0',
      [ITEM_KEYS.ACTIVE]: true,
      [ITEM_KEYS.POS_CODE]: '',
    }
  ],
});

export default INIT_STATE;
