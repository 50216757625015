import React, { useEffect } from 'react';
import { RadioButton } from '@etiquette-ui/selectors';
import { Subtitle } from '@etiquette-ui/typography';
import FormSpacer from 'components/Form/FormSpacer';
import FormSection from 'containers/Form/FormSection';
import useI18n from 'i18n/useI18n';
import useFamilies from 'state/families';
import ITEM_KEYS from '../keys';
import { ACTIONS } from '../Reducer';
import styled from 'styled-components';

const Radio = styled(RadioButton)`
  margin-right: 35px;
`;

const Family = ({ formData, dispatch }) => {
  const { translate, translateObject } = useI18n();
  const [families, dispatcher] = useFamilies();

  useEffect(() => {
    dispatcher.fetchStart();
  }, []);

  const family = formData[ITEM_KEYS.FAMILY].length > 0 ? formData[ITEM_KEYS.FAMILY][0] : '';

  return (
    <FormSection>
      <Subtitle>{translate('Family')}</Subtitle>
      <FormSpacer />
      <div style={{ display: 'flex' }}>
        {families.list.map(({ id, translations }) => (
          <Radio key={id} checked={family === id} onClick={() => dispatch(ACTIONS.FAMILY, id)}>
            {translateObject(translations).title}
          </Radio>
        ))}
      </div>
    </FormSection>
  );
};

export default Family;
