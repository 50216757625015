export const toFormData = (object) => {
  const formdata = new FormData();

  Object.entries(object).forEach(([key, value]) => {
    if(value !== null) {
      value = typeof value === "object" && !(value instanceof File) ? JSON.stringify(value) : value;
      formdata.append(key, value);
    }
  });

  return formdata;
};

export const compareStrings = (a, b) => a.trim().toLowerCase().includes(b.trim().toLowerCase());

export const mapIdsToObjects = (ids, objects) => {
  const filteredIds = ids.filter((uid) => objects.some(({id})=>+uid===+id));
  const mappedObjects = filteredIds.map((uid) => objects.find(({id})=>+uid===+id));
  return mappedObjects;
}