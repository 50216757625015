import React, {useEffect} from 'react';
import useStock86 from './useStock86';
import useInactive86 from 'state/inactive86';
import Dialog from './Dialog';
import { ALERT_TYPES, useAlert } from '@etiquette-ui/alert-dialog';

const verify86Items = ( items, lastSeenAt ) => {
  const today = new Date();
  let isImportant = false;  
  const data = [];

  items.forEach( item => {
    const date = new Date(item.when);
   
    if(date <= today ){
      data.push(item);
    }
    
    if(lastSeenAt !== undefined && today.toDateString() === date.toDateString() && new Date(today.toLocaleDateString()) > new Date(lastSeenAt.toLocaleDateString())) {
      isImportant = true;
    }
  });

  return({
    isImportant: isImportant,
    list: data
  });
}

const Alert = () => {
  const alert = useAlert();
  const { isAlertOpened, lastSeenAt, setIsAlertOpened } = useStock86();
  const [data, dispatcher] = useInactive86();
  const auth = JSON.parse(localStorage.getItem('auth'));

  useEffect(()=>{  
    if(!!auth) dispatcher.fetchStart();
  },[]);


  useEffect(()=>{
    if(!auth) return ;

    const {isImportant, list} = verify86Items(data.list, lastSeenAt);
    if((isAlertOpened === 0 || isImportant) && list.length >= 1){
      alert({
        type: ALERT_TYPES.CUSTOM,
        config: {
          body: Dialog,
          props: {
            setIsAlertOpened: () => setIsAlertOpened(1),
            list: list,
          }          
        },
      })
        .then(() => setIsAlertOpened(1))
        .catch(() => setIsAlertOpened(1));
    }
      
  },[isAlertOpened, data.list]);

  return (<></>)
}
export default Alert;

