import { PSmall, Subtitle, SubtitleSmall } from '@etiquette-ui/typography';
import useI18n from 'i18n/useI18n';
import React, { useMemo } from 'react';
import useUsers from 'state/users';
import styled from 'styled-components';
import { dateFormat } from 'utils/date';
import { getFullName } from 'utils/text';

const ExplainerContainer = styled.div`
    display: flex;
`;

const Explainer = ({ title, content }) => {
    return (
        <ExplainerContainer>
            <SubtitleSmall>{title}</SubtitleSmall>
            <PSmall style={{ marginLeft: '10px' }}>{content}</PSmall>
        </ExplainerContainer>
    );
};

const getUserName = (users, i) => {
    const user = users.find(({id})=>id===i);
    return user ? getFullName(user) : null; 
  }
  

const Values = ({ who, when, why }) => {
    const { translate } = useI18n();
    const [users] = useUsers();

    const name = useMemo(()=>getUserName(users.all, who), [users, who])

    return (
        <>
            <Explainer title={translate('Who 86')} content={name ? name : translate('Unknown User')} />
            <Explainer title={translate('When 86')} content={dateFormat(when)} />
            <Explainer title={translate('Why 86')} content={why} />
        </>
    );
};

const PreviousHistory = ({ history }) => {
    const { translate } = useI18n();
    return (
        <>
            <SubtitleSmall style={{textAlign: 'left'}}>{translate('LAST UPDATED')}</SubtitleSmall>
            <Values
                who={history[history.length - 1]?.who}
                when={history[history.length - 1]?.when}
                why={history[history.length - 1]?.why}
            />
            <div style={{ height: '10px' }} />
            <SubtitleSmall style={{textAlign: 'left'}}>{translate('PREVIOUS COMMENT')}</SubtitleSmall>
            <Values
                who={history[history.length - 2]?.who}
                when={history[history.length - 2]?.when}
                why={history[history.length - 2]?.why}
            />
        </>
    );
};

const History = ({ item }) => {
    const { translate } = useI18n();
    return (
        <>
            <Subtitle style={{ textAlign: 'left', marginBottom: '10px' }}>{translate('History')}</Subtitle>
            {item.history ? <PreviousHistory history={item.history} /> : <Values who={item.who} when={item.when} why={item.why} />}
        </>
    );
};

export default History;
