import { takeLatest, put, all, call } from 'redux-saga/effects';
import { serviceConnectorAPI } from 'resources/api';
import { processError } from 'state/utils';
import Types from './types';
import { getItemStatus } from 'utils/status';

function* fetchStartAsync() {
    try {
        const res = yield serviceConnectorAPI.getAll();
        yield put({ type: Types.FETCH_SUCCESS, payload: res.map((p) => ({ ...p, localStatus: getItemStatus(p) })) });
    } catch (error) {
        const message = processError(error);
        console.error(message);
        yield put({ type: Types.FETCH_ERROR, payload: message });
    }
}

function* fetchStart() {
  yield takeLatest(Types.FETCH_START, fetchStartAsync);
}

function* updateStartAsync({ payload }) {
    try {
        const res = yield serviceConnectorAPI.update(payload);
        yield put({ type: Types.UPDATE_SUCCESS, payload: res });
    } catch (error) {
        const message = processError(error);
        console.error(message);
        yield put({ type: Types.UPDATE_ERROR, payload: message });
    }
}

function* updateStart() {
  yield takeLatest(Types.UPDATE_START, updateStartAsync);
}

export default function* sagas() {
    yield all([
        call(fetchStart),
        call(updateStart),
    ]);
}
