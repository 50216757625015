import React, { useCallback, useState } from 'react';

const NavButtonsContext = React.createContext();

const NavButtonsProvider = ({ children }) => {
  const [printFunction, setPrintFunction] = useState(null);
  const [downloadFunction, setDownloadFunction] = useState(null);

  const registerPrintFunction = useCallback((func) => {
    setPrintFunction(() => func);
  }, []);

  const unregisterPrintFunction = useCallback(() => {
    setPrintFunction(null);
  }, []);

  const registerDownloadFunction = useCallback((func) => {
    setDownloadFunction(() => func);
  }, []);

  const unregisterDownloadFunction = useCallback(() => {
    setDownloadFunction(null);
  }, []);

  return <NavButtonsContext.Provider value={{
    printFunction,
    downloadFunction,
    registerPrintFunction,
    unregisterPrintFunction,
    registerDownloadFunction,
    unregisterDownloadFunction
  }
  }>{children}</NavButtonsContext.Provider>;
};

export const usePrintButton = () => React.useContext(NavButtonsContext);

export default NavButtonsProvider;