const TYPE = `[FILTERS]`;

export default {
    ITEMS_SET_LIST: `${TYPE} ITEMS SET LIST`,
    ITEMS_SET_SELECTED_CATEGORIES: `${TYPE} ITEMS SET SELECTED CATEGORIES`,
    ITEMS_SET_CATEGORIES_OPTIONS: `${TYPE} ITEMS SET CATEGORIES OPTIONS`,
    ITEMS_SET_SUBCATEGORIES_OPTIONS: `${TYPE} ITEMS SET SUBCATEGORIES OPTIONS`,
    ITEMS_SET_SELECTED_SUBCATEGORIES: `${TYPE} ITEMS SET SELECTED SUBCATEGORIES`,

    ARRANGE_ITEMS_SET_SELECTED_CATEGORY: `${TYPE} ARRANGE ITEMS SET SELECTED CATEGORY`,
    ARRANGE_ITEMS_SET_SELECTED_SUBCATEGORY: `${TYPE} ARRANGE ITEMS SET SELECTED SUBCATEGORY`,
}