import MainLayout from 'containers/MainLayout';
import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router';
import Arrange from './pages/Arrange';
import Create from './pages/Create';
import Edit from './pages/Edit';
import List from './pages/List';

const Categories = ({ location }) => {
  const { path } = useRouteMatch();
  return (
    <MainLayout location={location}>
      <Switch>
        <Route exact path={path} component={List} />
        <Route path={`${path}/create`} component={Create} />
        <Route path={`${path}/edit/:categoryId([0-9]+)`} component={Edit} />
        <Route path={`${path}/arrange`} component={Arrange} />
      </Switch>
    </MainLayout>
  );
};

export default Categories;
