import styled from 'styled-components';
import ResponsiveImage from '../responsive/Image';

const CustomImage = styled(ResponsiveImage)`
  margin-left: 16px;
  > div {
    background-color: transparent;
  }
`;

export default CustomImage
