import React from 'react';
import { Text, HeaderText } from '@etiquette-ui/dynamic-table-components';
import RowContainer from 'components/Table/RowContainer';
import SortText from 'components/Table/headers/SortText';
import ResponsiveHeaderText from 'components/Table/responsive/HeaderText';
import ResponsiveText from 'components/Table/responsive/Text'
import Categories from '../../../../cells/Categories';
import Status from './cells/Status';
import Frecuency from './cells/Frequency';
import Tags from '../../../../cells/Tags';

const structure = {
    row: RowContainer,
    columns: [
        {
            flex: 2,
            header: ({ globalState, ...rest }) => (
                <SortText property={'translations.en.title'} text={globalState.config.translate('Menu Item')} {...rest} />
            ),
            body: ({ item }) => <ResponsiveText text={item.translations.en.title} style={{ marginLeft: '16px' }} />,
        },
        {
            flex: 2,
            header: () => <ResponsiveHeaderText text={'Category'} />,
            body: Categories,
        },
        {
            flex: 1,
            header: () => <ResponsiveHeaderText text={'Tags'} />,
            body: Tags,
        },
        {
            flex: 2,
            header: ({ globalState }) => <ResponsiveHeaderText text={globalState.config.translate("86's frequency")} />,
            body: ({ item }) => <Frecuency count={item.passLife.count} avg={item.passLife.avg} />,
        },
        {
            flex: 2,
            header: ({ globalState }) => <ResponsiveHeaderText text={globalState.config.translate('Last 30 days')} />,
            body: ({ item }) => <Frecuency count={item.pass30.count} avg={item.pass30.avg} />,
        },
        {
            flex: 2,
            header: ({ globalState }) => <ResponsiveHeaderText text={globalState.config.translate('Last 90 days')} />,
            body: ({ item }) => <Frecuency count={item.pass90.count} avg={item.pass90.avg} />,
        },
        {
            flex: 2,
            header: ({ globalState }) => <ResponsiveHeaderText text={globalState.config.translate('Last 365 days')} />,
            body: ({ item }) => <Frecuency count={item.pass365.count} avg={item.pass365.avg} />,
        },
        {
            flex: 1,
            header: ({ globalState }) => <ResponsiveHeaderText text={globalState.config.translate('Status')} />,
            body: Status,
        },
    ],
};

export default structure;
