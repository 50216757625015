import { takeLatest, put, all, call } from 'redux-saga/effects';
import { getHosts, getManagers, getServers, getItemsByPerid, getItemsByBulk } from "../../resources/api/statistics";
import { processError } from 'state/utils';
import Types from './types';

function* fetchHostsAsync({ payload }) {
    try {
        const res = yield getHosts(payload.dateInit, payload.dateEnd);
        yield put({ type: Types.FETCH_SUCCESS_HOSTS, payload: res });
    } catch (error) {
        const message = processError(error);
        console.error(message);
        yield put({ type: Types.FETCH_ERROR_HOSTS, payload: message });
    }
}

function* fetchHosts() {
    yield takeLatest(Types.FETCH_START_HOSTS, fetchHostsAsync);
}

function* fetchServersAsync({ payload }) {
    try {
        const res = yield getServers(payload.dateInit, payload.dateEnd);
        yield put({ type: Types.FETCH_SUCCESS_SERVERS, payload: res });
    } catch (error) {
        const message = processError(error);
        console.error(message);
        yield put({ type: Types.FETCH_ERROR_SERVERS, payload: message });
    }
}

function* fetchServers() {
    yield takeLatest(Types.FETCH_START_SERVERS, fetchServersAsync);
}

function* fetchManagersAsync({ payload }) {
    try {
        const res = yield getManagers(payload.dateInit, payload.dateEnd);
        yield put({ type: Types.FETCH_SUCCESS_MANAGERS, payload: res });
    } catch (error) {
        const message = processError(error);
        console.error(message);
        yield put({ type: Types.FETCH_ERROR_MANAGERS, payload: message });
    }
}

function* fetchManagers() {
    yield takeLatest(Types.FETCH_START_MANAGERS, fetchManagersAsync);
}

function* fetchItemsByPeriodAsync({ payload }) {
    try {
        const res = yield getItemsByPerid(payload.report, payload.category, payload.dateInit, payload.dateEnd);
        yield put({
            type: Types.FETCH_SUCCESS_ITEMS_BY_PERIOD,
            payload: {
                report: payload.report,
                category: payload.category,
                data: res,
                dateInit: payload.dateInit,
                dateEnd: payload.dateEnd,
            }
        });
    } catch (error) {
        const message = processError(error);
        console.error(message);
        yield put({ type: Types.FETCH_ERROR_ITEMS_BY_PERIOD, payload: message });
    }
}

function* fetchItemsByPeriod() {
    yield takeLatest(Types.FETCH_START_ITEMS_BY_PERIOD, fetchItemsByPeriodAsync);
}

function* fetchItemsByBulkAsync({ payload }) {
    try {
        const res = yield getItemsByBulk(payload.report, payload.category, payload.years, payload.months);
        yield put({ type: Types.FETCH_SUCCESS_ITEMS_BY_BULK, payload: { report: payload.report, category: payload.category, data: res } });
    } catch (error) {
        const message = processError(error);
        console.error(message);
        yield put({ type: Types.FETCH_ERROR_ITEMS_BY_BULK, payload: message });
    }
}

function* fetchItemsByBulk() {
    yield takeLatest(Types.FETCH_START_ITEMS_BY_BULK, fetchItemsByBulkAsync);
}

export default function* sagas() {
    yield all([call(fetchItemsByPeriod), call(fetchItemsByBulk), call(fetchHosts), call(fetchManagers), call(fetchServers),]);
}
