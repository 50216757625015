import styled from 'styled-components';
import { Dropdown } from '@etiquette-ui/inputs-dropdowns';

const StatusFilter = styled(Dropdown)`
    flex: 1;
    margin-right: 20px;
    > div {
        width: unset;

        .selector__menu {
            min-width: 150px;
        }

        @media (max-width: 768px) {
            min-width: 190px;
        }
    }

    @media (max-width: 768px) {
        margin-right: 5px;
        > div {
            min-width: 210px;
        }
    }
`;

export default StatusFilter;
