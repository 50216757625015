import styled from "styled-components";

const Container = styled.div`
  padding-bottom: 16px;
  padding-top: 15px;
  height: 100%;
  max-width: 100%;
  overflow: scroll;

  @media( max-width: 768px ){
    position: relative;
  }    
`;

const Row = styled.div`
  display: flex;
  margin-bottom: 15px;
  justify-content: center;
`;

const Subtitles = styled.h2 `
  font: normal normal normal 16px/19px Montserrat;
  font-weight: 600;
`;

const ImageCard = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  @media( min-width: 600px ){
    flex-wrap: nowrap;
    gap: 0px;
  }
`;

const ImageButton = styled.div`
  max-width: 250px;
  min-width: 200px;
`;

const ImageLabel = styled.label`
  font: normal normal normal 14px/18px Montserrat;
  min-width: 250px;
  margin-left: 15px;
  flex-grow: 1;
`;

const TextAreas = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-left: -5px;
  margin-right: -5px;

  .card {
    max-width: 100%;
    flex: 100% 0 0 ;
    margin-bottom: 10px;
    padding-left: 5px;
    padding-right: 5px;

    @media(min-width: 750px){
      max-width: 50%;
      flex: 50% 0 0 ;
      margin-bottom: 0px;
    }
  }
 
`;

export {
  Container,
  Row,
  Subtitles,
  ImageCard,
  ImageLabel,
  ImageButton,
  TextAreas
}