import { RIGHT_V_LIGHT, WRONG_V_LIGHT } from '@etiquette-ui/colors';
import useI18n from 'i18n/useI18n';
import React from 'react';
import styled from 'styled-components';
import { DATE_FORMAT, dateFormat } from 'utils/date';
import { millisecondsToDays } from 'utils/time';
import ResponsiveText from 'components/Table/responsive/Text';

const Tag = styled(ResponsiveText)`
    background-color: ${({ $cancel }) => ($cancel ? WRONG_V_LIGHT : RIGHT_V_LIGHT)};
`;

const TimeTag = ({ estimate }) => {
    const today = new Date(dateFormat(null, DATE_FORMAT) + 'T00:00:00');
    const expected = new Date(estimate + 'T00:00:00');
    const differenceInDays = millisecondsToDays(expected - today);
    const { translate } = useI18n();

    return differenceInDays <= 0 ? (
        <Tag $cancel={differenceInDays !== 0}>
            {differenceInDays === 0 ? translate('Today') : translate('Overdue days', { days: Math.abs(differenceInDays) })}
        </Tag>
    ) : (
        <div></div>
    );
};

const WhyExpected = ({ item }) => {
    return (
        <div style={{ padding: '0 5px' }}>
            <ResponsiveText text={item.why ? item.why : '-'} />
            <div style={{ height: '10px' }}></div>
            {item.estimate ? (
                <div style={{ display: 'flex' }}>
                    <ResponsiveText text={dateFormat(item.estimate + 'T00:00:00')} />
                    <div style={{ width: '10px' }}></div>
                    <TimeTag estimate={item.estimate} />
                </div>
            ) : (
                <ResponsiveText text={'-'} />
            )}
        </div>
    );
};

export default WhyExpected;
