import React, {useState} from 'react';
import styled from 'styled-components';
import useConfig from 'state/config';
import { NAMES } from 'pages/Settings/resources/constants';
import { VirtualModalContainer, Background, VirtualModal } from './styles';
import useI18n from 'i18n/useI18n';

const Label = styled.div`
 font: normal normal 600 16px/19px Montserrat;
 position: relative;
  top: 45%;
  text-align: center;
  color: white;
  z-index: 1;
`;

const Figure = styled.figure`
  width: 100%;
  margin: auto;
  height: fit-content;
  position: relative;
  overflow: hidden;
  border-radius: 20px;
  background-color: ${({promoBg}) => !!promoBg ? promoBg : 'transparent'};
  &:focus-visible {
    outline: -webkit-focus-ring-color auto 1px;
  }
`;

const getImg = (data) => {
  if(!!data.img) return data.img;
  return data.imageURL ?? '';
}

const getData = (data, configData) => {
  if(!!data && data.name === NAMES.promotion) {
    return ({
      img: !!data.imgs.img ? data.imgs.img.dataURL : data.texts.imageURL,
      backgroundColor: data.texts.backgroundColor,
      active: data.texts.active
    })
  }

  if(!configData.map[NAMES.promotion]) return ({})

  return ({
    img: getImg(configData.map[NAMES.promotion].payload),
    backgroundColor: configData.map[NAMES.promotion].payload.backgroundColor ?? false ,
    active: configData.map[NAMES.promotion].payload.active ?? ''
  })
}

const Promotion = ({formData}) => {
  const [config] = useConfig();
  const [isOpen, setIsOpen] = useState(true);
  const {translate} = useI18n();

  const data = getData(formData, config);


  const onClick = () => {
    setIsOpen(!isOpen);
  }
  
  return( isOpen && (
    <VirtualModalContainer>
      <Background onClick={onClick} />
      {!!Object.keys(data).length > 0 && !!data.active ?  (
        <VirtualModal>
          <Figure promoBg={data.backgroundColor}>
            <img src={data.img} alt="Promotion" />
          </Figure>          
        </VirtualModal>
      ) : (
        <Label>
          {translate('Promotion is disabled')}
        </Label>
      )}
    </VirtualModalContainer>
  ))
}

export default Promotion;
 