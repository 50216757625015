export const CONFIG_ITEM_KEYS = {
    DESCRIPTION_REQUIRED: 'descriptionRequired',
    MKT_PRICE: 'marketPrice',
}

const ITEM_KEYS = {
    CATEGORIES: 'categories',
    CONFIG: 'config',
    FAMILY: 'family',
    IMAGE: 'img',
    INGREDIENTS: 'ingredients',
    LOGO: 'logo',
    POS_CODE: 'posCode',
    PRICE: 'price',
    STATUS: 'status',
    STOCK_ALERT: 'stockAlert',
    STOCK_PAR: 'stockPar',
    STOCK_TOTAL: 'stockTotal',
    TAGS: 'tags',
    TRANSLATIONS: 'translations',
    VARIANTS: 'variants',
    ACTIVE: 'active'
};

export default ITEM_KEYS;