import React, { useState, useEffect } from 'react';
import { VirtualModalContainer, Background, VirtualModal } from '../styles';
import { Label86, Body, Card, Details } from './styles';
import useI18n from 'i18n/useI18n';
import useProducts from 'state/products';
import { IMAGES_URL } from 'resources/constants/urls';
import { NAMES } from 'pages/Settings/resources/constants';

const getLabel = (data, lang) => {
  if(!!data && data.name === NAMES.outOfStock){
     return !!lang ? data.translates.text.en : data.translates.text.es;
  }
  return '';
}

const getProduct = (product, translateObject) => {
  return({
    id: product.id,
    name: translateObject(product.translations).title,
    description: !!product.img ? translateObject(product.translations).description : '',
    img: !!product.img ? `${IMAGES_URL}/${product.img}` : '',
    price: product.price
  })
}

const ProductsList = ({formData}) => {

  const { translateObject, selectedLang } = useI18n();
  const [products, productsDispatcher] = useProducts();
  const [modalProduct, setModalProduct] = useState(undefined);

  const [isOpen, setIsOpen] = useState(false);
  const onClick = (product) => {
    setIsOpen(true);
    setModalProduct(product);
  }

  useEffect(()=>{
    productsDispatcher.fetchStart();
  },[]);

  const productsList = products.list.slice(0,20).map(product => getProduct(product, translateObject));

  return (
    <Body>
      {productsList.map( (product, index) => 
        !!product.img && index !== 1 ? (
          <Card onClick={()=>onClick(product)} key={product.id}>
            <div className="top-price">
              ${product.price}
            </div>
            <figure className={`${index % 3 === 0  ? 'is86-img' : ''}`}>
              <img src={product.img} alt="" />
            </figure>
            <div className="content">
              <div className="title">
                {product.name}
              </div>
              <div className="description" 
                dangerouslySetInnerHTML={{ __html: product.description }}
              />
              <div className="footer">
                <div className="tag">Tags Example</div>
                {index % 3 === 0 && (
                  <Label86 
                    dangerouslySetInnerHTML={{ __html: getLabel(formData, selectedLang) }}
                  />
                )}
                
              </div>
            </div>
          </Card>
        ) : (
          <Card key={product.id}>
            <div className="simple-product">
                <div className="title">
                {product.name}
                </div>
                <p className="price">${product.price}</p>
              </div>
              <div className="footer">  
                {(index % 3 === 0 || index === 1) && (
                  <Label86 
                    dangerouslySetInnerHTML={{ __html: getLabel(formData, selectedLang) }}
                  />
                )}
              </div>
          </Card>
        )
      )}
      
      
      {!!isOpen && !!modalProduct && (
        <VirtualModalContainer>
          <Background onClick={() => onClick(undefined)} />
            <VirtualModal promoBg='white' modalWidth='80%'>
              <Details>
                <figure className='is86-img'>                  
                  <img src="https://menu.quincesma.com/images/mini.p-173ae36661f0.699264560034909.jpg" alt="" />
                </figure>
                <div className="data">
                  <div className="title-container">
                    <span className="title">
                      {modalProduct.name}
                    </span>
                    <span className="price">
                      ${modalProduct.price}
                    </span>
                  </div>
                  <div className="description"
                    dangerouslySetInnerHTML={{ __html: modalProduct.description }}
                  />
                  <Label86 
                    style={{marginTop: '15px'}}
                    dangerouslySetInnerHTML={{ __html: getLabel(formData, selectedLang) }}
                  />
                </div>
              </Details>            
            </VirtualModal>
        </VirtualModalContainer>
      )}
    </Body>
  )
}
export default ProductsList;
