import React, { useEffect, useState } from 'react';
import IconLink from 'components/IconLink';
import styled from "styled-components";

import CardContainer from 'containers/CardContainer';
import ResponsiveCard from 'containers/ResponsiveCard';
import ListActionsContainer from 'pages/MenuItems/containers/ListActionsContainer';
import ListLayout from 'pages/MenuItems/containers/ListLayout';
import ResponsiveSpacer from 'pages/MenuItems/components/ResponsiveSpacer';
import ResponsiveTitleContainer from 'pages/MenuItems/containers/ResponsiveTitleContainer';
import FormTitle from 'pages/MenuItems/components/FormTitle';

import Table from 'components/Table';
import useCategories from 'state/categories';
import structure from './structure';
import useI18n from 'i18n/useI18n';
import DragHandle from 'components/Table/DragHandle';

const CustomListLayout = styled(ListLayout)`
  @media( max-width: 768px ){
    grid-template-rows: 0px minmax(0, 1fr);
  }
`;

const List = ({ location }) => {
  const { translate } = useI18n();

  const [data, setData] = useState([]);
  const [categories, dispatcher] = useCategories();


  useEffect(() => {
    dispatcher.fetchStart();
  }, [])

  useEffect(() => {
    setData([...categories.list]);
  }, [categories])


  const onSort = ({ newIndex, oldIndex, commitMove }) => {
    const newData = [...data];
    const element = newData[oldIndex];
    let isSetAsFirst = false;

    if(newIndex === 0 && oldIndex > 0){
        isSetAsFirst = true;
    } else if(newIndex-1 < 0) return;
    
    const previousElement = newData[isSetAsFirst ? newIndex : newIndex-1];
    if(!isSetAsFirst && !previousElement.position) return;

    commitMove();

    dispatcher.position({
      id: element.id,
      position: newIndex + 1,
    });
  };

  return (
    <CustomListLayout>
      <ListActionsContainer>
        <IconLink to={'/menu/categories'}>{translate('Back to Menu Categories')}</IconLink>
      </ListActionsContainer>
      <ResponsiveTitleContainer>
        <FormTitle>{translate('Arrange')}</FormTitle>
      </ResponsiveTitleContainer>
      <ResponsiveSpacer />
      <CardContainer>
        <ResponsiveCard>
            <Table
              items={categories.list}
              structure={structure}
              config={{ translate }}
              onSortEnd={onSort}
              dragHandle={<DragHandle />}
              dragable={true}
            />
        </ResponsiveCard>
      </CardContainer>
    </CustomListLayout>
  );
};


export default List;
