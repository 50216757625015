import React, { useEffect, useState } from 'react';
import ReactTooltip from 'react-tooltip';
import {
  getCategoriesFromItem,
  getCategoryName
} from 'pages/MenuItems/utils/transformers';
import ResponsiveText from 'components/Table/responsive/Text';

const CategoryName = ({ item, globalState }) => {
  const [name, setName] = useState('');
  const [categories, setCategories] = useState('');

  useEffect(() => {
    const translate = globalState.config.translate;
    const selectedLang = globalState.config.selectedLang;
    const categories = globalState.config.categories;

    const cats = getCategoriesFromItem(categories, item);
    const name = getCategoryName(cats, translate, selectedLang);

    setName(name);
    setCategories(cats.length > 1 ? cats.map(({ translations }) => selectedLang === 1 ? translations.en.title : translations.es.title).join(', ') : '');

  }, [globalState.config.categories, globalState.config.translate, item.categories]);

  return (
    <div data-tip data-for={`cat:${item.id}`} style={{ alignItems: 'center', display: 'flex' }}>
      <ResponsiveText text={name} />
      {categories && (
        <ReactTooltip id={`cat:${item.id}`} place="top" effect="solid">
          {categories}
        </ReactTooltip>
      )}
    </div>
  );
};

export default CategoryName;
