import React, { createContext, useContext, useState } from 'react';

const EditPricesContext = createContext();

export const useEditPrices = () => useContext(EditPricesContext);

export const EditPricesProvider = ({ children }) => {
    const [hovers, setHovers] = useState({});
    const [focus, setFocus] = useState({});
    const [newPrices, setNewPrices] = useState({});

    const registerVariantHover = (id) => {
        setHovers((prev)=>({...prev, [id]: true}));
    }

    const unregisterVariantHover = (id) => {
        setHovers((prev)=>({...prev, [id]: false}));
    }

    const registerVariantFocus = (id) => {
        setFocus((prev)=>({...prev, [id]: true}));
    }

    const unregisterVariantFocus = (id) => {
        setFocus((prev)=>({...prev, [id]: false}));
    }

    const editVariantPrice = (variant, product, price) => {
        setNewPrices((prev)=>({...prev, [variant.id]: {price, variant, product}}));
    }

    const clearPrices = () => {
        setNewPrices({});
    }

    return (
        <EditPricesContext.Provider value={{
            hovers,
            registerVariantHover,
            unregisterVariantHover,
            focus,
            registerVariantFocus,
            unregisterVariantFocus,
            newPrices,
            editVariantPrice,
            clearPrices,
        }}>
            {children}
        </EditPricesContext.Provider>
    );
};
