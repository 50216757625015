import { useState } from 'react';

const useStep = () => {
    const [step, setStep] = useState(0);

    const previous = () => setStep(step - 1);
    const next = () => setStep(step + 1);

    const changeStep = (i) => i >= 0 && setStep(i);

    return [step, previous, next, changeStep];
}

export default useStep;