import React, {useEffect, useMemo } from 'react';
import { Dropdown } from '@etiquette-ui/inputs-dropdowns';
import useCategories from 'state/categories';
import useI18n from 'i18n/useI18n';
import styled from 'styled-components';
import { MENU_URL } from 'resources/constants/urls';

const Label = styled.label`
  margin: 0px;
  margin-left: 5px;
  font-size: 13px;
  position: relative;
  a {
    text-wrap: nowrap;
    position: absolute;
    left: 35px;
  }
`;

const URL_PATTERN = /\/?\w\/\d+[\-\w]+\/?/;

export const CategoryDropdown = ({onChange, value}) => {
  const [categories, dispatcher] = useCategories();
  const { translate, translateObject} = useI18n();

  const categoryOptions = categories.list.map(category => ({
    value: category.id, 
    label: translateObject(category.translations).title,
    url: `menu/${category.id}-${translateObject(category.translations).title.replace(' ','-').toLowerCase()}`
  }));

  const selectedCategory = useMemo(()=>{
    if(!value || !categoryOptions || !URL_PATTERN.test(value)) return undefined;

    const categoryId = value.match(/\/\d+\-/)[0].replace(/\/|\-/g,'');
    return categoryOptions.find( category => category.value === +categoryId);
  },[value, categoryOptions]);

  useEffect(()=>{
    dispatcher.fetchStart();
  },[]);

  return (
    <div style={{maxWidth: '350px'}}>
      <Dropdown
        labelProps={{ label: translate('Category') }}
        selectProps={{
          value: selectedCategory,
          placeholder: translate('Select Category'),
          options: categoryOptions,
          onChange: (value) => onChange(value.url),
        }}
      />
      {!!selectedCategory && (<Label ><b>URL: </b><a target='_blank' href={MENU_URL+value}>{MENU_URL+value}</a></Label>)}
    </div>
  )
}
