import React from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router';
import Categories from './pages/Categories';
import Items from './pages/Items';
import Subcategories from './pages/Subcategories';

const MenuItems = () => {
  const { path, url } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={path}>
        <Redirect to={`${url}/items`} />
      </Route>
      <Route path={`${path}/items`} component={Items} />
      <Route path={`${path}/categories`} component={Categories} />
      <Route path={`${path}/subcategories`} component={Subcategories} />
    </Switch>
  );
};

export default MenuItems;
