import ResponsiveText from 'components/Table/responsive/Text';
import useI18n from 'i18n/useI18n';
import React from 'react';
import useUsers from 'state/users';
import { getFullName } from 'utils/text';

const getUserName = (users, i) => {
    const user = users.find(({ id }) => id === i);
    return user ? getFullName(user) : '';
};

const WhoRestored = ({ item }) => {
    const [users] = useUsers();
    const { translate } = useI18n();

    return  <ResponsiveText text={item.whoRestored ? getUserName(users.all, item.whoRestored) : translate('Pending...')} />;
};

export default WhoRestored;
