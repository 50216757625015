import DynamicTable from '@etiquette-ui/dynamic-table';
import styled from 'styled-components';

const Table = styled(DynamicTable)`
    height: 100%;
    background-color: transparent;
    background: transparent;

    @media (max-width: 768px) {
        .body > div {
            &:last-child {
                margin-bottom: 135px;
            }
        }
    }
`;

export default Table;
