import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import Dispatcher from './dispatcher';
import selector from './selector';
import Utils from './utils';
import { useMemo } from 'react';

const usePOS = () => {
    const dispatch = useDispatch();
    const dispatcher = useMemo(()=>new Dispatcher(dispatch), [dispatch]);
    const data = useSelector(selector, shallowEqual)
    const utils = useMemo(()=>new Utils(data, dispatcher), [data, dispatcher]);;

    return [data, dispatcher, utils];
}


export default usePOS;