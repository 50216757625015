import React from 'react';
import styled from 'styled-components';
import ImageUploading from 'react-images-uploading';
import { IMAGES_URL } from 'resources/constants/urls';
import { SecondaryButton } from '@etiquette-ui/buttons';
import { ImageIcon, RemoveIcon } from '@etiquette-ui/icons';
import { PSmall } from '@etiquette-ui/typography';
import { WRONG_DARK } from '@etiquette-ui/colors';

const Container = styled.div`
  width: 100%;
  min-height: 56px;
  display: flex;
`;

const Content = styled.div`
  height: 100%;
  position: relative;
  flex: 1;
`;

const ImageContainer = styled.div`
  display: flex;
  position: relative;
`;

export const IMAGE_SHAPES = {
  ROUNDED_RECTANGLE: 'rounded_rectangle',
  CIRCLE: 'circle',
  SQUARE: 'square',
  PORTRAIT: 'portrait'
};

const SquareStyles = `
  max-width: 200px;
  max-height: 200px;
  width: 200px;
`;

const PortraitStyles = `
  max-width: 200px;
  height: 300px;
  max-height: 300px;
  width: auto;
`;

const RoundedRectangleStyles = `
  max-width: 200px;
  max-height: 120px;
  width: 200px;
  border-radius: 8px;
`;

const CircleStyles = `
  max-width: 120px;
  max-height: 120px;
  width: 120px;
  border-radius: 60px;
`;

const Image = styled.img`
  object-fit: cover;
  overflow: hidden;
  ${({ cutShape }) => {
    switch (cutShape) {
      case IMAGE_SHAPES.CIRCLE:
        return CircleStyles;
      case IMAGE_SHAPES.SQUARE:
        return SquareStyles;
      case IMAGE_SHAPES.PORTRAIT:
        return PortraitStyles;
      case IMAGE_SHAPES.ROUNDED_RECTANGLE:
      default:
        return RoundedRectangleStyles;
    }
  }}
`;

const Button = styled(SecondaryButton)`
  width: 175px;
  button {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: unset;
    margin: unset;
    height: 50px;
    width: 100%;
    font-weight: 600;
  }
  &.alt {
    position: absolute;
    left: 180px;
    bottom: 0;
  }

  @media( max-width: 768px ){
    &.alt {
      left: auto;
      right: 0px;
    }
  
  }
`;

const RemoveImg = styled.div`
  position: absolute;
  width: 24px;
  ${({ cutShape }) => {
    switch (cutShape) {
      case IMAGE_SHAPES.CIRCLE:
        return 'left: 95px; top: 0px;';
      case IMAGE_SHAPES.SQUARE:
      case IMAGE_SHAPES.ROUNDED_RECTANGLE:
        case IMAGE_SHAPES.PORTRAIT:
      default:
        return 'left: 188px; top: -12px;';
    }
  }}
  cursor: pointer;
`;

const ImageUpload = ({ className, text, altText, error, src, onChange, shape = IMAGE_SHAPES.SQUARE, onClick = null, extraTypes = '' }) => {

  const types = [
    ...["jpg", "jpeg", "png", "tiff", "webpg", "heic"],
    ...extraTypes.split(',')
  ];

  const handleChange = (imageList) => {
    if (imageList.length > 0) {
      onChange(imageList[0]);
    }
  };

  const getImgSrc = (src) => {
    if (typeof src === 'string' || src instanceof String) return `${IMAGES_URL}${src}`;
    if (typeof src === 'object') return src.dataURL;
    return '';
  };

  return (
    <Container className={className}>
      <Content>
        <ImageUploading onChange={handleChange} allowNonImageType={true} acceptType={types}>
          {({ onImageUpload, errors }) => (
            <ImageContainer>
              {src && <Image src={getImgSrc(src)} alt={'new group profile'} cutShape={shape} />}
              <Button type="button" onClick={onClick ? onClick : onImageUpload} className={src ? 'alt' : ''}>
                <ImageIcon styles={{ svg: { width: '25px', heigth: '25px', marginRight: '8px' } }} />
                {src ? altText : text}
              </Button>
              {src && (
                <RemoveImg onClick={() => onChange(null)} cutShape={shape}>
                  <RemoveIcon />
                </RemoveImg>
              )}
              {!!error && <PSmall style={{ color: WRONG_DARK }}>{error}</PSmall>}
            </ImageContainer>
          )}
        </ImageUploading>
      </Content>
    </Container>
  );
};

export default ImageUpload;
