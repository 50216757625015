import React, { useEffect } from 'react';
import Actions from 'components/Table/cells/Actions';
import { INPUTS, PRIMARY, WRONG_DARK, WRONG_LIGHT } from '@etiquette-ui/colors';
import usePOS from 'state/pos';

const RowActions = ({ item, globalState, state, count, setState, replaceState }) => {
  const [pos, posDispatcher] = usePOS();
  
  const editAction = {
    event: () => onEdit(),
    icon: state.editing ? 'complete' : 'edit',
    iconConfig: {
      line: {
        fill: 'none',
        strokeLinecap: 'round',
        strokeLinejoin: 'round',
        strokeWidth: '2px',
        stroke: item.price===item.posPrice ? PRIMARY : 'white',
      }
    }
  };

  const actions = state.editing ? [
    {
      event: () => replaceState({editing: false}),
      icon: 'wrong',
      iconConfig: {
        line: {
          fill: 'none',
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
          strokeWidth: '2px',
          stroke: item.price===item.posPrice ? WRONG_DARK : 'white',
        }
      }
    },
    editAction
  ] : [editAction];
  
  const onEdit = () => {
    if(state.editing) {
      posDispatcher.updateStart({ variantId: item.variantId, ...state });
      replaceState({editing: false});
      return;
    }
    replaceState({editing: true, price: item.price, posCode: item.posCode});
  }

  useEffect(()=>{
    setState('editing', false);
  }, []);

  return (
    <Actions
      loading={pos.fetching.state && pos.fetching.config?.id === item.variantId}
      actions={actions}
      style={{ alignItems: 'flex-start', marginTop: '10px', justifyContent: 'flex-end' }}
      spinnerBackground={item.price===item.posPrice ?  (count%2===0 ? INPUTS : 'white') : WRONG_LIGHT}
      spinnerForeground={item.price===item.posPrice ? PRIMARY : 'white'}
    />
  );
};

export default RowActions;
