import ResponsiveText from 'components/Table/responsive/Text';
import useI18n from 'i18n/useI18n';
import React from 'react';

const Frecuency = ({ count, avg }) => {

    const { translate } = useI18n();

    return (
        <div style={{ padding: '0 5px' }}>
            <ResponsiveText text={`${count} x`} />
            <div style={{ height: '10px' }}></div>
            <ResponsiveText text={`${avg} ${translate('days')}`} />
            
        </div>
    );
};

export default Frecuency;
