export const onSelectCategory = (selectedCategories, setSelectedCategories, initVal = null) => (selected) => {
    let newCategories = [...selectedCategories];
    if (selectedCategories.length === 1 && selectedCategories[0].value === -1) {
        newCategories = [];
    }
    const category = newCategories.find(({ value }) => +value === +selected.value);
    const index = category ? newCategories.indexOf(category) : -1;
    if (index === -1) {
        newCategories.push(selected);
    } else {
        newCategories.splice(index, 1);
    }
    if (selected.value === -1) {
        setSelectedCategories([selected]);
        return;
    }

    if(newCategories.length === 0 && !!initVal) newCategories = [...initVal];
    
    setSelectedCategories(newCategories);
};

export const onSelectSubcategory = (selectedSubcategories, setSelectedSubcategories) => (selected) => {
    let newCategories = [...selectedSubcategories];
    if (selectedSubcategories.length === 1 && (selectedSubcategories[0].value === -1 || selectedSubcategories[0].value === -2)) {
        newCategories = [];
    }
    const category = newCategories.find(({ value }) => +value === +selected.value);
    const index = category ? newCategories.indexOf(category) : -1;
    if (index === -1) {
        newCategories.push(selected);
    } else {
        newCategories.splice(index, 1);
    }
    if (selected.value === -1 || selected.value === -2) {
        setSelectedSubcategories([selected]);
        return;
    }
    setSelectedSubcategories(newCategories);
};
