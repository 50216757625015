import React, { createContext, useState } from "react";

export const FiltersContext = createContext();

export const FiltersProvider = ({ children }) => {
    const [selectedCategories, setSelectedCategories] = useState([]);

    const values = {
        selectedCategories: selectedCategories,
        setSelectedCategories: ( categories ) => {
            let ids = [];        
            for(const category of categories){
                ids = [...ids, category.value];
            }
            localStorage.setItem('filtered-categories', ids);
            setSelectedCategories(categories);
        },
        initFilters: ( categories ) => {
            const idsFromStorage = localStorage.getItem('filtered-categories');
            const ids = !!idsFromStorage ? idsFromStorage.split(',').map( id => +id) : [];

            if( ids.length === 0 || ids.includes(-1) ) {             
                setSelectedCategories(categories.filter(cat => cat.value === -1));
            } else {
                let filteredCategories = []; 
                for(const category of categories){
                    if(category.value !== -1 && ids.includes(category.value)){
                        filteredCategories = [...filteredCategories, category];
                    }
                }
                setSelectedCategories(filteredCategories);
            }                        
        }
    }

    return (<FiltersContext.Provider value={values}>{children}</FiltersContext.Provider>);
};
