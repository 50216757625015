import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
    padding : 20px;
    background-color : white;
    min-height : 70vh;
    color : #000;
`

const Report = ({ url, title }) => (
        <Container id="report">
            {!!url && <iframe title={title} width="100%" height="1060" src={url} frameborder="0" allowFullScreen="true"></iframe>}
        </Container>
    )

export default Report
