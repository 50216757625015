import styled from 'styled-components';
import { TEXT_PLACEHOLDER, TEXT } from '@etiquette-ui/colors';

const Body = styled.div`
  width: 100%;
  .header {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 5px 10px;
    padding-bottom: 0px;
    figure {
      margin: 0px;
      width: 120px;
      min-height: 48px;
      max-height: 50px;
      background-color: ${({exitsLogo})=> !!exitsLogo ? 'transparent' : TEXT_PLACEHOLDER};
      img {
        width: 100%;
        height: auto;
      }
    }
  }
  .label{
    margin: 1rem;
    font-size: 1rem;
    color: ${TEXT};
    font-weight: 400;
  }

  .grid-area {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 16px;
    padding: 0 1rem;
    margin-bottom: 2rem;
    background-color: white;
  }
`;

const Item = styled.div`
  border-radius: 0 0 8px 8px;
  background-color: ${({isWhiteCard}) => !!isWhiteCard ? 'white' : '#ebebeb'};
  display: grid;

  .img {
    width: 100%;
    height: 135px;
    background: ${({img})=> !!img ? `url(${img})` : TEXT_PLACEHOLDER};
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    z-index: 1;
  }

  .name {
    display: flex;
    max-width: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 10px 11px;

    font-weight: bold;
    font-size: 1rem;
    margin-right: 3px;
  }

  .description {
    margin: 0;
    font-size: 0.875rem;
    color: ${TEXT};
    font-weight: 400;
    text-align: center;
    align-self: center;
    background-color: white;
    * {
      margin: 0px;
    }
  }
`;

const Image = styled.img`
  width: 100%;
  height: auto;
  max-height: 135px;
  z-index: 1;
`;

export {
  Body,
  Item,
  Image
}
