import React, { useEffect } from 'react';
import useI18n from 'i18n/useI18n';
import useConfig from 'state/config';
import useForm from 'pages/Settings/formState/dispatcher';
import { ACTIONS } from 'pages/Settings/formState/formReducer';

import Loader from 'pages/Settings/components/Loader';
import { NAMES } from 'pages/Settings/resources/constants';
import FormTitle from 'pages/Settings/components/FormTitle';
import UpdateButton from 'pages/Settings/components/UpdateButton';
import { createFormObject, castConfigData } from 'pages/Settings/formState/utils';
import { Container, Row, Subtitles, ImageCard, ImageLabel, ImageButton } from 'pages/Settings/components/styles';

import CardContainer from 'containers/CardContainer';
import { Card } from '@etiquette-ui/misc';
import ImageUpload from 'components/ImageUpload';

const RestaurantLogo = ({setPreviewSetting}) => {

  const NAME = NAMES.logo;

  const { translate } = useI18n();
  const [configData, configDispatcher] = useConfig();

  const [formData, formDataDispatcher] = useForm();

  const keys = {
    img: {name: 'img', type: 'imgs'}
  }

  useEffect(()=>{
    configDispatcher.fetchStart();   
    setPreviewSetting({
      showPromoModal: false,
      bodyType: 'home'
    });       
  },[]);

  useEffect(()=>{  
    setPreviewSetting({
      showPromoModal: false,
      bodyType: 'home',
      formData
    });       
  },[formData]);

  useEffect(()=>{
    if(!!configData.map[NAME]){
    formDataDispatcher(
      ACTIONS.INIT, 
      castConfigData(configData.map[NAME], keys, NAME)
    );}
  },[configData]);

  const onChangeImage = (value) => {
    formDataDispatcher(
      ACTIONS.ADD_IMG, 
      { name: keys.img.name, value }
    );
  };

  const onSaveForm = () => {
    configDispatcher.updateStart({
      name: formData.name, 
      isPrivate: formData.isPrivate,
      ...createFormObject(formData),
    });
  }

  return (
    !!formData && formData.name === NAME && !!formData.isReadyToRender ? (<Container>
      <CardContainer>
        <Card>
          <FormTitle>{translate('Restaurant Logo On Menu')}</FormTitle>
          <Row>
            <Card>
              <Subtitles>{translate('Image')}</Subtitles>
              <ImageCard>
                <ImageButton>
                  <ImageUpload
                    text={translate('Add Photo')}
                    altText={translate('Edit Photo')}
                    src={!!formData.imgs.img ? formData.imgs.img : ''}
                    onChange={onChangeImage}
                    extraTypes='svg'
                  />
                </ImageButton>                
                <ImageLabel>{translate('Image must be PNG, JPEG or JPG. We recommend portrait orientation.')}</ImageLabel>
              </ImageCard>
            </Card>
          </Row>
          <UpdateButton isUpdating={configData.isUpdatingState} onClick={onSaveForm} />
        </Card>
      </CardContainer>
    </Container>) : (<Loader/>)
  )
}
export default RestaurantLogo;