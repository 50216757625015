import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: 420px;
`;

const Select = () => {
    return <Container>Please set up a search</Container>;
};

export default Select;
