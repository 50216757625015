import { PRIMARY } from '@etiquette-ui/colors';
import { DEFAULT_VIEW_OPTIONS, findLabel, TAGS_FILTER_TYPES_OPTIONS } from './config';
import CATEGORY_KEYS, { CATEGORY_CONFIG_KEYS } from './keys';

export const TRANSLATION = {
    title: '',
};

export const CATEGORY = (id) => ({ category: id, subcategories: [] });

export const DEFAULT_CATEGORY_CONFIG = () => ({
    [CATEGORY_CONFIG_KEYS.ICON]: '',
    [CATEGORY_CONFIG_KEYS.MESSAGE]: {
        color: PRIMARY,
        [CATEGORY_KEYS.TRANSLATIONS]: {
            en: { ...TRANSLATION },
            es: { ...TRANSLATION },
        },
    },
    [CATEGORY_CONFIG_KEYS.EXPANDED]: false,
    [CATEGORY_CONFIG_KEYS.VIEW]: {label: findLabel(DEFAULT_VIEW_OPTIONS, 'gallery'), value: 'gallery'},
    [CATEGORY_CONFIG_KEYS.FILTER_TYPE]: {label: findLabel(TAGS_FILTER_TYPES_OPTIONS, 'accumulative'), value: 'accumulative'},
    [CATEGORY_CONFIG_KEYS.WITH_SELECT_ALL]: true,
});

const INIT_STATE = () => {
    
    const CONFIG = DEFAULT_CATEGORY_CONFIG();

    return ({
    [CATEGORY_KEYS.IMAGE]: '',
    [CATEGORY_KEYS.ACTIVE]: true,
    [CATEGORY_KEYS.TRANSLATIONS]: {
        en: { ...TRANSLATION },
        es: { ...TRANSLATION },
    },
    [CATEGORY_KEYS.CONFIG]: {
        ...CONFIG,
        [CATEGORY_CONFIG_KEYS.MESSAGE]: {
            ...CONFIG[CATEGORY_CONFIG_KEYS.MESSAGE],
            [CATEGORY_KEYS.TRANSLATIONS]: {
                ...CONFIG[CATEGORY_CONFIG_KEYS.MESSAGE][CATEGORY_KEYS.TRANSLATIONS]
            }
        },
        [CATEGORY_CONFIG_KEYS.VIEW]: {
            ...CONFIG[CATEGORY_CONFIG_KEYS.VIEW]
        },
        [CATEGORY_CONFIG_KEYS.FILTER_TYPE]: {
            ...CONFIG[CATEGORY_CONFIG_KEYS.FILTER_TYPE]
        }
    },
})
};

export default INIT_STATE;
