
import React from 'react';
import Hosts from './reports/Hosts';
import Servers from './reports/Servers';
import Managers from './reports/Manager';
import Select from './reports/Select';
import CardContainer from 'containers/CardContainer';
import ResponsiveCard from 'containers/ResponsiveCard';
import styled from 'styled-components';

const ReportContainer = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    padding-bottom: 50px;
`;

const EmployeesPDFView = () => {
    const rawData = sessionStorage.getItem('data');
    const { data } = JSON.parse(rawData);
    const {report, data: reportData} = data;

    const getReport = () => {
        switch (report) {
            case 'hosts':
                return <Hosts data={reportData} isWaiting={false} />;
            case 'servers':
                return <Servers data={reportData} isWaiting={false} />;
            case 'managers':
                return <Managers data={reportData} isWaiting={false} />;
            default:
                return <Select />;
        }
    };

    return (
        <CardContainer>
            <ResponsiveCard>
                <ReportContainer>{getReport()}</ReportContainer>
            </ResponsiveCard>
        </CardContainer>
    )
}

export default EmployeesPDFView;