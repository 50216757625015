import React, { useEffect } from 'react';
import useI18n from 'i18n/useI18n';
import useConfig from 'state/config';

import Loader from 'pages/Settings/components/Loader';
import useForm from 'pages/Settings/formState/dispatcher';
import { NAMES } from 'pages/Settings/resources/constants';
import FormTitle from 'pages/Settings/components/FormTitle';
import { ACTIONS } from 'pages/Settings/formState/formReducer';
import UpdateButton from 'pages/Settings/components/UpdateButton';
import { createFormObject, castConfigData } from 'pages/Settings/formState/utils';
import { Container, Row, Subtitles, TextAreas } from 'pages/Settings/components/styles';

import CardContainer from 'containers/CardContainer';
import { Card } from '@etiquette-ui/misc';
import WYSIWYG from 'components/WYSIWYG';



const OutOfStockMessage = ({setPreviewSetting}) => {

  const NAME = NAMES.outOfStock;

  const { translate } = useI18n();
  const [configData, configDispatcher] = useConfig();

  const [formData, formDataDispatcher] = useForm();

  const keys = {
    text: {name: 'text', type: 'translates'}
  }


  useEffect(()=>{
    configDispatcher.fetchStart();    
  },[]);
  
  useEffect(()=>{
    setPreviewSetting({
      showPromoModal: false,
      bodyType: 'products-list',
      formData
    }); 
    return () => {
      setPreviewSetting({
        showPromoModal: false,
        bodyType: 'home',
        formData: undefined
      }); 
    }   
  },[formData]);

  useEffect(()=>{
    if(!!configData.map[NAME]){
    formDataDispatcher(
      ACTIONS.INIT, 
      castConfigData(configData.map[NAME], keys, NAME)
    );}
  },[configData]);

  const onChangeMessage = (language) => (value) => {
    formDataDispatcher(
      ACTIONS.ADD_TRANSLATE, 
      {name: keys.text.name, lang: language, value}
    );
  }

  const onSaveForm = () => {
    configDispatcher.updateStart({
      name: formData.name, 
      isPrivate: formData.isPrivate,
      ...createFormObject(formData),
    });
  }

  return (
    !!formData && formData.name === NAME && !!formData.isReadyToRender ? (<Container>
      <CardContainer>
        <Card>
          <FormTitle>{translate('Out Of Stock Message Settings')}</FormTitle>
          <Row>
            <Card>
              <Subtitles>{translate('Message')}</Subtitles>
              <TextAreas>
                <div className='card'>
                  <WYSIWYG
                    iconProps={{ icon: 'align-left' }}
                    labelProps={{
                      label: translate('Message (English)')
                    }}
                    onChange={ onChangeMessage('en')}
                    value={ !!formData[keys.text.type][keys.text.name] ? formData[keys.text.type][keys.text.name].en : ''}
                  />
                </div>
                <div className='card'>
                  <WYSIWYG
                    iconProps={{ icon: 'align-left' }}
                    labelProps={{
                      label: translate('Message (Espanish)')
                    }}
                    onChange={ onChangeMessage('es') }
                    value ={ !!formData[keys.text.type][keys.text.name] ? formData[keys.text.type][keys.text.name].es : '' }
                  />
                </div>
              </TextAreas>            
            </Card>
          </Row>
          <UpdateButton isUpdating={configData.isUpdatingState} onClick={onSaveForm} />
        </Card>
      </CardContainer>
    </Container>) : (<Loader/>)
  )
}
export default OutOfStockMessage;