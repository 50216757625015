import MainHeader from 'components/MainHeader';
import React from 'react';
import styled from 'styled-components';
import AppContainer from './AppContainer';

const Main = styled.main`
  height: 100%;
`;

const MainHeaderLayout = ({ children }) => {
  return (
    <AppContainer>
      <MainHeader />
      <Main>{children}</Main>
    </AppContainer>
  );
};

export default MainHeaderLayout;
