import React, { useEffect, useState } from 'react';
import IconLink from 'components/IconLink';

import CardContainer from 'containers/CardContainer';
import ResponsiveCard from 'containers/ResponsiveCard';
import ListActionsContainer from 'pages/MenuItems/containers/ListActionsContainer';
import ListLayout from 'pages/MenuItems/containers/ListLayout';
import ResponsiveSpacer from 'pages/MenuItems/components/ResponsiveSpacer';
import ResponsiveTitleContainer from 'pages/MenuItems/containers/ResponsiveTitleContainer';
import FormTitle from 'pages/MenuItems/components/FormTitle';

import Table from 'components/Table';
import structure from './structure';
import useI18n from 'i18n/useI18n';
import DragHandle from 'components/Table/DragHandle';
import useProducts from 'state/products';
import MainLayout from 'containers/MainLayout';
import useCategories from 'state/categories';
import Filters from './components/Filters';
import useFilters from 'state/filters';


import { EditPricesProvider } from '../List/providers/EditPricesProvider';

const List = ({ location }) => {
    const { translate } = useI18n();
    const [data, productsDispatcher] = useProducts();
    const [, categoriesDispatcher] = useCategories();
    const [list, setList] = useState([]);
    const [filters] = useFilters();


    useEffect(() => {
        productsDispatcher.fetchStart();
        categoriesDispatcher.fetchStart();
    }, []);

    const onSort = ({ newIndex, oldIndex, commitMove }) => {
        const element = list[oldIndex];
        let isSetAsFirst = false;

        if(newIndex === 0 && oldIndex > 0){
            isSetAsFirst = true;
        } else if(newIndex-1 < 0) return;

        const previousElement = list[isSetAsFirst ? newIndex : newIndex-1];
        const catPos = previousElement.categories.find(({ category }) => category === filters.items.arrangeSelectedSubcategory.value);

        if(!isSetAsFirst && (!catPos || !catPos.position)) return;

        commitMove();

        productsDispatcher.position({
            productId: element.id,
            categoryId: filters.items.arrangeSelectedSubcategory.value,
            position: newIndex + 1,
        });
    };

    return (        
        <EditPricesProvider>
            <MainLayout location={location}>
                <ListLayout>
                    <ListActionsContainer>
                        <IconLink to={'/menu/items'}>{translate('Back to Menu Items')}</IconLink>

                        <ResponsiveTitleContainer>
                            <FormTitle>{translate('Arrange')}</FormTitle>
                        </ResponsiveTitleContainer>
                        
                        <div style={{ flex: '2' }}></div>
                        <Filters
                            data={data}
                            setList={setList}
                        />
                    </ListActionsContainer>
                    <ResponsiveSpacer />

                    <CardContainer>
                        <ResponsiveCard>
                            <Table
                                items={list}
                                structure={structure}
                                config={{ translate, selectedSubcategory: filters.items.arrangeSelectedSubcategory }}
                                onSortEnd={onSort}
                                dragHandle={<DragHandle />}
                                dragable={true}
                            />
                        </ResponsiveCard>
                    </CardContainer>
                </ListLayout>
            </MainLayout>
        </EditPricesProvider>
    );
};

export default List;
