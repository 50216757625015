import Types from "./types";

export class Dispatcher {
    constructor(dispatch) {
        this.dispatch = dispatch;
    }

    fetchHost = (dateInit, dateEnd) => this.dispatch({ type: Types.FETCH_START_HOSTS, payload: {dateInit, dateEnd} });

    fetchServers = (dateInit, dateEnd) => this.dispatch({ type: Types.FETCH_START_SERVERS, payload: {dateInit, dateEnd} });

    fetchManagers = (dateInit, dateEnd) => this.dispatch({ type: Types.FETCH_START_MANAGERS, payload: {dateInit, dateEnd} });

    fetchItemsByPeriod = (report, category, dateInit, dateEnd) => this.dispatch({ type: Types.FETCH_START_ITEMS_BY_PERIOD, payload: {report, category, dateInit, dateEnd} });

    fetchItemsByBulk = (report, category, years, months) => this.dispatch({ type: Types.FETCH_START_ITEMS_BY_BULK, payload: {report, category, years, months} });

    setPercentage = (isPercentage) => this.dispatch({ type: Types.SET_PERCENTAGE, payload: isPercentage });
}