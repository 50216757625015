import React from 'react';
import { HeaderText } from '@etiquette-ui/dynamic-table-components';
import RowContainer from 'components/Table/RowContainer';
import SortText from 'components/Table/headers/SortText';
import Tags from './cells/Tags';
import ResponsiveText from 'components/Table/responsive/Text';
import ResponsiveHeaderText from 'components/Table/responsive/HeaderText';
import SortCategory from 'pages/MenuItems/pages/Items/cells/SortCategory';
import Category from 'pages/MenuItems/pages/Items/cells/Category';
import Status from 'pages/MenuItems/pages/Items/cells/Status';
import Who from './cells/Who';
import When from './cells/When';
import Days from './cells/Days';

const structure = {
    row: RowContainer,
    columns: [
        {
            flex: 2,
            header: ({ globalState, ...rest }) => (
                <SortText property={'translations.en.title'} text={globalState.config.translate('Item Name')} {...rest} />
            ),
            body: ({ item }) => <ResponsiveText style={{marginLeft: '16px'}} text={item.translations.en.title} />,
        },
        {
            flex: 2,
            header: ({ globalState, ...rest }) => (
              <SortCategory text={globalState.config.translate('Category')} globalState={globalState} {...rest} />
            ),
            body: Category,
          },
        {
            flex: 1,
            header: () => <ResponsiveHeaderText text={'Tags'} />,
            body: Tags,
        },
        {
            flex: 1,
            header: ({globalState}) => <ResponsiveHeaderText text={globalState.config.translate('Changed to archived')} />,
            body: Who,
        },
        {
            flex: 1,
            header: ({globalState}) => <ResponsiveHeaderText text={globalState.config.translate('Date of change')} />,
            body: When,
        },
        {
            flex: 1,
            header: ({globalState}) => <ResponsiveHeaderText text={globalState.config.translate('Days archived')} />,
            body: Days,
        },
        {
            flex: 1,
            header: ({ globalState }) => <ResponsiveHeaderText text={globalState.config.translate('Status')} />,
            body: Status,
        },
    ],
};

export default structure;
