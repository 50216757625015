import { PrimaryButton } from "@etiquette-ui/buttons";
import styled from "styled-components";

const FloatingButton = styled(PrimaryButton)`
  position: fixed;
  width: 80%;
  left: 10%;
  bottom: 70px;

  button {
    height: 48px;
  }

  @media( min-width: 768px ){
    width: 400px;
    left: calc(20% + calc(calc(80% * 0.9) / 2) - 200px);
    bottom: 25px;
  }
`;

export default FloatingButton;