import React from 'react';
import LogoutSVG from 'resources/img/logout.svg';
import styled from 'styled-components';

const Button = styled.img`
  width: 25px;
  cursor: pointer;
  margin-right: 35px;
  @media (max-width: 768px) {
    display: none;
  }
`;

const Logout = () => {
  const logout = () => {
    localStorage.removeItem('auth');
    window.location.reload();
  };

  return <Button src={LogoutSVG} onClick={logout} />;
};

export default Logout;
