import { Input } from "@etiquette-ui/inputs-dropdowns";
import useI18n from "i18n/useI18n";
import React, { useMemo } from "react";
import ReactTooltip from "react-tooltip";

const POSCodeInput = ({ variantId, posSync }) => {
    const { translate } = useI18n();

    const error = useMemo(()=>{
        if(!posSync) return false;
        if(!posSync.posCode) return false;
        if(!posSync.posId) return true;
        return false;
    }, [posSync]);

    return (
        <div data-tip data-for={`variantPOS:${variantId}`} style={{ alignItems: 'center', display: 'flex' }}>
        <ReactTooltip id={`variantPOS:${variantId}`} place="top" effect="solid">
          {translate('Update the code in ID Checkup')}
        </ReactTooltip>
        <Input
            errorProps={{
                error: error ? translate('POS Product Not Found') : ''
            }}
            labelProps={{ label: translate("POS Code") }}
            iconProps={{ icon: "pos" }}
            inputProps={{
                value: posSync?.posCode ?? "",
                onChange: (e) => {},
                disabled: true,
            }}
        />
        </div>
    );
};

export default POSCodeInput;
