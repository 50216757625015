import { Spinner } from "@etiquette-ui/misc";
import MainLayout from "containers/MainLayout";
import useI18n from "i18n/useI18n";
import React, { useEffect, useMemo, useReducer } from "react";
import { useParams } from "react-router";
import useCategories from "state/categories";
import useProducts from "state/products";
import styled from "styled-components";
import ItemForm from "../../components/ItemForm";
import INIT_STATE from "../../components/ItemForm/initState";
import { toFormData, toSubmitObject } from "../../components/ItemForm/processor";
import Reducer, { ACTIONS } from "../../components/ItemForm/Reducer";

const LoaderContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
`;

const Edit = ({ location }) => {
    const [formData, formDispatcher] = useReducer(Reducer, INIT_STATE());
    const { itemId } = useParams();
    const [data, productsDispatcher] = useProducts();
    const [categories, categoriesDispatcher] = useCategories();
    const { translate } = useI18n();

    useEffect(() => {
        categoriesDispatcher.fetchStart();
        productsDispatcher.fetchStart();
        productsDispatcher.fetchOneStart(itemId);
    }, []);

    const dispatch = useMemo(
        () => (type, payload) => formDispatcher({ type, payload }),
        [formDispatcher]
    );

    const previousData = useMemo(() => {
        if (
            data.fetching.state ||
            Object.keys(data.detail).length === 0 ||
            categories.list.lenth === 0
        )
            return null;

        return toFormData(data.detail, categories.list);
    }, [data.detail, data.fetching, categories.list]);

    useEffect(() => {
        if (
            data.fetching.state ||
            Object.keys(data.detail).length === 0 ||
            categories.list.lenth === 0
        )
            return;

        const item = toFormData(data.detail, categories.list);

        dispatch(ACTIONS.INIT, item);
    }, [data.detail, data.fetching, categories.list, dispatch]);

    const submit = () => {
        const item = toSubmitObject(formData, data.detail.categories);
        productsDispatcher.updateStart(item);
    };

    return (
        <MainLayout location={location}>
            {data.fetching.state ||
            Object.keys(data.detail).length === 0 ||
            categories.list.length === 0 ? (
                <LoaderContainer>
                    <Spinner
                        style={{
                            "--color": "white",
                            "--bg": "#364456",
                            "--size": "75px",
                            marginRight: "10px",
                        }}
                    />
                </LoaderContainer>
            ) : (
                <ItemForm
                    location={location}
                    formData={formData}
                    dispatch={dispatch}
                    submit={submit}
                    title={translate("Edit Item")}
                    button={translate("Update Item")}
                    isEdit={true}
                    previousData={previousData}
                />
            )}
        </MainLayout>
    );
};

export default Edit;
