import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import PrivateRoute from 'containers/PrivateRoute';
import Login from './pages/Login';
import Logout from './pages/Logout';
import MenuItems from './pages/MenuItems';
import Statistics from './pages/Statistics';
import Settings from 'pages/Settings';
import PowerBI from './pages/Powerbi';
import IDCheckup from 'pages/IDCheckup';
import AccessProvider from 'providers/access';
import SearchProvider from 'providers/search';
import { I18nProvider } from 'i18n/provider';
import RouterProvider from 'providers/router';
import AlertServiceProvider from '@etiquette-ui/alert-dialog';
import ErrorManagement from 'components/ErrorManagement';
import useProducts from 'state/products';
import useCategories from 'state/categories';
import useTags from 'state/tags';
import useFamilies from 'state/families';
import useIngredients from 'state/ingredients';
import useInactive86 from 'state/inactive86';
import useUsers from 'state/users';
import useConfig from 'state/config';
import useProductHistory from 'state/productHistory';
import Stock86Alert from 'components/Stock86Alert';
import usePOS from 'state/pos';
import EmployeesPDFView from 'pages/Statistics/employees/pdf_view';
import ItemsPDFView from 'pages/Statistics/items/pdf_view';

const App = () => {

  const [products, productsDispatcher] = useProducts();
  const [categories, categoriesDispatcher] = useCategories();
  const [tags, tagsDispatcher] = useTags();
  const [families, familiesDispatcher] = useFamilies();
  const [ingredients, ingredientsDispatcher] = useIngredients();
  const [inactive86, inactive86Dispatcher] = useInactive86();
  const [users, usersDispatcher] = useUsers();
  const [config, configDispatcher] = useConfig();
  const [productHistory, productHistoryDispatcher] = useProductHistory();
  const [pos, posDispatcher] = usePOS();

  return (
    <RouterProvider>
      <AccessProvider>
        <SearchProvider>
          <I18nProvider>
            <AlertServiceProvider>
              <ErrorManagement
                states={{
                  categories: { state: categories.error, resetError: categoriesDispatcher.resetError },
                  config: { state: config.error, resetError: configDispatcher.resetError },
                  families: { state: families.error, resetError: familiesDispatcher.resetError },
                  inactive86: { state: inactive86.error, resetError: inactive86Dispatcher.resetError },
                  ingredients: { state: ingredients.error, resetError: ingredientsDispatcher.resetError },
                  productHistory: { state: productHistory.error, resetError: productHistoryDispatcher.resetError },
                  products: { state: products.error, resetError: productsDispatcher.resetError },
                  tags: { state: tags.error, resetError: tagsDispatcher.resetError },
                  users: { state: users.error, resetError: usersDispatcher.resetError },
                  pos: { state: pos.error, resetError: posDispatcher.resetError },
                }}
              />
              <Route exact path="/generate-pdf/employee" component={EmployeesPDFView} ></Route>
              <Route exact path="/generate-pdf/items" component={ItemsPDFView} ></Route>
              <Stock86Alert>
                <Switch>
                  <Route path="/login" component={Login} />
                  <Route path="/logout" component={Logout} />
                  <Route exact path="/">
                    <Redirect to="/menu" />
                  </Route>
                  <PrivateRoute path="/menu" component={MenuItems} />
                  <PrivateRoute path="/statistics" component={Statistics} />
                  <PrivateRoute path="/powerbi" component={PowerBI} />
                  <PrivateRoute path="/id-checkup" component={IDCheckup} />                  
                  <PrivateRoute path="/settings" component={Settings} />
                </Switch>
              </Stock86Alert>
            </AlertServiceProvider>
          </I18nProvider>
        </SearchProvider>
      </AccessProvider>
    </RouterProvider>
  );
};

export default App;
