import { ItemsHistoryIcon } from '@etiquette-ui/icons';
import { Card } from '@etiquette-ui/misc';
import IconLink from 'components/IconLink';
import MainHeaderLayout from 'containers/MainHeaderLayout';
import useI18n from 'i18n/useI18n';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import { H3 } from '@etiquette-ui/typography';
import useInactive86 from 'state/inactive86';
import useUsers from 'state/users';
import useProducts from 'state/products';
import VerificationTable from './components/VerificationTable';
import History86Table from './components/History86Table';
import { Link } from 'react-router-dom';
import { PRIMARY, SECONDARY, SECONDARY_LIGHT } from '@etiquette-ui/colors';
import FullPageContainer from '../../containers/FullPageContainer';
import FullPageLayout from '../../containers/FullPageLayout';
import { useSearch } from 'providers/search';
import CardContainer from 'containers/CardContainer';
import ResponsiveCard from 'containers/ResponsiveCard';
import ResponsiveTitleContainer from 'pages/MenuItems/containers/ResponsiveTitleContainer';
import FormTitle from 'pages/MenuItems/components/FormTitle';

const CustomResponsiveCard = styled(ResponsiveCard)`
    height: fit-content;
`;

const Actions = styled.div`
    display: flex;
    min-height: 57px;

    @media( max-width: 768px ){
        padding-right: 8px;
    }
`;

const Button = styled(Link)`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 15px;
    height: 100%;
    font-weight: 600;
    text-decoration: none;
    color: ${PRIMARY};
    background-color: ${SECONDARY};
    &:hover {
        background-color: ${SECONDARY_LIGHT};
    }
    border-radius: 8px;
`;

const CustomSubtitle = styled(H3)`
    text-align: center;
    font-weight: 500;
    font-size: 1.5rem;
`;

const Management86 = () => {
    const { translate } = useI18n();
    const [, dispatcher] = useInactive86();
    const [, usersDispatcher] = useUsers();
    const [, productsDispatcher] = useProducts();
    const { setVisible } = useSearch();

    useEffect(() => {
        dispatcher.fetchStart();
        dispatcher.fetchHistoryStart();
        usersDispatcher.fetchStart();
        productsDispatcher.fetchStart();
        setVisible(true);
        return () => setVisible(false);
    }, []);

    return (
        <MainHeaderLayout>
            <FullPageContainer>
                <FullPageLayout>
                    <Actions>
                        <IconLink to={'/menu/items'}>{translate('Back to Menu Items')}</IconLink>
                        <div style={{ flex: 1 }}></div>
                        <ResponsiveTitleContainer>
                            <FormTitle>{translate('86 Management')}</FormTitle>
                        </ResponsiveTitleContainer>
                        <Button to={'/menu/items/history-86'}>
                            <ItemsHistoryIcon styles={{ svg: { width: '20px', height: '20px', marginRight: '8px' } }} />
                            {translate('Items History')}
                        </Button>
                    </Actions>
                    <div style={{ minHeight: '18px' }} />
                    <CardContainer>
                        <CustomResponsiveCard>
                            <div>
                                <CustomSubtitle>{translate("86's Verification Page")}</CustomSubtitle>
                                <div style={{ height: '30px' }} />
                                <VerificationTable />
                            </div>
                        </CustomResponsiveCard>
                        <div style={{ minHeight: '30px' }} />
                        <CustomResponsiveCard>
                            <div>
                                <CustomSubtitle>{translate("History 86's Items")}</CustomSubtitle>
                                <div style={{ height: '30px' }} />
                                <History86Table />
                            </div>
                        </CustomResponsiveCard>
                        <div style={{ minHeight: '30px' }} />
                    </CardContainer>
                </FullPageLayout>
            </FullPageContainer>
        </MainHeaderLayout>
    );
};

export default Management86;
