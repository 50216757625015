import useI18n from 'i18n/useI18n';
import React, { useMemo } from 'react';
import ReactTooltip from 'react-tooltip';
import ResponsiveText from 'components/Table/responsive/Text';

const Categories = ({ item }) => {
    const { translate, translateObject } = useI18n();

    const getCategoryName = useMemo(() => {
        if (item.categories.length === 0) return '';
        if (item.categories.length > 1) return translate('Multiple Categories');
        return translateObject(item.categories[0]).title;
    }, [item.categories, translate, translateObject]);

    const getCategoriesTooltip = useMemo(
        () => item.categories.map((cat) => translateObject(cat).title).join(', '),
        [item.categories, translateObject]
    );

    return (
        <div data-tip data-for={`histCat:${item.id}`} style={{ alignItems: 'center', display: 'flex' }}>
            <ResponsiveText text={getCategoryName} />
            {item.categories.length > 1 && (
                <ReactTooltip id={`histCat:${item.id}`} place="top" effect="solid">
                    {getCategoriesTooltip}
                </ReactTooltip>
            )}
        </div>
    );
};

export default Categories;
