import styled from 'styled-components';
import { MultiSelect } from '@etiquette-ui/inputs-dropdowns';

const Filter = styled(MultiSelect)`
    .selector {
        &__placeholder {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            max-width: 150px;
            @media (min-width: 1367px) {
                max-width: 250px;        
            }
        }
        &__menu {
            min-width: 150px;
        }
        &__control {
            flex-wrap: nowrap;
            @media (max-width: 768px) {
                min-width: 190px;           
            }
            @media (min-width: 769px) {
                margin-right: 0px;
                min-width: 210px;            
            }
        }

    }
`;

export default Filter;
