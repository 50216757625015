import useI18n from 'i18n/useI18n';
import { filterByCategory } from 'pages/MenuItems/utils/filters';
import {
    getProcessedCategoriesWithOptions,
    getProcessedSubcategoriesWithOptions,
    getTranslatedCategories,
    getTranslatedCategory,
} from 'pages/MenuItems/utils/transformers';
import React, { useEffect, useState } from 'react';
import useCategories from 'state/categories';
import Filter from 'components/Filter';
import { onSelectCategory, onSelectSubcategory } from 'pages/MenuItems/utils/events';
import useWindowDimensions from 'hooks/useWindowDimensions';
import { ITEM_STATUS } from 'utils/status';

const restrictTextLength = (text = '', width) => {
    let maxLength = 18;
    if (width > 1024) {
        maxLength = 27;
    }

    if (text.length < maxLength) return text;
    const textElements = text.split('');
    textElements.splice(maxLength - 3, text.length - (maxLength - 3));
    return textElements.join('') + '...';
};

const Filters = ({ products, setList }) => {
    const { translate, selectedLang } = useI18n();
    const [categories] = useCategories();
    const { width } = useWindowDimensions();

    const [categoriesOptions, setCategoriesOptions] = useState([]);
    const [selectedCategories, setSelectedCategories] = useState([]);

    const [subcategoriesOptions, setSubcategoriesOptions] = useState([]);
    const [selectedSubcategories, setSelectedSubcategories] = useState([]);

    useEffect(() => {
        const processed = getProcessedCategoriesWithOptions(categories);
        setCategoriesOptions(processed);
    }, [categories.list, categories.subcategories]);

    useEffect(() => {
        if (categoriesOptions.length === 0) return;
        setSelectedCategories([categoriesOptions[0]]);
    }, [categoriesOptions]);

    useEffect(() => {
        if (selectedCategories.length == 0 && categoriesOptions.length > 0) {
            const defaultCategory = getTranslatedCategory(categoriesOptions[0], translate, selectedLang);
            setSelectedCategories([defaultCategory]);
        }
        if (selectedCategories.length === 1 && selectedCategories[0].value === -1) {
            setSubcategoriesOptions(getProcessedSubcategoriesWithOptions(categories.subcategories));
            return;
        }
        const reduced = selectedCategories.reduce((acc, curr) => [...acc, ...curr.subcategories], []);
        setSubcategoriesOptions(getProcessedSubcategoriesWithOptions(reduced));
    }, [selectedCategories]);

    useEffect(() => {
        if (subcategoriesOptions.length === 0) return;
        setSelectedSubcategories([subcategoriesOptions[0]]);
    }, [subcategoriesOptions]);

    useEffect(() => {
        const newList = products
            .filter((p) => filterByCategory(p, selectedCategories, selectedSubcategories, categoriesOptions))
            .filter((p) => p.localStatus === ITEM_STATUS.ARCHIVED);

        setList(newList);
    }, [products, selectedCategories, selectedSubcategories]);

    return (
        <>
            <Filter
                labelProps={{ label: translate('Category') }}
                selectProps={{
                    onChange: onSelectCategory(selectedCategories, setSelectedCategories),
                    options: getTranslatedCategories(categoriesOptions, translate, selectedLang),
                    placeholder: restrictTextLength(selectedCategories.map((cat) => cat.label).join(' | '), width),
                }}
                selectedValues={selectedCategories}
            />
            <Filter
                labelProps={{ label: translate('Subcategory') }}
                selectProps={{
                    onChange: onSelectSubcategory(selectedSubcategories, setSelectedSubcategories),
                    options: getTranslatedCategories(subcategoriesOptions, translate, selectedLang),
                    placeholder: restrictTextLength(selectedSubcategories.map((cat) => cat.label).join(' | '), width),
                }}
                selectedValues={selectedSubcategories}
            />
        </>
    );
};

export default Filters;
