import { ALERT_TYPES, useAlert } from '@etiquette-ui/alert-dialog';
import { PSmall, Subtitle } from '@etiquette-ui/typography';
import FormSpacer from 'components/Form/FormSpacer';
import ImageUpload from 'components/ImageUpload';
import FormSection from 'containers/Form/FormSection';
import useI18n from 'i18n/useI18n';
import ImagePickerDialog from 'pages/MenuItems/alert/ImagePicker';
import ImageSectionContainer from 'pages/MenuItems/containers/ImageSectionContainer';
import ITEM_KEYS from 'pages/MenuItems/pages/Items/components/ItemForm/keys';
import React from 'react';
import styled from 'styled-components';

const CustomImageUpload = styled(ImageUpload)`
    width: 250px;
`;

const Image = ({ formData, handleItemInfo }) => {
    const { translate } = useI18n();
    const alert = useAlert();

    const openImagePicker = () => {
        alert({
            type: ALERT_TYPES.CUSTOM,
            config: {
              body: ImagePickerDialog,
              props: {},
            },
          }).then((img)=>{
            handleItemInfo(ITEM_KEYS.IMAGE, img);
          }).catch(()=>{});
    }

    return (
        <FormSection>
            <Subtitle>{translate('Representative image')}</Subtitle>
            <FormSpacer />
            <ImageSectionContainer>
                <CustomImageUpload
                    text={translate('Add Photo')}
                    altText={translate('Edit Photo')}
                    src={formData[ITEM_KEYS.IMAGE]}
                    onChange={(value) => handleItemInfo(ITEM_KEYS.IMAGE, value)}
                    onClick={openImagePicker}
                />
                <div style={{ width: '20px' }}></div>
            </ImageSectionContainer>
        </FormSection>
    );
};

export default Image;
