import React, { useEffect, useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import { Card } from '@etiquette-ui/misc';
import styled from 'styled-components';
import useI18n from 'i18n/useI18n';
import CardContainer from 'containers/CardContainer';
import useStatistics from 'state/statistics';
import Message from './components/Message';
import Pbi from './pbi';
import { usePrintButton } from '../print/NavButtonsProvider';
import { getPDF } from 'resources/api/statistics';

const Container = styled.div`
  width: 100%;
  height: 100%;
  overflow: scroll;
  padding-bottom: 16px;
  padding-top: 15px;
`;

const Items = () => {
  const { registerPrintFunction, unregisterPrintFunction, registerDownloadFunction,  unregisterDownloadFunction} = usePrintButton();

  const [data] = useStatistics();
  const { translate } = useI18n();

  const reportContent = useRef();
  const cardContainerRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => reportContent.current,
  });

  const handleDownloadPDF = async () => {
    try {
      const response = await getPDF('items', data)

      const blob = new Blob([response], { type: 'application/pdf' });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;

      link.setAttribute('download', `report.pdf`); 
      document.body.appendChild(link);
      link.click();


      window.URL.revokeObjectURL(url);
      link.parentNode.removeChild(link);
    } catch (error) {
      console.error('Error downloading the PDF', error);
    }
  }


  useEffect(()=>{
    if(!!data.itemsData) {
      registerPrintFunction(handlePrint);
      registerDownloadFunction(handleDownloadPDF);
    }

    return () => {
      unregisterPrintFunction();
      unregisterDownloadFunction();
    }
  }, [data, registerPrintFunction, unregisterPrintFunction]);

  return (
    <Container>
      <div style={{
          display: 'flex', 
          justifyContent: 'end', 
          gap: '15px', 
          flexWrap: 'wrap', 
          marginBottom: '10px'
        }}
      >
      </div>
      <CardContainer ref={cardContainerRef}>
        <Card>
            {
              <Pbi reference={reportContent}/> || <Message message={translate('Please Select a Report')} />
            }
        </Card>
      </CardContainer>
    </Container>
  );
}
export default Items;