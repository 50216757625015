import styled from 'styled-components';
import { MultiSelect, Dropdown } from '@etiquette-ui/inputs-dropdowns';

export const MultiSelector = styled(MultiSelect)`
  width: 100%;
  .selector_ {
    &_placeholder {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: 80px;
    }
    &_single-value {
      font-size: .875rem;
      padding-bottom: 2px;
    }
  }
  label {
    top: -8px;
  }
`;

export const Selector = styled(Dropdown)`
  .selector_ {
    &_single-value,
    &_placeholder {
      font-size: .875rem;
      padding-bottom: 2px;
    }
  }
  label {
    top: -8px;
  }
`;
