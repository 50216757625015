import React from 'react'
import styled from 'styled-components';

const Container = styled.div`
    text-align: center;
    margin-top: 30px;
`;

const Row = styled.div`
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 42px;
    grid-gap: 2px;
`;

const Title = styled.div`
    font-size: 19px;
    letter-spacing: 0px;
    margin: 0px;
    padding-bottom: 10px;
    font-weight: 600;
`;
const Subtitle = styled.div`
    font-size: 15px;
    letter-spacing: 0px;
    font-weight: 600;

    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${({color}) => (color)};
    border-width: 2px;
`;

const TableHeader = ({ title, opacity }) => {
    const isOdd = title !== " " ? '#F6F6F6' : 'transparent';
    const isDarker = opacity && '#EEEEEE';

    return (
        <Container>
            <Title>{title}</Title>
            <Row>
                <Subtitle color={opacity ? isDarker : isOdd}>{title !== " " && "Stars"}</Subtitle>
                <Subtitle color={opacity ? isDarker : isOdd}>{title !== " " && "Cmts."}</Subtitle>
                <Subtitle color={opacity ? isDarker : isOdd}>{title !== " " && "Total"}</Subtitle>
            </Row>
        </Container>
    );
};

export default TableHeader;
