import FloatingActionButton from 'components/FloatingActionButton';
import Tabs from 'components/Tabs';
import TabLayoutContainer from 'containers/TabLayout';
import React, { useMemo } from 'react';
import { useRouteMatch } from 'react-router';
import styled from 'styled-components';

const Content = styled.div`
  width: 90%;
  margin-bottom: 40px;
  overflow: hidden;
  @media (max-width: 768px) {
    width: 100%;
    margin-bottom: 5px;
  }
`;

const NavBarContainer = styled.div`
  width: 60%;
  height: 100%;
  display: flex;

  &.isExtended {
    width: 90%;
  }
  
  @media (max-width: 768px) {
    &, &.isExtended {
      width: 100%;
    }
  }
`;

const getRoutes = (routesArray, url) => {
  return(routesArray.map(route => {
    return({
      path: `${url}${route.path}`,
      title: route.title
    })
  }));
}

const BaseMenuTabsContainer = ({ location, routes, customPath=null,  fabLink, fabText, fabDisabled = false, children, navbarItems, isExtendedNavbar = false }) => {
  const { url } = useRouteMatch();

  const finalRoutes = useMemo(()=>{
    const path = customPath ?? url;
    return getRoutes(routes, path);
  }, [customPath]);

  return (
    <TabLayoutContainer>
      <NavBarContainer className={isExtendedNavbar ? 'isExtended' : ''}>
        <Tabs routes={finalRoutes} pathIndex={2} current={location.pathname} />
        {navbarItems}
      </NavBarContainer>
      <Content>{children}</Content>
      {fabLink && (
        <FloatingActionButton to={fabLink} disabled={fabDisabled}>
          {fabText}
        </FloatingActionButton>
      )}
    </TabLayoutContainer>
  );
};

export default BaseMenuTabsContainer;
