import { Image } from '@etiquette-ui/dynamic-table-components';
import styled from 'styled-components';

const ResponsiveImage = styled(Image)`
    @media (max-width: 768px) {
        width: 32px;
        height: 32px;
        > div {
            width: 32px;
            height: 32px;
        }
    }
`;

export default ResponsiveImage;