import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import useI18n from 'i18n/useI18n';

import { ImageIcon } from '@etiquette-ui/icons';

import ListActionsContainer from 'pages/MenuItems/containers/ListActionsContainer';
import ListLayout from 'pages/MenuItems/containers/ListLayout';
import MenuTabsContainer from 'pages/MenuItems/containers/MenuTabsContainer';

import useCategories from 'state/categories';
import Table from 'components/Table';
import structure from './structure';
import { BaseButton } from 'components/Buttons/ArrangeButton';
import { fetchGalleries } from 'resources/api';
import { ALERT_TYPES, useAlert } from '@etiquette-ui/alert-dialog';
import GalleryPicker from './alert/GalleryPicker';
import ResponsiveSpacer from 'pages/MenuItems/components/ResponsiveSpacer';
import ResponsiveArrangeButton from 'pages/MenuItems/components/ResponsiveArrangeButton';
import styled from 'styled-components';
import CardContainer from 'containers/CardContainer';
import ResponsiveCard from 'containers/ResponsiveCard';
import FloatingArrangeButton from 'pages/MenuItems/components/FloatingArrangeButton';
import useConfig from 'state/config';
import { useSearch } from 'providers/search';
import { compareStrings } from 'utils';

const Actions = styled(ListActionsContainer)`
    justify-content: flex-end;
    @media (max-width: 768px) {
        justify-content: flex-start;
    }
`;

const List = ({ location }) => {
    const { translate } = useI18n();
    const [categories, dispatcher] = useCategories();
    const history = useHistory();
    const [galleries, setGalleries] = useState({});
    const [selectedGallery, setSelectedGallery] = useState('');
    const alert = useAlert();
    const [configData, configDispatcher] = useConfig();
    const { setVisible, search } = useSearch();
    const [list, setList] = useState([]);

    const asyncFetchGalleries = async () => {
        const res = await fetchGalleries();
        setGalleries(res);
    };

    useEffect(() => {
        dispatcher.fetchStart();
        configDispatcher.fetchStart();
        asyncFetchGalleries();
        setVisible(true);
        return () => setVisible(false);
    }, []);

    useEffect(() => {
        const newList = categories.list
            .filter(
                ({ translations }) => 
                Object.values(translations).some(({ title, description }) => compareStrings(title ?? '', search) || compareStrings(description ?? '', search))
            );

        setList(newList);
    }, [categories.list, search]);

    useEffect(() => {
        if (!configData?.map['selected-icon-set']?.payload['icon-set']) return;
        setSelectedGallery(configData?.map['selected-icon-set']?.payload['icon-set']);
    }, [configData]);

    const showIconGalleries = () => {
        alert({
            type: ALERT_TYPES.CUSTOM,
            config: {
                body: GalleryPicker,
                props: {
                    galleries,
                    selectedGallery: configData.map['selected-icon-set'].payload['icon-set'],
                },
            },
        })
            .then((selectedGallery) => {
                configDispatcher.updateStart({name: 'selected-icon-set', payload: { 'icon-set': selectedGallery }, isPrivate: false });
            })
            .catch(() => {});
    };

    const arrange = () => history.push('categories/arrange')

    return (
        <MenuTabsContainer location={location} fabLink={`${location.pathname}/create`} fabText={'Add Item'}>
            <ListLayout>
                <Actions>
                    <BaseButton onClick={showIconGalleries}>
                        <ImageIcon styles={{ svg: { width: '20px', height: '20px', marginRight: '8px' } }} />{' '}
                        {selectedGallery ? galleries[selectedGallery] : translate('Icons not assigned')}
                    </BaseButton>
                    <div style={{ width: '17px' }} />
                    <ResponsiveArrangeButton onClick={arrange} />
                </Actions>
                <ResponsiveSpacer />
                <CardContainer>
                    <ResponsiveCard>
                        <Table structure={structure} config={{ translate, alert }} items={list} />
                    </ResponsiveCard>
                </CardContainer>
            </ListLayout>
            <FloatingArrangeButton onClick={arrange}/>
        </MenuTabsContainer>
    );
};

export default List;
