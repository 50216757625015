import styled from 'styled-components';

const AppContainer = styled.div`
  display: grid;
  grid-template-rows: 90px minmax(0, 1fr);
  height: 100vh;
  width: 100vw;
  @media (max-width: 768px) {
    grid-template-rows: 67px minmax(0, 1fr);
  }
`;

export default AppContainer;
