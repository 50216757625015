import React, { useEffect } from 'react';
import styled from 'styled-components';
import { ALERT_TYPES, useAlert } from '@etiquette-ui/alert-dialog';
import useI18n from 'i18n/useI18n';
import { ACCESS_LIST, ACCESS_USER_TYPES, APP_ID } from 'resources/constants/config';

const Window = styled.iframe`
    width: 100vw;
    height: 100vh;
    border: none;
`;

const Login = () => {
    const alert = useAlert();
    const { translate } = useI18n();
    useEffect(() => (window.onload = onLoad), []);

    const onLoad = () => {
        const loginFrame = document.getElementById('loginframe')?.contentWindow;
        if (!!loginFrame) {
            loginFrame.postMessage(`getData:${APP_ID}`, process.env.REACT_APP_AUTH_URL);

            window.addEventListener(
                'message',
                (e) => {
                    if (typeof e.data !== 'string' || !e.data.startsWith('data:')) return;
                    const toStore = e.data.slice(5);
                    if (toStore !== '') {
                        const auth = toStore.split('|')[0];
                        const parsed = JSON.parse(auth);
                        const userType = parsed.ofType;
                        const accessList = parsed.access;
                        if (
                            userType === ACCESS_USER_TYPES.GLOBAL ||
                            userType === ACCESS_USER_TYPES.ROOT ||
                            (Array.isArray(accessList) &&
                                accessList.length > 0 &&
                                accessList.some((access) => Object.values(ACCESS_LIST).includes(access)))
                        ) {
                            localStorage.setItem('auth', auth);
                            window.location.href = '/';
                        } else {
                            alert({
                                type: ALERT_TYPES.INFO,
                                config: {
                                    title: translate('Unauthorized'),
                                    body: translate("You don't have access to this app"),
                                    buttonText: translate('OK'),
                                },
                            })
                                .then(() => {})
                                .catch(() => {});
                        }
                    }
                },
                false
            );
        }
    };

    return <Window id="loginframe" title="login" src={process.env.REACT_APP_AUTH_URL}></Window>;
};

export default Login;
