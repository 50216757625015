import { Card } from '@etiquette-ui/misc';
import styled from 'styled-components';

const FormCard = styled(Card)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 1rem;
  padding: 20px 24px;
  flex: 1;

  @media( max-width: 768px ){
    padding-left: 0px;
    padding-right: 0px;
    padding-bottom: 40px;
  }
`;

export default FormCard;
