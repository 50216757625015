import Types from "./types";

const INITIAL_STATE = {
    items: {
        list: [],
        selectedCategories: [],
        categoriesOptions: [],
        subcategoriesOptions: [],
        selectedSubcategories: [],
        arrangeSelectedCategory: null,
        arrangeSelectedSubcategory: null,
    },
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case Types.ITEMS_SET_LIST:
            return { ...state, items: { ...state.items, list: action.payload } };
        case Types.ITEMS_SET_CATEGORIES_OPTIONS:
            if(state.items.selectedCategories.length === 0) {
              return { ...state, items: { ...state.items, categoriesOptions: action.payload, selectedCategories: [action.payload[0]] } };
            }
            return { ...state, items: { ...state.items, categoriesOptions: action.payload } };
        case Types.ITEMS_SET_SELECTED_CATEGORIES:
            if(action.payload.length === 0) {
              return { ...state, items: { ...state.items, selectedCategories: [state.items.categoriesOptions[0]] } };
            }
            return { ...state, items: { ...state.items, selectedCategories: action.payload } };
        case Types.ITEMS_SET_SUBCATEGORIES_OPTIONS:
            if(state.items.selectedSubcategories.length === 0) {
              return { ...state, items: { ...state.items, subcategoriesOptions: action.payload, selectedSubcategories: [action.payload[0]] } };
            }
            for(const selectedSubcategory of state.items.selectedSubcategories) {
              if(!action.payload.some(subcategory => subcategory.value === selectedSubcategory.value)) {
                return { ...state, items: { ...state.items, subcategoriesOptions: action.payload, selectedSubcategories: [action.payload[0]] } };
              }
            }
            return { ...state, items: { ...state.items, subcategoriesOptions: action.payload } };
        case Types.ITEMS_SET_SELECTED_SUBCATEGORIES:
            if(action.payload.length === 0) {
              return { ...state, items: { ...state.items, selectedSubcategories: [state.items.subcategoriesOptions[0]] } };
            }
            return { ...state, items: { ...state.items, selectedSubcategories: action.payload } };
        case Types.ARRANGE_ITEMS_SET_SELECTED_CATEGORY:
            return { ...state, items: { ...state.items, arrangeSelectedCategory: action.payload } };
        case Types.ARRANGE_ITEMS_SET_SELECTED_SUBCATEGORY:
            return { ...state, items: { ...state.items, arrangeSelectedSubcategory: action.payload } };
        default:
            return state;
    }
};
