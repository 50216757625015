import ResponsiveText from 'components/Table/responsive/Text';
import React from 'react';

const Position = ({ globalState, item }) => {
    const subcategory = globalState.config.selectedSubcategory;

    const cat = subcategory ? item.categories.find(({ category }) => category === subcategory.value) : null;

    return <ResponsiveText text={cat ? `${cat.position}` : ''} />;
};

export default Position;
