import React from 'react';
import styled from 'styled-components';
import Home from './Home';
import Promotion from './Promotion';
import ProductsList from './ProductsList/index';
import { BaseDialog } from '@etiquette-ui/alert-dialog';

const Body = styled.div`
  width: 350px;
  height: 650px;
  border-radius: 20px;
  overflow-y: scroll;
`;

const CustomDialog = styled(BaseDialog)`
  .dialog-module_content__2NnHL {
    padding: 0px;
  }
  .dialog-module_mainContent__3RV04 {
    margin: 0px;
  }
`;

const BodyComponent = (type, formData) => {
  switch(type){
    case 'products-list':
      return <ProductsList formData={formData}/>
    default: 
      return <Home formData={formData}/>
  };
}

const Dialog = ({bodyType= undefined, showPromoModal = false, formData, open, onClose }) => {
  return (
    <CustomDialog open={open} onClose={onClose} isResponsive={true} children={
      <Body>
        {BodyComponent(bodyType, formData)}
        {!!showPromoModal && (<Promotion formData={formData}/>)}
      </Body>}>      
    </CustomDialog>
  )
}

export default Dialog;
 