import React, { useEffect, useState } from 'react';
import { Spinner } from '@etiquette-ui/misc';

const withGroupingAndBlacklist = (WrappedComponent) => {
    return ({ data, ...props }) => {
        const [blacklist, setBlacklist] = useState();
        const [waitersBF, setWaitersBF] = useState();
        const [groupedData, setGroupedData] = useState(data);

        useEffect(() => {
            const fetchBlacklist = fetch("/data/managerIds.json")
                                    .then((response) => response.json());

            const fetchWaitersBF = fetch("/data/bfIds.json")
                                    .then((response) => response.json());

            Promise.all([fetchBlacklist, fetchWaitersBF]).then(([blacklistData, waitersData]) => {
                setBlacklist(blacklistData);
                setWaitersBF(waitersData);
            });
        }, []);

        useEffect(() => {
            if (!waitersBF || !data || !data.stats.length) {
                return;
            }

            const newStats = [];
            data.stats.forEach(stat => {
                const groupA = [];
                const groupB = [];

                stat.data.forEach(el => {
                    if (waitersBF[el.id]) {
                        groupB.push(el);
                    } else {
                        groupA.push(el);
                    }
                });

                if (groupA.length > 0) {
                    newStats.push({ ...stat, data: groupA });
                }

                if (groupB.length > 0) {
                    newStats.push({ ...stat, data: groupB });
                }
            });
            setGroupedData({ ...data, stats: newStats });
        }, [waitersBF, data]);

        return <WrappedComponent data={groupedData} blacklist={blacklist} {...props} />;
    };
};

export default withGroupingAndBlacklist;
