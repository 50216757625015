import { takeLatest, put, all, call } from 'redux-saga/effects';
import { inactive86API, productsAPI, serviceConnectorAPI } from 'resources/api';
import { processError } from 'state/utils';
import { toFormData } from 'utils';
import { getItemStatus } from 'utils/status';
import Types from './types';
import { goBack } from 'connected-react-router';

function* fetchStartAsync() {
    try {
        const res = yield productsAPI.getList();
        yield put({ type: Types.FETCH_SUCCESS, payload: res.map((p) => ({ ...p, localStatus: getItemStatus(p) })) });
    } catch (error) {
        const message = processError(error);
        console.error(message);
        yield put({ type: Types.FETCH_ERROR, payload: message });
    }
}

function* fetchStart() {
    yield takeLatest(Types.FETCH_START, fetchStartAsync);
}

function* fetchOneStartAsync({ payload }) {
    try {
        const res = yield productsAPI.getOne(payload);
        yield put({ type: Types.FETCH_ONE_SUCCESS, payload: res });
    } catch (error) {
        const message = processError(error);
        console.error(message);
        yield put({ type: Types.FETCH_ERROR, payload: message });
    }
}

function* fetchOneStart() {
    yield takeLatest(Types.FETCH_ONE_START, fetchOneStartAsync);
}

function* updateStartAsync({ payload }) {
    const { item, goBack: shouldGoBack } = payload;
    const { posCode, ...restPayload } = item;

    try {
        const form = toFormData(restPayload);
        yield productsAPI.update(form);
        yield put({ type: Types.UPDATE_SUCCESS, payload: restPayload });
        if (shouldGoBack) yield put(goBack());
    } catch (error) {
        const message = processError(error);
        console.error(message);
        yield put({ type: Types.UPDATE_ERROR, payload: message });
    }

    if (!!posCode) {
        try {
            const posProduct = {
                productId: restPayload.id,
                price: restPayload.price,
                posCode: posCode,
            };
            yield serviceConnectorAPI.update(posProduct);
        } catch (error) {
            const message = processError(error);
            console.error(message);
            yield put({ type: Types.UPDATE_ERROR, payload: message });
        }
    }
}

function* updateStart() {
    yield takeLatest(Types.UPDATE_START, updateStartAsync);
}

function* createStartAsync({ payload }) {
    const { item, goBack: shouldGoBack } = payload;
    const { posCode, ...restPayload } = item;

    try {
        const form = toFormData(restPayload);
        yield productsAPI.create(form);
        yield put({ type: Types.CREATE_SUCCESS, payload: restPayload });
        if (shouldGoBack) yield put(goBack());
    } catch (error) {
        const message = processError(error);
        console.error(message);
        yield put({ type: Types.CREATE_ERROR, payload: message });
    }

    if (!!posCode) {
        try {
            const posProduct = {
                productId: restPayload.id,
                price: restPayload.price,
                posCode: posCode,
            };
            yield serviceConnectorAPI.update(posProduct);
        } catch (error) {
            const message = processError(error);
            console.error(message);
            yield put({ type: Types.UPDATE_ERROR, payload: message });
        }
    }
}

function* createStart() {
    yield takeLatest(Types.CREATE_START, createStartAsync);
}

function* inactive86Async({ payload }) {
    try {
        yield inactive86API.inactivate(payload);
        yield put({ type: Types.UPDATE_SUCCESS, payload: { id: payload.id, stockTotal: 0, status: 'active' } });
    } catch (error) {
        const message = processError(error);
        console.error(message);
        yield put({ type: Types.UPDATE_ERROR, payload: message });
    }
}

function* inactive86() {
    yield takeLatest(Types.INACTIVE_86, inactive86Async);
}

function* positionAsync({ payload }) {
    try {
        yield productsAPI.position(payload);
        const res = yield productsAPI.getList();
        yield put({ type: Types.POSITION_SUCCESS, payload: res.map((p) => ({ ...p, localStatus: getItemStatus(p) })) });
    } catch (error) {
        const message = processError(error);
        console.error(message);
        yield put({ type: Types.POSITION_ERROR, payload: message });
    }
}

function* position() {
    yield takeLatest(Types.POSITION_START, positionAsync);
}

const createObject = (data) => {
    let arrayData = [];
    for(const newPrice of data) {
        arrayData.push({
            "variantId": +newPrice.variant.id,
            "price": +newPrice.price
        });
    }
    return arrayData;
}

function* updatePricesStartAsync({ payload }) {
    try {
        const data = createObject(payload);
        yield productsAPI.updatePrices(data);
        yield put({ type: Types.UPDATE_PRICES_SUCCESS, payload });
    } catch (error) {
        const message = processError(error);
        console.error(message);
        yield put({ type: Types.UPDATE_ERROR, payload: message });
    }
}

function* updatePricesStart() {
    yield takeLatest(Types.UPDATE_PRICES_START,updatePricesStartAsync);
}

export default function* sagas() {
    yield all([call(fetchStart), call(fetchOneStart), call(updateStart), call(updatePricesStart), call(createStart), call(inactive86), call(position)]);
}
