const TYPE = `[POS]`;

export default {
    FETCH_START: `${TYPE} FETCH START`,
    FETCH_SUCCESS: `${TYPE} FETCH SUCCESS`,
    FETCH_ERROR: `${TYPE} FETCH ERROR`,

    UPDATE_START: `${TYPE} UPDATE START`,
    UPDATE_SUCCESS: `${TYPE} UPDATE SUCCESS`,
    UPDATE_ERROR: `${TYPE} UPDATE ERROR`,

    RESET_ERROR: `${TYPE} RESET ERROR`,
}