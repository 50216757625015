import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import useI18n from 'i18n/useI18n';
import { isCurrent } from 'utils/routes';
import { NavItem } from '@etiquette-ui/selectors';
import { BG_DARK } from '@etiquette-ui/colors';

const Nav = styled.nav`
  display: flex;
  flex: 1;
  align-items: flex-start;
  gap: 50px;
  @media (max-width: 768px) {
    height: 50px;
    padding-top: 10px;
    background-color: ${BG_DARK};
    border-radius: 0 0 24px 24px;
    padding: 8px;
    padding-top: 12px;
  }
`;

const CustomNav = styled(NavItem)`
  min-width: unset;
  max-width: 120px;
  margin-right: unset;
  
  .navitem-module_indicator__7mzYy {
    margin-top: 5px;
  }

  @media (max-width: 768px) {
    flex: 1;
    display: flex;
    justify-content: center;
    > div {

      a {
        font-size: 0.875rem;
      }

      > div {

        > div {
          margin-top: 5px;
          height: 2px;
          width: 30px;
        }
      }
    }
  }
`;

const CustomLink = styled(Link)`
  color: white;
  text-decoration: none;
  font-weight: 500;
  font-size: 1rem;
  &.current {
    font-weight: 600;
  }
`;

const Tabs = ({ routes = [], pathIndex, current, className }) => {
  const { translate } = useI18n();
  return (
    <Nav className={className}>
      {routes.map(({ path, title }, i) => (
        <CustomNav key={i} isActive={isCurrent(path, current, pathIndex)}>
          <CustomLink to={path} className={isCurrent(path, current, pathIndex) ? 'current' : ''}>
            {translate(title)}
          </CustomLink>
        </CustomNav>
      ))}
    </Nav>
  );
};

export default Tabs;
