import { useContext } from 'react'
import { FiltersContext } from './provider';

const useFilters = () => {
    const { selectedCategories, setSelectedCategories, initFilters } = useContext(FiltersContext);

    return { selectedCategories, setSelectedCategories, initFilters };
}


export default useFilters;