import styled from 'styled-components';

const CardContainer = styled.div`
    @media (max-width: 768px) {
        overflow-x: scroll;
        width: 100%;
        padding: 0 8px;
    }
`;

export default CardContainer;
