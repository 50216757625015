import React from 'react';
import { Subtitle } from '@etiquette-ui/typography';
import FormSpacer from 'components/Form/FormSpacer';
import FormSection from 'containers/Form/FormSection';
import GridContainer from 'containers/Form/GridContainer';
import useI18n from 'i18n/useI18n';
import { Dropdown } from '@etiquette-ui/inputs-dropdowns';
import { TEXT, TEXT_PLACEHOLDER } from '@etiquette-ui/colors';
import CATEGORY_KEYS, { CATEGORY_CONFIG_KEYS } from '../keys';
import { DEFAULT_VIEW_OPTIONS, findOption, TAGS_FILTER_TYPES_OPTIONS } from '../config';
import { SwitchButton } from '@etiquette-ui/selectors';
import { TextInactive } from 'components/Texts';
import styled from 'styled-components';
import { ACTIONS } from '../Reducer';

const Grid = styled(GridContainer)`
    align-items: flex-start;
`;

const SwitchContainer = styled.div`
    display: flex;
    align-self: center;
    > div {
        margin-left: unset;
        div:hover {
            border-width: 1px;
            width: 15px;
            height: 15px;
            border-color: ${TEXT_PLACEHOLDER};
        }
    }
`;

const Settings = ({ formData, dispatch }) => {
    const { translate } = useI18n();

    const addTranslation = (obj) => ({ ...obj, label: translate(obj.label) });

    const expandedEnabled = formData[CATEGORY_KEYS.CONFIG][CATEGORY_CONFIG_KEYS.VIEW].value === 'list';

    return (
        <FormSection>
            <Subtitle>{translate('Settings')}</Subtitle>
            <FormSpacer />
            <Grid>
                <div>
                    <Dropdown
                        labelProps={{
                            label: translate('Default view'),
                        }}
                        leadingIconProps={{
                            icon: 'gallery',
                            styles: {
                                line: {
                                    fill: 'none',
                                    strokeLinecap: 'round',
                                    strokeLinejoin: 'round',
                                    strokeWidth: '1.5px',
                                    stroke: TEXT,
                                },
                            },
                        }}
                        selectProps={{
                            onChange: (value) =>
                                dispatch(ACTIONS.CONFIG, {
                                    key: CATEGORY_CONFIG_KEYS.VIEW,
                                    value: findOption(DEFAULT_VIEW_OPTIONS, value.value),
                                }),
                            options: DEFAULT_VIEW_OPTIONS.map((opt) => addTranslation(opt)),
                            placeholder: translate('Pick one...'),
                            value: addTranslation(formData[CATEGORY_KEYS.CONFIG][CATEGORY_CONFIG_KEYS.VIEW]),
                        }}
                    />
                    <div style={{ height: '10px' }} />
                    <SwitchContainer>
                        <SwitchButton
                            active={formData[CATEGORY_KEYS.CONFIG][CATEGORY_CONFIG_KEYS.EXPANDED]}
                            disabled={!expandedEnabled}
                            onClick={
                                expandedEnabled
                                    ? () =>
                                          dispatch(ACTIONS.CONFIG, {
                                              key: CATEGORY_CONFIG_KEYS.EXPANDED,
                                              value: !formData[CATEGORY_KEYS.CONFIG][CATEGORY_CONFIG_KEYS.EXPANDED],
                                          })
                                    : () => {}
                            }
                        />
                        <div style={{ width: '10px' }} />
                        <TextInactive disabled={!expandedEnabled}>
                            {translate(
                                formData[CATEGORY_KEYS.CONFIG][CATEGORY_CONFIG_KEYS.EXPANDED] ? 'Expanded List' : 'Minimized List'
                            )}
                        </TextInactive>
                    </SwitchContainer>
                </div>
                <div>
                    <Dropdown
                        labelProps={{
                            label: translate('Tags Filter Type'),
                        }}
                        leadingIconProps={{
                            icon: 'tags',
                            styles: {
                                line: {
                                    fill: 'none',
                                    strokeLinecap: 'round',
                                    strokeLinejoin: 'round',
                                    strokeWidth: '1.5px',
                                    stroke: TEXT,
                                },
                            },
                        }}
                        selectProps={{
                            onChange: (value) =>
                                dispatch(ACTIONS.CONFIG, {
                                    key: CATEGORY_CONFIG_KEYS.FILTER_TYPE,
                                    value: findOption(TAGS_FILTER_TYPES_OPTIONS, value.value),
                                }),
                            options: TAGS_FILTER_TYPES_OPTIONS.map((opt) => addTranslation(opt)),
                            placeholder: translate('Pick one...'),
                            value: addTranslation(formData[CATEGORY_KEYS.CONFIG][CATEGORY_CONFIG_KEYS.FILTER_TYPE]),
                        }}
                    />
                    <div style={{ height: '10px' }} />
                    <SwitchContainer>
                        <SwitchButton
                            active={formData[CATEGORY_KEYS.CONFIG][CATEGORY_CONFIG_KEYS.WITH_SELECT_ALL]}
                            onClick={() =>
                                dispatch(ACTIONS.CONFIG, {
                                    key: CATEGORY_CONFIG_KEYS.WITH_SELECT_ALL,
                                    value: !formData[CATEGORY_KEYS.CONFIG][CATEGORY_CONFIG_KEYS.WITH_SELECT_ALL],
                                })
                            }
                        />
                        <div style={{ width: '10px' }} />
                        <TextInactive>
                            {translate(
                                formData[CATEGORY_KEYS.CONFIG][CATEGORY_CONFIG_KEYS.WITH_SELECT_ALL]
                                    ? 'With "Full Menu"'
                                    : 'Without "Full Menu"'
                            )}
                        </TextInactive>
                    </SwitchContainer>
                </div>
            </Grid>
            <FormSpacer />
        </FormSection>
    );
};

export default Settings;
