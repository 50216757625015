import Types from './types';
export default class ProductsDispatcher {
  constructor(dispatch) {
    this.dispatch = dispatch;
  }

  fetchStart = () => this.dispatch({ type: Types.FETCH_START, payload: {} });

  fetchOneStart = (payload) => this.dispatch({ type: Types.FETCH_ONE_START, payload });

  updateStart = (item, goBack=true) => this.dispatch({ type: Types.UPDATE_START, payload: { item, goBack } });

  position = (payload) => this.dispatch({ type: Types.POSITION_START, payload });

  inactive86 = (payload) => this.dispatch({ type: Types.INACTIVE_86, payload });

  createStart = (item, goBack=true) => this.dispatch({ type: Types.CREATE_START, payload: { item, goBack } });

  deleteStart = (payload) => this.dispatch({ type: Types.DELETE_START, payload });

  updatePositionStart = (payload) => this.dispatch({ type: Types.UPDATE_POSITION_START, payload });

  resetError = () => this.dispatch({ type: Types.RESET_ERROR, payload: {} });

  updatePrices = (payload) => this.dispatch({type: Types.UPDATE_PRICES_START, payload});
  
}
