import React, { useCallback, useMemo, useState } from "react";
import { SwitchButton } from "@etiquette-ui/selectors";
import { COLORS } from '../../utils/constants';
import { getChartData } from '../../utils';
import useI18n from "i18n/useI18n";
import useWindowDimensions from "hooks/useWindowDimensions";

import { getOptions } from "./options";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  Title,
  Tooltip,
  Legend
} from "chart.js";
import { Bar } from "react-chartjs-2";
import zoomPlugin from "chartjs-plugin-zoom";
import ChartDataLabels from "chartjs-plugin-datalabels";

import {
  Container,
  SwipeContainer,
  ChartSlider,
  Quotes,
  TypeSelector,
  LegendContainer,
  LegendLabel
} from './styles';
import { ReactComponent as StarHeader } from "resources/img/star-icon.svg";
import useStatistics from "state/statistics";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  zoomPlugin,
  ChartDataLabels
);

const BarChart = ({ dataObject, category, report, width: predefWidth, defaultPercentage = true}) => {
  const { translate } = useI18n();
  const [isPercentage, setIsPercentage] = useState(defaultPercentage);
  const [,dispatcher] = useStatistics();

  const windowDimensions = useWindowDimensions();

  const defineColor = useCallback(
    (index) => {
      return COLORS[index % COLORS.length];
    },
    [dataObject]
  );

  const defineWidth = useCallback(
    (length) => {
      if (predefWidth === "full") {
        return windowDimensions.width;
      }
      const { width } = windowDimensions;
      const paddingContainer = 50;

      if (width >= 1460) {
        return 1200;
      }

      if (width <= 720) return width - paddingContainer;

      if (length > 8) return 800;

      if (length <= 5) return 600

      return 1000;
    }, [dataObject]
  );

  const options = useMemo(() => {
    const { width } = windowDimensions;
    if (dataObject.length > 8 || width <= 720) {
      return getOptions({
        isHorizontal: true,
        isStacked: true,
        isPercentageSet: isPercentage,
        yAxis: {
          hasTextAsValue: true,
          showLabels: false,
          grid: {
            display: false,
            drawBorder: true,
            drawOnChartArea: false,
            borderWidth: 2,
          },
        },
        xAxis: {
          grid: {
            display: true,
            drawBorder: true,
            drawOnChartArea: true,
            borderWidth: 2,
          },
        }
      })
    }

    return getOptions({
      isPercentageSet: isPercentage,
      xAxis: {
        hasTextAsValue: true,
        showLabels: true,
        grid: {
          display: false,
          drawBorder: true,
          drawOnChartArea: true,
          borderWidth: 2,
        },
      },
      yAxis: {
        grid: {
          display: true,
          drawBorder: true,
          drawOnChartArea: true,
          borderWidth: 2,
        },
      }
    });

  }, [dataObject, isPercentage, windowDimensions]);

  const data = useMemo(() => {
    if (!dataObject) return null;

    const [datasets, labels] = getChartData(dataObject, defineColor, isPercentage);
    const data = {
      labels: labels,
      datasets: datasets.map((dataset, index) => ({
        data: dataset.data,
        label: dataset.label === 'rated_total' ? translate(dataset.label) : dataset.label,
        backgroundColor: dataset.color,
        borderColor: dataset.color,
        barThickness: 25,
      })),
    };

    return data;
  }, [dataObject, defineColor, isPercentage]);

  const legends = useMemo(() => {
    if (!dataObject) return null;

    const [, , legends] = getChartData(dataObject, defineColor, isPercentage);
    return legends;
  }, [dataObject, isPercentage]);

  return (
    <Container>
      <Quotes>
        <TypeSelector active={isPercentage}>
          <SwitchButton active={isPercentage} onClick={() => {
            dispatcher.setPercentage(!isPercentage);
            setIsPercentage(!isPercentage)
          }} />
          <span>{isPercentage ? translate('Percentages') : translate('Totals')}</span>
        </TypeSelector>
        <LegendContainer lenght={dataObject.length}>
          {Array.from(legends).map((legend) => (
            <LegendLabel
              key={'legend-' + legend.label}
              color={legend.color}
            >
              {isNaN(+legend.label) ? legend.label === 'rated_total' ? translate(legend.label) : translate(`${report}_${category}_${legend.label}`) : legend.label}
              {!!legend.label && !isNaN(+legend.label) && (<StarHeader />)}
            </LegendLabel>
          ))}
        </LegendContainer>
      </Quotes>

      <SwipeContainer>
        <ChartSlider>
          {!!options && !!data && (            
            <Bar 
              options={options} data={data} 
              width={ defineWidth(dataObject.length) } 
              height={dataObject.length > 8 ? (dataObject.length * 25) + 100 : 350}
            />
          )}
        </ChartSlider>
      </SwipeContainer>

    </Container>
  );
};
export default BarChart;
