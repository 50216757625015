import React, { useEffect } from 'react';
import useI18n from 'i18n/useI18n';
import useConfig from 'state/config';

import ImageUpload from 'components/ImageUpload';
import Loader from 'pages/Settings/components/Loader';
import useForm from 'pages/Settings/formState/dispatcher';
import { NAMES } from 'pages/Settings/resources/constants';
import FormTitle from 'pages/Settings/components/FormTitle';
import { ACTIONS } from 'pages/Settings/formState/formReducer';
import ToggleButton from 'pages/Settings/components/ToggleButton';
import UpdateButton from 'pages/Settings/components/UpdateButton';
import { ALERT_TYPES, useAlert } from '@etiquette-ui/alert-dialog';
import { createFormObject, getCroppedFile, castConfigData } from 'pages/Settings/formState/utils';
import { Container, Row, Subtitles, ImageCard, ImageLabel, ImageButton } from 'pages/Settings/components/styles';


import { Card } from '@etiquette-ui/misc';
import CardContainer from 'containers/CardContainer';
import ImageCropDialog from 'components/ImageCropDialog';
import { ColorInput } from '@etiquette-ui/inputs-dropdowns';

const Promotion = ({setPreviewSetting}) => {
  const NAME = NAMES.promotion;

  const alert = useAlert();
  const { translate } = useI18n();
  const [configData, configDispatcher] = useConfig();

  const [formData, formDataDispatcher] = useForm();

  const keys = {
    img: {name: 'img', type: 'imgs'},
    color: {name: 'backgroundColor', type: 'texts'},
    active: {name: 'active', type: 'texts'},
  }
  
  useEffect(()=>{
    configDispatcher.fetchStart();
  },[]);

  useEffect(()=>{
    setPreviewSetting({
      bodyType: 'home',
      showPromoModal: true,
      formData
    }); 
    return () => {
      setPreviewSetting({        
        bodyType: 'home',
        showPromoModal: false,
        formData: undefined
      }); 
    }
  },[formData]);

  useEffect(()=>{
    if(!!configData.map[NAME]){
    formDataDispatcher(
      ACTIONS.INIT, 
      castConfigData(configData.map[NAME], keys, NAME)
    );}
  },[configData]);

  const onChangeImage = (value) => {
    const name = value.file.name;
    const type = value.file.type || `image/${name.split('.').pop()}`;
    alert({
      type: ALERT_TYPES.CUSTOM,
      config: {
        body: ImageCropDialog,
        props: {
          src: value.dataURL,
          fileType: type,
          isSquareAspect: false
        },
      },
    }).then(async (croppedImage) => {
      const file = await getCroppedFile(croppedImage, name, type);
      formDataDispatcher(
        ACTIONS.ADD_IMG, 
        {
          name: keys.img.name, 
          value: {dataURL: croppedImage, file}
        }
      );
    })
  };

  const onChangeColor = (color) => {
    const value = !!color.hex ? color.hex : color;
    formDataDispatcher(ACTIONS.ADD_TEXT, {name: keys.color.name, value } );
  }

  const onChangeActive = (active) => {
    formDataDispatcher(ACTIONS.ADD_TEXT, {name: keys.active.name, value: active } );
  }

  const onSaveForm = () => {
    configDispatcher.updateStart({
      name: formData.name, 
      isPrivate: formData.isPrivate,
      ...createFormObject(formData),
    });
  }

  return (
    !!formData && formData.name === NAME && !!formData.isReadyToRender ? (<Container>
      <CardContainer>
        <Card>
          <FormTitle>{translate('Promotion Image Settings')}</FormTitle>
          <Row>
            <Card>
              <Subtitles>{translate('Image')}</Subtitles>
              <ImageCard>
                <ImageButton>
                  <ImageUpload
                    text={translate('Add Photo')}
                    altText={translate('Edit Photo')}
                    src={!!formData[keys.img.type] && !!formData[keys.img.type][keys.img.name] ? formData[keys.img.type][keys.img.name] : ''}
                    onChange={onChangeImage}
                    shape='portrait'
                  />
                </ImageButton>                
                <ImageLabel>{translate('Image must be PNG, JPEG or JPG. We recommend portrait orientation.')}</ImageLabel>
              </ImageCard>
            </Card>
          </Row>
          <Row>
            <Card>
              <Subtitles>{translate('Background color')}</Subtitles>
              <div style={{maxWidth: '350px'}}>
                <ColorInput 
                  initVal={formData[keys.color.type][keys.color.name]} 
                  onChange={onChangeColor}
                  labelProps={{
                    label: 'Color'
                  }}
                />
              </div>              
            </Card>
          </Row>
          <Row>
            <ToggleButton initState={formData[keys.active.type][keys.active.name]} onChange={onChangeActive}/>    
          </Row>
          <UpdateButton isUpdating={configData.isUpdatingState} onClick={onSaveForm} />
        </Card>
      </CardContainer>
    </Container>) : (<Loader/>)
  )
}
export default Promotion;