import React, { useEffect, useState } from 'react';
import useI18n from 'i18n/useI18n';
import { Subtitle } from '@etiquette-ui/typography';
import FormSection from 'containers/Form/FormSection';
import FormSpacer from 'components/Form/FormSpacer';
import { Dropdown } from '@etiquette-ui/inputs-dropdowns';
import useIngredients from 'state/ingredients';
import Table from 'components/Table';
import structure from './structure';
import ITEM_KEYS from '../keys';
import { mapIdsToObjects } from 'utils';
import { ACTIONS } from '../Reducer';
import GridContainer from 'containers/Form/GridContainer';
import { AddIcon } from '@etiquette-ui/icons';
import { CustomSecondaryButton } from 'components/Buttons';
import styled from 'styled-components';
import FloatingButton from '../../FloatingButton';
import FormTitle from 'pages/MenuItems/components/FormTitle';

const Button = styled(CustomSecondaryButton)`
  button {
    width: 50%;
  }
`;

const Ingredients = ({ formData, dispatch, submit, title, button }) => {
  const { translate, translateObject } = useI18n();
  const [ingredients, dispatcher] = useIngredients();
  const [list, setList] = useState([]);

  useEffect(() => {
    dispatcher.fetchStart();
  }, []);

  useEffect(() => {
    setList(mapIdsToObjects(formData[ITEM_KEYS.INGREDIENTS], ingredients.list));
  }, [formData[ITEM_KEYS.INGREDIENTS], ingredients.list]);

  const onSubmit = (e) => {
    e.preventDefault();
    submit();
  }

  return (
    <form style={{ width: '100%' }} onSubmit={onSubmit}>
      <FormTitle style={{ marginBottom: '20px', fontSize: '1.5rem' }}>{title}</FormTitle>
      <FormSection>
        <Subtitle>{translate('Ingredients')}</Subtitle>
        <FormSpacer />
        <GridContainer>
          <Dropdown
            labelProps={{
              label: translate('Search Ingredient'),
            }}
            leadingIconProps={{
              icon: 'search',
            }}
            selectProps={{
              onChange: ({ value }) => dispatch(ACTIONS.INGREDIENTS, value),
              options: ingredients.list
                .filter(({ id }) => !list.some((ing) => ing.id === id))
                .map(({ id, translations }) => ({
                  value: id,
                  label: translateObject(translations).title,
                })),
              placeholder: translate('Pick one...'),
              value: null,
            }}
          />
          <Button type="button" onClick={() => {}}>
            <AddIcon styles={{ svg: { width: '20px', height: '20px', marginRight: '8px' } }} /> {translate('Add Ingredient')}
          </Button>
        </GridContainer>
        <FormSpacer />
        <Table structure={structure} config={{ translate, alert }} items={list} />
        <FormSpacer />
        <FormSpacer />
      </FormSection>
      <FormSpacer />
      <FormSpacer />
      <FloatingButton type={'submit'}>{button}</FloatingButton>
    </form>
  );
};

export default Ingredients;
