import React, { useMemo } from 'react';
import ReactTooltip from 'react-tooltip';
import { getSubcategoriesFromItem, getSubcategoryName } from 'pages/MenuItems/utils/transformers';
import ResponsiveText from 'components/Table/responsive/Text';
import useFilters from 'state/filters';

const SubcategoryName = ({ item, globalState }) => {
  const [filters] = useFilters();
  const [selectedLang] = globalState.config.languages || [1];

  const itemCategoriesFiltered = useMemo(() => {
    const subcategories = globalState.config.subcategories;
    const cats = getSubcategoriesFromItem(subcategories, item);

    return cats.filter(({ id }) => filters.items.subcategoriesOptions.some(({ value }) => +value === +id));
  }, [globalState.config.subcategories, item.categories, filters.items.subcategoriesOptions]);

  const name = useMemo(() => {
    if (!itemCategoriesFiltered) return '';
    const translate = globalState.config.translate;

    return getSubcategoryName(itemCategoriesFiltered, translate, selectedLang, filters.items.subcategoriesOptions);
  }, [itemCategoriesFiltered, globalState.config.translate, filters.items.subcategoriesOptions]);

  const categories = useMemo(() => {
    if (!itemCategoriesFiltered) return '';
    return itemCategoriesFiltered.length > 1 ? itemCategoriesFiltered.map(({ translations }) => selectedLang === 1 ? translations.en.title : translations.es.title).join(', ') : '';
  }, [itemCategoriesFiltered]);

  return (
    <div data-tip data-for={`subcat:${item.id}`} style={{ alignItems: 'center', display: 'flex' }}>
      <ResponsiveText text={name} />
      {categories && (
        <ReactTooltip id={`subcat:${item.id}`} place="top" effect="solid">
          {categories}
        </ReactTooltip>
      )}
    </div>
  );
};

export default SubcategoryName;
