import { useAlert } from '@etiquette-ui/alert-dialog';
import Table from 'components/Table';
import useI18n from 'i18n/useI18n';
import React, { useEffect } from 'react';
import useCategories from 'state/categories';
import useProductHistory from 'state/productHistory';
import useProducts from 'state/products';
import useTags from 'state/tags';
import useUsers from 'state/users';
import structure from './structure';

const ArchivedTable = ({ list }) => {
    const { translate, selectedLang } = useI18n();
    const [products] = useProducts();
    const [categories] = useCategories();
    const alert = useAlert();
    const [, tagsDispatcher] = useTags();
    const [, productHistoryDispatcher] = useProductHistory();
    const [, usersDispatcher] = useUsers();

    useEffect(() => {
        tagsDispatcher.fetchStart();
        productHistoryDispatcher.fetchStart();
        usersDispatcher.fetchStart();
    }, []);

    return (
        <Table
            structure={structure}
            config={{
                translate,
                selectedLang,
                alert,
                fetching: products.fetching,
                categories: categories.list,
            }}
            items={list}
        />
    );
};

export default ArchivedTable;
