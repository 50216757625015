import { EditableText } from '@etiquette-ui/dynamic-table-components';
import styled from 'styled-components';

const ResponsiveEditableText = styled(EditableText)`
    font-family: unset;
    margin-left: 16px;
    width: calc(100% - 16px);
    align-self: center;
    @media (max-width: 768px) {
        font-size: 0.625rem;
    }
`;

export default ResponsiveEditableText;