const SUBCATEGORY_KEYS = {
    TRANSLATIONS: 'translations',
    PARENT_ID: 'parentId',
    IMAGE: 'img',
    POSITION: 'position',
    ACTIVE: 'active',
    SUBCATEGORIES: 'subcategories',
    CONFIG: 'config',
};

export default SUBCATEGORY_KEYS;