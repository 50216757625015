export const getData = (element, dateFormat, blacklist) => {
    const day = element.period.day;
    const start = element.period.dateInit;
    const end = element.period.dateEnd;
    const formatTitle = 'MMM. dd’yy';
    const range = !!start && !!end ? `${dateFormat(start + 'T00:00:00', formatTitle)} - ${dateFormat(end + 'T00:00:00', formatTitle)}` : '';
    const title = !!element.period.day ? dateFormat(day + 'T00:00:00', formatTitle) : range;
    const data = blacklist !== undefined ? element.data.filter(el => !blacklist[el.id]) : element.data
    const avgs = getAvgOfTable(data);

    return { title, items: data, avgs };
};

const getAvgOfTable = (table) => {

    let stars = 0;
    let starsCount = 0;

    let comments = 0;
    let commentsCount = 0;

    table.forEach(person => {
        const hours = Object.values(person.hours)

        hours.forEach(shift => {
            if (!!shift.stars) {
                stars += shift.stars;
                starsCount++;
            }

            if (!!shift.tags) {
                comments += shift.tags;
                commentsCount++;
            }
        })
    })

    const starsAvg = (starsCount > 0) ? (stars / starsCount).toFixed(1) : "";
    const commentsAvg = (commentsCount > 0) ? (comments / commentsCount).toFixed(1) : "";

    return {
        comments: commentsAvg,
        stars: starsAvg
    }
}