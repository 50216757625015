import { PRIMARY } from '@etiquette-ui/colors';
import Icon from '@etiquette-ui/icons';
import { P } from '@etiquette-ui/typography';
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const CustomLink = styled(Link)`
    text-decoration: none;
    @media (max-width: 768px) {
        flex: 1;
    }
`;

const Container = styled.div`
    display: flex;
    align-items: center;
    color: white;
    margin: 1rem 0;
    width: 100%;
    min-height: 3rem;
    font-weight: bold;
    position: relative;
    padding: 0.5rem 1rem;
    ${({ disabled }) => (disabled ? 'cursor: default; opacity: 0.2; pointer-events: none;' : '')}
    @media (max-width: 768px) {
        flex-direction: column;
        justify-content: center;
        min-height: unset;
        height: 100%;
        padding: 0;
        margin: 0;
    }
`;

const Grid = styled.div`
    display: grid;
    grid-template-columns: 1rem 2.5rem auto;
    align-items: center;
    width: 100%;
    height: 100%;
    z-index: 2;
    @media (max-width: 768px) {
        grid-template-columns: auto;
        grid-template-rows: 2fr 1fr;
    }
`;

const ActiveIndicatorBackground = styled.div`
    height: 100%;
    width: 100%;
    position: absolute;
    background-color: ${PRIMARY};
    opacity: 0.2;
    border-radius: 8px;
    @media (max-width: 768px) {
        display: none;
    }
`;

const ActiveIndicatorContainer = styled.div`
    @media (max-width: 768px) {
        display: none;
    }
`;

const ActiveIndicator = styled.div`
    width: 3px;
    min-height: 24px;
    margin-left: 4px;
    border-radius: 24px;
    background-color: ${PRIMARY};
    @media (max-width: 768px) {
        display: none;
    }
`;

const IconContainer = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    @media (max-width: 768px) {
        width: ${({$active})=> $active ? '30px' : '24px'};
        justify-self: center;
    }
`;

const CustomText = styled(P)`
  color: white;
  padding-left: 0.625rem;
  @media (max-width: 768px) {
        padding-left: 0;
        text-align: center;
        font-size: 0.75rem;
        font-weight: ${({$active})=> $active ? '600' : '400'};
    }
`;

const ImgIcon = styled.img`
    width: 27px;
    height: 27px;
    @media( min-width: 750px ) {
        width: 42px;
        height: 42px;
    }
`;

const SideNavItem = ({ iconProps, imgIcon = undefined, active = false, linkProps, disabled = false }) => {
    const { icon = 'search', iconStyles = {} } = iconProps;
    const { content, href = '', ...restLinkProps } = linkProps;
    return (
        <CustomLink to={href} {...restLinkProps}>
            <Container disabled={disabled}>
                {active && <ActiveIndicatorBackground />}
                <Grid>
                    <ActiveIndicatorContainer>{active && <ActiveIndicator />}</ActiveIndicatorContainer>
                    <IconContainer $active={active}>
                        {!!imgIcon ? (
                            <ImgIcon src={imgIcon} />
                        ) : (
                            <Icon
                                icon={icon}
                                styles={{
                                    line: {
                                        fill: 'none',
                                        strokeLinecap: 'round',
                                        strokeLinejoin: 'round',
                                        strokeWidth: '1.5px',
                                        stroke: 'white',
                                    },
                                    ...iconStyles,
                                }}
                            />
                        )}
                        
                    </IconContainer>
                    <CustomText $active={active}>{content}</CustomText>
                </Grid>
            </Container>
        </CustomLink>
    );
};

export default SideNavItem;
