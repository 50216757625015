import React from 'react';
import RowContainer from 'components/Table/RowContainer';
import SortNumber from 'components/Table/headers/SortNumber';
import SortText from 'components/Table/headers/SortText';
import ResponsiveText from './components/Text';
import { ReactComponent as StarHeader } from "resources/img/star-icon.svg";

const getStructure = (labels, isStarBased) => {
  let structure = {
    row: RowContainer,
    columns: [
      {
        flex: 2,
        header: ({ globalState, ...rest }) => <SortText
          property={'Item Name'}
          style={{ marginLeft: '16px' }}
          text={globalState.config.translate('Item Name')}
          {...rest}
        />,
        body: ({ item }) => <ResponsiveText text={item['Item Name']} style={{ marginLeft: '16px' }} />
      }
    ]
  }

  if (labels.includes('Rating')) {
    structure.columns = [...structure.columns, {
      flex: 1,
      header: ({ globalState, ...rest }) => <SortNumber
        property={'Rating'}
        style={{ marginLeft: '16px' }}
        text={globalState.config.translate('Rating')}
        {...rest}
      />,
      body: ({ item }) => <ResponsiveText text={item['Rating']} style={{ marginLeft: '16px' }} />
    }];
  }

  labels.forEach(label => {
    if (label !== 'Item Name' && label !== 'Rating' && label !== 'rated_total') {
      structure.columns = [...structure.columns, {
        flex: 1,
        header: ({ globalState, ...rest }) => <div style={{ display: "flex", alignItems: "center" }}>
          {isStarBased && <StarHeader />}
          <SortNumber
            property={label}
            text={isStarBased ? label : globalState.config.translate(`${globalState.config.report}_${globalState.config.category}_${label}`)}
            {...rest}
          />
        </div>,
        body: ({ item }) => <ResponsiveText text={item[label]} style={{ marginLeft: '16px' }} />
      }];
    }
  });

  if (labels.includes('rated_total')) {
    structure.columns = [...structure.columns, {
      flex: 1,
      header: ({ globalState, ...rest }) => <SortNumber
        property={'rated_total'}
        style={{ marginLeft: '16px' }}
        text={globalState.config.translate('rated_total')}
        {...rest}
      />,
      body: ({ item }) => <ResponsiveText text={item['rated_total']} style={{ marginLeft: '16px' }} />
    }];
  }

  return structure;
}
export default getStructure;