import ResponsiveText from 'components/Table/responsive/Text';
import React from 'react';
import useProductHistory from 'state/productHistory';
import { dateFormat } from 'utils/date';

const getDate = (i, history) => {
    const status = history.find(({productId})=> productId === i);
    return status ? dateFormat(status.when) : '';
};

const When = ({ item }) => {
    const [productHistory] = useProductHistory();

    return <ResponsiveText text={item.id ? getDate(item.id, productHistory.list) : ''} />;
};

export default When;
