import { CONFIG } from './initState';
import ITEM_KEYS from './keys';

export const toFormData = (product, categories) => {
  const categoriesMap = new Map();

  for (const cat of product.categories) {
    if (categories.some(({ id }) => +id === +cat.category)) {
      if (!categoriesMap.has(cat.category)) categoriesMap.set(cat.category, []);
      continue;
    }
    const category = categories.find(({ subcategories }) => subcategories.some(({ id }) => +id === +cat.category));
    if (!!category) {
      if (categoriesMap.has(category.id)) {
        categoriesMap.set(category.id, [...categoriesMap.get(category.id), +cat.category]);
        continue;
      }
      categoriesMap.set(category.id, [+cat.category]);
    }
  }

  const processed = {
    ...product,
    [ITEM_KEYS.STOCK_TOTAL]: product[ITEM_KEYS.STOCK_TOTAL] === null ? 0 : product[ITEM_KEYS.STOCK_TOTAL],
    [ITEM_KEYS.STOCK_ALERT]: product[ITEM_KEYS.STOCK_ALERT] === null ? 0 : product[ITEM_KEYS.STOCK_ALERT],
    [ITEM_KEYS.STOCK_PAR]: product[ITEM_KEYS.STOCK_PAR] === null ? 0 : product[ITEM_KEYS.STOCK_PAR],
    [ITEM_KEYS.CONFIG]: product[ITEM_KEYS.CONFIG] === null ? {...CONFIG()} : {...CONFIG(), ...product[ITEM_KEYS.CONFIG]},
    [ITEM_KEYS.CATEGORIES]: Array.from(categoriesMap.keys()).map((key) => ({
      category: key,
      subcategories: categoriesMap.get(key),
    })),
  };

  return processed;
};

export const toSubmitObject = (formData, oldCategories) => {

  let categories = formData[ITEM_KEYS.CATEGORIES]
    .filter(({ subcategories }) => subcategories.length === 0)
    .map(({ category }) => category);

  categories = [
    ...categories,
    ...formData[ITEM_KEYS.CATEGORIES]
      .filter(({ subcategories }) => subcategories.length > 0)
      .reduce((acc, curr) => [...acc, ...curr.subcategories], []),
  ];

  const item = {
    ...formData,
    [ITEM_KEYS.CATEGORIES]: categories.map((cat) => ({ category: cat, position: getPositionIfExists(cat, oldCategories) })),
  };

  delete item[ITEM_KEYS.PRICE];

  const image = formData[ITEM_KEYS.IMAGE];
  item[ITEM_KEYS.IMAGE] = image?.file ? image.file : image || "";

  const logo = formData[ITEM_KEYS.LOGO];
  item[ITEM_KEYS.LOGO] = logo?.file ? logo.file : logo || "";

  return item;
};

const getPositionIfExists = (catId, categories) => {
  if (!!categories && categories.some(({ category }) => +category === +catId)) {
    return categories.find(({ category }) => +category === +catId).position;
  }
  return null;
};
