import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { ReactComponent as AddIcon } from "resources/img/add.svg";
import { PRIMARY } from "@etiquette-ui/colors";

const Container = styled.div`
  position: fixed;
  bottom: 40px;
  right: 40px;
  @media (max-width: 768px) {
    bottom: 80px;
    right: 25px;
  }
  & > a {
    text-decoration: none;
    ${({disabled})=> disabled ? 'cursor: default; pointer-events: none;' : ''}
  }
`;

const ButtonArea = styled.div`
  display: flex;
  padding: 15px;
  justify-content: center;
  align-items: center;
  height: 4rem;
  width: 4rem;
  border-radius: 50%;
  background-color: ${PRIMARY};
  transition: width 150ms, border-radius 150ms;
  transition-timing-function: ease-in;
  ${({disabled})=> disabled ? 'opacity: 0.2;' : ''}
  &:hover {
    transition-timing-function: ease-out;
    justify-content: flex-start;
    width: ${({disabled})=> disabled ? '4rem' : 'auto'};
    border-radius: ${({disabled})=> disabled ? '50%' : '10px'};
    & > div {
      display: ${({disabled})=> disabled ? 'none' : 'block'};
    }
  }
  & > div {
    display: none;
  }
`;

const ChildrenArea = styled.div`
  color: white;
  overflow: hidden;
  white-space: nowrap;
`;

const FloatingActionButton = ({ to, children, disabled }) => {
  return (
    <Container disabled={disabled}>
      <Link to={disabled ? '#' : to}>
        <ButtonArea disabled={disabled}>
          <AddIcon />
          <ChildrenArea>{children}</ChildrenArea>
        </ButtonArea>
      </Link>
    </Container>
  );
};

export default FloatingActionButton;
