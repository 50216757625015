import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import useI18n from 'i18n/useI18n';
import { MENU_URL } from 'resources/constants/urls';

const BlankAnchor = styled.a`
  padding: .8rem 1.2rem;
  border-radius: 8px;
  max-width: 85%;
  margin-left: auto;
  margin-right: auto;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  color: #388CFF;
  background-color: #EEFBFF;
  box-shadow: 0px -4px 12px #03326914;
  text-decoration: none; 

  &.onSidebar {
    display: none;
  }

  @media (min-width: 768px ) {
    color: white;
    box-shadow: unset;
    margin-top: 2rem;
    border: #388CFF 1px solid;
    background-color: transparent;
    transition-duration: .5s;

    &.onSidebar {
      display: block;
    }

    &:hover {
      color: #388CFF;
      background-color: #EEFBFF;
      border-color: transparent;
    }
  }
`;

const PublicMenuLink = ({className}) => {
  const { translate } = useI18n();

  return (
    <BlankAnchor href={MENU_URL} target="_blank" className={className}>
      {translate('Open Menu Website')}
    </BlankAnchor>
  );
};

export default PublicMenuLink;
