import React from 'react';
import MainLayout from 'containers/MainLayout';
import BaseMenuTabsContainer from 'components/BaseMenuTabsContainer';
import { Route, Switch, useRouteMatch, Redirect } from 'react-router';
import Employees from './employees';
import Items from './items';
import NavButtonsProvider from './print/NavButtonsProvider';
import NavPrintButton from './print/NavPrintButton';

const routes = [
  { path: `/employees`, title: 'Employees' },
  { path: `/items`, title: 'Items' },
];

const Statistics = ({ location }) => {

  const { path } = useRouteMatch();
    
  return (
    <NavButtonsProvider>
      <MainLayout location={location}>
        <BaseMenuTabsContainer
          location={location}
          routes={routes}
          isExtendedNavbar={true}      
          navbarItems={<NavPrintButton />}
        >
          <Switch>
            <Route exact path={path}>
              <Redirect to={`${path}/employees`} />
            </Route>
            <Route path={`${path}/employees`} >
              <Employees/>
            </Route>
            <Route path={`${path}/items`} >
              <Items/>
            </Route>
          </Switch>
        </BaseMenuTabsContainer>
      </MainLayout>
    </NavButtonsProvider>
  );
};

export default Statistics;
