import { PRIMARY, SECONDARY } from '@etiquette-ui/colors';
import { Subtitle } from '@etiquette-ui/typography';
import FormSpacer from 'components/Form/FormSpacer';
import FormSection from 'containers/Form/FormSection';
import useI18n from 'i18n/useI18n';
import React, { useEffect, useState } from 'react';
import { fetchGalleries, fetchIcons } from 'resources/api';
import { ICONS_URL } from 'resources/constants/urls';
import useConfig from 'state/config';
import styled from 'styled-components';
import CATEGORY_KEYS, { CATEGORY_CONFIG_KEYS } from '../keys';
import { ACTIONS } from '../Reducer';

const Container = styled.div`
    display: flex;
    overflow: scroll;
`;

const ElementContainer = styled.div`
    padding: 8px;
    margin: 5px;
    background-color: ${({ $selected }) => ($selected ? SECONDARY : 'transparent')};
    border-radius: 8px;
    cursor: pointer;
`;

const Square = `
    display: flex;
    align-items: center;
    justify-content: center;
    height: 64px;
    width: 64px;
    border-radius: 8px;
`;

const Light = styled.div`
    ${Square}
    background-color: white;
    border: 1px solid #a3a3a3;
`;

const Dark = styled.div`
    ${Square}
    background-color: ${({ $selected }) => ($selected ? PRIMARY : '#A3A3A3')};
`;

const IconSrc = styled.img`
    width: 36px;
`;

const Element = ({ icon, selected, onClick }) => {
    const [configData] = useConfig();
    return (
        <ElementContainer onClick={onClick} $selected={selected}>
            <Light>
                <IconSrc
                    src={`${ICONS_URL}${configData?.map['selected-icon-set']?.payload['icon-set']}/${icon}`}
                    alt={'icon-light'}
                />
            </Light>
            <Dark $selected={selected}>
                <IconSrc
                    src={`${ICONS_URL}${configData?.map['selected-icon-set']?.payload['icon-set']}/dark/${icon}`}
                    alt={'icon-dark'}
                />
            </Dark>
        </ElementContainer>
    );
};

const Icon = ({ formData, dispatch }) => {
    const { translate } = useI18n();
    const [icons, setIcons] = useState({});
    const [configData, configDispatcher] = useConfig();

    useEffect(() => {
        configDispatcher.fetchStart();
    }, []);

    const asyncFetchIcons = async (gallery) => {
        const res = await fetchIcons(gallery);
        setIcons(res);
    };

    useEffect(() => {
        if (!configData?.map['selected-icon-set']?.payload['icon-set']) return;
        asyncFetchIcons(configData?.map['selected-icon-set']?.payload['icon-set']);
    }, [configData]);

    return (
        <FormSection>
            <Subtitle>{translate('Representative icon')}</Subtitle>
            <FormSpacer />
            <Container>
                {typeof icons === 'object' &&
                    Object.keys(icons).map((key) => (
                        <Element
                            key={key}
                            icon={key}
                            onClick={() =>
                                dispatch(ACTIONS.CONFIG, {
                                    key: CATEGORY_CONFIG_KEYS.ICON,
                                    value: key,
                                })
                            }
                            selected={formData[CATEGORY_KEYS.CONFIG][CATEGORY_CONFIG_KEYS.ICON]===key}
                        />
                    ))}
            </Container>
        </FormSection>
    );
};

export default Icon;
