import useI18n from 'i18n/useI18n';
import React, { useEffect, useMemo, useReducer } from 'react';
import useCategories from 'state/categories';
import CategoryForm from '../../components/CategoryForm';
import INIT_STATE from '../../components/CategoryForm/initState';
import { toSubmitObject } from '../../components/CategoryForm/processor';
import Reducer from '../../components/CategoryForm/Reducer';

const Create = ({ location }) => {
    const [, categoriesDispatcher] = useCategories();
    const [formData, formDispatcher] = useReducer(Reducer, INIT_STATE());
    const dispatch = useMemo(() => (type, payload) => formDispatcher({ type, payload }), [formDispatcher]);
    const { translate } = useI18n();

    useEffect(() => {
        categoriesDispatcher.fetchStart();
    }, []);

    const submit = (e) => {
        e.preventDefault();
        const item = toSubmitObject(formData);

        categoriesDispatcher.createStart('list', item);
    };

    return (
        <CategoryForm
            location={location}
            formData={formData}
            dispatch={dispatch}
            submit={submit}
            title={translate('New Category')}
            button={translate('Create Category')}
        />
    );
};

export default Create;
