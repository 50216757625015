import Types from './types';
export default class Dispatcher {
  constructor(dispatch) {
    this.dispatch = dispatch;
  }

  fetchStart = () => this.dispatch({ type: Types.FETCH_START, payload: {} });

  fetchOneStart = (payload) => this.dispatch({ type: Types.FETCH_ONE_START, payload });

  updateStart = (listKey, item, goBack=true, fetchAll=false) => this.dispatch({ type: Types.UPDATE_START, payload: { listKey, item, goBack, fetchAll } });

  position = (payload) => this.dispatch({ type: Types.POSITION_START, payload });

  toggleActive = (listKey, item) => this.dispatch({ type: Types.TOGGLE_ACTIVE, payload: {listKey, item} });

  createStart = (listKey, item, goBack=true) => this.dispatch({ type: Types.CREATE_START, payload: { listKey, item, goBack } });

  deleteStart = (listKey, item) => this.dispatch({ type: Types.DELETE_START, payload: { listKey, item }  });

  resetError = () => this.dispatch({ type: Types.RESET_ERROR, payload: {} });
}
