import useI18n from 'i18n/useI18n';
import React, { useMemo } from 'react';
import useUsers from 'state/users';
import { dateFormat } from 'utils/date';
import { getFullName } from 'utils/text';
import ResponsiveText from 'components/Table/responsive/Text';

const getUserName = (users, i) => {
  const user = users.find(({id})=>id===i);
  return user ? getFullName(user) : null; 
}

const WhoWhen = ({ item }) => {
  const [users] = useUsers();
  const { translate } = useI18n();

  const name = useMemo(()=>getUserName(users.all, item.who), [users, item])

  return (
    <div style={{padding: '0 5px'}}>
      <ResponsiveText text={name ? name : translate('Unknown User')} />
      <div style={{height: '10px'}}></div>
      <ResponsiveText text={dateFormat(item.when)} />
    </div>
  );
};

export default WhoWhen;
