import styled from 'styled-components';

const ResponsiveTitleContainer = styled.div`
    display: contents;

    @media( min-width: 768px ) {
        display: none;
    }
`;

export default ResponsiveTitleContainer;
