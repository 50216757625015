import { Card } from '@etiquette-ui/misc';
import styled from 'styled-components';

const FormSection = styled(Card)`
  padding: 20px 24px;

  @media( max-width: 768px ){
    padding: 5px 15px 5px 15px;
    box-shadow: unset!important;
    border-radius: 0px;
    margin-top: -1px;
  }
`;

export default FormSection;