import { ITEM_STATUS } from "utils/status";
import INIT_STATE, { CATEGORY } from "./initState";

export const ACTIONS = {
  INIT: 'init',
  ITEM_INFO: 'itemInfo',
  STATUS: 'status',
  TRANSLATIONS: 'translations',
  CATEGORIES: 'categories',
  SUBCATEGORIES: 'subcategories',
  FAMILY: 'family',
  TAGS: 'tags',
  INGREDIENTS: 'ingredients',
  CONFIG: 'config',
  NEW_VARIANT: 'new_variant',
  REMOVE_VARIANT: 'remove_variant',
  VARIANT: 'variant',
};

const Reducer = (state = INIT_STATE(), action) => {

  switch (action.type) {
    case ACTIONS.INIT: { 
      const newState = {...action.payload};
      return newState;
    }
    case ACTIONS.ITEM_INFO: {
      const newState = { ...state, ...action.payload };
      return newState;
    }
    case ACTIONS.STATUS: {
      const newState = { ...state };
      newState.status = newState.status === ITEM_STATUS.ACTIVE ? ITEM_STATUS.INACTIVE : ITEM_STATUS.ACTIVE;
      return newState;
    }
    case ACTIONS.FAMILY: {
      const newState = { ...state };
      newState.family = [action.payload];
      return newState;
    }
    case ACTIONS.CATEGORIES: {
      const newState = { ...state };
      const categories = [...newState.categories];
      const ids = categories.map(({category})=>category);

      const index = ids.indexOf(action.payload);
      if(index >= 0){
        categories.splice(index, 1);
      } else {
        categories.push(CATEGORY(action.payload));
      }

      newState.categories = categories;
      return newState;
    }
    case ACTIONS.SUBCATEGORIES: {
      const newState = { ...state };
      const categories = [...newState.categories];
      const subcategories = [...categories[action.payload.categoryIndex].subcategories];

      const index = subcategories.indexOf(action.payload.subcategory);
      if(index >= 0){
        subcategories.splice(index, 1);
      } else {
        subcategories.push(action.payload.subcategory);
      }

      categories[action.payload.categoryIndex].subcategories = subcategories;
      newState.categories = categories;
      return newState;
    }
    case ACTIONS.TAGS: {
      const newState = { ...state };
      const tags = [...newState.tags];

      const index = tags.indexOf(action.payload);
      if(index >= 0){
        tags.splice(index, 1);
      } else {
        tags.push(action.payload);
      }

      newState.tags = tags;
      return newState;
    }
    case ACTIONS.INGREDIENTS: {
      const newState = { ...state };
      const ingredients = [...newState.ingredients];

      const index = ingredients.indexOf(action.payload);
      if(index >= 0){
        ingredients.splice(index, 1);
      } else {
        ingredients.push(action.payload);
      }

      newState.ingredients = ingredients;
      return newState;
    }
    case ACTIONS.TRANSLATIONS: {
      const newState = { ...state };
      const { lang, text, value } = action.payload;

      const translations = { ...newState.translations };
      translations[lang][text] = value;

      newState.translations = translations;

      return newState;
    }
    case ACTIONS.CONFIG: {
      const newState = { ...state };
      const { key, value } = action.payload;

      const config = { ...newState.config };
      config[key] = value;

      newState.config = config;

      return newState;
    }
    case ACTIONS.NEW_VARIANT: {
      const newState = { ...state };
      const variants = [...state.variants];
      
      variants.push({
        price: 0,
        active: true,
        translations: {
          en: '',
          es: ''
        }
      });

      newState.variants = variants;

      return newState;
    }
    case ACTIONS.REMOVE_VARIANT: {
      const newState = { ...state };
      const variants = [...state.variants];
      
      variants.splice(action.payload, 1);

      newState.variants = variants;

      return newState;
    }
    case ACTIONS.VARIANT: {
      const newState = { ...state };
      const variants = [...state.variants];

      const { idx, variant } = action.payload;
      
      variants[idx] = variant;

      newState.variants = variants;

      return newState;
    }
    default:
      throw new Error(`Action of type ${action.type} not valid`);
  }
};

export default Reducer;
