import React, { useEffect } from 'react';
import { ArchivedIcon, NotFoundIcon } from '@etiquette-ui/icons';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import useI18n from 'i18n/useI18n';

const Container = styled.div`
    display: flex;
    justify-content: flex-end;
    flex: 1;
    @media (max-width: 768px) {
        display: none;

        &.isFloating{
            display: flex;
            flex-direction: column;
            justify-content: start;
            align-items: start;
            padding: 2rem .5rem;

            a {
                margin-top: 1rem;
                font-weight: 300;
            }
        }
    }
`;

const Button = styled(Link)`
    div {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 8px 0;
        margin: 0;
    }
    height: min-content;
    font-weight: 600;
    text-decoration: none;
    color: white;
    margin-left: 10px;
`;

const NavbarItems = ({ isFloating = false }) => {
    const { translate } = useI18n();

    return (
        <Container className={isFloating ? 'isFloating' : ''}>
            <Button to={`/menu/items/management-86`}>
                <div>
                    <NotFoundIcon
                        styles={{
                            svg: {
                                width: '20px',
                                height: '20px',
                                marginRight: '8px',
                            },
                            line: {
                                fill: 'none',
                                stroke: 'white',
                                strokeLinecap: 'round',
                                strokeLinejoin: 'round',
                                strokeWidth: '1.5px',
                            },
                        }}
                    />
                    {translate('86 Management')}
                </div>
            </Button>
            <div style={{ width: '10px' }}></div>
            <Button to={`/menu/items/archived`}>
                <div>
                    <ArchivedIcon
                        styles={{
                            svg: {
                                width: '20px',
                                height: '20px',
                                marginRight: '8px',
                            },
                            line: {
                                fill: 'none',
                                stroke: 'white',
                                strokeLinecap: 'round',
                                strokeLinejoin: 'round',
                                strokeWidth: '1.5px',
                            },
                        }}
                    />
                    {translate('Archived Management')}
                </div>
            </Button>
        </Container>
    );
};

export default NavbarItems;
