import { Input } from '@etiquette-ui/inputs-dropdowns';
import { PSmall } from '@etiquette-ui/typography';
import useI18n from 'i18n/useI18n';
import React from 'react';

const Reason = ({reason, setReason}) => {
    const { translate } = useI18n();

    return (
        <>
            <PSmall style={{ textAlign: 'left', marginBottom: '8px' }}>{translate("Why 86's (Not on menu)")}</PSmall>
            <Input
                labelProps={{ label: translate('Reason') }}
                iconProps={{ icon: '' }}
                inputProps={{
                    value: reason,
                    onChange: (e) => setReason(e.target.value),
                    required: true,
                }}
            />
        </>
    );
};

export default Reason;
