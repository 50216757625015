import React from 'react';
import styled from 'styled-components';
import { SearchIcon } from '@etiquette-ui/icons';
import { useSearch } from 'providers/search';

const Container = styled.div`
  display: none;

  & .searchIconLabel {
    position: absolute;
    z-index: 2;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    bottom: 0px;
    right: 0px;
  }

  @media (max-width: 768px) {
    display: block;
    margin-right: 15px;
    position: relative
  }
`;

const SearchSmall = () => {
  const { visible } = useSearch();
  return visible ? (
    <Container>
      <SearchIcon styles={{ svg: { width: '25px', height: '25px' } }} />
      <label htmlFor="searchIconToggle" className='searchIconLabel'>
      </label>
    </Container>
  ) : (
    <div></div>
  );
};

export default SearchSmall;
