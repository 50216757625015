import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Table from 'components/Table';
import structure from './structure';
import useI18n from 'i18n/useI18n';
import useInactive86 from 'state/inactive86';
import { useSearch } from 'providers/search';
import { compareStrings } from 'utils';

const TableContainer = styled.div`
    flex: 1;
`;

const History86Table = () => {
    const { translate } = useI18n();
    const [data] = useInactive86();
    const { search } = useSearch();
    const [list, setList] = useState([]);

    useEffect(() => {
        const newList = data.history.filter(
            ({ translations, why }) =>
                compareStrings(why ?? '', search) ||
                Object.values(translations).some(
                    ({ title, description }) => compareStrings(title ?? '', search) || compareStrings(description ?? '', search)
                )
        );
        setList(newList);
    }, [data.history, search]);

    return (
        <TableContainer>
            <Table
                structure={structure}
                config={{
                    translate,
                }}
                items={list}
            />
        </TableContainer>
    );
};

export default History86Table;
