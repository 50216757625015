import { PSmall } from '@etiquette-ui/typography';
import useI18n from 'i18n/useI18n';
import React from 'react';
import styled from 'styled-components';
import { Calendar as RootCalendar } from '@etiquette-ui/calendars';

const CustomizedCalendar = styled(RootCalendar)`
  .react-datepicker {
    width: 100%;
  }
`;

const Calendar = ({date, setDate}) => {

    const { translate, formatDate } = useI18n();

    return (
        <>
            <PSmall style={{ textAlign: 'left', marginBottom: '8px' }}>
                {translate('When do you think this item will be in stock again?')}
            </PSmall>
            <CustomizedCalendar
                selected={date}
                formatDate={(date) => formatDate(date, 'MMMM yyyy')}
                minDate={new Date()}
                onChange={(date) => setDate(date)}
                showMonthYearDropdown={true}
            />
        </>
    );
};

export default Calendar;
