import { ALERT_TYPES, useAlert } from '@etiquette-ui/alert-dialog';
import { INPUTS } from '@etiquette-ui/colors';
import React from 'react';
import useProducts from 'state/products';
import styled from 'styled-components';
import StatusOptions from '../../../../../alert/StatusOptions';
import Inactive86Alert from '../../../../../alert/Inactive86Alert';
import StatusButton from 'components/Buttons/StatusButton';
import useInactive86 from 'state/inactive86';
import { ITEM_STATUS } from 'utils/status';

const CustomStatus = styled(StatusButton)`
    justify-content: flex-start;
`;

const Status = ({ item, count }) => {
    const alert = useAlert();
    const [products] = useProducts();
    const [, inactive86Dispatcher] = useInactive86();

    const product = products.list.find(({ id }) => id === item.productId);

    const showAlert = () => {
        alert({
            type: ALERT_TYPES.CUSTOM,
            config: {
                body: StatusOptions,
                props: {
                    item: product,
                },
            },
        })
            .then((status) => {
                if (status === ITEM_STATUS.ACTIVE || status === ITEM_STATUS.INACTIVE || status === ITEM_STATUS.ARCHIVED) {
                    inactive86Dispatcher.updateProductStart({ id: product.id, status, stockTotal: 1 });
                    return;
                }
                alert({
                    type: ALERT_TYPES.CUSTOM,
                    config: {
                        body: Inactive86Alert,
                        props: {
                            item: product,
                        },
                    },
                })
                    .then(({ date, reason }) => {
                        inactive86Dispatcher.inactive86({ id: product.id, why: reason, estimate: date });
                    })
                    .catch(() => { });
            })
            .catch(() => { });
    };

    return product ? (
        <CustomStatus
            item={product}
            onClick={showAlert}
            loading={products.fetching.state && products.config?.id === product.id}
            spinnerBackground={count % 2 === 0 ? INPUTS : 'white'}
        />
    ) : (
        <div style={{ display: 'flex', alignItems: 'center' }}>{'-'}</div>
    );
};

export default Status;
