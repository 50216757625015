const getStandardStructure = (object, type, lang = 'en') => {
  let dataObject = [];
  let legends = new Set();
  for(const label in object){
    dataObject = [...dataObject, {
      label: getLabel(object[label], label, type, lang),
      data: {
        ...object[label].rating,
        rated_total: object[label].counter,
      },
    }];
    legends = union(legends, new Set(Object.keys(object[label].rating)));
  }
  
  const finalDataset = unifyingDatasets(dataObject, legends);
  return finalDataset.sort((a, b) => b.data.rated_total - a.data.rated_total);
}

const getLabel = (object, label, type, lang) => {
  switch(type){
    case 'hostess':
    case 'server':
      return label;
    case 'manager':
      return object.name;
    default:
      if(lang === 'en') return object.name_en;
      return object.name_es;
  }
}

const getChartData = ( data, defineColor, isPercentage = false ) => {
  let labels = [];
  let datasets = [];
  let legends = new Set();

  let datasetsObject = {};
  data.forEach( item => {
    let totalRating = 0;
    let totalCount = 0;

    for (const label in item.data) {
      if (label !== 'rated_total') {
        const value = item.data[label];
        totalRating += parseFloat(label) * value; 
        totalCount += value;
      }
    }
    const average = totalCount > 0 ? (totalRating / totalCount).toFixed(2) : 0;
    labels.push(!!average && !isNaN(average) ? `${item.label} (${average})` : item.label);

    for(const label in item.data){
      if((!isPercentage || label !== 'rated_total') && !datasetsObject[label]){
        datasetsObject[label] = [];
      }
      if(isPercentage && label !== 'rated_total') 
        datasetsObject[label].push(item.data[label] === 0 ? item.data[label] : item.data[label]);

      if(!isPercentage)
        datasetsObject[label].push(item.data[label]);
    }
  });

  const processedDatasetsObject = isPercentage ? calculatePercentagesForGroupedData(datasetsObject) : datasetsObject;

  datasets = Object.keys(processedDatasetsObject).map( (label, index) => {
    const color = defineColor(index);
    legends.add({label, color});
    return ({
      label,
      color,
      data: processedDatasetsObject[label]
    });
  });

  return ([datasets, labels, legends]);
}

const getTableData = (data, addAverage = false) => {
  let datasets = [];
  let headers = new Set();

  data.forEach( item => {
    let dataset = {};
    dataset['Item Name'] = item.label;
    headers.add('Item Name');
    
    let total = 0;
    for(const label in item.data){
      headers.add(label);

      dataset[label] = item.data[label];
      if(addAverage && item.data['rated_total'] && label !== 'rated_total'){
        total += (item.data[label] * +label) / item.data['rated_total'];
      }
    }
    if(addAverage){
      dataset['Rating'] = total > 0 ? total.toFixed(2) : total;
      headers.add('Rating');
    }

    datasets.push(dataset);
  });

  return ([Array.from(headers), datasets]);
}

const unifyingDatasets = (datasets, labels) => {
  return datasets.map(dataset => {
    const missingLabels = Array.from(labels).filter(label => !Object.keys(dataset.data).includes(label));
    
    if(missingLabels.length === 0) return dataset;

    missingLabels.forEach( label => {
      dataset.data[label] = 0;
    });

    return dataset;
  });
}

const toFixed = (num, fixed) => {
  if(!num) return '0.0';
  let re = new RegExp('^-?\\d+(?:.\\d{0,' + (fixed || -1) + '})?');
  return num.toString().match(re)[0];
}

const union = (setA, setB) => {
  let union = new Set(setA);
  for (let elem of setB) {
    union.add(elem);
  }
  return union;
}

function calculatePercentagesForGroupedData(data) {
  const keys = Object.keys(data);
  const groupCount = data[keys[0]].length;
  let result = keys.reduce((acc, key) => ({ ...acc, [key]: [] }), {});

  for (let i = 0; i < groupCount; i++) {
      let counts = keys.map(key => data[key][i]);
      let percentages = calculatePercentages(counts);

      keys.forEach((key, index) => {
          result[key].push(percentages[index]);
      });
  }

  return result;
}

function calculatePercentages(counts) {
  const total = counts.reduce((acc, val) => acc + val, 0);
  if (total === 0) return counts.map(_ => 0);
  
  let roundedPercentages = counts.map(count => Math.round(count / total * 100));
  let sumRounded = roundedPercentages.reduce((acc, val) => acc + val, 0);

  let difference = 100 - sumRounded;
  for (let i = 0; i < Math.abs(difference); i++) {
      if (difference > 0) {
          let minIndex = roundedPercentages.indexOf(Math.min(...roundedPercentages));
          roundedPercentages[minIndex]++;
      } else {
          let maxIndex = roundedPercentages.indexOf(Math.max(...roundedPercentages));
          roundedPercentages[maxIndex]--;
      }
  }

  return roundedPercentages;
}

export {
  toFixed,
  getStandardStructure,
  getChartData,
  getTableData,
}