import { all, call } from 'redux-saga/effects';
import products from './products/sagas';
import categories from './categories/sagas';
import tags from './tags/sagas';
import families from './families/sagas';
import ingredients from './ingredients/sagas';
import inactive86 from './inactive86/sagas';
import users from './users/sagas';
import config from './config/sagas';
import productHistory from './productHistory/sagas';
import pos from './pos/sagas';
import statistics from './statistics/sagas';

export default function* rootSaga() {
  yield all([
    call(products),
    call(categories),
    call(tags),
    call(families),
    call(ingredients),
    call(inactive86),
    call(users),
    call(config),
    call(productHistory),
    call(pos),    
    call(statistics),
  ]);
}
