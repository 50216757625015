import React from 'react';
import RowContainer from 'components/Table/RowContainer';
import SortText from 'components/Table/headers/SortText';
import ResponsiveHeaderText from 'components/Table/responsive/HeaderText';
import ResponsiveText from 'components/Table/responsive/Text';
import WhoWhen from './cells/WhoWhen';
import WhyExpected from './cells/WhyExpected';
import WhoRestored from './cells/WhoRestored';
import Categories from '../../../../cells/Categories';
import Tags from '../../../../cells/Tags';

const structure = {
  row: RowContainer,
  columns: [
    {
      flex: 2,
      header: ({ globalState, ...rest }) => (
        <SortText property={'translations.en.title'} text={globalState.config.translate('Menu Item')} {...rest} />
      ),
      body: ({ item }) => <ResponsiveText text={item.translations.en.title} style={{ marginLeft: '16px' }} />,
    },
    {
      flex: 2,
      header: () => <ResponsiveHeaderText text={'Category'} />,
      body:  Categories,
    },
    {
      flex: 1,
      header: () => <ResponsiveHeaderText text={'Tags'} />,
      body:  Tags,
    },
    {
      flex: 2,
      header: ({ globalState }) => <ResponsiveHeaderText text={globalState.config.translate('Who & When 86')} />,
      body: WhoWhen,
    },
    {
      flex: 3,
      header: ({ globalState }) => <ResponsiveHeaderText text={globalState.config.translate('Why 86 & Expected restock')} />,
      body: WhyExpected,
    },
    {
      flex: 2,
      header: ({ globalState }) => <ResponsiveHeaderText text={globalState.config.translate('Removed from')} />,
      body: WhoRestored,
    },
    {
      flex: 2,
      header: ({ globalState }) => <ResponsiveHeaderText text={globalState.config.translate('How long 86')} />,
      body: ({ item, globalState }) => <ResponsiveText text={`${item.howLong86} ${globalState.config.translate('days')}`} />,
    },
  ],
};

export default structure;
