import React, { useEffect, useMemo, useState } from 'react';
import useI18n from 'i18n/useI18n';
import { ITEM_STATUS } from 'utils/status';
import { SubtitleSmall } from '@etiquette-ui/typography';
import styled from 'styled-components';

const Text = styled(SubtitleSmall)`
    align-self: center;
    @media (max-width: 768px) {
        font-size: 13px;
    }
`;

const Status = ({ item }) => {
    const { translate } = useI18n();

    const status = useMemo(() => {
        const itemStatus = item.localStatus;
        if (itemStatus === ITEM_STATUS.ACTIVE) {
            return 'Active';
        }
        if (itemStatus === ITEM_STATUS.INACTIVE) {
            return 'Inactive';
        }
        if (itemStatus === ITEM_STATUS.ARCHIVED) {
            return 'Archived';
        }
        if (itemStatus === ITEM_STATUS.INACTIVE86) {
            return "86's no stock";
        }
        return '';
    }, [item.localStatus]);

    return (
      <Text>{translate(status)}</Text>
    );
};

export default Status;
