import styled from 'styled-components';

const ListLayout = styled.div`
  flex: 1;

  margin-top:15px;

  height: 100%;
  width: 100%;
  position: relative;

  display: grid;
  grid-gap: 16px;
  grid-template-rows: 57px minmax(0, 1fr);
`;

export default ListLayout;
