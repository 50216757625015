import styled from "styled-components";

const VirtualModalContainer = styled.div`
  min-height: 200px;
  min-width: 200px;
  overflow: hidden;
  position: absolute;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
  border-radius: 19px;
`;

const Background = styled.div`
  z-index: 1;
  top: -100px;
  left: -100px;
  bottom: -100px;
  right: -100px;
  position: absolute;
  background: rgba(0,0,0,.8);
  &:hover {
    cursor: pointer;
  }
`;

const VirtualModal = styled.div`
  width: ${({modalWidth}) => !!modalWidth ? modalWidth : '90%'};
  max-height: 600px;
  height: fit-content;
  background-color: ${({promoBg}) => !!promoBg ? promoBg : 'transparent'};
  position: relative;
  z-index: 2;
  margin: auto;
  margin-top: 15%;

  &:before {
    top: -25px;
    right: 0px;
    color: white;
    content: '\\00d7';
    position: absolute;
    font-size: 25px;
    pointer-events: none;
  }

  img {
    width: 100%;
    height: auto;
    border-radius: 20px;
  }
`;

export {
  Background,
  VirtualModal,
  VirtualModalContainer
}
