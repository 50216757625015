const TYPE = `[PRODUCTS]`;

const TYPES = {
    FETCH_START: `${TYPE} FETCH START`,
    FETCH_SUCCESS: `${TYPE} FETCH SUCCESS`,
    FETCH_ERROR: `${TYPE} FETCH ERROR`,

    FETCH_ONE_START: `${TYPE} FETCH ONE START`,
    FETCH_ONE_SUCCESS: `${TYPE} FETCH ONE SUCCESS`,

    UPDATE_START: `${TYPE} UPDATE START`,
    UPDATE_SUCCESS: `${TYPE} UPDATE SUCCESS`,
    UPDATE_ERROR: `${TYPE} UPDATE ERROR`,

    POSITION_START: `${TYPE} POSITION START`,
    POSITION_SUCCESS: `${TYPE} POSITION SUCCESS`,
    POSITION_ERROR: `${TYPE} POSITION ERROR`,

    DELETE_START: `${TYPE} DELETE START`,
    DELETE_SUCCESS: `${TYPE} DELETE SUCCESS`,
    DELETE_ERROR: `${TYPE} DELETE ERROR`,

    CREATE_START: `${TYPE} CREATE START`,
    CREATE_SUCCESS: `${TYPE} CREATE SUCCESS`,
    CREATE_ERROR: `${TYPE} CREATE ERROR`,

    UPDATE_POSITION_START : `${TYPE} UPDATE POSITION START`,
    UPDATE_POSITION_SUCCESS : `${TYPE} UPDATE POSITION SUCCESS`,
    UPDATE_POSITION_ERROR : `${TYPE} UPDATE POSITION ERROR`,

    UPDATE_PRICES_START : `${TYPE} UPDATE PRICES START`,
    UPDATE_PRICES_SUCCESS : `${TYPE} UPDATE PRICES SUCCESS`,
    UPDATE_PRICES_ERROR : `${TYPE} UPDATE PRICES ERROR`,

    INACTIVE_86 : `${TYPE} INACTIVE 86`,

    RESET_ERROR: `${TYPE} RESET ERROR`,
};

export default TYPES;