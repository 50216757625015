
import React from 'react';
import { SecondaryButton } from '@etiquette-ui/buttons';
import styled from 'styled-components';
import useI18n from 'i18n/useI18n';
import { ArrangeIcon } from '@etiquette-ui/icons';

const ArrangeButton = ({ ...props }) => {
    const { translate } = useI18n();

    return (
        <BaseButton {...props}>
            <ArrangeIcon styles={{
                svg: {
                    width: '20px',
                    height: '20px',
                    marginRight: '8px'
                }
            }} /> {translate('Arrange')}
        </BaseButton>
    )
}

export const BaseButton = styled(SecondaryButton)`
  button {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: unset;
    margin: unset;
    height: 100%;
    max-height: 48px;
    padding: 0 20px;
    min-width: 180px;
    font-weight: 600;
  }
`;

export default ArrangeButton;