import styled from "styled-components";

const FullPageContainer = styled.div`
    width: 100%;
    max-width: 100%;
    height: 100%;
    max-height: 100%;
    overflow: scroll;
    display: flex;
    justify-content: center;
    padding-top: 16px;
`;

export default FullPageContainer;