import React from 'react';
import styled from 'styled-components';
import Dialog from './Dialog';
import { ALERT_TYPES, useAlert } from '@etiquette-ui/alert-dialog';
import { SecondaryButton } from '@etiquette-ui/buttons';
import useI18n from 'i18n/useI18n';
import Icon from '@etiquette-ui/icons';
import { PRIMARY_DARK } from '@etiquette-ui/colors';

const Title = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;  
`;

const PreviewButton = ( props ) => {
  const alert = useAlert();
  const {translate} = useI18n();

  const onClick = () => {
    alert({
      type: ALERT_TYPES.CUSTOM,
      config: {
        body: Dialog,
        props: {
          ...props,
        }          
      },
    })
    .then(() => {})
    .catch(() => {});
  }

  
  return (
    <SecondaryButton onClick={onClick}>
      <Title>
        <Icon
          icon='show-pass'
          styles={{
            svg: {
              width: '20px',
              height: '20px',
              marginRight: '5px',
            },
            line: {
              fill: 'none',
              strokeLinecap: 'round',
              strokeLinejoin: 'round',
              strokeWidth: '2.5px',
              stroke: PRIMARY_DARK,
            },
          }}
        />
        {translate('Preview')}
      </Title>
    </SecondaryButton>
  )
}
export default PreviewButton;
 