import { takeLatest, put, all, call } from 'redux-saga/effects';
import { configAPI } from 'resources/api';
import { processError } from 'state/utils';
import { toFormData } from 'utils';
import Types from './types';

function* fetchStartAsync() {
    try {
        const res = yield configAPI.getAll();
        yield put({ type: Types.FETCH_SUCCESS, payload: res });
    } catch (error) {
        const message = processError(error);
        console.error(message);
        yield put({ type: Types.FETCH_ERROR, payload: message });
    }
}

function* fetchStart() {
  yield takeLatest(Types.FETCH_START, fetchStartAsync);
}

function* updateConfigAsync({ payload }) {
    try {
        if(Object.keys(payload.payload).length > 0 || !!payload.hasImages){
            yield configAPI.update(toFormData(payload));
            yield put({ type: Types.UPDATE_SUCCESS, payload });
        }  else throw "Payload must be not empty";      
    } catch (error) {
        const message = processError(error) ?? error;
        console.error(message);
        yield put({ type: Types.UPDATE_ERROR, payload: message });
    }
}

function* updateConfig() {
    yield takeLatest(Types.UPDATE_START, updateConfigAsync);
}

export default function* sagas() {
    yield all([
        call(fetchStart),
        call(updateConfig),
    ]);
}
