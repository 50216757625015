import React, { useState } from 'react';

const SearchContext = React.createContext();

const SearchProvider = ({ children }) => {
  const [visible, setVisible] = useState(false);
  const [search, setSearch] = useState('');

  return <SearchContext.Provider value={{search, setSearch, visible, setVisible}}>{children}</SearchContext.Provider>;
};

export const useSearch = () => React.useContext(SearchContext);

export default SearchProvider;