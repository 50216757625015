import React, {useMemo} from 'react';
import styled from 'styled-components';
import { Input } from '@etiquette-ui/inputs-dropdowns';
import { RadioButton } from '@etiquette-ui/selectors';
import useI18n from 'i18n/useI18n';
import { CategoryDropdown } from './CategoryDropdown';

const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 10px;
`;

const RadioItem = styled.div`
  display: flex;
  max-width: 200px;
  min-width: 150px;
  padding-right: 15px;
`;

const Label = styled.div`
  font: normal normal normal 14px/18px Montserrat;
`;

const InputContainer = styled.div`
  width: 100%;
`;

const LinkItem = ({value  = '', checkValue = false, onChange}) => {
  const { translate } = useI18n();

  const linkDestination = useMemo(() => {
      return ({url: value, isSectionOnMenu: checkValue});
  },[value,checkValue]);

  const inputChange = (value) => {
    const destination = linkDestination;
    destination.url = value.target.value;
    if(!!onChange) onChange(destination);
  } 

  const radioChange = (value) => {
    const destination = linkDestination;
    destination.isSectionOnMenu = value;
    if(!!onChange) onChange(destination);
  } 
  
  const dropdownChange = (value) => {
    const destination = linkDestination;
    destination.url = value;
    if(!!onChange) onChange(destination);
  }

  return (
    <div>
      <Row>
        <RadioItem onClick={()=>radioChange(false)}>
          <RadioButton checked={!linkDestination.isSectionOnMenu}/>
          <Label>{translate('URL')}</Label>
        </RadioItem>
        <RadioItem onClick={()=>radioChange(true)}>
          <RadioButton checked={linkDestination.isSectionOnMenu}/>
          <Label>{translate('Section on Menu')}</Label>
        </RadioItem>
      </Row>
      <Row>
        <InputContainer>
          {linkDestination.isSectionOnMenu ? (
            <CategoryDropdown onChange={dropdownChange} value={linkDestination.url} />
          ) : (
            <Input
              iconProps={{
                icon:'link'
              }}
              labelProps={{
                label: translate('URL')
              }}
              inputProps={{
                value: linkDestination.url,
                onChange: inputChange
              }}
            />
          )}
          
        </InputContainer>        
      </Row>
    </div>
  )
}
export default LinkItem;
