import React from 'react';
import styled from 'styled-components';
import useI18n from 'i18n/useI18n';
import { SearchIcon } from '@etiquette-ui/icons';
import { useSearch } from 'providers/search';
import { INPUTS } from '@etiquette-ui/colors';

const InternalContainer = styled.div`
  display: flex;
  width: 75%;
  height: 48px;
  border-radius: 8px;
  background-color: ${INPUTS};
  align-items: center;
  padding: 15px 22px;
  @media (max-width: 992px) {
    width: 100%;
  }
`;

const InputAlternative = styled.input`
  flex: 1;
  font-weight: 500;
  background-color: transparent;
  border: 0px;
  padding: 0px;
  font-size: 0.875rem;
  outline: none;
`;



const Search = () => {
  const { translate } = useI18n();
  const { search, setSearch, visible } = useSearch();
  return visible ? (
    <InternalContainer>
      <InputAlternative
        type="text"
        placeholder={translate('Search')}
        value={search}
        onChange={(e) => setSearch(e.target.value)}
      />
      <SearchIcon
        styles={{
          svg: { width: '28px' },
          line: {
            fill: 'none',
            strokeLinecap: 'round',
            strokeLinejoin: 'round',
            strokeWidth: '1.5px',
            stroke: 'black',
          },
        }}
      />
    </InternalContainer>
  ) : (
    <div></div>
  );
};

export default Search;
