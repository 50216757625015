import React, { useEffect, useState } from 'react';
import { useRouteMatch } from 'react-router';
import BaseMenuTabsContainer from 'components/BaseMenuTabsContainer';

const routes = () => [
  { path: `/items`, title: 'Items' },
  { path: `/categories`, title: 'Categories' },
  { path: `/subcategories`, title: 'Subcategories' },
];


const MenuTabsContainer = ({ location, fabLink, fabText, fabDisabled = false, children, navbarItems, isExtendedNavbar = false }) => {
  const { url } = useRouteMatch();
  const [path, setPath] = useState('');

  useEffect(() => {
    const urlArray = url.split('/');
    urlArray.pop();
    setPath(urlArray.join('/'));
  }, []);

  return (
    <BaseMenuTabsContainer 
      routes={routes()}
      customPath={path}
      location={location}
      fabLink={fabLink}
      fabText={fabText}
      fabDisabled={fabDisabled}
      children={children}
      navbarItems={navbarItems}
      isExtendedNavbar={isExtendedNavbar}
    />
  );
};

export default MenuTabsContainer;
