import React, {useMemo} from 'react';
import { MultiSelector } from '../pbi/components/Selects';
import styled from 'styled-components';
import useI18n from 'i18n/useI18n';
import { STATISTICS_DATE_LIMIT } from 'resources/constants/config';

const Container = styled.div`
  width: 100%;
  background: #ededed;
  border-radius: 8px;

  display: flex;
`;

const Item = styled.div`
  min-width: 160px;
  max-width: 165px;
`;

export const CustomDateOptions = ({labels, selectedValues, setSelectedValues}) => {
  const { translate, selectedLang } = useI18n();

  const [yearsLabel, monthsLabel] = labels;
  const [selectedYears, selectedMonths] = selectedValues;
  const [setSelectedYears, setSelectedMonths] = setSelectedValues;

  const initYear = new Date(STATISTICS_DATE_LIMIT).getFullYear();
  
  const years = useMemo(()=>{
    let options = [{
      value: '-1', 
      label: translate('All options'),
      realValue: ''
    }];
    const limit = new Date().getUTCFullYear();
    for(let year = initYear; year <= limit; year++){
      options = [...options, {value: `${year}`, label: `${year}`}];
      options[0].realValue += `${year}${year < limit ? ',' : ''}`;
    }
    return options;
  },[]);  

  const months = useMemo(()=>{
    let options = [{
      value: '-1', 
      label: translate('All options'),
      realValue: ''
    }];
    for(let month = 0; month < 12; month++){
      const nameMonth = new Date(initYear,month,1).toLocaleString(selectedLang === 0 ? 'es' : 'en-us',{month:'long'});
      const value = `${month<9 ? '0'+(month+1) : month+1}`;
      options = [...options, {value, label: `${nameMonth}`}];
      options[0].realValue += `${value}${month+1 < 12 ? ',' : ''}`;
    }
    return options;
  }, []);

  const getPlaceholder = ( options ) => {
    if(options.length === 0) return translate('Options');
    return options.map((option) => option.label).join(' | ');
  }

  const onSelectOption = (selectedOptions, setSelectedOptions, initVal = null) => (selected) => {
    if (selected.value === '-1') {
      setSelectedOptions([selected]);
      return;
    }

    let newOptions = [...selectedOptions];
    if (selectedOptions.length === 1 && selectedOptions[0].value === '-1') {
      newOptions = [];
    }
    const option = newOptions.find(({ value }) => value === selected.value);
    const index = option ? newOptions.indexOf(option) : -1;
    if (index === -1) {
      newOptions.push(selected);
    } else {
      newOptions.splice(index, 1);
    }

    if(newOptions.length === 0 && !!initVal) newOptions = [initVal]
    
    setSelectedOptions(newOptions);
  }; 

  return (
    <Container>
      <Item>
        <MultiSelector
          labelProps={{ label: yearsLabel }}
          selectProps={{
              onChange: onSelectOption(selectedYears, setSelectedYears, years[0]),
              options: years,
              placeholder: getPlaceholder(selectedYears),
          }}
          selectedValues={selectedYears}
        />
      </Item>
      <Item>
        <MultiSelector
          labelProps={{ label: monthsLabel }}
          selectProps={{
              onChange: onSelectOption(selectedMonths, setSelectedMonths, months[0]),
              options: months,
              placeholder: getPlaceholder(selectedMonths),
          }}
          selectedValues={selectedMonths}
        />
      </Item>
      
    </Container>
  )
}
