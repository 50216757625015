import styled from "styled-components";

const CustomGridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(${({columns}) => (columns)}, 1fr);
  column-gap: 30px;
  align-items: flex-end;
  width: ${({width}) => (width || '100%')};

  @media( max-width: 768px ){
    grid-template-columns: 1fr;
    grid-gap: 15px;
  }
`;

export default CustomGridContainer;