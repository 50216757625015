const categories = [
  {
    label: 'food',
    subcategories: [
      'breakfast',
      'salads',
      'starters',
      'desserts',
      'sandwiches',
      'soups',
      'sushi',
    ]
  },
  {
    label: 'mixology',
    subcategories:['mixology']
  },
  {
    label: 'server',
    subcategories:[
      'stars',
      'speed',
      'delivery',
      'questions',
      'interruptions',
      'friendly',
      'attitude',
      'temperature',
    ]
  },
  {
    label: 'hostess',
    subcategories:[
      'stars',
      'communication',
      'friendly',
      'reservations',
      'smiling',
      'courtesy',
    ]
  },
  {
    label: 'manager',
    subcategories:[
      'stars',
      'smiling',
      'friendly',
      'helpful',
      'perfection',
      'checked',
    ]
  },
];

const COLORS = ['#FD7090', '#FDA855', '#FEE76B', '#B9E9AA', '#5BC7C6', '#388cff66', '#388cff33'];

export {categories, COLORS};