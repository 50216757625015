import React, {useMemo} from 'react';
import Table from 'components/Table';
import { getTableData } from '../../utils';
import getStructure from './structure';
import useI18n from 'i18n/useI18n';
import styled from 'styled-components';

const Container = styled.div`
  width: 100%;

  overflow-x: scroll;

  margin-top: 50px;

  scrollbar-width: none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const SwipeItem = styled.div`
  width: ${props => props.width === "full" ? '100%' : 'fit-content'};
  height: fit-content;
`;

const Assessments = ({ dataObject, category, report, reference, width }) => {
  const { translate } = useI18n();
  const isStarBased = report === 'food' || report === 'mixology' || category === 'stars' ? true : false

  const [headers, data] = useMemo(() => {
    if (!dataObject) return [null, null];

    const [headers, datasets] = getTableData(dataObject, isStarBased);

    return [headers, datasets];
  }, [dataObject, report, category]);

  const structure = useMemo(() => {
    if (!headers) return null;

    return getStructure(headers, isStarBased);
  }, [headers]);

  return (
    <Container>
      <SwipeItem ref={reference} width={width}>
        {!!data && !!structure && (<Table items={data} structure={structure} config={{ translate, category, report }} style={{minWidth: '500px'}} />)}
      </SwipeItem>    
    </Container>
  )
}
export default Assessments;
