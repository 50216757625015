import { toFixed } from "../../utils";

export const getOptions = ({
  isHorizontal = false,
  isStacked = false,
  isPercentageSet = false,
  xAxis = { hasTextAsValue: false, showLabels: true },
  yAxis = { hasTextAsValue: false, showLabels: true }
}) => {
  const options = {
    responsive: true,
    indexAxis: isHorizontal ? "y" : "x",
    scales: {
      x: {
        type: isHorizontal ? "linear" : "category",
        grid: xAxis.grid,
        stacked: isStacked,
        ticks:
          xAxis.hasTextAsValue
            ? {font: {
                size: 11  
              }}
            : isPercentageSet && isHorizontal 
              ? {
                callback: function (val) {
                  return `${val}%`;
                },
                display: true
              }:{
                callback: function (val) {
                  if (typeof val === "string") return val;
                  return formattNumber(val);
                },
                display: xAxis.showLabels,
              },
        bounds: "ticks",
      },
      y: {
        stacked: isStacked,
        ticks: yAxis.hasTextAsValue
          ? {font: {
            size: 10
          }}
          : isPercentageSet && !isHorizontal 
            ? {
              callback: function (val) {
                return `${val}%`;
              },
              display: true
            } :{
              callback: function (val) {
                if (typeof val === "string") return val;
                return formattNumber(val);
              },
              display: yAxis.showLabels,
            },
        min: yAxis.min,
        max: yAxis.max,
        grid: yAxis.grid
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
      zoom: {
        pan: {
          enabled: true,
          mode: "xy",
          threshold: 1,
        },
        zoom: {
          wheel: {
            enabled: false,
          },
          pinch: {
            enabled: true,
          },
          mode: "xy",
        },
      },
      datalabels: {
        display: true,
        anchor: "center",
        align: "center",
        color: "#454546",
        offset: -9,
        font: { size: 12 },
        formatter: (value) => {
          if (isPercentageSet && value != 0) {
            return `${Math.round(value)}%`;
          }
          if(value === 0 || value === '0' || value == 0) return '';

          return formattNumber(value);
        },
      },
      tooltip: {
        body: ( value )=>{
          if (isPercentageSet) {
            return `${Math.round(value)}%`;
          }
          return value;
        }
      }
    },
  };

  options.scales.x.ticks.font = {
    ...options.scales.x.ticks.font,
    weight: 'bold',
  }
  options.scales.y.ticks.font = {
    ...options.scales.y.ticks.font,
    weight: 'bold',
  }

  return options;
};

const formattNumber = (number) => {
  if(typeof number === 'string')
    return number;
  if(Math.abs(number)>=1000000){
    return `${toFixed(number/1000000, 1)}M`
  } else if(Math.abs(number)>1000){
    return `${toFixed(number/1000, 1)}K`
  } else {
    return toFixed(number, 1);
  }
}