import React from 'react';
import useI18n from 'i18n/useI18n';
import styled from 'styled-components';
import { PrimaryButton, SecondaryButton } from '@etiquette-ui/buttons';
import { ALERT_TYPES, useAlert } from '@etiquette-ui/alert-dialog';
import ConfirmNewPrices from '../alert/ConfirmNewPrices';

const Container = styled.div`
  gap: 15px;
  display: flex;
  margin-top: auto;

  position: fixed;
  bottom: 80px;
  left: 25px;
  height: 48px;
  z-index: 5;

  button {
    width: 90px;
  }

  @media (min-width: 769px) {
    position: relative;
    bottom: auto;
    left: auto;
    height: auto;
  };
`;

const EditPriceButtons = ({newPrices, onClean, onSave}) => {
  const { translate } = useI18n();
  const alert = useAlert();

  const onClick = () => {
    alert({
      type: ALERT_TYPES.CUSTOM,
      config: {
        props: {
          data: newPrices,
          onClean,
          onSave
        },
        body: ConfirmNewPrices,
      },
    }).then(()=>{
      onSave();
    })
    .catch(()=>{
      onClean();
    });
  }

  return (
    <Container>
      <SecondaryButton onClick={onClean}>{translate('Cancel')}</SecondaryButton>
      <PrimaryButton onClick={onClick}>{translate('Save')}</PrimaryButton>
    </Container>
  )
}
export default EditPriceButtons;
