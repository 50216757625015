import React from 'react';
import CustomImage from 'components/Table/cells/CustomImage';
import { IMAGE_SHAPES } from '@etiquette-ui/dynamic-table-components';
import { ICONS_URL } from 'resources/constants/urls';
import useConfig from 'state/config';
import { Spinner } from '@etiquette-ui/misc';
import { INPUTS, PRIMARY } from '@etiquette-ui/colors';

const Icon = ({ item, count }) => {
    const [configData] = useConfig();

    const iconUrl = (configData?.map['selected-icon-set']?.payload['icon-set'] ?? '') + '/' + (item.config?.icon ?? '');

    return configData.fetching.state ? (
        <Spinner
            style={{
                '--color': PRIMARY,
                '--bg': count % 2 === 0 ? INPUTS : 'white',
                marginRight: '10px',
            }}
        />
    ) : (
        <CustomImage prefix={ICONS_URL} image={iconUrl} shape={IMAGE_SHAPES.RECTANGLE} />
    );
};

export default Icon;
