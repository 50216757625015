
import styled from "styled-components";

const Container = styled.div`
  height: fit-content;
  width: 100%;
  height: fit-content;
`;

const SwipeContainer = styled.div`
  width: 100%;
  height: fit-content;
  overflow-x: scroll;
`;

const ChartSlider = styled.div`
  width: fit-content;
  height: fit-content;
`;

const Quotes = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;

const TypeSelector = styled.div`
  max-width: 20%;
  flex: 0 0 20%;
  min-width: 150px;

  display: flex;
  align-items: center;

  > div {
    margin-top: 5px;
    margin-bottom: 5px;
    --color: ${({active}) => active ? '' : '#388CFF!important'}
  }

  span {
    font-size: 14px; 
    padding-left: 8px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  

  @media( max-width: 768px ){
    order: 2;
  }
`;

const LegendContainer = styled.div`
  position: relative;
  max-width: 100%;
  min-width: 300px;
  flex: 1 0 0;

  padding-top: 15px;
  padding-bottom: 8px;
  padding-left: 10px;

  display: flex;
  align-items: start;
  flex-wrap: wrap;

  overflow-x: scroll;

  scrollbar-width: none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const LegendLabel = styled.div`
  position: relative;
  padding-left: 20px;
  margin-right: 15px;
  display: flex;
  align-items: center;
  
  &:before {
    content: '';
    width: 15px;
    height: 15px;
    position: absolute;
    top: 2px;
    left: 0px;
    background-color: ${({color}) => color ? color : 'white'};
  }
`;

export {
  Container,
  SwipeContainer,
  ChartSlider,
  Quotes,
  TypeSelector,
  LegendContainer,
  LegendLabel
}